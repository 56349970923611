import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonContent, IonHeader, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourImageComponent,
  ParkourLoadingSpinnerComponent,
  ParkourModalService,
} from '@parkour/ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../shared/components/page-layout/page-layout.component';
import { NavigationStart, Router } from '@angular/router';
import { concat, filter, Subscription } from 'rxjs';
import { UserService } from '../../user/service/user.service';

@Component({
  standalone: true,
  templateUrl: './acm-idm-login-modal.component.html',
  imports: [
    ParkourImageComponent,
    AsyncPipe,
    NgIf,
    ParkourDividerComponent,
    ParkourLoadingSpinnerComponent,
    ParkourButtonComponent,
    TranslateModule,
    PageLayoutComponent,
    IonHeader,
    IonContent,
    IonTitle,
    IonToolbar,
  ],
})
export class AcmIdmLoginModalComponent implements OnInit, OnDestroy {
  @Input({ required: true }) aanmeldenClickCallback!: () => void;
  eventsSubscription?: Subscription;

  constructor(
    private modalService: ParkourModalService,
    private userService: UserService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.eventsSubscription = concat(
      this.router.events.pipe(filter((e) => e instanceof NavigationStart)),
      this.userService.getCurrentUser$().pipe(filter((user) => user.isIngelogd())),
    ).subscribe(() => this.modalService.closeTopModal());
  }

  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
  }

  onAanmeldenClicked() {
    this.aanmeldenClickCallback();
  }

  onCancelClicked() {
    this.modalService.closeTopModal();
  }
}
