import { Routes } from '@angular/router';
import { DoelenTeamlidPage } from './page/doelen-teamlid/doelen-teamlid.page';
import { DoelSuggestPage } from './page/doel-suggest/doel-suggest.page';
import { dirtyFormGuard } from '../../shared/components/dirty-form-guard';
import { TeamlidDoelDetailPage } from './page/doel-detail-teamlid/teamlid-doel-detail.page';
import { BerichtProbleemAddPage } from '../../bericht/page/bericht-probleem-add-page/bericht-probleem-add.page';
import { NotitieAddPage } from '../../profiel/page/notitie-add-page/notitie-add.page';
import { artikelOverviewRoutes, specificArtikelRoute } from '../../watwat/routes';

export const doelTeamlidRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DoelenTeamlidPage,
  },
  {
    path: 'suggereer',
    component: DoelSuggestPage,
    canDeactivate: [dirtyFormGuard],
    title: 'doelen.header.suggestie',
  },
  {
    path: ':id',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: TeamlidDoelDetailPage,
      },
      artikelOverviewRoutes,
      specificArtikelRoute,
      {
        path: 'probleem',
        component: BerichtProbleemAddPage,
        canDeactivate: [dirtyFormGuard],
      },
    ],
  },
  {
    path: ':id/notitie',
    component: NotitieAddPage,
  },
];
