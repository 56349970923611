import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../../user/service/user.service';
import { Subscription } from 'rxjs';
import { User, UserWithProfiel } from '../../user/model/user';

@Directive({
  selector: '[parkourIsUserWithProfiel]',
  standalone: true,
})
export class IsUserWithProfielDirective implements OnInit, OnDestroy {
  hasView = false;
  private subscription?: Subscription;

  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private readonly userService: UserService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.userService
      .getCurrentUser$()
      .subscribe((user) => this.onContextTypeChanged(user));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private onContextTypeChanged(user: User) {
    if (user instanceof UserWithProfiel && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!(user instanceof UserWithProfiel) && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
