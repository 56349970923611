import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule } from '@angular/forms';
import {
  NaamFormGroup,
  ProfileDetailsProfielnaamEditComponent,
} from '../../../profiel/component/profile-details-profielnaam-edit/profile-details-profielnaam-edit.component';
import {
  ContactFormGroup,
  ProfileDetailsContactEditComponent,
} from '../../../profiel/component/profile-details-contact-edit/profile-details-contact-edit.component';
import {
  OverJezelfFormGroup,
  ProfileDetailsOverJezelfEditComponent,
} from '../../../profiel/component/profile-details-over-jezelf-edit/profile-details-over-jezelf-edit.component';
import { ProfielFormService } from '../../service/profiel-form.service';
import { MyProfielUpsertDto } from 'parkour-web-app-dto';
import {
  ProfielDetailsFotoEditComponent,
  ProfielFotoFormGroup,
} from '../../../profiel/component/profiel-details-foto-edit/profiel-details-foto-edit.component';
import { ProfielFotoService } from '../../../profiel/service/profiel-foto.service';
import { AfwezigComponent, AfwezigFormGroup } from '../afwezig/afwezig.component';
import { ParkourButtonComponent, ParkourIconComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe, NgIf } from '@angular/common';
import { MyProfiel } from '../../model/profiel';

@Component({
  standalone: true,
  selector: 'parkour-profile-details-edit',
  templateUrl: './profiel-details-edit.component.html',
  imports: [
    ProfielDetailsFotoEditComponent,
    FormsModule,
    ParkourIconComponent,
    TranslateModule,
    ParkourButtonComponent,
    NgIf,
    DatePipe,
    ProfileDetailsProfielnaamEditComponent,
    ProfileDetailsOverJezelfEditComponent,
    ProfileDetailsContactEditComponent,
    AfwezigComponent,
  ],
})
export class ProfielDetailsEditComponent implements OnInit {
  @Input({ required: true }) profiel!: MyProfiel;
  @Input({ required: true }) formId!: string;
  @Output() formSubmit = new EventEmitter<{
    upsert: MyProfielUpsertDto;
    profielFotoFile: File | undefined;
    profielFotoChanged: boolean;
  }>();
  @Output() verwijderingStopzettenClick = new EventEmitter<void>();

  profielForm!: FormGroup<{
    afwezigData: AfwezigFormGroup;
    naamData: NaamFormGroup;
    fotoData: ProfielFotoFormGroup;
    contactData: ContactFormGroup;
    overJezelfData: OverJezelfFormGroup;
  }>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly profielFormService: ProfielFormService,
    private readonly profielFotoService: ProfielFotoService,
  ) {}

  ngOnInit(): void {
    this.profielForm = this.fb.nonNullable.group({
      afwezigData: this.profielFormService.createAfwezigFormGroup({ ...this.profiel.afwezigheid }),
      naamData: this.profielFormService.createProfielNaamFormGroup(this.profiel),
      fotoData: this.profielFormService.createProfielFotoFormGroup({
        avatar: this.profiel.avatar,
        profielFoto: this.profiel.hasProfielFoto
          ? this.profielFotoService.getProfielFotoUrl(
              this.profiel.id,
              this.profiel.profielFotoId,
              'full',
            )
          : undefined,
        profielFotoFile: undefined,
      }),
      contactData: this.profielFormService.createContactFormGroup({ ...this.profiel }),
      overJezelfData: this.profielFormService.createOverJezelfFormGroup({ ...this.profiel }),
    });
  }

  onSubmit() {
    this.profielForm.markAllAsTouched();

    if (this.profielForm.valid) {
      this.formSubmit.emit({
        upsert: {
          afwezigheid: {
            ...this.profielForm.getRawValue().afwezigData,
            van: this.profielForm.getRawValue().afwezigData.van
              ? new Date(`${this.profielForm.getRawValue().afwezigData.van}`).toISOString()
              : '',
            tot: this.profielForm.getRawValue().afwezigData.tot
              ? new Date(`${this.profielForm.getRawValue().afwezigData.tot}`).toISOString()
              : undefined,
          },
          profielType: this.profiel.profielType,
          profielnaamType: this.profielForm.getRawValue().naamData.profielnaamType,
          avatar: this.profielForm.getRawValue().fotoData.avatar,
          roepnaam:
            this.profielForm.getRawValue().naamData.roepnaam === ''
              ? undefined
              : this.profielForm.getRawValue().naamData.roepnaam,
          bio:
            this.profielForm.value.overJezelfData?.bio === ''
              ? undefined
              : this.profielForm.value.overJezelfData?.bio,
          links: this.profielForm.value.overJezelfData?.links ?? [],
          adres:
            this.profielForm.value.overJezelfData?.adres === ''
              ? undefined
              : this.profielForm.value.overJezelfData?.adres,
          email:
            this.profielForm.value.contactData?.email === ''
              ? undefined
              : this.profielForm.value.contactData?.email,
          telefoonnummer:
            this.profielForm.value.contactData?.telefoonnummer === ''
              ? undefined
              : this.profielForm.value.contactData?.telefoonnummer,
        },
        profielFotoFile: this.profielForm.value.fotoData?.profielFotoFile,
        profielFotoChanged: this.profielForm.controls.fotoData.dirty,
      });
    }
  }

  isValid() {
    return this.profielForm.valid;
  }

  onVerwijderingStopzettenClick() {
    this.verwijderingStopzettenClick.emit();
  }
}
