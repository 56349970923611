<form [formGroup]="actieSectionGroup">
  <ng-container formArrayName="acties">
    <ion-reorder-group
      (ionItemReorder)="handleReorder($any($event))"
      *ngIf="actieSectionGroup.controls.acties.controls.length > 0"
      [disabled]="isDisabled"
      class="mb-4 block divide-y-[1.5px] divide-n-300"
    >
      <parkour-actie-item-edit
        (actieDeleted)="deleteActie(i)"
        *ngFor="let actieForm of actieSectionGroup.controls.acties.controls; let i = index"
        [actieItemGroup]="actieForm"
        class="block py-4 first:pt-0 last:pb-0"
      />
    </ion-reorder-group>
  </ng-container>

  <parkour-button (clicked)="addActie()" class="w-full" iconAfter="plus-circle" testId="add-action">
    {{ 'doelen.doel-toevoegen-page.acties.actie-toevoegen' | translate }}
  </parkour-button>
</form>
