import { Component } from '@angular/core';
import { DoelenService } from '../../../doel-shared/service/doelen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, switchMap, take, takeUntil } from 'rxjs';
import { validateDoelId } from '../../../utils';
import { Actie, Doel } from '../../../doel-shared/model/doel';
import { SuggestieService } from '../../../../suggestie/service/suggestie.service';
import { ReactieService } from '../../../doel-shared/service/reactie.service';
import { DoelId, InteractieEmoji, ReactieDto, SuggestieId } from 'parkour-web-app-dto';
import { themaMapping } from '../../../../home/model/thema.const';
import { BerichtenService } from '../../../../bericht/service/berichten.service';
import { UserService } from '../../../../user/service/user.service';
import { environment } from 'src/environments/environment';
import { meldingenForPage } from '../../../../meldingen/config';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourHeaderActionComponent } from '../../../../shared/components/parkour-header-action/parkour-header-action.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import {
  ParkourAnimationModalComponent,
  ParkourButtonComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { DoelDetailsComponent } from '../../../doel-shared/component/doel-details/doel-details.component';
import { ReactieOverviewComponent } from '../../../doel-shared/component/reactie-overview/reactie-overview.component';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { PageSectionDirective } from '../../../../shared/directives/page-section.directive';
import { DoelBerichtenComponent } from '../../../../bericht/component/doel-berichten/doel-berichten.component';
import { SheetService } from 'src/app/shared/services/parkour-sheet.service';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { CustomPageTitle } from '../../../../shared/custom-page-title';
import { ShowIfSuccesfulDirective } from '../../../../shared/directives/show-if-succesful.directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  ViewDidEnter,
  ViewWillEnter,
  ViewWillLeave,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../../shared/marks-meldingen-as-read';

@Component({
  standalone: true,
  templateUrl: './doel-detail.page.html',
  imports: [
    ParkourHeaderActionComponent,
    NgIf,
    TranslateModule,
    ParkourBackButtonDirective,
    AsyncPipe,
    ParkourPageImageComponent,
    DoelDetailsComponent,
    ParkourButtonComponent,
    ParkourAnimationModalComponent,
    ReactieOverviewComponent,
    PageLayoutComponent,
    PageContentDirective,
    PageSectionDirective,
    DoelBerichtenComponent,
    ShowIfSuccesfulDirective,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
  ],
})
export class DoelDetailPage
  extends ParkourDataPage<Doel>
  implements
    ViewWillEnter,
    ViewWillLeave,
    ViewDidEnter,
    CustomPageTitle,
    MarksMeldingenAsReadOnView
{
  doelCompleteAnimationOpen = false;
  protected readonly environment = environment;
  protected readonly themaMapping = themaMapping;
  private doelId$ = this.route.paramMap.pipe(
    take(1),
    map((params) => validateDoelId(params.get('id'))),
  );
  protected interacties$ = this.extraPageData(
    this.doelId$.pipe(switchMap((id) => this.interactieService.getReactiesForDoel(id))),
  );
  protected gesprekData$ = this.extraPageData(
    combineLatest({
      user: this.userService.userWithProfiel$(),
      gesprek: this.doelId$.pipe(switchMap((id) => this.berichtenService.getGesprekOfDoel(id))),
    }),
  );

  constructor(
    private readonly userService: UserService,
    private readonly doelenService: DoelenService,
    private readonly suggestieService: SuggestieService,
    private readonly interactieService: ReactieService,
    private readonly berichtenService: BerichtenService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly sheetService: SheetService,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  override retrieveMainData() {
    return this.doelId$.pipe(switchMap((id) => this.doelenService.getDoel(id)));
  }

  getCustomPageTitle() {
    return this.generateDefaultTitle((doel) => doel.naam, 'doelen.doel-detail-page.title');
  }

  getMeldingenToMarkAsRead() {
    return this.mainPageData$.pipe(
      map(
        (doel) =>
          new MeldingenToMarkAsRead(
            meldingenForPage.DOELEN_TAB.DOELEN_PAGE.DOEL_PAGE.specificMeldingen,
            (melding) => doel.success && melding.metaData.doelId === doel.value.id,
          ),
      ),
    );
  }

  ionViewDidEnter() {
    this.mainPageData$.pipe(
      map((result) => {
        if (this.route.snapshot.queryParams['sheet'] === 'true' && result.success) {
          this.sheetService.open();

          this.sheetService.isOpen.pipe(takeUntil(this.pageWillLeave$)).subscribe((isOpen) => {
            if (!isOpen) {
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  sheet: false,
                },
                queryParamsHandling: 'merge',
              });
            }
          });
        }
      }),
    );
  }

  updateActies({ doel, acties }: { doel: Doel; acties: Actie[] }) {
    this.doelenService
      .updateDoel(validateDoelId(doel.id), {
        ...doel,
        acties,
      })
      .subscribe(() => this.refreshMainData());
  }

  completeDoel(doel: Doel) {
    this.doelenService.completeDoel(doel.id).subscribe(() => {
      this.doelCompleteAnimationOpen = true;
      this.refreshMainData();
    });
  }

  ignoreGebeurtenisSuggestie(suggestieId: SuggestieId) {
    this.suggestieService
      .updateGebeurtenisSuggestieStatus(suggestieId, 'AFGEWEZEN')
      .subscribe(() => this.refreshMainData());
  }

  onInteractieSelect(doelId: DoelId, interactieEmoji: InteractieEmoji) {
    this.interactieService.updateReactie(interactieEmoji, doelId).subscribe();
  }

  onInteractieUnselect(doelId: DoelId, reactie: ReactieDto) {
    this.interactieService.verwijderReactie(reactie, doelId).subscribe();
  }
}
