import { AfterContentChecked, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DoelenService } from '../../../doel-shared/service/doelen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { DoelUpsert } from '../../../doel-shared/model/doel-upsert';
import {
  DoelForm,
  DoelFormComponent,
} from '../../../doel-shared/component/doel-form/doel-form.component';
import { TeamService } from '../../../../team/service/team.service';
import { validatedSuggestieId } from '../../../../verhaal/verhaal-shared/model/gebeurtenis';
import { SuggestieService } from '../../../../suggestie/service/suggestie.service';
import { SuggestieId, Thema } from 'parkour-web-app-dto';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { DirtyFormCheck } from '../../../../shared/components/dirty-form-guard';
import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ParkourHeaderActionComponent } from '../../../../shared/components/parkour-header-action/parkour-header-action.component';
import { ParkourPageGradientComponent, ParkourPageImageComponent } from '@parkour/ui';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { RawValueOfFormGroup } from '../../../../shared/components/parkour-form/parkour-form.types';

@Component({
  standalone: true,
  templateUrl: './doelen-add.page.html',
  imports: [
    ParkourBackButtonDirective,
    TranslateModule,
    NgIf,
    ParkourHeaderActionComponent,
    DoelFormComponent,
    ParkourPageImageComponent,
    AsyncPipe,
    PageLayoutComponent,
    IonHeader,
    IonBackButton,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    ParkourPageGradientComponent,
  ],
})
export class DoelenAddPage implements ViewWillEnter, AfterContentChecked, DirtyFormCheck {
  teamleden$!: Observable<Array<TeamlidProfiel>>;

  initialState: Partial<DoelUpsert> = {};
  initialStateLoaded = false;
  originSuggestieId?: SuggestieId;

  @ViewChild('doelForm') doelForm?: DoelFormComponent;

  constructor(
    private doelenService: DoelenService,
    private teamService: TeamService,
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private readonly suggestieService: SuggestieService,
  ) {}

  async ionViewWillEnter(): Promise<void> {
    this.teamleden$ = this.teamService.getMyTeamleden().pipe(shareReplay(1));

    const params = await firstValueFrom(this.route.queryParamMap);

    if (params.has('suggestieId')) {
      this.originSuggestieId = validatedSuggestieId(params.get('suggestieId'));
      const suggestie = await firstValueFrom(
        this.suggestieService.getDoelSuggestie(this.originSuggestieId),
      );

      this.initialState = suggestie.doelData;
    }

    if (params.has('thema')) {
      this.initialState = {
        ...this.initialState,
        thema: params.get('thema') as Thema,
      };
    }

    this.initialStateLoaded = true;
  }

  isFormDirty() {
    return this.doelForm?.doelForm.dirty ?? false;
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  submitDoel = (formValues: RawValueOfFormGroup<DoelForm>) => {
    return this.teamleden$.pipe(
      switchMap((teamleden) => {
        if (formValues.doelDataSection.thema === '') {
          throw new Error('Thema is verplicht');
        }

        const doelUpsert: DoelUpsert = {
          naam: formValues.doelDataSection.naam,
          omschrijving: formValues.doelDataSection.omschrijving,
          thema: formValues.doelDataSection.thema,
          streefDatum:
            formValues.doelDataSection.streefDatum !== ''
              ? new Date(formValues.doelDataSection.streefDatum)
              : undefined,
          acties: formValues.actieSection.acties.map((actie) => ({
            naam: actie.naam,
            voltooid: actie.voltooid,
            order: actie.order,
          })),
          deelMode: formValues.gedeeldMetSection.deelMode,
          gedeeldMet: teamleden
            .filter((teamLid, index) => formValues.gedeeldMetSection.gedeeldMet[index].gedeeldMet)
            .map((teamlid) => teamlid.id),
          originSuggestieId: this.originSuggestieId,
        };

        return this.doelenService
          .addDoel(doelUpsert)
          .pipe(tap(() => this.router.navigate(['..'], { relativeTo: this.route })));
      }),
    );
  };
}
