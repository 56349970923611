import {ApiProperty, getSchemaPath} from '@nestjs/swagger';
import {IdOf} from '../branded-type';
import {BeeldbelGesprekDto} from './beeldbellen.dto';
import {ProfielId} from './profiel.dto';
import {JongereProfielDto} from './jongere-profiel.dto';
import {ActiefTeamlidDto, GeblokkeerdTeamlidDto, InactiefTeamlidDto, TeamlidProfielDto,} from './teamlid-profiel.dto';

export type AfspraakId = IdOf<'afspraak'>;
export function validateAfspraakId(id?: string | null): AfspraakId {
  if (!id) {
    throw new Error('id is undefined');
  }

  return id as AfspraakId;
}
export class BasicAfspraakDto {
  readonly type!: 'BASIC';
  readonly titel!: string;
  @ApiProperty({ type: String })
  readonly id!: AfspraakId;
  @ApiProperty({ type: String })
  readonly jongereProfielId!: ProfielId;
  readonly van!: string;
  readonly tot!: string;
  readonly omschrijving?: string;
}

export class VideogesprekAfspraakDto {
  readonly type!: 'VIDEOGESPREK';
  @ApiProperty({ type: String })
  readonly id!: AfspraakId;
  @ApiProperty({ type: String })
  readonly jongereProfielId!: ProfielId;
  @ApiProperty({ type: String })
  readonly teamlidProfielId!: ProfielId;
  readonly van!: string;
  readonly tot!: string;
  readonly omschrijving?: string;
}

export type AfspraakDto = BasicAfspraakDto | VideogesprekAfspraakDto;

export class BasicAfspraakViewDetailDto {
  readonly type!: 'BASIC';
  readonly titel!: string;
  @ApiProperty({ type: String })
  readonly id!: AfspraakId;
  readonly jongere!: JongereProfielDto;
  readonly datum!: string;
  readonly omschrijving?: string;
  readonly van!: string;
  readonly tot!: string;
}

export class VideogesprekAfspraakViewDetailDto {
  readonly type!: 'VIDEOGESPREK';
  @ApiProperty({ type: String })
  readonly id!: AfspraakId;
  readonly jongere!: JongereProfielDto;
  @ApiProperty({
    type: 'object',
    anyOf: [
      { $ref: getSchemaPath(ActiefTeamlidDto) },
      { $ref: getSchemaPath(GeblokkeerdTeamlidDto) },
      { $ref: getSchemaPath(InactiefTeamlidDto) },
    ],
  })
  readonly teamlid!: TeamlidProfielDto;
  readonly datum!: string;
  readonly omschrijving?: string;
  readonly van!: string;
  readonly tot!: string;
  readonly gesprek?: BeeldbelGesprekDto;
}

export type AfspraakViewDetailDto = BasicAfspraakViewDetailDto | VideogesprekAfspraakViewDetailDto;

export class BasicAfspraakInsertDto {
  readonly type!: 'BASIC';
  readonly titel!: string;
  @ApiProperty({ type: String })
  readonly jongereProfielId!: ProfielId;
  readonly van!: string;
  readonly tot!: string;
  readonly omschrijving?: string;
}

export class VideogesprekAfspraakInsertDto {
  readonly type!: 'VIDEOGESPREK';
  @ApiProperty({ type: String })
  readonly jongereProfielId!: ProfielId;
  @ApiProperty({ type: String })
  readonly teamlidProfielId!: ProfielId;
  readonly van!: string;
  readonly tot!: string;
  readonly omschrijving?: string;
}

export type AfspraakInsertDto = BasicAfspraakInsertDto | VideogesprekAfspraakInsertDto;
export type AfspraakUpdateDto = AfspraakInsertDto & {id: AfspraakId};
