import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  defaultIfEmpty,
  delay,
  filter,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
  retry,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { ContextId, MeldingDto, MeldingOfType, MeldingType, ProfielId } from 'parkour-web-app-dto';
import { MeldingVisualizationService } from './melding-visualization.service';
import {
  isSupportedMelding,
  Melding,
  MeldingenPage,
  MeldingView,
  SupportedMelding,
} from '../model/meldingen';
import { UserService } from '../../user/service/user.service';
import { UserWithProfiel } from '../../user/model/user';
import { ContextService } from '../../shared/services/context.service';
import { MeldingLinkService } from './melding-link.service';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { PusherService } from '../../bericht/service/pusher.service';
import { HumanReadableError } from '../../core/human-readable-error';
import { meldingenForWisselTeamPage } from '../../meldingen/config';
import { LoggingService } from '../../core/logging.service';

type Page<T> = {
  readonly content: T[];
  readonly totalPages: number;
  readonly number: number;
  readonly last: boolean;
};

export type MeldingenMapType<Type extends MeldingType> = {
  readonly [key in Type]: key;
};

@Injectable({
  providedIn: 'root',
})
export class MeldingenService {
  wisselTeamMeldingen$ = combineLatest([
    this.getMeldingenNotInCurrentContext(),
    this.getMeldingenInCurrentContextByType(meldingenForWisselTeamPage.allMeldingen),
  ]).pipe(
    map(([meldingenNotInCurrentcontext, wisselTeamButtonMeldingen]) =>
      meldingenNotInCurrentcontext.concat(wisselTeamButtonMeldingen),
    ),
  );
  private readonly ongelezenMeldingenFilters: ((melding: SupportedMelding) => boolean)[] = [];
  private readonly _ongelezenMeldingen$ = new BehaviorSubject<SupportedMelding[]>([]);

  constructor(
    private readonly http: HttpClient,
    private readonly meldingVisualizationService: MeldingVisualizationService,
    private readonly meldingLinkService: MeldingLinkService,
    private readonly userService: UserService,
    private readonly contextService: ContextService,
    private readonly router: Router,
    private readonly pusherService: PusherService,
    private readonly loggingService: LoggingService,
  ) {}

  get ongelezenMeldingen$(): Observable<SupportedMelding[]> {
    return this._ongelezenMeldingen$;
  }

  public startMeldingenFetcher() {
    App.addListener('appStateChange', ({ isActive }) => {
      if (isActive) {
        this.fetchOngelezenMeldingen();
      }
    });

    this.userService.getCurrentUser$().subscribe(() => {
      this.fetchOngelezenMeldingen();
    });

    this.pusherService.pusherReconnected$.subscribe(() => this.fetchOngelezenMeldingen());

    this.pusherService.createPusherObservableForEvent('nieuwe-melding').subscribe({
      next: (meldingEvent) => {
        this.getMeldingWithRetry(meldingEvent.meldingId).subscribe({
          next: (melding) => {
            if (melding && isSupportedMelding(melding) && !melding.gelezen) {
              const currentOngelezenMeldingen = this._ongelezenMeldingen$.value;
              currentOngelezenMeldingen.push(melding);
              this.updateOngelezenMeldingen(currentOngelezenMeldingen);
            }
          },
          error: (err) => this.loggingService.error(err),
        });
      },
      complete: () => {
        throw new HumanReadableError(
          'Er is iets misgegaan tijdens het ophalen van je meldingen. Probeer opnieuw of herstart de applicatie.',
        );
      },
    });
  }

  isMeldingSupportedEnGelezen(meldingId: string) {
    return this.getMeldingWithRetry(meldingId).pipe(
      map(
        (melding) =>
          melding &&
          isSupportedMelding(melding) &&
          (melding.gelezen || this.matchesOngelezenMeldingenFilter(melding)),
      ),
    );
  }

  getMeldingen(profielId: ProfielId, pageNumber?: number): Observable<Page<MeldingView>> {
    return this.http
      .get<MeldingenPage>(`${environment.API_BASE_URL}/api/meldingen/targets/${profielId}`, {
        params: { pageNumber: pageNumber || 0 },
      })
      .pipe(
        switchMap((pageOfMeldingDto) =>
          forkJoin(
            pageOfMeldingDto.content.map((melding) =>
              this.meldingVisualizationService.transform(melding),
            ),
          ).pipe(
            map((transformedMeldingen) => {
              return {
                ...pageOfMeldingDto,
                content: transformedMeldingen,
              };
            }),
          ),
        ),
        defaultIfEmpty({
          content: [],
          last: true,
          number: 0,
          totalPages: 0,
        }),
      );
  }

  getMelding(meldingId: string): Observable<Melding> {
    return this.http.get<Melding>(`${environment.API_BASE_URL}/api/meldingen/${meldingId}`);
  }

  markMeldingAsRead(meldingId: string): Observable<void> {
    return this.http
      .put(`${environment.API_BASE_URL}/api/meldingen/${meldingId}/gelezen`, {})
      .pipe(map(() => {}));
  }

  getOngelezenMeldingen(profielId: ProfielId): Observable<SupportedMelding[]> {
    return this.http
      .get<MeldingDto[]>(`${environment.API_BASE_URL}/api/meldingen/targets/${profielId}/ongelezen`)
      .pipe(tap(() => this.loggingService.log('Fetched ongelezen meldingen')));
  }

  fetchOngelezenMeldingen() {
    this.userService
      .getCurrentUser$()
      .pipe(
        mergeMap((user) => {
          if (user instanceof UserWithProfiel) {
            return this.getOngelezenMeldingenForUser(user);
          } else {
            return of([]);
          }
        }),
        map((meldingen) => meldingen ?? []),
        take(1),
      )
      .subscribe((meldingen) => this.updateOngelezenMeldingen(meldingen));
  }

  getOngelezenMeldingenForUser(userWithProfiel: UserWithProfiel) {
    return this.getOngelezenMeldingen(userWithProfiel.profiel.id).pipe(
      catchError((error) => {
        this.loggingService.error('Error while fetching ongelezen meldingen', error);

        return of([]);
      }),
    );
  }

  isMeldingOfTypes<T extends MeldingType>(
    melding: MeldingDto,
    types: MeldingenMapType<T>,
  ): melding is MeldingOfType<T> {
    return Object.keys(types).includes(melding.type);
  }

  getMeldingenInCurrentContextByType<T extends MeldingType>(
    types: MeldingenMapType<T>,
  ): Observable<MeldingOfType<T>[]> {
    return this.contextService.context$.pipe(
      mergeMap((context) =>
        this.ongelezenMeldingen$.pipe(
          map((meldingen) =>
            meldingen.filter((melding) => {
              return (
                this.isMeldingRelevantInContext(melding, context.contextId) &&
                this.isMeldingOfTypes(melding, types)
              );
            }),
          ),
        ),
      ),
      map((meldingen) => meldingen as MeldingOfType<T>[]),
    );
  }

  hasMeldingenInContextByType(contextId: ContextId): Observable<boolean> {
    return this.ongelezenMeldingen$.pipe(
      map(
        (meldingen) =>
          meldingen.filter((melding) => {
            return 'contextId' in melding.metaData && melding.metaData.contextId === contextId;
          }).length > 0,
      ),
    );
  }

  getMeldingenNotInCurrentContext(): Observable<SupportedMelding[]> {
    return this.contextService.context$.pipe(
      mergeMap((user) => {
        return this.ongelezenMeldingen$.pipe(
          map((meldingen) => {
            return meldingen.filter(
              (melding) =>
                'contextId' in melding.metaData && melding.metaData.contextId !== user.contextId,
            );
          }),
        );
      }),
    );
  }

  markMeldingenInCurrentContextAsReadWithTypes<T extends MeldingType>(
    types: MeldingenMapType<T>,
    matcher: (melding: MeldingOfType<T>) => boolean = () => true,
  ): void {
    this.markMeldingenAsRead(
      this.getMeldingenInCurrentContextByType(types).pipe(
        take(1),
        map((meldingen) => meldingen.filter((melding) => matcher(melding))),
      ),
    );
  }

  hasWisselTeamMeldingen(): Observable<boolean> {
    return this.wisselTeamMeldingen$.pipe(map((meldingen) => meldingen.length > 0));
  }

  hasMeldingenInCurrentContextByType<T extends MeldingType>(
    types: MeldingenMapType<T>,
  ): Observable<boolean> {
    return this.getMeldingenInCurrentContextByType(types).pipe(
      map((meldingen) => meldingen.length > 0),
    );
  }

  navigateToMelding(meldingId: string) {
    this.loggingService.log('Navigate To Melding', meldingId);
    this.getMelding(meldingId)
      .pipe(
        filter(isSupportedMelding),
        switchMap((melding) => this.meldingLinkService.getMeldingLink(melding)),
        take(1),
      )
      .subscribe({
        next: (url) => {
          if (Array.isArray(url)) {
            this.router.navigate(url, { replaceUrl: true });
          } else {
            this.router.navigate(url.path, { replaceUrl: true, queryParams: url.queryParams });
          }
        },
        error: (err) => this.loggingService.error('Failed navigating to melding', err),
      });
  }

  addOngelezenMeldingenFilter(filter: (melding: MeldingDto) => boolean) {
    this.ongelezenMeldingenFilters.push(filter);
  }

  removeOngelezenMeldingenFilter(filter: (melding: MeldingDto) => boolean) {
    this.ongelezenMeldingenFilters.splice(this.ongelezenMeldingenFilters.indexOf(filter), 1);
  }

  private getMeldingWithRetry(meldingId: string): Observable<Melding | undefined> {
    //Fetchen of melding immediately after pusher event can fail due to uncommitted database changes
    return this.userService.getCurrentUser$().pipe(
      switchMap((user) => {
        if (!(user instanceof UserWithProfiel)) {
          return of(undefined);
        } else {
          return this.getMelding(meldingId).pipe(delay(500), retry(2));
        }
      }),
      take(1),
    );
  }

  private updateOngelezenMeldingen(currentOngelezenMeldingen: SupportedMelding[]) {
    const meldingenToMarkAsRead = currentOngelezenMeldingen.filter((melding) =>
      this.matchesOngelezenMeldingenFilter(melding),
    );
    const ongelezenMeldingen = currentOngelezenMeldingen.filter(
      (melding) => !this.matchesOngelezenMeldingenFilter(melding),
    );
    this._ongelezenMeldingen$.next(ongelezenMeldingen);
    meldingenToMarkAsRead.forEach((melding) => this.markMeldingAsRead(melding.id).subscribe());
  }

  private matchesOngelezenMeldingenFilter(melding: SupportedMelding): boolean {
    return this.ongelezenMeldingenFilters.some((filter) => filter(melding));
  }

  private isMeldingRelevantInContext(melding: MeldingDto, contextId: ContextId): boolean {
    return !('contextId' in melding.metaData) || melding.metaData.contextId === contextId;
  }

  private markMeldingenAsRead(meldingen$: Observable<SupportedMelding[]>): void {
    meldingen$
      .pipe(
        take(1),
        mergeMap((meldingen) =>
          combineLatest(meldingen.map((melding) => this.markMeldingAsRead(melding.id))),
        ),
      )
      .subscribe(() => this.fetchOngelezenMeldingen());
  }
}
