<parkour-page-with-hero [heroImage]="verhaalHeroSources" [title]="gebeurtenis.titel">
  <div class="flex flex-col items-center" hero-above-title>
    <div class="flex items-center gap-x-2 mb-4 bg-n-200 rounded p-2">
      <parkour-image
        alt="Vlaamse Overheid"
        class="block w-[1.75rem] h-[1.75rem] bg-n-1000 p-1 rounded-sm"
        fit="contain"
        src="/assets/header/vlaamse-overheid-logo.svg"
      />

      <span class="typo-body variant-body-small variant-body-fat"> Opgroeien </span
      ><!-- TODO: UX Copy verhaal aan JSON toevoegen -->
    </div>
    <p class="typo-body variant-body-fat">
      {{ 'verhaal.detail-page.sub-titel-jeugdhulphistoriek' | translate }}
    </p>
  </div>
  @if (gebeurtenis.startDatum) {
    <p class="text-n-600 typo-h5" hero-content data-testid="datum">
      {{ (gebeurtenis.startDatum | date) + ' tot ' + ((gebeurtenis.eindDatum | date) ?? 'heden') }}
    </p>
  }
  <div class="container-1-col mt-4">
    <div class="flex justify-center mb-4">
      <parkour-gedeeld-met-indicator
        (clicked)="gedeeldMetInfoComponent.open()"
        [deelMode]="gebeurtenis.deelMode"
        [gedeeldMet]="gebeurtenis.gedeeldMet"
        format="button"
        size="large"
        testId="deel-details-button"
      />
    </div>

    <parkour-info-card *parkourActiefAlsJongere>
      {{ 'verhaal.detail-page.info-jongere-eyouth' | translate }}
    </parkour-info-card>

    <p class="typo-body mt-8" data-testid="omschrijving">
      {{ gebeurtenis.omschrijving }}
    </p>
  </div>
</parkour-page-with-hero>

<parkour-gedeeld-met-info
  #gedeeldMetInfoComponent
  [deelMode]="gebeurtenis.deelMode"
  [gedeeldMet]="gebeurtenis.gedeeldMet"
/>
