import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, Observable, switchMap, takeUntil } from 'rxjs';
import { themaMapping } from '../../../home/model/thema.const';
import { ArtikelId, BookmarkId, Thema } from 'parkour-web-app-dto';
import { TCustomSelectOptions } from '@parkour/ui/projects/parkour-ui-components/src/lib/parkour-custom-select/parkour-custom-select.types';
import {
  ArtikelWithBookmarkQueryResult,
  WatwatService,
} from '../../../shared/services/watwat.service';
import { BookmarkService } from '../../../shared/services/bookmark.service';
import {
  ParkourButtonComponent,
  ParkourCustomSelectComponent,
  ParkourFormFieldComponent,
  ParkourInputComponent,
  ParkourPageGradientComponent,
  ParkourPaginationComponent,
} from '@parkour/ui';
import { CoreModule } from '../../../core/core.module';
import { ArtikelCardComponent } from '../../components/artikel-card/artikel-card.component';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { MakeReferencesAbsolutePipe } from '../../pipes/make-references-absolute.pipe';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageSectionDirective } from '../../../shared/directives/page-section.directive';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';

@Component({
  templateUrl: './artikels.page.html',
  standalone: true,
  imports: [
    CoreModule,
    ArtikelCardComponent,
    ParkourBackButtonDirective,
    MakeReferencesAbsolutePipe,
    ParkourPageGradientComponent,
    ParkourFormFieldComponent,
    ParkourInputComponent,
    ParkourButtonComponent,
    ParkourCustomSelectComponent,
    ParkourPaginationComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonContent,
    PageLayoutComponent,
    PageSectionDirective,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
  ],
})
export class ArtikelsPage extends ParkourDataPage<ArtikelWithBookmarkQueryResult> {
  @ViewChild(IonContent) contentRef!: IonContent;

  enteredQueryString = '';
  enteredThema: Thema | 'ALL' = 'ALL';
  themas: TCustomSelectOptions = {
    ALL: {
      label: 'Alle themas',
    },
    ...this.mapThemasToOptions(),
  };

  queryString$ = this.activatedRoute.queryParamMap.pipe(map((map) => this.getQueryString(map)));
  themaString$ = this.activatedRoute.queryParamMap.pipe(map((map) => this.getThema(map)));
  page$ = this.activatedRoute.queryParamMap.pipe(map((map) => this.getPageNr(map)));

  protected readonly String = String;

  constructor(
    private readonly watwatService: WatwatService,
    private readonly bookmarkService: BookmarkService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
  ) {
    super();
  }

  override retrieveMainData(): Observable<ArtikelWithBookmarkQueryResult> {
    return this.activatedRoute.queryParamMap.pipe(
      switchMap((queryParams) => {
        const thema = this.getThema(queryParams);
        return this.watwatService.getArtikels({
          query: this.getQueryString(queryParams),
          page: this.getPageNr(queryParams),
          thema: thema === 'ALL' ? undefined : thema,
        });
      }),
    );
  }

  override ionViewWillEnter() {
    super.ionViewWillEnter();

    this.queryString$.pipe(takeUntil(this.pageWillLeave$)).subscribe((queryString) => {
      this.enteredQueryString = queryString;
    });

    this.themaString$.pipe(takeUntil(this.pageWillLeave$)).subscribe((themaString) => {
      this.enteredThema = themaString;
    });
  }

  onSearch() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        query: this.enteredQueryString,
        thema: this.enteredThema,
      },
    });
  }

  changePage(page: number) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { page },
      queryParamsHandling: 'merge',
    });
  }

  mapThemasToOptions(): TCustomSelectOptions {
    const options: TCustomSelectOptions = {};
    Object.entries(themaMapping).forEach(
      ([thema, view]) =>
        (options[thema] = {
          label: view.label,
          sources: {
            default: {
              src: view.smallImage.src,
              fallback: view.smallImage.fallback,
            },
          },
        }),
    );
    return options;
  }

  onThemaChanged(thema: Thema) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        thema,
        query: this.enteredQueryString,
      },
    });
  }

  onBookmarkClicked(
    hasBookmarked: boolean,
    artikelId: ArtikelId,
    bookmarkId: BookmarkId | undefined,
  ) {
    if (hasBookmarked) {
      this.bookmarkService
        .addBookmark({
          itemId: String(artikelId),
          itemType: 'ARTIKEL',
        })
        .subscribe(() => this.refreshMainData());
    } else {
      if (bookmarkId) {
        this.bookmarkService.deleteBookmark(bookmarkId).subscribe(() => this.refreshMainData());
      }
    }
  }

  private getPageNr(map: ParamMap): number | undefined {
    const page = map.get('page');

    return page === null ? undefined : parseInt(page);
  }

  private getQueryString(map: ParamMap) {
    const query = map.get('query');

    return query ?? '';
  }

  private getThema(map: ParamMap): Thema | 'ALL' {
    const query = map.get('thema');

    return (query as Thema) ?? 'ALL';
  }
}
