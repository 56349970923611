import { Routes } from '@angular/router';
import {
  actiefAlsJongereMatcher,
  actiefAlsTeamlidMatcher,
  actiefVoorMezelfMatcher,
} from '../core/matchers';
import { verhaalTeamlidRoutes } from './verhaal-teamlid/routes';
import { verhaalJongereRoutes } from './verhaal-jongere/routes';
import { VerhalenNoTeamPage } from './verhaal-shared/page/verhalen-no-team-page/verhalen-no-team.page';
import { VerhalenNotAangemeldPage } from './verhaal-shared/page/verhalen-not-aangemeld-page/verhalen-not-aangemeld.page';

export const verhaalRoutes: Routes = [
  {
    path: '',
    canMatch: [actiefAlsTeamlidMatcher],
    children: verhaalTeamlidRoutes,
  },
  {
    path: '',
    canMatch: [actiefAlsJongereMatcher],
    children: verhaalJongereRoutes,
  },
  { path: '', canMatch: [actiefVoorMezelfMatcher], component: VerhalenNoTeamPage },
  { path: '', component: VerhalenNotAangemeldPage },
];
