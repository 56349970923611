import { Injectable } from '@angular/core';
import { combineLatest, map, mergeMap, Observable, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Gebeurtenis, GebeurtenisUpsert } from '../model/gebeurtenis';
import { asType, stripNullProperties } from '../../../utils';
import { environment } from 'src/environments/environment';
import {
  DrieKolommenDocumentDto,
  FileData,
  FotoId,
  GebeurtenisDto,
  GebeurtenisId,
  GebeurtenisUpsertDto,
  VerhaalDto,
} from 'parkour-web-app-dto';
import { ContextService } from '../../../shared/services/context.service';
import { GebeurtenisFotoFormGroup } from '../component/gebeurtenis-form/gebeurtenis-form.types';
import { FormBuilder } from '@angular/forms';
import { Capacitor, HttpHeaders } from '@capacitor/core';
import { FileService } from '../../../shared/services/file.service';
import AuthService from '../../../authentication/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class VerhalenService {
  constructor(
    private http: HttpClient,
    private readonly contextService: ContextService,
    private readonly authService: AuthService,
    private readonly formBuilder: FormBuilder,
    private readonly fileService: FileService,
  ) {}

  getGebeurtenis(id: GebeurtenisId): Observable<Gebeurtenis> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((profielId) =>
          this.http.get<GebeurtenisDto>(
            `${environment.API_BASE_URL}/api/jongere/${profielId}/gebeurtenissen/${id}`,
          ),
        ),
      );
  }

  getDocument(id: string): Observable<DrieKolommenDocumentDto> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http
            .get<DrieKolommenDocumentDto>(
              `${environment.API_BASE_URL}/api/jongere/${contextId}/drie-kolommen-document/${id}`,
            )
            .pipe(map((dto) => dto)),
        ),
      );
  }

  getVerhaalTijdlijn(): Observable<VerhaalDto> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http.get<VerhaalDto>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen`,
          ),
        ),
      );
  }

  getGedeeldeGebeurtenissen(): Observable<VerhaalDto> {
    return combineLatest([
      this.contextService.teamlidContext$(),
      this.authService.getAangemeldeUser$(),
    ]).pipe(
      mergeMap(([context, user]) =>
        this.http.get<VerhaalDto>(
          `${environment.API_BASE_URL}/api/jongere/${context.jongereProfiel.id}/gebeurtenissen`,
          { params: { gedeeldMet: user.profielId } },
        ),
      ),
    );
  }

  addGebeurtenis(gebeurtenis: GebeurtenisUpsert): Observable<GebeurtenisDto> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http.post<GebeurtenisDto>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen`,
            stripNullProperties(asType<GebeurtenisUpsertDto>({ ...gebeurtenis })),
          ),
        ),
      );
  }

  updateGebeurtenis(
    gebeurtenisId: GebeurtenisId,
    gebeurtenis: GebeurtenisUpsert,
  ): Observable<GebeurtenisDto> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http.put<GebeurtenisDto>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}`,
            stripNullProperties(asType<GebeurtenisUpsertDto>({ ...gebeurtenis })),
          ),
        ),
      );
  }

  deleteGebeurtenis(gebeurtenisId: GebeurtenisId) {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http.delete<void>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}`,
          ),
        ),
      );
  }

  uploadGebeurtenisFoto(gebeurtenisId: GebeurtenisId, gebeurtenisFoto: File): Observable<void> {
    let headers: HttpHeaders = {};
    if (Capacitor.isNativePlatform()) {
      headers = { 'Content-Type': 'multipart/form-data; boundary=uploadGebeurtenisFoto' };
    }
    const data: FormData = new FormData();

    data.append('file', gebeurtenisFoto);

    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http.put<void>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}/foto`,
            data,
            { headers },
          ),
        ),
      );
  }

  deleteGebeurtenisFoto(gebeurtenisId: GebeurtenisId, gebeurtenisFotoId: FotoId): Observable<void> {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        switchMap((contextId) =>
          this.http.delete<void>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}/foto/${gebeurtenisFotoId}`,
          ),
        ),
      );
  }

  startFetchingJeugdhulpHistoriekGebeurtenissen() {
    return this.contextService.context$.subscribe((context) => {
      if (context.type === 'jongere') {
        this.http
          .post<void>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/jeugdhulphistoriek/refresh`,
            {},
          )
          .subscribe();
      }
    });
  }

  createGebeurtenisFotoFormGroup(gebeurtenisPicture: {
    gebeurtenisFoto?: string;
    gebeurtenisFotoFile?: File;
  }): GebeurtenisFotoFormGroup {
    return this.formBuilder.nonNullable.group({
      gebeurtenisFoto: this.formBuilder.nonNullable.control(gebeurtenisPicture.gebeurtenisFoto),
      gebeurtenisFotoFile: this.formBuilder.nonNullable.control(
        gebeurtenisPicture.gebeurtenisFotoFile,
      ),
    });
  }

  downloadBijlage(gebeurtenisId: GebeurtenisId, fileData: FileData) {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        switchMap((contextId) =>
          this.fileService.downloadFileFromUrl(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/gebeurtenissen/${gebeurtenisId}/bijlage/${fileData.id}`,
            fileData.naam,
          ),
        ),
      );
  }
}
