import { Component } from '@angular/core';
import { ProfielService } from '../../service/profiel.service';
import { UserService } from '../../../user/service/user.service';
import { ExterneHoedanigheid } from 'parkour-web-app-dto';
import { combineLatest, Observable } from 'rxjs';
import { ProfielCreateService } from '../../service/profiel-create.service';
import { User, UserWithProfiel } from '../../../user/model/user';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ProfielDetailsComponent } from '../../../profiel/component/profile-details/profiel-details.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { MyProfiel, Profiel } from '../../model/profiel';

export type MijnProfielPageData = {
  mijnProfielen: MyProfiel[];
  hoedanigheid: ExterneHoedanigheid;
  user: User;
};

@Component({
  standalone: true,
  templateUrl: './mijn-profiel.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourHeaderActionComponent,
    NgIf,
    ContentUnavailableCardComponent,
    PageLayoutComponent,
    ProfielDetailsComponent,
    AsyncPipe,
    TranslateModule,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    ParkourRefreshDirective,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonBackButton,
    PageContentDirective,
  ],
})
export class MijnProfielPage extends ParkourDataPage<MijnProfielPageData> {
  constructor(
    private readonly profielService: ProfielService,
    private readonly userService: UserService,
    private readonly profielCreateService: ProfielCreateService,
  ) {
    super();
  }

  override retrieveMainData(): Observable<MijnProfielPageData> {
    return combineLatest({
      mijnProfielen: this.profielService.getMyProfielen(),
      hoedanigheid: this.userService.getExterneHoedanigheid(),
      user: this.userService.getCurrentUser$(),
    });
  }

  onProfielSelect(profiel: Profiel) {
    this.userService.switchProfiel(profiel.id, ['profiel', 'mijn']).subscribe();
  }

  hasProfiel(user: User): user is UserWithProfiel {
    return user instanceof UserWithProfiel;
  }

  onCreatePersoonlijkProfiel() {
    this.profielCreateService.startCreatingPersoonlijkProfiel();
  }

  createProfessioneelProfiel() {
    this.profielCreateService.startCreatingProfessioneelProfiel();
  }
}
