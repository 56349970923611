import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ArtikelWithBookmark } from '../../../shared/services/watwat.service';
import { ArtikelId, BookmarkId } from 'parkour-web-app-dto';
import { ParkourCarouselModule } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ArtikelPreviewCardComponent } from '../artikel-preview-card/artikel-preview-card.component';

export type TParkourArtikelsPreviewBookmarkEvent = {
  hasBookmarked: boolean;
  artikelId: ArtikelId;
  bookmarkId?: BookmarkId;
};

@Component({
  standalone: true,
  selector: 'parkour-artikels-preview',
  templateUrl: './artikels-preview.component.html',
  styleUrls: ['./artikels-preview.styles.css'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  encapsulation: ViewEncapsulation.None,
  imports: [ParkourCarouselModule, TranslateModule, ArtikelPreviewCardComponent],
})
export class ArtikelsPreviewComponent {
  @Input({ required: true }) artikels!: ArtikelWithBookmark[];

  @Output() public clickedBookmark: EventEmitter<TParkourArtikelsPreviewBookmarkEvent> =
    new EventEmitter<TParkourArtikelsPreviewBookmarkEvent>();

  onClickedBookmark(hasBookmarked: boolean, artikelId: ArtikelId, bookmarkId?: BookmarkId) {
    this.clickedBookmark.emit({
      hasBookmarked,
      artikelId,
      bookmarkId,
    });
  }
}
