<parkour-modal
  [icon]="deelMode === 'SPECIFIEK' ? 'lock' : 'people'"
  [title]="
    deelMode === 'SPECIFIEK'
      ? ('delen.delen-specifiek' | translate)
      : ('delen.delen-iedereen' | translate)
  "
  data-testid="gedeeldMetInfo"
>
  <div content>
    @if (teamleden$ | async; as teamleden) {
      <ng-container *ngIf="deelMode === 'IEDEREEN'">
        <parkour-info-card *ngIf="teamleden.length === 0; else hasTeamleden">
          <p>{{ 'delen.geen-teamleden' | translate }}</p>
        </parkour-info-card>
      </ng-container>
      <ng-container *ngIf="deelMode === 'SPECIFIEK'">
        <parkour-info-card *ngIf="gedeeldMet.length === 0; else hasTeamleden">
          <p>{{ 'delen.geen-gedeelde-teamleden' | translate }}</p>
        </parkour-info-card>
      </ng-container>
      <ng-template #hasTeamleden>
        <p class="typo-body variant-body-fat">{{ 'delen.actieve-teamleden' | translate }}</p>

        <ul class="mt-6 flex flex-col gap-y-6">
          <li *ngFor="let teamlid of getActieveTeamleden(teamleden)">
            <parkour-profiel-block [profielData]="teamlid" />
          </li>
        </ul>
      </ng-template>
    }
  </div>

  <ng-container footer>
    @if (teamleden$ | async; as teamleden) {
      @if (hasGeblokkeerdeTeamleden(teamleden)) {
        <div>
          <p class="typo-body variant-body-fat">{{ 'delen.geblokkeerde-teamleden' | translate }}</p>

          <ul class="mt-6 flex flex-col gap-y-6">
            <li *ngFor="let teamlid of getGeblokkeerdeTeamleden(teamleden)">
              <parkour-profiel-block [profielData]="teamlid" />
            </li>
          </ul>
        </div>
      }
    }
  </ng-container>
</parkour-modal>
