import { Component, Input } from '@angular/core';
import { Gesprek } from '../../model/gesprek';
import { UserWithProfiel } from '../../../user/model/user';
import {
  ProfielFotoBadgeComponent,
  ProfielFotoBadgeSize,
} from '../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { IParkourAvatarBadgeImageProps } from '@parkour/ui';
import { BerichtenService } from '../../service/berichten.service';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  ReplaySubject,
  switchMap,
} from 'rxjs';
import { FotoBadgeComponent } from '../../../shared/components/foto-badge/foto-badge.component';
import { asType } from '../../../utils';
import { ProfielFotoData } from '../../../shared/model/teamlid-info';
import { FotoId, GesprekId } from 'parkour-web-app-dto';
import { AsyncPipe, NgClass } from '@angular/common';
import { FotoBadgePlaceholderComponent } from '../../../shared/components/foto-badge-placeholder/foto-badge-placeholder.component';
import { getSecureImage } from '../../../shared/pipes/secure-image.pipe';

@Component({
  selector: 'parkour-gesprek-foto-badge',
  templateUrl: './gesprek-foto-badge.component.html',
  standalone: true,
  imports: [
    ProfielFotoBadgeComponent,
    FotoBadgeComponent,
    AsyncPipe,
    NgClass,
    FotoBadgePlaceholderComponent,
  ],
})
export class GesprekFotoBadgeComponent {
  @Input({ required: true }) user!: UserWithProfiel;
  @Input({ required: true }) size!: ProfielFotoBadgeSize;
  @Input({ required: true }) overrideImage!: boolean;
  @Input() grayout = false;

  constructor(private readonly berichtenService: BerichtenService) {}

  _gesprek!: Gesprek;
  gesprek$ = new ReplaySubject<Gesprek>(1);
  imageOverride$ = new BehaviorSubject<string | null>(null);

  @Input() set imageOverride(image: string | null) {
    this.imageOverride$.next(image);
  }

  @Input({ required: true }) set gesprek(gesprek: Gesprek) {
    this.gesprek$.next(gesprek);
    this._gesprek = gesprek;
  }

  gesprekFoto$: Observable<
    | {
        type: 'profiel-foto';
        profiel: ProfielFotoData;
      }
    | IParkourAvatarBadgeImageProps
  > = combineLatest([this.gesprek$, this.imageOverride$]).pipe(
    switchMap(([gesprek, imageOverride]) => {
      if (this.overrideImage) {
        if (imageOverride) {
          return of(
            asType<IParkourAvatarBadgeImageProps>({
              type: 'image',
              src: imageOverride,
              alt: '',
            }),
          );
        } else {
          return this.getProfielFoto(gesprek);
        }
      } else if (gesprek.fotoId) {
        return this.getCustomFotoFromGesprek(gesprek.id, gesprek.fotoId);
      } else {
        return this.getProfielFoto(gesprek);
      }
    }),
  );

  private getProfielFoto(gesprek: Gesprek) {
    return of(
      asType<{ type: 'profiel-foto'; profiel: ProfielFotoData }>({
        type: 'profiel-foto',
        profiel: gesprek.getVisibleDeelnemer(this.user),
      }),
    );
  }

  private getCustomFotoFromGesprek(gesprekId: GesprekId, fotoId: FotoId) {
    return this.berichtenService
      .getGesprekFotoUrl(gesprekId, fotoId, 'SMALL')
      .pipe(switchMap((imageUrl) => getSecureImage(imageUrl)))
      .pipe(
        map((fotoData) =>
          asType<IParkourAvatarBadgeImageProps>({
            type: 'image',
            src: fotoData,
            alt: '',
          }),
        ),
      );
  }
}
