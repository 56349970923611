import { Injectable } from '@angular/core';
import Pusher, { Channel } from 'pusher-js';
import {
  combineLatest,
  dematerialize,
  filter,
  materialize,
  NEVER,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BerichtEvent, MeldingEvent, ProfielId, PusherConfig } from 'parkour-web-app-dto';
import { environment } from '../../../environments/environment';
import { App } from '@capacitor/app';
import { UserWithProfiel } from '../../user/model/user';
import { UserService } from '../../user/service/user.service';
import { LoggingService } from '../../core/logging.service';

type PusherContainer = {
  berichtenPusher: Pusher;
  meldingenPusher: Pusher;
};

type PusherEvent =
  | 'nieuw-bericht'
  | 'bericht-bewerkt'
  | 'nieuwe-melding'
  | 'bericht-verwijderd'
  | 'videogesprek-gestopt'
  | 'videogesprek-gestart'
  | 'videogesprek-call-gestopt'
  | 'team-changed'
  | 'contexten-changed';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  pusherContainer$ = new ReplaySubject<PusherContainer>(1);
  public readonly pusherReconnected$ = new Subject<void>();
  public readonly profielPusherChannel$: Observable<Channel | undefined> = combineLatest([
    this.userService.getCurrentUser$(),
    this.pusherContainer$,
  ]).pipe(
    switchMap(([user, pusherContainer]) => {
      if (user instanceof UserWithProfiel) {
        return this.createChannelObservable(
          pusherContainer.meldingenPusher,
          this.getProfielPusherChannel(user.profiel.id),
        );
      } else {
        return of(undefined);
      }
    }),
    shareReplay(1),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly loggingService: LoggingService,
  ) {
    Pusher.logToConsole = false;
    this.initializePusher();
  }

  getProfielPusherChannel(profielId: ProfielId) {
    return 'private-' + profielId;
  }

  getPusherAppKey(): Observable<PusherConfig> {
    // Authenticated user is required to fetch config
    return this.userService.getCurrentUser$().pipe(
      filter((user) => user instanceof UserWithProfiel),
      take(1),
      switchMap(() => this.http.get<PusherConfig>(`${environment.API_BASE_URL}/api/pusher/config`)),
    );
  }

  initializePusher() {
    this.getPusherAppKey().subscribe((berichtenConfig) => {
      const berichtenPusher = new Pusher(berichtenConfig.pusherAppKey, {
        cluster: 'eu',
        channelAuthorization: {
          endpoint: `${environment.API_BASE_URL}/api/gesprekken/notifications/auth`,
          transport: 'ajax',
        },
      });

      const meldingenPusher = new Pusher(berichtenConfig.pusherAppKey, {
        cluster: 'eu',
        channelAuthorization: {
          endpoint: `${environment.API_BASE_URL}/api/pusher/profiel/auth`,
          transport: 'ajax',
        },
      });
      this.loggingService.log('Pusher initialized');
      this.pusherContainer$.next({ berichtenPusher, meldingenPusher });

      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          this.attemptPusherReconnect();
        }
      });

      setInterval(() => this.attemptPusherReconnect(), 1000 * 30);
    });
  }

  attemptPusherReconnect() {
    this.pusherContainer$.pipe(take(1)).subscribe((pusherContainer) => {
      if (pusherContainer.berichtenPusher.connection.state === 'disconnected') {
        pusherContainer.berichtenPusher.connect();
      }
      if (pusherContainer.meldingenPusher.connection.state === 'disconnected') {
        pusherContainer.meldingenPusher.connect();
        this.pusherReconnected$.next();
      }
    });
  }

  createBerichtenPusherSubscription(
    channel: Observable<Channel | undefined>,
    eventName: PusherEvent,
  ): Observable<BerichtEvent> {
    return channel.pipe(
      take(1),
      switchMap((channel) => {
        if (channel) {
          return this.createObservableForPusherEvent<BerichtEvent>(channel, eventName);
        } else {
          this.loggingService.error('Channel is not available');
          return NEVER;
        }
      }),
    );
  }

  createPusherObservableForEvent(eventName: PusherEvent): Observable<MeldingEvent> {
    return this.profielPusherChannel$.pipe(
      switchMap((channel) => {
        if (channel) {
          return this.createObservableForPusherEvent<MeldingEvent>(channel, eventName);
        } else {
          return NEVER;
        }
      }),
      materialize(),
      filter((value) => {
        if (value.kind !== 'E') {
          return true;
        } else {
          this.loggingService.error(value.error);
          return false;
        }
      }),
      dematerialize(),
    );
  }

  private createChannelObservable(pusher: Pusher, channelName: string): Observable<Channel> {
    return new Observable<Channel>((subscriber) => {
      const channel = pusher.subscribe(channelName);
      subscriber.next(channel);

      return () => {
        channel.unsubscribe();
      };
    });
  }

  public createPusherChannel(channelName: string): Observable<Channel> {
    return this.pusherContainer$.pipe(
      switchMap((pusherContainer) =>
        this.createChannelObservable(pusherContainer.berichtenPusher, channelName),
      ),
    );
  }

  private createObservableForPusherEvent<Event>(
    channel: Channel,
    eventName: PusherEvent,
  ): Observable<Event> {
    return new Observable<Event>((subscriber) => {
      const handler = (event: Event) => {
        subscriber.next(event);
      };
      channel.bind(eventName, handler);

      return () => {
        channel.unbind(eventName, handler);
      };
    });
  }
}
