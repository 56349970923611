import { Component } from '@angular/core';
import { map, Observable, of, switchMap, take } from 'rxjs';
import { VerhalenService } from '../../../verhaal-shared/service/verhalen.service';
import { validateGebeurtenisId } from '../../../verhaal-shared/utils';
import { ActivatedRoute } from '@angular/router';
import { DrieKolommenDocumentDto } from 'parkour-web-app-dto';
import { meldingenForPage } from '../../../../meldingen/config';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe, NgIf } from '@angular/common';
import { ParkourPageImageComponent } from '@parkour/ui';
import { DominoGebeurtenisDetailsComponent } from '../../../verhaal-shared/component/drie-kolommen-gebeurtenis-details-component/drie-kolommen-gebeurtenis-details-component.component';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { CustomPageTitle, PageTitle } from '../../../../shared/custom-page-title';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../../shared/marks-meldingen-as-read';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

@Component({
  standalone: true,
  templateUrl: './drie-kolommen-gebeurtenis-details.page.html',
  imports: [
    ParkourBackButtonDirective,
    TranslateModule,
    AsyncPipe,
    ParkourPageImageComponent,
    DominoGebeurtenisDetailsComponent,
    NgIf,
    PageLayoutComponent,
    PageContentDirective,
    ParkourRefreshDirective,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonRefresher,
    IonRefresherContent,
  ],
})
export class DrieKolommenGebeurtenisDetailsPage
  extends ParkourDataPage<DrieKolommenDocumentDto>
  implements CustomPageTitle, MarksMeldingenAsReadOnView
{
  constructor(
    private readonly verhalenService: VerhalenService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslatePipe,
  ) {
    super();
  }

  override retrieveMainData(): Observable<DrieKolommenDocumentDto> {
    return this.route.paramMap.pipe(
      take(1),
      map((params) => params.get('gebeurtenis-id')),
      map(validateGebeurtenisId),
      switchMap((id) => this.verhalenService.getDocument(id)),
    );
  }

  getCustomPageTitle(): Observable<PageTitle> {
    const categoryName = this.translate.transform('verhaal.detail-page.domino-titel');
    const detail = this.translate.transform('verhaal.gebeurtenis-details-page.title');

    return of({ category: categoryName, title: detail });
  }

  getMeldingenToMarkAsRead() {
    return of(
      new MeldingenToMarkAsRead(
        meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.DRIE_KOLOMMEN_DOCUMENT_PAGE.specificMeldingen,
      ),
    );
  }
}
