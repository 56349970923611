import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { WatWatVideoContent } from 'parkour-web-app-dto';
import Player from '@vimeo/player';
import { ParkourCookiesService } from '../../../shared/services/parkour-cookies.service';
import { YouTubePlayer } from '@angular/youtube-player';
import { ParkourButtonComponent, ParkourInfoCardComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-artikel-video-content',
  templateUrl: './artikel-video-content.component.html',
  styleUrls: ['./artikel-video-component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [YouTubePlayer, ParkourInfoCardComponent, ParkourButtonComponent],
})
export class ArtikelVideoContentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input({ required: true }) content!: WatWatVideoContent;
  videoId: string | undefined | null = '';
  videoPlayerScript?: HTMLElement;
  @ViewChild('vimeoPlayer') vimeoPlayer!: ElementRef;

  cookiesEnabled = false;
  playerVars = {
    disablekb: 1,
  };

  constructor(protected readonly parkourCookiesService: ParkourCookiesService) {}

  ngOnInit() {
    this.cookiesEnabled = this.parkourCookiesService.isMarketingCookiesEnabled();

    if (this.cookiesEnabled && this.content.content.type === 'youtube') {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      this.videoPlayerScript = document.body.appendChild(tag);

      this.videoId = this.getYoutubeVideoId(this.content.content.youtube);
    }
  }

  getYoutubeVideoId(url: string): string | null {
    const urlObj = new URL(url);

    // Links can either be of format: https://www.youtube.com/watch?v=videoId or https://www.youtube.com/embed/videoId or https://youtu.be/cQuaabTvv4o

    if (urlObj.hostname === 'www.youtube.com' && urlObj.pathname === '/watch') {
      return urlObj.searchParams.get('v');
    } else if (urlObj.hostname === 'www.youtube.com' && urlObj.pathname.includes('/embed/')) {
      return urlObj.pathname.split('/embed/')[1];
    } else if (urlObj.hostname === 'youtu.be') {
      return urlObj.pathname.slice(1);
    } else {
      return null;
    }
  }

  ngAfterViewInit() {
    if (
      this.cookiesEnabled &&
      this.content.content.type === 'vimeo' &&
      this.vimeoPlayer.nativeElement
    ) {
      new Player(this.vimeoPlayer.nativeElement, {
        url: this.content.content.vimeo,
        keyboard: false,
      });
    }
  }

  ngOnDestroy() {
    if (this.videoPlayerScript) {
      document.body.removeChild(this.videoPlayerScript);
    }
  }

  onOpenCookieVoorkeuren() {
    this.parkourCookiesService.callback = () => window.location.reload();
    this.parkourCookiesService.openCookiePopup();
  }
}
