<div *ngIf="value" [ngClass]="classes" class="relative">
  <select
    (ngModelChange)="handleChange($event)"
    [(ngModel)]="value"
    [attr.data-testid]="testId"
    [attr.required]="required"
    [disabled]="disabled"
    [id]="selectId"
    [name]="selectId"
    [ngClass]="{
      'border-n-500 focus:border-n-600': !error && !disabled,
      'border-negative': error,
      'border-n-300': disabled,
    }"
    class="appearance-none w-full border-[1.5px] rounded min-h-[3rem] pl-3 pr-8 py-2 bg-n-200 transition-colors"
    parkourFocusVisibleElement
  >
    @for (option of options; track option.value) {
      <option [value]="option.value">
        {{ option.label }}
      </option>
    }
  </select>

  <parkour-icon
    class="absolute top-[50%] translate-y-[-50%] right-2"
    name="chevron-down"
    size="3"
  />
</div>
