<parkour-page-image
  classes="absolute top-0 left-0 z-0"
  src="/assets/verhaal/background/background_verhaal_blur.jpg"
/>

<div class="relative z-10 pt-4 pb-8 container container-space">
  <parkour-hero
    [image]="{
      sources: {
        lg: {
          src: '/assets/verhaal/background/lg/background_verhaal_lg.webp',
          fallback: '/assets/verhaal/background/lg/background_verhaal_lg.jpg',
        },
        md: {
          src: '/assets/verhaal/background/md/background_verhaal_md.webp',
          fallback: '/assets/verhaal/background/md/background_verhaal_md.jpg',
        },
        default: {
          src: '/assets/verhaal/background/default/background_verhaal_default.webp',
          fallback: '/assets/verhaal/background/default/background_verhaal_default.jpg',
        },
      },
    }"
  >
    <div class="flex flex-col items-center text-center container-1-col">
      <div class="flex items-center gap-x-2 mb-4 bg-n-200 rounded p-2">
        <parkour-image
          alt="Vlaamse Overheid"
          class="block w-[1.75rem] h-[1.75rem] bg-n-1000 p-1 rounded-sm"
          fit="contain"
          src="/assets/header/vlaamse-overheid-logo.svg"
        />

        <span class="typo-body variant-body-small variant-body-fat"> Opgroeien </span>
      </div>

      <p class="typo-body variant-body-fat">
        {{ 'verhaal.detail-page.sub-titel-domino' | translate }}
      </p>

      <h1>{{ 'verhaal.detail-page.domino-titel' | translate }}</h1>

      <p *ngIf="document.startDatum" class="text-n-600 typo-h5" data-testid="datum">
        {{ (document.startDatum | date) ?? '' }}
      </p>

      <parkour-divider class="mt-4" />
    </div>
  </parkour-hero>

  <div class="container-1-col">
    <div class="flex justify-center mt-4 mb-4">
      <parkour-gedeeld-met-indicator
        (clicked)="deelInfoModal.open()"
        [ariaLabel]="'delen.delen-voor-jou' | translate"
        [gedeeldMet]="[]"
        deelMode="SPECIFIEK"
        format="button"
        size="large"
        testId="deel-details-button"
      />
    </div>
    <parkour-info-card>
      {{ 'verhaal.detail-page.info-jongere-domino' | translate }}
    </parkour-info-card>

    @if (teleOnthaalOrganisation$ | async; as teleOnthaalOrganisation) {
      <parkour-helpline-card-item
        [organisation]="teleOnthaalOrganisation[0]"
        [segmentsToHulplijn]="['hulplijn', String(teleOnthaalOrganisation[0].id)]"
        class="block mt-8"
      />
    } @else {
      <parkour-helpline-card-item
        [organisation]="teleOnthaalOrganisation"
        [segmentsToHulplijn]="['hulplijn', String(teleOnthaalOrganisation.id)]"
        class="block mt-8"
      />
    }

    <ng-container *ngIf="zorgen">
      <h2 *ngIf="zorgen.length > 0" class="typo-h4 mt-8">
        {{ 'verhaal.detail-page.zorgen-titel' | translate }}
      </h2>
      <parkour-accordion [items]="zorgen" />
    </ng-container>
    <ng-container *ngIf="krachten">
      <h2 *ngIf="krachten.length > 0" class="typo-h4 mt-8">
        {{ 'verhaal.detail-page.krachten-titel' | translate }}
      </h2>
      <parkour-accordion [items]="krachten" />
    </ng-container>
    <ng-container *ngIf="acties">
      <h2 *ngIf="acties.length > 0" class="typo-h4 mt-8">
        {{ 'verhaal.detail-page.acties-titel' | translate }}
      </h2>
      <parkour-accordion [items]="acties" />
    </ng-container>
    <!-- TODO: UX Copy verhaal aan JSON toevoegen -->
    <parkour-modal #deelInfoModal [title]="'delen.delen-voor-jou' | translate" icon="lock">
      <parkour-info-card content>
        <p>Dit document wordt enkel met jou gedeeld uit het dossiersysteem van Jeugdhulp.</p>
      </parkour-info-card>
    </parkour-modal>
  </div>
</div>
