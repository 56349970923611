import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  catchError,
  combineLatest,
  map,
  mergeMap,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import {
  ArtikelId,
  ArtikelQueryResultDto,
  BookmarkDto,
  BookmarkId,
  BookmarksDto,
  OrganisationId,
  Thema,
  WatWatArtikelDto,
  WatWatOrganisation,
  WatWatOrganisationMethodType,
  WatWatSelfTest,
} from 'parkour-web-app-dto';
import { environment } from 'src/environments/environment';
import { asType, repeatableIgnoreErrorsExceptInitial } from '../../utils';
import {
  ArtikelQueryResult,
  OrganisationWithBookmark,
  OrganisationWithBookmarkQueryResult,
  OrganisationWithOpen,
  WatWatArtikel,
  WatWatArtikelSummary,
} from '../model/artikel';
import { OrganisationsQueryResult } from '../model/organisations';
import { BookmarkService } from './bookmark.service';
import { LoggingService } from '../../core/logging.service';
import AuthService from '../../authentication/service/auth.service';

export type ArtikelWithBookmarkQueryResult = {
  total: number;
  pageSize: number;
  results: ArtikelWithBookmark[] | ArtikelSummaryWithBookmark[];
};

export type ArtikelWithBookmark = WatWatArtikel & {
  bookmarkId?: BookmarkId;
};

export type ArtikelSummaryWithBookmark = WatWatArtikelSummary & {
  bookmarkId?: BookmarkId;
};

export const teleOnthaalOrganisation: OrganisationWithBookmark = {
  image: '/assets/gebeurtenis/tele-onthaal.jpg',
  helpOrganisationText: '',
  title: 'Tele-Onthaal',
  url: 'https://www.tele-onthaal.be/',
  description: `Zit je met iets? Praten helpt. Tele-Onthaal staat dag en nacht voor je klaar. Anoniem, voor kleine of grote zorgen. Bel 106 of chat anoniem op tele-onthaal.be`,
  methods: [],
  id: 76 as OrganisationId,
  bookmarkId: undefined,
  aangemaaktOpTijdstip: undefined,
  content: [],
};

@Injectable({
  providedIn: 'root',
})
export class WatwatService {
  enrichArtikelsWithBookmarks = switchMap((artikelResult: ArtikelQueryResult) => {
    const artikelIds = artikelResult.results.map((artikel) => String(artikel.id));

    return this.bookmarkService.getBookmarks('ARTIKEL', artikelIds).pipe(
      map((bookmarks) => {
        const artikelsWithBookmark = artikelResult.results.map((artikel) => ({
          ...artikel,
          bookmarkId: bookmarks.find(
            (bookmark: BookmarkDto) => Number(bookmark.itemId) === artikel.id,
          )?.id,
        }));

        return {
          results: artikelsWithBookmark,
          pageSize: artikelResult.pageSize,
          total: artikelResult.total,
        };
      }),
    );
  });
  enrichArtikelWithBookmark = switchMap((artikel: WatWatArtikel) => {
    return this.bookmarkService.getBookmarks('ARTIKEL', [String(artikel.id)]).pipe(
      map((bookmarks) => ({
        ...artikel,
        bookmarkId: bookmarks.find(
          (bookmark: BookmarkDto) => Number(bookmark.itemId) === artikel.id,
        )?.id,
      })),
    );
  });

  private getAllOrganisationsWithoutBookmarks() {
    return this.getOrganisations({ amount: 100 }).pipe(
      map((result) => result.results),
      switchMap((organisations) => this.enrichOrganisationWithNowOpen(organisations)),
      catchError(() => of([teleOnthaalOrganisation])),
    );
  }

  public getSuggestedArtikels(
    thema: Thema,
    maxArtikels: number = 5,
  ): Observable<ArtikelSummaryWithBookmark[]> {
    return this.getBewaardeArtikelsThema$(thema, maxArtikels).pipe(
      switchMap((bewaardeArtikels) => {
        return this.getArtikelsThema$(thema, maxArtikels + bewaardeArtikels.length).pipe(
          switchMap((artikels) => {
            const filteredArtikels = artikels.filter((artikel) => {
              return !bewaardeArtikels.some((bewaardeArtikel) => bewaardeArtikel.id === artikel.id);
            });
            return of([...bewaardeArtikels, ...filteredArtikels].slice(0, maxArtikels));
          }),
        );
      }),
    );
  }

  private getBewaardeArtikelsThema$(
    thema: Thema,
    amount: number,
  ): Observable<ArtikelSummaryWithBookmark[]> {
    return this.bookmarkService.getBookmarks('ARTIKEL', undefined, amount, 0).pipe(
      switchMap((bookmarks: BookmarksDto) => {
        const bookmarkItemIds = bookmarks.map((bookmark: BookmarkDto) => bookmark.itemId);

        if (bookmarkItemIds.length === 0) {
          return of([]);
        }

        return this.getArtikelSummaries({
          thema,
          artikelIds: bookmarkItemIds,
          amount,
        }).pipe(map((artikels) => artikels.results));
      }),
    );
  }

  private getArtikelsThema$(thema: Thema, amount: number) {
    return this.getArtikelSummaries({ thema, amount }).pipe(map((artikels) => artikels.results));
  }

  public getBookmarksWithOrganisation(): Observable<OrganisationWithBookmark[]> {
    return this.getAllOrganisationsWithoutBookmarks().pipe(
      switchMap((organisations) => this.enrichOrganisationsWithBookmarks(organisations)),
      map((organisations) =>
        organisations.filter((organisation) => organisation.bookmarkId !== undefined),
      ),
      map((organisations) => {
        return organisations.sort((a, b) => {
          if (a.aangemaaktOpTijdstip && b.aangemaaktOpTijdstip) {
            return (
              new Date(b.aangemaaktOpTijdstip).valueOf() -
              new Date(a.aangemaaktOpTijdstip).valueOf()
            );
          }
          return 0;
        });
      }),
    );
  }

  private getOpenTelOrganisations() {
    return this.getOrganisations({
      amount: 100,
      nowOpen: true,
      type: 'tel',
    }).pipe(
      map((result) => result.results),
      catchError(() => {
        this.loggingService.error('Failed to get open tel organisations');
        return of([]);
      }),
    );
  }

  private getOpenChatOrganisations() {
    return this.getOrganisations({
      amount: 100,
      nowOpen: true,
      type: 'chat',
    }).pipe(
      map((result) => result.results),
      catchError(() => {
        this.loggingService.error('Failed to get open chat organisations');
        return of([]);
      }),
    );
  }

  private readonly refreshBookmarks$ = new Subject<void>();
  private getAllOrganisationsWithBookmarks() {
    return this.getAllOrganisationsWithoutBookmarks()
      .pipe(
        switchMap((organisations) => {
          const organisationIds = organisations.map((organisation) => organisation.id);
          return repeatableIgnoreErrorsExceptInitial(
            () =>
              this.bookmarkService.getBookmarks(
                'HULPLIJN',
                organisationIds.map((id) => String(id)),
              ),
            [],
            this.refreshBookmarks$,
          );
        }),
      )
      .pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );
  }

  constructor(
    private httpClient: HttpClient,
    private readonly bookmarkService: BookmarkService,
    private readonly authService: AuthService,
    private readonly loggingService: LoggingService,
  ) {
    this.authService.user$.subscribe(() => this.updateOrganisationBookmarks());
  }

  enrichOrganisationWithNowOpen(
    organisations: WatWatOrganisation[],
  ): Observable<OrganisationWithOpen[]> {
    return combineLatest([this.getOpenTelOrganisations(), this.getOpenChatOrganisations()]).pipe(
      map(([allOpenTelOrganisations, allOpenChatOrganisations]) => {
        return this.calculateOpenForOrganisations(
          allOpenTelOrganisations,
          allOpenChatOrganisations,
          organisations,
        );
      }),
    );
  }

  calculateOpenForOrganisations(
    openTelOrganisations: WatWatOrganisation[],
    openChatOrganisations: WatWatOrganisation[],
    organisations: WatWatOrganisation[],
  ): OrganisationWithOpen[] {
    const openTelIds = new Set(openTelOrganisations.map((line) => line.id));
    const openChatIds = new Set(openChatOrganisations.map((line) => line.id));

    return organisations.map((allItem) => ({
      ...allItem,
      methods: allItem.methods.map((method) => ({
        ...method,
        nowOpen:
          (method.type === 'tel' && openTelIds.has(allItem.id)) ||
          (method.type === 'chat' && openChatIds.has(allItem.id)),
      })),
    }));
  }

  enrichOrganisationsWithBookmarks(
    organisations: OrganisationWithOpen[],
  ): Observable<OrganisationWithBookmark[]> {
    return this.getAllOrganisationsWithBookmarks().pipe(
      map((bookmarks) => {
        return organisations.map((organisation) => {
          const bookmark = bookmarks.find(
            (bookmark: BookmarkDto) => Number(bookmark.itemId) === organisation.id,
          );
          return {
            ...organisation,
            bookmarkId: bookmark?.id,
            aangemaaktOpTijdstip: bookmark?.aangemaaktOpTijdstip,
          };
        });
      }),
    );
  }

  updateOrganisationBookmarks() {
    this.refreshBookmarks$.next();
  }

  private fetchArtikels(options: {
    query?: string;
    artikelIds?: string[];
    page?: number;
    thema?: Thema;
    amount?: number;
  }): Observable<ArtikelQueryResultDto> {
    const params: Record<string, string | number | string[]> = {};

    if (options.query) {
      params['query'] = options.query;
    }

    if (options.artikelIds !== undefined) {
      params['artikelIds'] = options.artikelIds;
    }

    params['page'] = options.page ?? 1;

    if (options.thema) {
      params['thema'] = options.thema;
    }

    if (options.amount) {
      params['amount'] = options.amount;
    }

    return this.httpClient.get<ArtikelQueryResultDto>(
      `${environment.API_BASE_URL}/api/watwat/artikels`,
      {
        params,
      },
    );
  }

  getArtikelSummaries(options: {
    query?: string;
    artikelIds?: string[];
    page?: number;
    thema?: Thema;
    amount?: number;
  }): Observable<ArtikelWithBookmarkQueryResult> {
    return this.fetchArtikels({
      query: options.query,
      artikelIds: options.artikelIds,
      page: options.page,
      thema: options.thema,
      amount: options.amount,
    }).pipe(
      map((artikelQueryResultDto: ArtikelQueryResultDto) => {
        const artikels: WatWatArtikelSummary[] = artikelQueryResultDto.results.map((artikel) => ({
          ...artikel,
        }));
        return asType<ArtikelQueryResult>({
          pageSize: artikelQueryResultDto.pageSize,
          total: artikelQueryResultDto.total,
          results: this.sortByIds(artikels, options.artikelIds),
        });
      }),
      this.enrichArtikelsWithBookmarks,
    );
  }

  getArtikel(id: ArtikelId): Observable<ArtikelWithBookmark> {
    return this.httpClient
      .get<WatWatArtikelDto>(`${environment.API_BASE_URL}/api/watwat/artikels/${id}`)
      .pipe(
        mergeMap((artikel) => this.enrichArtikelWithOrganisations(artikel)),
        this.enrichArtikelWithBookmark,
      );
  }

  enrichArtikelWithOrganisations(artikel: WatWatArtikelDto): Observable<WatWatArtikel> {
    return this.getAllOrganisationsWithoutBookmarks().pipe(
      map((organisations) => {
        const watWatOrganisations: OrganisationWithOpen[] = artikel.helplines
          .map((helpline) => helpline.organisation)
          .filter((item, index, list) => list.indexOf(item) === index)
          .map((organisationId) =>
            organisations.find((organisation) => Number(organisation.id) === organisationId),
          )
          .filter(
            (organisation): organisation is OrganisationWithOpen => organisation !== undefined,
          )
          .map((organisation) => {
            const methods = organisation.methods.filter(
              (method) =>
                artikel.helplines.find(
                  (help) => help.organisation === organisation.id && help.method === method.type,
                ) !== undefined,
            );
            return {
              ...organisation,
              methods,
            };
          });
        return {
          ...artikel,
          helplines: watWatOrganisations,
        };
      }),
    );
  }

  getOrganisationsOfIds(ids: number[]): Observable<OrganisationWithBookmark[]> {
    return this.getAllOrganisationsWithoutBookmarks().pipe(
      map((organisations) => organisations.filter((organisation) => ids.includes(organisation.id))),
      switchMap((organisations) => this.enrichOrganisationWithNowOpen(organisations)),
      switchMap((organisations) => this.enrichOrganisationsWithBookmarks(organisations)),
    );
  }

  getMostRecentOrganisations(notIds: number[]): Observable<OrganisationWithBookmark[]> {
    return this.getAllOrganisationsWithoutBookmarks().pipe(
      map((organisations) =>
        organisations.filter((organisation) => !notIds.includes(organisation.id)),
      ),
      map((organisations) => organisations.slice(0, 3)),
      switchMap((organistations) => this.enrichOrganisationsWithBookmarks(organistations)),
    );
  }

  public getEnrichedOrganisations(options: {
    type?: WatWatOrganisationMethodType;
    nowOpen?: boolean;
    query?: string;
    page?: number;
  }): Observable<OrganisationWithBookmarkQueryResult> {
    return this.getOrganisations(options).pipe(
      switchMap((queryResult) =>
        this.enrichOrganisationWithNowOpen(queryResult.results).pipe(
          switchMap((organisations) =>
            this.enrichOrganisationsWithBookmarks(organisations).pipe(
              map((enrichedOrganisations) => ({
                total: queryResult.total,
                pageSize: queryResult.pageSize,
                results: enrichedOrganisations,
              })),
            ),
          ),
        ),
      ),
    );
  }

  getSelfTests(ids: number[]): Observable<WatWatSelfTest[]> {
    if (ids.length < 1) {
      return of([]);
    }

    let params = new HttpParams();

    if (ids.length > 0) {
      params = params.append('id', ids.join(','));
    }

    return this.httpClient.get<WatWatSelfTest[]>(
      `${environment.API_BASE_URL}/api/watwat/self-tests`,
      {
        params,
      },
    );
  }

  deleteOrganisationBookmark(bookmarkId: BookmarkId): Observable<void> {
    return this.bookmarkService
      .deleteBookmark(bookmarkId)
      .pipe(tap(() => this.updateOrganisationBookmarks()));
  }

  addOrganisationBookmark(hulplijnId: OrganisationId) {
    return this.bookmarkService
      .addBookmark({
        itemId: String(hulplijnId),
        itemType: 'HULPLIJN',
      })
      .pipe(tap(() => this.updateOrganisationBookmarks()));
  }

  private sortByIds(artikels: WatWatArtikel[] | WatWatArtikelSummary[], ids?: string[]) {
    if (ids === undefined) {
      return artikels;
    }

    return artikels.sort((a, b) => {
      return ids.indexOf(String(a.id)) - ids.indexOf(String(b.id));
    });
  }

  private getOrganisations(options: {
    type?: WatWatOrganisationMethodType;
    nowOpen?: boolean;
    query?: string;
    organisationIds?: OrganisationId[];
    page?: number;
    amount?: number;
  }): Observable<OrganisationsQueryResult> {
    const params: Record<string, string | number | OrganisationId[] | boolean> = {};

    if (options.query) {
      params['query'] = options.query;
    }

    if (options.organisationIds !== undefined) {
      params['organisationIds'] = options.organisationIds;
    }

    if (options.page) {
      params['page'] = options.page;
    }

    if (options.amount) {
      params['amount'] = options.amount;
    }

    if (options.type) {
      params['type'] = options.type;
    }

    if (options.nowOpen) {
      params['nowOpen'] = options.nowOpen;
    }

    return this.httpClient.get<OrganisationsQueryResult>(
      `${environment.API_BASE_URL}/api/watwat/organisations`,
      {
        params,
      },
    );
  }
}
