<div class="bg-n-200 rounded p-4">
  <div class="flex items-center justify-between gap-x-2">
    <div class="flex items-center gap-x-2">
      <parkour-icon class="text-n-600" name="clock-snooze" />

      <h5>{{ 'profiel.unavailable' | translate }}</h5>
    </div>

    <parkour-toggle
      (toggleChange)="setAfwezigheid($event)"
      [ariaLabel]="'Afwezigheid staat uit'"
      [checked]="afwezigheidEnabled"
      class="flex"
    />
  </div>

  @if (afwezigheidEnabled) {
    <div class="mt-4 space-y-2">
      <parkour-form-field
        [additionalErrors]="getVanErrors()"
        [errorMessages]="{
          dateRangeInvalid: 'De gekozen datum mag niet verder liggen dan de tot datum.',
        }"
        [inputFormControl]="afwezigFormGroup.controls.van"
        [label]="'profiel.unavailable-from' | translate"
        [required]="true"
        class="block"
        inputId="vanUur"
      >
        <parkour-input
          [formControl]="afwezigFormGroup.controls.van"
          class="block"
          inputId="vanUur"
          inputPlaceholder="van"
          type="date"
        />
      </parkour-form-field>

      <parkour-form-field
        [inputFormControl]="afwezigFormGroup.controls.tot"
        [label]="'profiel.unavailable-to' | translate"
        class="block"
        inputId="totUur"
      >
        <parkour-input
          [formControl]="afwezigFormGroup.controls.tot"
          class="block"
          inputId="totUur"
          inputPlaceholder="tot"
          type="date"
        />
      </parkour-form-field>

      <parkour-form-field
        [inputFormControl]="afwezigFormGroup.controls.bericht"
        [label]="'profiel.unavailable-message' | translate"
        class="block"
        inputId="bericht"
      >
        <parkour-textarea
          [formControl]="afwezigFormGroup.controls.bericht"
          class="block"
          inputId="bericht"
        />
      </parkour-form-field>
    </div>
  }
</div>
