import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ParkourButtonComponent, ParkourModalComponent } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { OrganisationWithBookmark, WatWatArtikel } from '../../../shared/model/artikel';
import { ArtikelCardComponent } from '../artikel-card/artikel-card.component';
import { IsUserWithProfielDirective } from '../../../shared/directives/is-user-with-profiel.directive';
import { AsyncPipe, NgForOf } from '@angular/common';
import { GesprekOverviewCardPage } from '../../../shared/components/gesprek-overview-card/gesprek-overview-card.component';
import { Gesprek } from '../../../bericht/model/gesprek';
import { UserWithProfiel } from '../../../user/model/user';
import { HelplineCardItemComponent } from '../../../home/component/helpline-card-item/helpline-card-item.component';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import { combineResults, Result } from '../../../utils';
import { UserService } from '../../../user/service/user.service';
import { BerichtenService } from '../../../bericht/service/berichten.service';
import { GesprekId, ProfielId } from 'parkour-web-app-dto';
import { Context } from '../../../shared/model/context';
import { ContextService } from '../../../shared/services/context.service';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';

export interface IShareArtikel {
  gesprekId: GesprekId;
  gesprekNaam: string;
  resourceId: string;
  profielId: ProfielId;
}

@Component({
  selector: 'parkour-share-button',
  standalone: true,
  imports: [
    TranslateModule,
    ArtikelCardComponent,
    IsUserWithProfielDirective,
    AsyncPipe,
    GesprekOverviewCardPage,
    NgForOf,
    HelplineCardItemComponent,
    ParkourButtonComponent,
    ParkourModalComponent,
    PageContentDirective,
  ],
  templateUrl: './share-button.component.html',
})
export class ShareButtonComponent implements OnInit {
  @ViewChild(ParkourModalComponent) parkourModal!: ParkourModalComponent;

  @Input() artikel?: WatWatArtikel;
  @Input() organisation?: OrganisationWithBookmark;

  @Output() public shareArtikelOrOrganisation: EventEmitter<IShareArtikel> =
    new EventEmitter<IShareArtikel>();

  data$: Observable<
    Result<{
      user: UserWithProfiel;
      gesprekken: Gesprek[];
    }>
  > = new Observable();
  context$: Observable<Context> = new Observable();

  constructor(
    private readonly userService: UserService,
    private readonly berichtenService: BerichtenService,
    private readonly contextService: ContextService,
  ) {}

  ngOnInit() {
    this.data$ = combineLatest({
      user: this.userService.userWithProfiel$(),
      gesprekken: this.berichtenService.getGesprekken(),
    }).pipe(map(combineResults));

    this.context$ = this.contextService.context$.pipe(shareReplay(1));
  }

  openModal() {
    this.parkourModal.open();
  }

  onShare(gesprek: Gesprek, user: UserWithProfiel) {
    this.shareArtikelOrOrganisation.emit({
      gesprekId: gesprek.id,
      gesprekNaam: gesprek.getGesprekNaam(user),
      resourceId: this.organisation ? `${this.organisation?.id}` : `${this.artikel?.id}`,
      profielId: user.profiel.id,
    });

    this.parkourModal.close();
  }
}
