<div class="flex items-center justify-center w-full h-[9rem]">
  @if (artikel$ | async; as artikel) {
    @if (artikel.success) {
      <parkour-article-card
        (clickedBookmark)="onBookmarkClicked($event, artikel.value.id, artikel.value.bookmarkId)"
        [image]="{ src: artikel.value.image, alt: '' }"
        [isBookmarked]="!!artikel.value.bookmarkId"
        [link]="['artikel', String(artikel.value.id)]"
        [themes]="artikel.value.themas"
        [title]="artikel.value.title"
        class="block w-full rounded border-[1px] border-solid border-n-500"
      >
        <ng-content></ng-content>
      </parkour-article-card>
    } @else {
      <parkour-content-unavailable-card class="block w-full" />
    }
  } @else {
    <parkour-loading-spinner />
  }
</div>
