<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title>{{ 'berichten.bericht-probleem-add-page.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <h1 class="text-center mb-4">
          {{ 'berichten.bericht-probleem-add-page.title' | translate }}
        </h1>

        <parkour-divider />

        <section class="mt-8">
          <p>{{ 'berichten.bericht-probleem-add-page.description' | translate }}</p>

          @if (bericht$ | async; as bericht) {
            @if (bericht | isSupportedBericht) {
              <div class="bg-n-300 p-2 rounded mt-4">
                <p>{{ 'berichten.bericht-probleem-add-page.report-bericht' | translate }}</p>

                @if (gesprek$ | async; as gesprek) {
                  <div class="bg-n-200 rounded mt-2">
                    @switch (bericht.type) {
                      @case ('TEKST') {
                        <parkour-bericht-text-wrapper
                          [bericht]="bericht"
                          [isUser]="true"
                          [disabled]="true"
                        >
                          <parkour-bericht-text-content
                            [gesprek]="gesprek"
                            [bericht]="bericht"
                            [isNameVisible]="true"
                          />
                        </parkour-bericht-text-wrapper>
                      }
                      @case ('ATTACHMENT') {
                        <parkour-bericht-attachment-content
                          [gesprekId]="gesprekId"
                          [gesprek]="gesprek"
                          [bericht]="bericht"
                          [isUser]="true"
                          [disabled]="true"
                          [isNameVisible]="true"
                        />
                      }
                      @case ('SPRAAK') {
                        <parkour-bericht-speech-wrapper
                          [bericht]="bericht"
                          [isUser]="true"
                          [disabled]="true"
                        >
                          <parkour-bericht-speech-content
                            [gesprek]="gesprek"
                            [bericht]="bericht"
                            [isNameVisible]="true"
                          />
                        </parkour-bericht-speech-wrapper>
                      }
                    }
                  </div>
                } @else {
                  <div class="mt-2 min-h-[3rem] flex items-center justify-center">
                    <parkour-loading-spinner />
                  </div>
                }
              </div>
            }
          }

          <form
            (ngSubmit)="submitForm()"
            [formGroup]="berichtProbleemForm"
            class="mt-8 space-y-8"
            id="bericht-probleem-form"
          >
            <parkour-form-field
              [inputFormControl]="berichtProbleemForm.controls.type"
              [label]="'berichten.bericht-probleem-add-page.form.type' | translate"
              [required]="true"
              class="block"
              inputId="bericht-probleem-type"
            >
              <parkour-select
                [formControl]="berichtProbleemForm.controls.type"
                [options]="typeOptions"
                [required]="true"
                placeholder=""
                selectId="bericht-probleem-type"
              />
            </parkour-form-field>

            <parkour-form-field
              [errorMessages]="{ required: 'Vul een beschrijving in.' }"
              [inputFormControl]="berichtProbleemForm.controls.beschrijving"
              [label]="'berichten.bericht-probleem-add-page.form.beschrijving' | translate"
              [required]="true"
              class="block"
              inputId="bericht-probleem-beschrijving"
            >
              <parkour-textarea
                [required]="true"
                class="block"
                formControlName="beschrijving"
                inputId="bericht-probleem-beschrijving"
              />
            </parkour-form-field>

            @if (user$ | async; as user) {
              @if (user && hasProfiel(user)) {
                @if (user.profiel.email) {
                  <p>
                    {{ 'profiel.probleem-page.reminder-email' | translate }}
                    <span class="typo-body variant-body-fat">{{ user.profiel.email }}</span>
                  </p>
                }

                @if (user.profiel.telefoonnummer) {
                  <p>
                    {{ 'profiel.probleem-page.reminder-phone' | translate }}
                    <span class="typo-body variant-body-fat">{{
                      user.profiel.telefoonnummer
                    }}</span>
                  </p>
                }
              }
            }

            <parkour-info-card [style]="'bg-n-200'" class="block">
              <p>{{ 'profiel.probleem-page.fyi' | translate }}</p>
            </parkour-info-card>

            <parkour-button class="block w-full mt-10" iconAfter="paper-plane" type="submit">
              {{ 'buttons.message-report' | translate }}
            </parkour-button>
          </form>
        </section>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
