<div
  class="relative w-full aspect-[4/3] flex items-end p-4 rounded overflow-hidden group cursor-pointer has-[:focus-visible]:outline-[3px] has-[:focus-visible]:outline-[yellow] has-[:focus-visible]:outline-dashed has-[:focus-visible]:outline-offset-[2px]"
>
  <parkour-picture
    [sources]="{
      default: {
        src: themaMapping[thema].smallImage.src,
        fallback: themaMapping[thema].smallImage.fallback,
      },
    }"
    alt=""
    class="absolute inset-0 z-10"
  />

  <div
    class="absolute inset-0 z-20 bg-gradient-to-t to-transparent from-n-100 bg-transparent group-hover:bg-n-0-rgb-2 group-has-[:focus]:bg-n-0-rgb-2 transition-colors"
  ></div>

  <p class="z-30 typo-body variant-body-fat text-n-1000 transition-transform">
    @if (isHidden) {
      <parkour-action
        [link]="['artikels']"
        [queryParams]="{ thema }"
        [tabIndex]="-1"
        classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-30 focus-visible:outline-none"
      >
        {{ themaMapping[thema].label }}
      </parkour-action>
    } @else {
      <parkour-action
        [link]="['artikels']"
        [queryParams]="{ thema }"
        classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-30 focus-visible:outline-none"
      >
        {{ themaMapping[thema].label }}
      </parkour-action>
    }
  </p>
</div>
