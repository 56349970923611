import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { WatwatService } from '../../../shared/services/watwat.service';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ParkourActionComponent, ParkourIconComponent, ParkourImageComponent } from '@parkour/ui';
import { BookmarkButtonComponent } from '../../../shared/components/bookmark-button/bookmark-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { HelplineIconListComponent } from '../helpline-icon-list/helpline-icon-list.component';
import { OrganisationWithBookmark } from '../../../shared/model/artikel';
import { WatWatOrganisationMethodType } from 'parkour-web-app-dto';

const methodTypeLabels: Record<WatWatOrganisationMethodType, string> = {
  activity: 'Activiteit',
  chat: 'Chat',
  forum: 'Forum',
  mail: 'Mail',
  tel: 'Bel',
  visit: 'Bezoek',
};

function getMethodLabel(type: WatWatOrganisationMethodType): string {
  if (type in methodTypeLabels) {
    return methodTypeLabels[type];
  } else {
    return type; // Fallback to the type itself if unknown type
  }
}

@Component({
  selector: 'parkour-helpline-card-item',
  standalone: true,
  imports: [
    RouterLink,
    NgTemplateOutlet,
    NgClass,
    ParkourImageComponent,
    ParkourActionComponent,
    BookmarkButtonComponent,
    ParkourIconComponent,
    TranslateModule,
    HelplineIconListComponent,
  ],
  templateUrl: './helpline-card-item.component.html',
})
export class HelplineCardItemComponent {
  @Input({ required: true }) organisation!: OrganisationWithBookmark;
  @Input({ required: true }) segmentsToHulplijn!: string[] | false;
  @Input() public isBookmarkable: boolean = true;
  protected readonly String = String;

  constructor(private readonly watwatService: WatwatService) {}

  onClickedBookmark() {
    if (this.organisation.bookmarkId) {
      this.watwatService.deleteOrganisationBookmark(this.organisation.bookmarkId).subscribe();
    } else {
      this.watwatService.addOrganisationBookmark(this.organisation.id).subscribe();
    }
  }

  getOpenMethods() {
    return this.organisation.methods
      .filter((method) => method.nowOpen)
      .map((method) => getMethodLabel(method.type))
      .join(', ');
  }

  getAriaLabel() {
    return `${this.organisation.title}${
      this.getOpenMethods() ? ', Nu open: ' + this.getOpenMethods() + '.' : '.'
    }`;
  }
}
