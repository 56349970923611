import { Routes } from '@angular/router';
import { VerhaalForTeamlidPage } from './page/verhaal-for-teamlid-page/verhaal-for-teamlid.page';
import { GebeurtenisDetailsForTeamlidPage } from './page/gebeurtenis-details-for-teamlid-page/gebeurtenis-details-for-teamlid-page';
import { specificHulplijnRoute } from '../../watwat/routes';
import { GebeurtenisSuggestieAddPage } from './page/gebeurtenis-suggestie-add-page/gebeurtenis-suggestie-add.page';
import { dirtyFormGuard } from '../../shared/components/dirty-form-guard';

export const verhaalTeamlidRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: VerhaalForTeamlidPage,
  },
  {
    path: 'suggereer',
    component: GebeurtenisSuggestieAddPage,
    canDeactivate: [dirtyFormGuard],
  },
  {
    path: ':type/:gebeurtenis-id',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: GebeurtenisDetailsForTeamlidPage,
      },
      specificHulplijnRoute,
    ],
  },
];
