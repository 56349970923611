<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title role="heading">{{ 'profiel.mijn-profiel-page.title' | translate }}</ion-title>

    <ion-buttons slot="end">
      <parkour-header-action
        [label]="'profiel.mijn-profiel-page.profiel-edit' | translate"
        [link]="['wijzig']"
        form="doel-edit-form"
        icon="edit"
        testId="edit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <div
        *ngIf="data.user && data.hoedanigheid && data.mijnProfielen && hasProfiel(data.user)"
        class="container-1-col"
      >
        <parkour-profiel-details
          (createPersoonlijkProfiel)="onCreatePersoonlijkProfiel()"
          (createProfessioneelProfiel)="createProfessioneelProfiel()"
          (profielSelect)="onProfielSelect($event)"
          [mode]="{
            type: 'JONGERE',
            profielen: data.mijnProfielen,
            externeHoedanigheid: data.hoedanigheid,
          }"
          [privateInfo]="data.user.profiel"
          [profiel]="data.user.profiel"
        ></parkour-profiel-details>
        <ng-template #profielFailure>
          <parkour-content-unavailable-card />
        </ng-template>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
