<ion-header></ion-header>

<ion-content>
  <ion-refresher slot="fixed" [parkourRefresh]="onPageRefresh">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let profiel">
      <parkour-page-image
        classes="absolute top-0 left-0 z-0"
        src="/assets/verhaal/background/background_verhaal_blur.jpg"
      />

      <div class="relative z-10 pt-4 pb-8 container container-space flex flex-col gap-y-8">
        <parkour-hero
          [image]="{
            sources: {
              lg: {
                src: '/assets/verhaal/background/lg/background_verhaal_lg.webp',
                fallback: '/assets/verhaal/background/lg/background_verhaal_lg.jpg',
              },
              md: {
                src: '/assets/verhaal/background/md/background_verhaal_md.webp',
                fallback: '/assets/verhaal/background/md/background_verhaal_md.jpg',
              },
              default: {
                src: '/assets/verhaal/background/default/background_verhaal_default.webp',
                fallback: '/assets/verhaal/background/default/background_verhaal_default.jpg',
              },
            },
          }"
        >
          <div class="flex flex-col items-center gap-y-4 text-center container-1-col">
            <h1>{{ 'verhaal.overview-page.title' | translate }}</h1>

            <parkour-divider />
          </div>
        </parkour-hero>

        <div class="container-1-col space-y-8">
          <p class="text-center">
            @if (profiel.profielType === 'PROFESSIONEEL') {
              {{ 'verhaal.overview-page.no-team-text-professioneel' | translate }}
            } @else {
              {{ 'verhaal.overview-page.no-team-text' | translate }}
            }
          </p>

          <parkour-block-placeholder
            [sources]="{
              default: {
                src: '/assets/verhaal/placeholder/placeholder_verhaal_default.svg',
              },
              md: {
                src: '/assets/verhaal/placeholder/placeholder_verhaal_md.svg',
              },
            }"
            alt=""
            class="block"
          />
        </div>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
