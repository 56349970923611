<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="['..']" />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'doelen.doel-detail-page.title' | translate }}
    </ion-title>

    <ion-buttons *parkourShowIfSuccessful="mainPageData$ | async; let doel" slot="end">
      <parkour-header-action
        *ngIf="!doel.isBehaald"
        [label]="'doelen.doel-detail-page.doel-bewerken' | translate"
        [link]="['edit']"
        form="doel-new-form"
        icon="edit"
        testId="edit"
      />

      <parkour-header-action
        *ngIf="doel.isBehaald"
        [label]="'doelen.doel-detail-page.doel-bewerken' | translate"
        [link]="['edit-behaald']"
        form="doel-new-form"
        icon="edit"
        testId="edit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let doel">
      <article>
        <parkour-page-image
          [src]="themaMapping[doel.thema].largeImage.blur"
          classes="absolute top-0 left-0 z-0"
        />

        <div class="relative z-10 pt-4 pb-8 container container-space flex flex-col gap-y-8">
          <parkour-doel-details
            (actiesModified)="updateActies($event)"
            (ignoreGebeurtenisSuggestie)="ignoreGebeurtenisSuggestie($event)"
            [contextType]="'jongere'"
            [doel]="doel"
            [editable]="true"
          />

          <div *ngIf="!doel.isBehaald" class="flex flex-col items-center mt-4 container-1-col">
            <p *ngIf="doel.alleActiesZijnVoltooid()" class="typo-body variant-body-fat mb-4">
              {{ 'doelen.doel-detail-page.alle-acties-afgevinkt' | translate }}
            </p>

            <parkour-button (clicked)="completeDoel(doel)" class="w-full" iconAfter="check">
              {{ 'doelen.doel-detail-page.doel-voltooien' | translate }}
            </parkour-button>
          </div>

          <parkour-animation-modal
            (completed)="doelCompleteAnimationOpen = false"
            [isOpen]="doelCompleteAnimationOpen"
            [src]="environment.API_BASE_URL + '/assets/doelen/animatie_doel_behaald.json'"
          />

          <ng-container *parkourSectionContent="{ data: gesprekData$ | async }; let gesprekData">
            <parkour-reactie
              (reactieSelect)="onInteractieSelect(doel.id, $event)"
              (reactieUnSelect)="onInteractieUnselect(doel.id, $event)"
              *parkourSectionContent="{ data: interacties$ | async }; let interacties"
              [reactieOverview]="interacties"
              [userProfielId]="gesprekData.user.profiel.id"
              class="block container-1-col"
            />
            <parkour-doel-berichten
              [doelId]="doel.id"
              [gesprek]="gesprekData.gesprek"
              [user]="gesprekData.user"
              class="block container-1-col"
            />
          </ng-container>
        </div>
      </article>
    </ng-container>
  </parkour-page-layout>
</ion-content>
