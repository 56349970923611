<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="berichtenHero$ | async"
      [title]="'berichten.gesprekken-overview-page.title' | translate"
    >
      <ng-container *parkourSectionContent="{ data: mainPageData$ | async }; let data">
        <ng-container *ngIf="data.gesprekken.length > 0; else geenGesprekken">
          <div class="container-1-col space-y-8">
            <parkour-button [link]="['groepsgesprek-add']" class="w-full" iconAfter="plus-circle"
              >{{ 'berichten.gesprekken-overview-page.groepsgesprek-button' | translate }}
            </parkour-button>

            <ul class="space-y-6">
              <li *ngFor="let gesprek of data.gesprekken">
                <parkour-gesprek-overview-card
                  [gesprek]="gesprek"
                  [ongelezenBerichtenMeldingen]="ongelezenBerichtenMeldingen$ | async"
                  [user]="data.user"
                />
              </li>
            </ul>
          </div>
        </ng-container>
        <ng-template #geenGesprekken>
          <div class="container-1-col flex flex-col gap-y-8">
            <p class="text-center">
              {{ 'berichten.gesprekken-overview-page.no-teamleden-intro' | translate }}
            </p>
            <parkour-button
              *parkourActiefAlsJongere
              [link]="'teamlid-add'"
              class="w-full"
              iconAfter="plus-circle"
            >
              {{ 'berichten.gesprekken-overview-page.teamleden-button' | translate }}
            </parkour-button>

            <parkour-block-placeholder
              [sources]="{
                default: {
                  src: '/assets/berichten/placeholder/placeholder_berichten_default.svg',
                },
                md: {
                  src: '/assets/berichten/placeholder/placeholder_berichten_md.svg',
                },
              }"
              alt=""
              class="block"
            />
          </div>
        </ng-template>
      </ng-container>
    </parkour-page-with-hero>
  </parkour-page-layout>
  <parkour-page-image
    classes="absolute top-0 left-0 z-0"
    src="/assets/berichten/background/background_berichten_blur.jpg"
  />
</ion-content>
