<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="['../../..']" />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.kalender-afspraak-detail-page.title' | translate }}
    </ion-title>

    <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let data">
      <ion-buttons slot="end">
        @if (canEditAfspraak(data.user, data.afspraak)) {
          <parkour-header-action
            [link]="['..', 'wijzig']"
            [label]="'kalender.kalender-afspraak-detail-page.afspraak-edit' | translate"
            icon="edit"
            testId="edit-afspraak-button"
          />
        } @else if (canRemoveAfspraak(data.user, data.afspraak)) {
          <parkour-header-action
            (clicked)="removeAfspraak(data.afspraak.id)"
            [label]="'kalender.kalender-afspraak-detail-page.afspraak-delete' | translate"
            icon="trash"
            testId="remove-afspraak-button"
          />
        }
      </ion-buttons>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-gradient />
    <div
      *parkourPageContent="mainPageData$ | async; let data"
      class="relative z-10 py-8 container container-space"
    >
      <div class="flex flex-col gap-y-8 container-1-col">
        @if (getGespreksPartner(data.user, data.afspraak); as gespreksPartner) {
          @if (data.afspraak.type === 'VIDEOGESPREK') {
            <h1 class="typo-h5">
              {{ 'kalender.kalender-afspraak-detail-page.afspraak-met' | translate }}
              <span class="sr-only">{{ gespreksPartner | profielnaam }}</span>
            </h1>
          }

          <div class="flex flex-row gap-x-4">
            @if (data.afspraak.type === 'VIDEOGESPREK') {
              <parkour-profiel-foto-badge [alt]="false" [profiel]="gespreksPartner" [size]="'3'" />
            }

            <div class="flex flex-col gap-y-2">
              @if (data.afspraak.type === 'BASIC') {
                <h1 class="typo-h3">{{ data.afspraak.titel }}</h1>
              }

              @if (data.afspraak.type === 'VIDEOGESPREK') {
                <p class="typo-h2">{{ gespreksPartner | profielnaam }}</p>
              }

              <p class="flex gap-x-2 items-center">
                <parkour-icon classes="flex" name="calendar" type="inline" />

                <span>{{ data.afspraak.datum | date: 'dd LLLL • EEEE' }}</span>
              </p>

              <p class="flex gap-x-2 items-center">
                <parkour-icon classes="flex" name="clock" type="inline" />

                <span data-testid="van-tot"
                  >{{ data.afspraak.van | date: 'HH:mm' }} -{{
                    data.afspraak.tot | date: 'HH:mm'
                  }}</span
                >
              </p>
            </div>
          </div>

          @if (data.afspraak.omschrijving) {
            <div>
              <p class="typo-label">
                {{ 'kalender.kalender-afspraak-detail-page.beschrijving' | translate }}
              </p>

              <p>{{ data.afspraak.omschrijving }}</p>
            </div>
          }

          @if (data.afspraak.type === 'VIDEOGESPREK') {
            <parkour-button
              (clicked)="startVideogesprek(data.afspraak, data.user.getProfielNaam(), data.user)"
              *ngIf="data.afspraak.gesprek && data.user.profiel.id === data.afspraak.teamlid.id"
              class="w-full"
              iconAfter="video-camera"
            >
              {{ 'kalender.kalender-afspraak-detail-page.start-videogesprek-button' | translate }}
            </parkour-button>

            <parkour-button
              (clicked)="startVideogesprek(data.afspraak, data.user.getProfielNaam(), data.user)"
              *ngIf="
                data.afspraak.gesprek &&
                data.afspraak.gesprek.status === 'GESTART' &&
                data.user.profiel.id !== data.afspraak.teamlid.id
              "
              class="w-full"
              iconAfter="calendar"
            >
              {{ 'kalender.join-gesprek-button' | translate }}
            </parkour-button>
          }
        }
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
<div #jaasContainer></div>
