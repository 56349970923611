import { UitnodigingId } from './uitnodiging.dto';
import { ContextId, ProfielId } from './profiel.dto';
import { AfspraakId } from './kalender.dto';
import { GesprekId } from './bericht.dto';
import { Page } from './page.dto';
import { ApiProperty } from '@nestjs/swagger';
import { DoelId } from './doel.dto';
import { InteractieEmoji } from './reactie.dto';
import { GebeurtenisId } from './gebeurtenis.dto';
import { SuggestieId } from './gebeurtenis-suggestie.dto';

export class RegistreerVoorMeldingenDto {
  readonly token!: string;
}

class MeldingBase {
  readonly id!: string;
  readonly tijdstip!: string;
  readonly gelezen!: boolean;
}

export class MeldingEvent {
  readonly meldingId!: string;
  readonly timestamp!: string;
}

export class TeamVerlatenMelding extends MeldingBase {
  readonly type!: 'TEAM_VERLATEN';
  readonly metaData!: TeamVerlatenMeldingMetaData;
}

class TeamVerlatenMeldingMetaData {
  readonly naam!: string;
  @ApiProperty({ type: String })
  readonly contextId!: ContextId;
}

export class UitnodigingAanvaardMelding extends MeldingBase {
  readonly type!: 'UITNODIGING_AANVAARD';
  readonly metaData!: UitnodigingAanvaardMeldingMetaData;
}

class UitnodigingAanvaardMeldingMetaData {
  @ApiProperty({ type: String })
  uitnodigingId!: UitnodigingId;
  @ApiProperty({ type: String })
  uitgenodigdeId!: ProfielId;
  @ApiProperty({ type: String })
  contextId!: ContextId;
}

export class UitnodigingGeweigerdMelding extends MeldingBase {
  readonly type!: 'UITNODIGING_GEWEIGERD';
  readonly metaData!: UitnodigingGeweigerdMeldingMetaData;
}

class UitnodigingGeweigerdMeldingMetaData {
  @ApiProperty({ type: String })
  uitnodigingId!: UitnodigingId;
  uitgenodigdeNaam!: string;
  @ApiProperty({ type: String })
  contextId!: ContextId;
}

export class UitnodigingBevestigdMelding extends MeldingBase {
  readonly type!: 'UITNODIGING_BEVESTIGD';
  readonly metaData!: UitnodigingBevestigdMeldingMetaData;
}

class UitnodigingBevestigdMeldingMetaData {
  @ApiProperty({ type: String })
  uitnodigingId!: UitnodigingId;
  @ApiProperty({ type: String })
  jongereId!: ProfielId;
  @ApiProperty({ type: String })
  contextId!: ContextId;
}

export class UitnodigingBevestigingGeweigerd extends MeldingBase {
  readonly type!: 'BEVESTIGING_GEWEIGERD';
  readonly metaData!: UitnodigingBevestigingGeweigerdMetaData;
}

class UitnodigingBevestigingGeweigerdMetaData {
  @ApiProperty({ type: String })
  uitnodigingId!: UitnodigingId;
  @ApiProperty({ type: String })
  jongereId!: ProfielId;
}

export class UitgenodigdMelding extends MeldingBase {
  readonly type!: 'UITGENODIGD';
  readonly metaData!: UitgenodigdMeldingMetaData;
}

class UitgenodigdMeldingMetaData {
  @ApiProperty({ type: String })
  uitnodigingId!: UitnodigingId;
  @ApiProperty({ type: String })
  jongereId!: ProfielId;
  @ApiProperty({ type: String })
  contextId!: ContextId;
}

export class NieuwDrieKolommenDocumentMelding extends MeldingBase {
  readonly type!: 'NIEUW_DRIE_KOLOMMEN_DOCUMENT';
  readonly metaData!: NieuwDrieKolommenDocumentMeldingMetaData;
}

class NieuwDrieKolommenDocumentMeldingMetaData {
  documentId!: string;
  @ApiProperty({ type: String })
  contextId!: ContextId;
}

export class NieuwEyouthGebeurtenisMelding extends MeldingBase {
  readonly type!: 'NIEUWE_EYOUTH_GEBEURTENIS';
  readonly metaData!: NieuwEyouthGebeurtenisMeldingMetaData;
}

class NieuwEyouthGebeurtenisMeldingMetaData {
  gebeurtenisId!: string;
  @ApiProperty({ type: String })
  contextId!: ContextId;
}

export class AfspraakHerinneringMelding extends MeldingBase {
  readonly type!: 'AFSPRAAK_HERINNERING';
  readonly metaData!: AfspraakHerinneringMeldingMetaData;
}

class AfspraakHerinneringMeldingMetaData {
  @ApiProperty({type: String})
  afspraakId!: AfspraakId;
  @ApiProperty({type: String})
  jongereId!: ProfielId;
  titel!: string;
  van!: string;
}

export class AfspraakAangemaaktMelding extends MeldingBase {
  readonly type!: 'AFSPRAAK_AANGEMAAKT';
  readonly metaData!: AfspraakAangemaaktMeldingMetaData;
}

class AfspraakAangemaaktMeldingMetaData {
  @ApiProperty({ type: String })
  afspraakId!: AfspraakId;
  @ApiProperty({ type: String })
  with!: ProfielId;
  @ApiProperty({ type: String })
  jongereId!: ProfielId;
  van!: string;
  tot!: string;
}

export class AfspraakVerwijderdMelding extends MeldingBase {
  readonly type!: 'AFSPRAAK_VERWIJDERD';
  readonly metaData!: AfspraakVerwijderdMeldingMetaData;
}

class AfspraakVerwijderdMeldingMetaData {
  van!: string;
  tot!: string;
  @ApiProperty({ type: String })
  with!: ProfielId;
  @ApiProperty({ type: String })
  jongereId!: ProfielId;
}

export class AfspraakGestartMelding extends MeldingBase {
  readonly type!: 'AFSPRAAK_GESTART';
  readonly metaData!: AfspraakGestartMeldingMetaData;
}

class AfspraakGestartMeldingMetaData {
  @ApiProperty({ type: String })
  with!: ProfielId;
  @ApiProperty({ type: String })
  contextId!: ProfielId;
  @ApiProperty({ type: String })
  jongereId!: ProfielId;
  @ApiProperty({ type: String })
  afspraakId!: AfspraakId;
}

export class NieuwChatBerichtMelding extends MeldingBase {
  readonly type!: 'NIEUW_CHAT_BERICHT';
  readonly metaData!: NiewChatBerichtMetaData;
}

class NiewChatBerichtMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly gesprekId!: GesprekId;
  @ApiProperty({ type: String })
  readonly verzenderId!: ProfielId;
  readonly groepsgesprek!: string;
  @ApiProperty({ type: String })
  readonly gesprekOwnerId!: ProfielId;
  @ApiProperty({
    type: 'array',
    items: {
      type: 'string',
    },
  })
  readonly deelnemers!: string;
}

export class NieuwChatReactieMelding extends MeldingBase {
  readonly type!: 'NIEUW_CHAT_REACTIE';
  readonly metaData!: NiewChatReactieMetaData;
}

class NiewChatReactieMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly gesprekId!: GesprekId;
  @ApiProperty({ type: String })
  readonly verzenderId!: ProfielId;
  @ApiProperty({ type: String })
  readonly reactieType!: InteractieEmoji;
  readonly groepsgesprek!: string;
  @ApiProperty({ type: String })
  readonly gesprekOwnerId!: ProfielId;
  @ApiProperty({
    type: 'array',
    items: {
      type: 'string',
    },
  })
  readonly deelnemers!: string;
}

export class DoelGedeeldMelding extends MeldingBase {
  readonly type!: 'DOEL_GEDEELD';
  readonly metaData!: DoelGedeeldMeldingMetaData;
}

class DoelGedeeldMeldingMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  readonly doelNaam!: string;
  @ApiProperty({ type: String })
  readonly doelId!: DoelId;
}

export class DoelGesuggereerdMelding extends MeldingBase {
  readonly type!: 'DOEL_GESUGGEREERD';
  readonly metaData!: DoelGesuggereerdMeldingMetaData;
}

class DoelGesuggereerdMeldingMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly suggestieId!: SuggestieId;
  @ApiProperty({ type: String })
  readonly suggestedById!: ProfielId;
}

export class GebeurtenisGesuggereerdMelding extends MeldingBase {
  readonly type!: 'GEBEURTENIS_GESUGGEREERD';
  readonly metaData!: GebeurtenisGesuggereerdMeldingMetaData;
}

class GebeurtenisGesuggereerdMeldingMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly suggestieId!: SuggestieId;
  @ApiProperty({ type: String })
  readonly suggestedById!: ProfielId;
}

export class DoelEmojiReactieAddedMelding extends MeldingBase {
  readonly type!: 'DOEL_EMOJI_REACTIE_ADDED';
  readonly metaData!: DoelEmojiReactieAddedMetaData;
}

class DoelEmojiReactieAddedMetaData {
  @ApiProperty({ type: String })
  readonly doelId!: DoelId;
  readonly doelNaam!: string;
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly reactie!: InteractieEmoji;
  @ApiProperty({ type: String })
  readonly reactieOwnerId!: ProfielId;
}

export class DoelVoltooidMelding extends MeldingBase {
  readonly type!: 'DOEL_VOLTOOID';
  readonly metaData!: DoelVoltooidMeldingMetaData;
}

class DoelVoltooidMeldingMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly doelId!: DoelId;
  readonly doelNaam!: string;
}

export class GebeurtenisGedeeldMelding extends MeldingBase {
  readonly type!: 'GEBEURTENIS_GEDEELD';
  readonly metaData!: GebeurtenisGedeeldMeldingMetaData;
}

class GebeurtenisGedeeldMeldingMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly gebeurtenisId!: GebeurtenisId;
}

export class JeugdhulpHistoriekGedeeldMelding extends MeldingBase {
  readonly type!: 'JEUGDHULPHISTORIEK_GEDEELD';
  readonly metaData!: JeugdhulphistoriekGedeeldMeldingMetaData;
}

class JeugdhulphistoriekGedeeldMeldingMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly gebeurtenisId!: GebeurtenisId;
}

export class DoelBerichtAddedMelding extends MeldingBase {
  readonly type!: 'DOEL_NIEUW_BERICHT';
  readonly metaData!: DoelBerichtAddedMetaData;
}

class DoelBerichtAddedMetaData {
  @ApiProperty({ type: String })
  readonly verzenderId!: ProfielId;
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly gesprekId!: GesprekId;
  @ApiProperty({ type: String })
  readonly doelId!: DoelId;
  readonly doelNaam!: string;
}

export class DoelBerichtReactieAddedMelding extends MeldingBase {
  readonly type!: 'DOEL_NIEUW_BERICHT_REACTIE';
  readonly metaData!: DoelBerichtReactieAddedMetaData;
}

class DoelBerichtReactieAddedMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly gesprekId!: GesprekId;
  @ApiProperty({ type: String })
  readonly verzenderId!: ProfielId;
  @ApiProperty({ type: String })
  readonly reactieType!: InteractieEmoji;
  @ApiProperty({ type: String })
  readonly doelId!: DoelId;
  readonly doelNaam!: string;
}

export class DoelReactie extends MeldingBase {
  readonly type!: 'DOEL_REACTIE';
  readonly metaData!: DoelBerichtReactieAddedMetaData;
}

class VideogesprekGestartMeldingMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly gesprekId!: GesprekId;
  @ApiProperty({ type: String })
  readonly with!: ProfielId;
}

export class VideogesprekGestartMelding extends MeldingBase {
  readonly type!: 'VIDEOGESPREK_GESTART';
  readonly metaData!: VideogesprekGestartMeldingMetaData;
}

class GeblokkeerdMeldingMetaData {
  @ApiProperty({ type: String })
  readonly jongereId!: ProfielId;
}

export class GeblokkeerdMelding extends MeldingBase {
  readonly type!: 'GEBLOKKEERD';
  readonly metaData!: GeblokkeerdMeldingMetaData;
}

class GedeblokkeerdMeldingMetaData {
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  @ApiProperty({ type: String })
  readonly jongereId!: ProfielId;
}

export class GedeblokkeerdMelding extends MeldingBase {
  readonly type!: 'GEDEBLOKKEERD';
  readonly metaData!: GedeblokkeerdMeldingMetaData;
}

class VerwijderdUitTeamMeldingMetaData {
  @ApiProperty({ type: String })
  readonly jongereId!: ProfielId;
}

export class VerwijderdUitTeamMelding extends MeldingBase {
  readonly type!: 'VERWIJDERD_UIT_TEAM';
  readonly metaData!: VerwijderdUitTeamMeldingMetaData;
}

export type MeldingDto =
  | TeamVerlatenMelding
  | UitnodigingAanvaardMelding
  | UitnodigingGeweigerdMelding
  | UitnodigingBevestigdMelding
  | UitnodigingBevestigingGeweigerd
  | UitgenodigdMelding
  | NieuwDrieKolommenDocumentMelding
  | NieuwEyouthGebeurtenisMelding
    | AfspraakHerinneringMelding
  | AfspraakAangemaaktMelding
  | AfspraakVerwijderdMelding
  | AfspraakGestartMelding
  | NieuwChatBerichtMelding
  | NieuwChatReactieMelding
  | DoelGedeeldMelding
  | DoelEmojiReactieAddedMelding
  | DoelGesuggereerdMelding
  | DoelVoltooidMelding
  | GebeurtenisGedeeldMelding
  | JeugdhulpHistoriekGedeeldMelding
  | DoelBerichtAddedMelding
  | DoelBerichtReactieAddedMelding
  | VideogesprekGestartMelding
  | GeblokkeerdMelding
  | GedeblokkeerdMelding
  | GebeurtenisGesuggereerdMelding
  | VerwijderdUitTeamMelding;

export class MeldingenPageDto implements Page<MeldingDto> {
  readonly content!: MeldingDto[];
  readonly totalPages!: number;
  readonly number!: number;
  readonly last!: boolean;
}

export type MeldingType = MeldingDto['type'];
export type MeldingOfType<T extends MeldingType> = Extract<
  MeldingDto,
  {
    type: T;
  }
>;
