<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.kalender-page.title' | translate }}
    </ion-title>

    <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let data">
      <ion-buttons *ngIf="data.user" slot="end">
        @if (data.user.isProfessioneel()) {
          <parkour-header-action
            [label]="'kalender.kalender-page.videogesprek-inplannen-button' | translate"
            [link]="['afspraak', 'nieuw']"
            icon="calendar-video"
          />
        }

        @if (data.context.type === 'jongere') {
          <parkour-header-action
            [label]="'kalender.kalender-page.afspraak-inplannen-button' | translate"
            [link]="['afspraak', 'nieuw']"
            icon="calendar-add"
          />
        }
      </ion-buttons>
    </ng-container>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-gradient />
    <div class="relative z-10 py-8 container container-space flex flex-col gap-y-8">
      <ng-container *parkourPageContent="mainPageData$ | async; let data">
        <ng-container
          *ngIf="
            data.vroegereAfsprakenMaandGroep && data.komendeAfsprakenMaandGroep && data.user;
            else loading
          "
        >
          <parkour-segment
            (changed)="segmentChanged($event)"
            [defaultValue]="activeSegmentItem"
            [items]="segmentItems"
          />

          <parkour-info-card *ngIf="data.user.profiel.isJongere" class="block container-1-col">
            {{ 'kalender.kalender-page.info-jongere' | translate }}
          </parkour-info-card>

          <div [ngSwitch]="activeSegmentItem">
            <div *ngSwitchCase="'komendeAfspraken'" class="flex flex-col gap-y-8 container-1-col">
              <div class="flex flex-col gap-y-3">
                @if (data.user.isProfessioneel()) {
                  <parkour-button
                    [link]="['afspraak', 'nieuw']"
                    class="w-full"
                    iconAfter="calendar-video"
                    variant="default"
                  >
                    {{ 'kalender.kalender-page.videogesprek-inplannen-button' | translate }}
                  </parkour-button>
                }

                @if (data.context.type === 'jongere') {
                  <parkour-button
                    [link]="['afspraak', 'nieuw']"
                    class="w-full"
                    iconAfter="calendar-add"
                    variant="default"
                  >
                    {{ 'kalender.kalender-page.afspraak-inplannen-button' | translate }}
                  </parkour-button>
                }

                @if (data.komendeAfsprakenMaandGroep.length > 0) {
                  <parkour-download-afspraken
                    [afspraken]="data.komendeAfsprakenMaandGroep"
                    [user]="data.user"
                  />
                }
              </div>

              <ng-container *ngIf="data.komendeAfsprakenMaandGroep.length > 0; else geenAfspraken">
                <parkour-afspraken-per-maand
                  (joinAfspraakClicked)="onJoinAfspraakClicked($event, data.user)"
                  [afspraken]="data.komendeAfsprakenMaandGroep"
                  [ongelezenAfspraakIds]="(ongelezenAfspraakIds$ | async) ?? []"
                  [user]="data.user"
                  class="mt-4 block"
                />
              </ng-container>
            </div>

            <div *ngSwitchCase="'vroegereAfspraken'" class="flex flex-col gap-y-8 container-1-col">
              <ng-container *ngIf="data.vroegereAfsprakenMaandGroep.length > 0; else geenAfspraken">
                <parkour-afspraken-per-maand
                  (joinAfspraakClicked)="onJoinAfspraakClicked($event, data.user)"
                  [afspraken]="data.vroegereAfsprakenMaandGroep"
                  [hasFade]="true"
                  [ongelezenAfspraakIds]="(ongelezenAfspraakIds$ | async) ?? []"
                  [user]="data.user"
                ></parkour-afspraken-per-maand>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-template #loading>
        <parkour-loading-spinner></parkour-loading-spinner>
      </ng-template>

      <ng-template #geenAfspraken>
        <div class="flex flex-col gap-y-8">
          <p class="text-center">{{ 'kalender.kalender-page.geen-afspraken' | translate }}</p>

          <parkour-block-placeholder
            [sources]="{
              default: {
                src: '/assets/kalender/placeholder/placeholder_kalender_default.svg',
              },
              md: {
                src: '/assets/kalender/placeholder/placeholder_kalender_md.svg',
              },
            }"
            alt="Geen kalender items"
            class="block"
          />
        </div>
      </ng-template>
    </div>
  </parkour-page-layout>
</ion-content>
