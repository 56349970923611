<div [formGroup]="actieItemGroup" class="flex w-full gap-x-2">
  <parkour-form-field-without-label
    [additionalErrors]="actieItemGroup.controls.naam.errors"
    [errorMessages]="{
      maxlength: 'Vul een geldige stap in.',
    }"
    [inputFormControl]="actieItemGroup.controls.naam"
    [required]="true"
    [accessibleLabel]="'Stap'"
    class="w-full block flex-1"
    errorLabel="stap"
    inputId="stap-naam"
  >
    <parkour-input
      [formControl]="actieItemGroup.controls.naam"
      [required]="true"
      formControlName="naam"
      inputId="stap-naam"
      inputPlaceholder=""
      testId="register-step-naam"
    />
  </parkour-form-field-without-label>

  <parkour-button
    (clicked)="deleteActie()"
    [ariaLabel]="'buttons.delete' | translate"
    class="flex"
    classes="!text-negative"
    format="iconButton"
    icon="trash"
    variant="ghost-light"
  />

  <!-- TODO: add if reorder feature is needed -->
  <!--<ion-reorder>
		  <parkour-icon class="text-n-600" name="menu-simple-off-15-3-round" size="3" />
	  </ion-reorder>-->
</div>
