<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'watwat.artikels-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-gradient />
    <!-- TODO: UX Copy Ondersteuning aan JSON toevoegen -->
    <div class="relative z-10 py-8 container-1-col container-space">
      <form (ngSubmit)="onSearch()">
        <parkour-form-field
          [inputFormControl]="null"
          [isLabelHidden]="true"
          inputId="artikel-search-term"
          label="Wat zoek je?"
        >
          <parkour-input
            [(ngModel)]="enteredQueryString"
            inputId="artikel-search-term"
            inputPlaceholder="Wat zoek je?"
            name="artikel-search-term"
          >
            <parkour-button
              [ariaLabel]="'buttons.search' | translate"
              format="iconButton"
              icon="search"
              size="medium"
              type="submit"
            />
          </parkour-input>
        </parkour-form-field>

        <parkour-custom-select
          (ngModelChange)="onThemaChanged($event)"
          [(ngModel)]="enteredThema"
          [ngModelOptions]="{ standalone: true }"
          [options]="themas"
          class="mt-8 block"
          label="Thema"
        />
      </form>

      <ng-container *parkourSectionContent="{ data: mainPageData$ | async }; let artikelResult">
        <div class="flex items-center justify-between gap-4 mt-8" aria-live="polite">
          <p class="typo-body variant-body-fat text-n-600" data-testid="artikel-count">
            {{ artikelResult.total }} artikels
          </p>
        </div>

        <ul class="flex flex-col mt-6 gap-y-2">
          @for (artikel of artikelResult.results; track artikel.id) {
            <li>
              <parkour-article-card
                (clickedBookmark)="onBookmarkClicked($event, artikel.id, artikel.bookmarkId)"
                [image]="{ src: artikel.image, alt: '' }"
                [link]="[String(artikel.id)]"
                [themes]="artikel.themas"
                [title]="artikel.title"
                [isBookmarked]="!!artikel.bookmarkId"
                testId="artikel"
              />
            </li>
          }
        </ul>

        @if (artikelResult.total > 1) {
          <div class="mt-10">
            <parkour-pagination
              (clicked)="changePage($event)"
              [collectionSize]="artikelResult.total"
              [currentPage]="(page$ | async) ?? 1"
              [pageSize]="artikelResult.pageSize"
              [contentRef]="contentRef"
              class="mt-10"
            />
          </div>
        }
      </ng-container>
    </div>
  </parkour-page-layout>
</ion-content>
