import { Component, Input, ViewChild } from "@angular/core";
import { TCustomSelectOptions } from "./parkour-custom-select.types";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ParkourModalComponent } from "../parkour-modal/parkour-modal.component";
import { ParkourActionComponent } from "../parkour-action/parkour-action.component";
import { ParkourPictureComponent } from "../parkour-picture/parkour-picture.component";
import { NgForOf, NgIf } from "@angular/common";
import { ParkourIconComponent } from "../parkour-icon/parkour-icon.component";

@Component({
  standalone: true,
  selector: "parkour-custom-select",
  templateUrl: "./parkour-custom-select.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ParkourCustomSelectComponent,
    },
  ],
  imports: [
    ParkourActionComponent,
    ParkourPictureComponent,
    NgIf,
    ParkourIconComponent,
    ParkourModalComponent,
    NgForOf,
  ],
})
export class ParkourCustomSelectComponent implements ControlValueAccessor {
  @ViewChild("modal") modal!: ParkourModalComponent;
  @Input({ required: true }) public label!: string;
  @Input({ required: true }) public options!: TCustomSelectOptions;
  @Input() public disabled = false;
  @Input() public error?: string;

  @Input() set value(value: string) {
    this.writeValue(value);
  }

  get value(): string | undefined {
    return this._value;
  }

  public onChange?: (value: string) => void;
  public onTouched?: () => void;

  private _value?: string;

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  openModal() {
    if (!this.disabled) {
      this.modal.open();
    }
  }

  writeValue(value: string) {
    this._value = value;
  }

  getOptionValues(): string[] {
    return Object.keys(this.options);
  }

  selectOption(option: string) {
    if (!this.disabled && option !== this._value) {
      this.writeValue(option);
      this.onChange?.(option);

      this.modal.close();
    }
  }
}
