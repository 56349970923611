<ion-header parkourAdaptToSidebarVisible>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'over-parkour.cookie-beleid-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <h1 class="text-center mb-4">{{ 'over-parkour.cookie-beleid-page.title' | translate }}</h1>

        <parkour-divider />

        <parkour-info-card class="block mt-8">
          <div class="flex flex-col gap-y-2">
            <p>Je kan steeds je voorkeuren voor cookies in PARKOUR aanpassen.</p>

            <parkour-button
              (clicked)="parkourCookiesService.openCookiePopup()"
              size="none"
              variant="ghost-light"
            >
              Cookievoorkeuren
            </parkour-button>
          </div>
        </parkour-info-card>

        <article class="wysiwyg space-y-8">
          <section class="mt-8 space-y-3">
            <h2 class="typo-h4">Wat zijn cookies?</h2>

            <p>
              Een cookie is een klein tekstbestand dat tijdens een websitebezoek op je computer,
              tablet of smartphone wordt geplaatst. In dit tekstbestand wordt informatie opgeslagen
              over je surfgedrag op de website. Deze informatie kan tijdens je huidige sessie of bij
              een later bezoek weer worden herkend door diezelfde website.
            </p>

            <p>
              De server van een website kan alleen de cookies lezen die hij zelf geplaatst heeft,
              hij heeft geen toegang tot andere informatie die zich op je computer of mobiel
              apparaat bevindt. Cookies worden op je computer of je mobiel apparaat opgeslagen in de
              folder van je browser.
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Wat doen cookies?</h2>

            <p>
              Cookies maken het gebruik van de website over het algemeen gemakkelijker en sneller.
              Ze helpen de gebruiker om te navigeren tussen de verschillende onderdelen van een
              website. Cookies kunnen ook gebruikt worden om de inhoud van een website of de
              publiciteit op een website relevanter te maken voor de bezoeker en aan te passen aan
              zijn persoonlijke smaak en behoeften.
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Hoe kan ik mijn voorkeuren aanpassen?</h2>

            <p>
              Bij je eerste bezoek aan onze website vragen we je welke cookies je wil aanvaarden. Je
              kan dit steeds aanpassen via de link
              <parkour-button
                (clicked)="parkourCookiesService.openCookiePopup()"
                size="none"
                variant="ghost"
              >
                Cookievoorkeuren
              </parkour-button>
              .
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">PARKOUR gebruikt cookies</h2>

            <p>
              Sommige cookies zijn noodzakelijk om PARKOUR goed te laten werken. Andere cookies zijn
              optioneel. Je kan bepaalde onderdelen pas zien als je toestemming geeft om deze
              optionele cookies in te schakelen.
            </p>

            <h3 class="typo-h5">Noodzakelijke cookies</h3>

            <p>
              Noodzakelijke cookies helpen onze website bruikbaarder te maken, door basisfuncties
              als paginanavigatie en toegang tot beveiligde gedeelten van de website mogelijk te
              maken. Zonder deze cookies kan de website niet naar behoren werken.
            </p>

            <table>
              <thead>
                <tr>
                  <th>Naam</th>
                  <th>Aanbieder</th>
                  <th>Doel</th>
                  <th>Vervaltermijn</th>
                  <th>Type</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td data-head="Naam">VOGANONUSER</td>
                  <td data-head="Aanbieder">
                    <a href="http://vlaanderen.be" target="_blank"> Vlaamse overheid </a>
                  </td>
                  <td data-head="Doel">Reverse proxy</td>
                  <td data-head="Vervaltermijn">Geldigheid van maximaal 24 uur</td>
                  <td data-head="Type">Permanente cookies</td>
                </tr>

                <tr>
                  <td data-head="Naam">
                    SimpleSAMLAuthToken,<br />
                    SimpleSAMLSessionID,<br />
                    simplesamlphp_auth_returnto
                  </td>
                  <td data-head="Aanbieder">
                    <a href="https://overheid.vlaanderen.be/gebruikersbeheer" target="_blank">
                      Gebruikersbeheer Vlaamse overheid
                    </a>
                  </td>
                  <td data-head="Doel">ACM/IDM toegangsbeheer</td>
                  <td data-head="Vervaltermijn">Duur van de sessie</td>
                  <td data-head="Type">Sessiecookies</td>
                </tr>

                <tr>
                  <td data-head="Naam">authsessionid</td>
                  <td data-head="Aanbieder">
                    <a href="https://www.opgroeien.be/" target="_blank"> Opgroeien </a>
                  </td>
                  <td data-head="Doel">
                    Toegang verlenen en behouden tot PARKOUR, herkennen anonieme of ingelogde
                    gebruiker
                  </td>
                  <td data-head="Vervaltermijn">Duur van de sessie</td>
                  <td data-head="Type">Sessiecookies</td>
                </tr>

                <tr>
                  <td data-head="Naam">marketingCookiesEnabled</td>
                  <td data-head="Aanbieder">
                    <a href="https://www.opgroeien.be/" target="_blank"> Opgroeien </a>
                  </td>
                  <td data-head="Doel">Bijhouden toelating marketing cookies</td>
                  <td data-head="Vervaltermijn">365 dagen</td>
                  <td data-head="Type">Permanente cookie</td>
                </tr>

                <tr>
                  <td data-head="Naam">TSxxxxxxxx</td>
                  <td data-head="Aanbieder">
                    <a href="https://www.opgroeien.be/" target="_blank"> Opgroeien </a>
                  </td>
                  <td data-head="Doel">Veilige toegang en gebruik PARKOUR</td>
                  <td data-head="Vervaltermijn">600 seconden</td>
                  <td data-head="Type">HTTP cookie</td>
                </tr>
              </tbody>
            </table>

            <h3 class="typo-h5">Marketing cookies</h3>

            <p>
              Marketing cookies zijn optioneel en dienen om video&rsquo;s van derde partijen te
              kunnen afspelen binnen PARKOUR. Onze website kan ingebedde elementen van deze derde
              partijen bevatten. Ze kunnen activiteiten op verschillende websites volgen om een
              gebruiksprofiel samen te stellen. Zo kunnen ze relevantere inhoud aanbieden op andere
              bezochte websites.
            </p>

            <p>We raden aan om het privacybeleid van deze derde partijen te controleren:</p>

            <ul>
              <li>
                <a
                  href="https://www.youtube.com/howyoutubeworks/our-commitments/protecting-user-data/#protecting-viewer-data"
                  target="_blank"
                >
                  YouTube
                </a>
              </li>

              <li>
                <a href="https://vimeo.com/privacy" target="_blank"> Vimeo </a>
              </li>
            </ul>

            <h3 class="typo-h5">Analytische tracking</h3>

            <p>
              Analytische tracking met Matomo verzamelt en rapporteert anoniem surfgedrag zonder
              cookies. Dit helpt ons om het gebruik van PARKOUR beter te begrijpen. Je kan dit
              uitschakelen (opt-out) als je niet wil dat we kunnen leren van je surfgedrag om de
              website te verbeteren.
            </p>

            <table>
              <thead>
                <tr>
                  <th>Naam</th>
                  <th>Aanbieder</th>
                  <th>Doel</th>
                  <th>Vervaltermijn</th>
                  <th>Type</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td data-head="Naam">piwik_ignore</td>
                  <td data-head="Aanbieder">
                    <a href="https://matomo.org/" target="_blank"> Matomo </a>
                  </td>
                  <td data-head="Doel">Uitschakelen van analytische tracking (opt-out)</td>
                  <td data-head="Vervaltermijn">2 jaar</td>
                  <td data-head="Type">Permanente cookie</td>
                </tr>
              </tbody>
            </table>

            <p>
              De cookie wordt geplaatst als je analytische tracking uitschakelt. Hou er rekening mee
              dat sommige browsers de opt-out cookie kunnen verwijderen als je de website een tijdje
              niet meer bezocht hebt.
            </p>
          </section>

          <section class="space-y-3">
            <h2 class="typo-h4">Heb je vragen over het gebruik van cookies?</h2>

            <p>
              In onze
              <parkour-button [link]="['..', 'privacy']" variant="ghost">
                privacyverklaring
              </parkour-button>
              vind je meer informatie over wie we zijn, hoe je contact met ons kan opnemen en hoe we
              je persoonlijke gegevens verwerken.
            </p>

            <p>Cookiebeleid laatst bijgewerkt op 20 juni 2024.</p>
          </section>
        </article>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
