import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, map, Observable, of, Subject, switchMap, take, timeout } from 'rxjs';
import {
  User,
  UserWithPersoonInfo,
  UserWithProfiel,
  validateUserWithExternalInfo,
  validateUserWithProfiel,
} from '../model/user';
import { ExterneHoedanigheid, ProfielId, UserDto } from 'parkour-web-app-dto';
import { userFromDto } from '../model/utils';
import { ContextService } from '../../shared/services/context.service';
import { environment } from '../../../environments/environment';
import { AnomymousUser } from '../model/anomymous-user';
import { Network } from '@capacitor/network';
import { GlobalRefreshCause, RefreshService } from '../../shared/services/refresh.service';
import { ParkourPopupService } from '@parkour/ui';
import { NavigationHelperService } from './navigation-helper.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { App } from '@capacitor/app';
import { LoggingService } from '../../core/logging.service';
import { asType } from '../../utils';

export type ContextType = 'jongere' | 'teamlid' | 'anoniem' | 'voor-mezelf';
export type UserRefreshCause = 'initial' | 'user-refresh' | 'logout' | 'resume';

const onInitialError: UserWithRefreshCause = {
  user: new AnomymousUser(),
  refreshCause: 'initial',
};

type UserWithRefreshCause = { user: User; refreshCause: GlobalRefreshCause | UserRefreshCause };

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly refreshUser$ = new Subject<UserRefreshCause>();
  private loggedOutModalVisible: boolean = false;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly contextService: ContextService,
    private readonly refreshService: RefreshService,
    private readonly popupService: ParkourPopupService,
    private readonly navigationHelperService: NavigationHelperService,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly loggingService: LoggingService,
    private readonly zone: NgZone,
  ) {}

  configureSessionTimeoutDetection() {
    App.addListener('resume', async () => {
      await this.zone.run(async () => {
        await this.onResumeApp();
      });
    });
  }

  async onResumeApp() {
    this.refreshUser$.next('resume');
  }

  // Initially if it fails to get the user, it will return an anonymous user, later it will ignore the error result.
  public readonly userWithRefreshCause$: Observable<UserWithRefreshCause> =
    this.refreshService.repeatOnRefreshWithInitialOnError<UserWithRefreshCause, UserRefreshCause>(
      (refreshCause) =>
        this.getSessionUser().pipe(
          map((user) =>
            asType<UserWithRefreshCause>({
              user,
              refreshCause: refreshCause ?? 'initial',
            }),
          ),
        ),
      onInitialError,
      this.refreshUser$,
      'concat',
      'Failed to get user, old user maintained',
      'Failed to get initial user, default to anonymous user',
    );

  public readonly user$ = this.userWithRefreshCause$.pipe(map(({ user }) => user));

  public startPollingStatus(): void {
    // Poll user status every minute to check if user is still logged in, used to trigger automatic logout
    setInterval(
      () =>
        this.pollStatus().subscribe({
          error: (err) => this.loggingService.error('Getting user status failed', err),
        }),
      1000 * 30,
    );
  }

  waitUntilUserMeetsRequirements(condition: (user: User) => boolean): Observable<void> {
    return this.user$.pipe(
      filter((user) => condition(user)),
      timeout(10000), // If the user does not meet the requirements within 10 seconds, throw an error
      take(1),
      map(() => {}),
    );
  }

  setProfiel(profielId: ProfielId): Observable<void> {
    return this.httpClient
      .put<UserDto>(`${environment.API_BASE_URL}/api/users/current/profiel`, { profielId })
      .pipe(
        switchMap(() => {
          this.refreshUser$.next('user-refresh');

          return this.waitUntilUserMeetsRequirements(
            (user: User) => user instanceof UserWithProfiel && user.profiel.id === profielId,
          );
        }),
      );
  }

  switchProfiel(profielId: ProfielId, redirectSegments: string[]): Observable<void> {
    return this.setProfiel(profielId).pipe(
      switchMap(() => this.contextService.switchContext(profielId, redirectSegments)),
      map(() => {}),
    );
  }

  getCurrentUser$(): Observable<User> {
    return this.user$;
  }

  userWithProfiel$(): Observable<UserWithProfiel> {
    return this.getCurrentUser$().pipe(
      map((user: User) => validateUserWithProfiel(user)),
      take(1),
    );
  }

  userWithExterneInfo$(): Observable<UserWithPersoonInfo> {
    return this.getCurrentUser$().pipe(
      map((user: User) => validateUserWithExternalInfo(user)),
      take(1),
    );
  }

  getExterneHoedanigheid(): Observable<ExterneHoedanigheid> {
    return this.httpClient
      .get(`${environment.API_BASE_URL}/api/externe-info/hoedanigheid`, { responseType: 'text' })
      .pipe(map((dto) => dto as ExterneHoedanigheid));
  }

  pollStatus(): Observable<string> {
    return this.user$.pipe(
      switchMap((user) => {
        if (user.isIngelogd()) {
          return this.httpClient.get(`${environment.API_BASE_URL}/api/users/current/status`, {
            responseType: 'text',
          });
        } else {
          return of('NIET_AANGEMELD');
        }
      }),
    );
  }

  startNetworkChangeDetection() {
    Network.addListener('networkStatusChange', async (status) => {
      if (status.connected) {
        this.refreshUser$.next('user-refresh');
      }
    });
  }

  refeshUser() {
    this.loggingService.log('Refreshing user');
    this.refreshUser$.next('user-refresh');
  }

  refreshUserAfterLogout() {
    this.loggingService.log('Refreshing user after logout');
    this.refreshUser$.next('logout');
  }

  private getSessionUser(): Observable<User> {
    return this.httpClient
      .get<UserDto>(`${environment.API_BASE_URL}/api/users/current`)
      .pipe(map((dto) => userFromDto(dto)));
  }
}
