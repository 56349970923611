import { Component, ViewChild } from '@angular/core';
import { KalenderService } from '../../service/kalender.service';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import {
  AfspraakFormComponent,
  AfspraakFormGroup,
} from '../../component/afspraak-form/afspraak-form.component';
import { combineLatest, map, mergeMap, switchMap, tap } from 'rxjs';
import { ProfielService } from '../../service/profiel.service';
import { ContextDto, ContextOptionDto } from 'parkour-web-app-dto';
import { ParkourPageGradientComponent, ParkourToastService, ToastConfig } from '@parkour/ui';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { DirtyFormCheck } from '../../../shared/components/dirty-form-guard';
import { UserService } from '../../../user/service/user.service';
import { UserWithProfiel } from '../../../user/model/user';
import { ActivatedRoute, Router } from '@angular/router';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe, NgIf } from '@angular/common';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { ContextService } from '../../../shared/services/context.service';
import { RawValueOfFormGroup } from '../../../shared/components/parkour-form/parkour-form.types';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { toAfpsraakInsertDto } from '../../model/afspraak';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';

type PageData = {
  user: UserWithProfiel;
  context: ContextDto;
  mijnTeamlidContexten: Array<ContextOptionDto>;
};

@Component({
  standalone: true,
  templateUrl: './kalender-afpraak-add.page.html',
  imports: [
    ParkourBackButtonDirective,
    NgIf,
    ParkourHeaderActionComponent,
    ParkourPageGradientComponent,
    PageLayoutComponent,
    TranslateModule,
    PageContentDirective,
    AfspraakFormComponent,
    AsyncPipe,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    ShowIfSuccesfulDirective,
  ],
})
export class KalenderAfpraakAddPage extends ParkourDataPage<PageData> implements DirtyFormCheck {
  @ViewChild('addAfspraakForm') addAfspraakForm: AfspraakFormComponent | undefined;

  constructor(
    private readonly kalenderService: KalenderService,
    private readonly profielService: ProfielService,
    private readonly toastService: ParkourToastService,
    private readonly translate: TranslatePipe,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly userService: UserService,
    private readonly contextService: ContextService,
  ) {
    super();
  }

  override retrieveMainData() {
    return combineLatest({
      user: this.userService.userWithProfiel$(),
      context: this.contextService.context$,
      mijnTeamlidContexten: this.userService
        .userWithProfiel$()
        .pipe(
          mergeMap((user) =>
            this.profielService
              .getMijnSortedContexten()
              .pipe(
                map((contexten) =>
                  contexten.filter((context) => context.contextId !== user.profiel.id),
                ),
              ),
          ),
        ),
    });
  }

  submitAfspraakForm = (afspraakFormData: RawValueOfFormGroup<AfspraakFormGroup>) => {
    return this.getCurrentMainDataOrThrow().pipe(
      switchMap((data) => {
        const afspraakInsertDto = toAfpsraakInsertDto(afspraakFormData, data.user.profiel.id);

        return this.kalenderService.addAfspraak(afspraakInsertDto).pipe(
          tap(() =>
            this.toastService.showToast(this.getSuccessToastContentForAfspraak(afspraakFormData)),
          ),
          tap(() => this.router.navigate(['../../'], { relativeTo: this.route })),
        );
      }),
    );
  };

  getSuccessToastContentForAfspraak(afspraak: RawValueOfFormGroup<AfspraakFormGroup>): ToastConfig {
    switch (afspraak.type) {
      case 'BASIC':
        return {
          header: this.translate.transform(
            'kalender.kalender-afspraak-add-page.success-toast-title-basic',
          ),
          content: this.translate.transform(
            'kalender.kalender-afspraak-add-page.success-toast-description-basic',
          ),
        };
      case 'VIDEOGESPREK':
        return {
          header: this.translate.transform(
            'kalender.kalender-afspraak-add-page.success-toast-title-videogesprek',
          ),
          content: this.translate.transform(
            'kalender.kalender-afspraak-add-page.success-toast-description-videogesprek',
          ),
        };
    }
  }

  isFormDirty() {
    return this.addAfspraakForm?.afspraakForm.dirty || false;
  }
}
