import { Component } from '@angular/core';
import { map, Observable, switchMap, take } from 'rxjs';
import { VerhalenService } from '../../../verhaal-shared/service/verhalen.service';
import { validateGebeurtenisId } from '../../../verhaal-shared/utils';
import { ActivatedRoute } from '@angular/router';
import { Gebeurtenis } from '../../../verhaal-shared/model/gebeurtenis';
import { meldingenForPage } from '../../../../meldingen/config';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe, NgIf } from '@angular/common';
import { ParkourHeaderActionComponent } from '../../../../shared/components/parkour-header-action/parkour-header-action.component';
import { JeugdhulpHistoriekDetailsComponent } from '../../../verhaal-shared/component/jeugdhulp-historiek-details-component/jeugdhulp-historiek-details-component.component';
import { ParkourGebeurtenisDetailsComponent } from '../../../verhaal-shared/component/parkour-gebeurtenis-details-component/parkour-gebeurtenis-details-component.component';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { ShowIfSuccesfulDirective } from '../../../../shared/directives/show-if-succesful.directive';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { GebeurtenisDto } from 'parkour-web-app-dto';
import { CustomPageTitle, PageTitle } from '../../../../shared/custom-page-title';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../../shared/marks-meldingen-as-read';
import { asType } from '../../../../utils';

@Component({
  standalone: true,
  templateUrl: './gebeurtenis-details.page.html',
  imports: [
    ParkourBackButtonDirective,
    AsyncPipe,
    TranslateModule,
    ParkourHeaderActionComponent,
    NgIf,
    JeugdhulpHistoriekDetailsComponent,
    ParkourGebeurtenisDetailsComponent,
    PageContentDirective,
    PageLayoutComponent,
    ShowIfSuccesfulDirective,
    ParkourRefreshDirective,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    IonContent,
    IonRefresher,
    IonRefresherContent,
  ],
})
export class GebeurtenisDetailsPage
  extends ParkourDataPage<Gebeurtenis>
  implements MarksMeldingenAsReadOnView, CustomPageTitle
{
  constructor(
    private readonly verhalenService: VerhalenService,
    private readonly route: ActivatedRoute,
  ) {
    super();
  }

  override retrieveMainData(): Observable<GebeurtenisDto> {
    return this.route.paramMap.pipe(
      take(1),
      map((params) => params.get('gebeurtenis-id')),
      map(validateGebeurtenisId),
      switchMap((gebeurtenisId) => this.verhalenService.getGebeurtenis(gebeurtenisId)),
    );
  }

  getCustomPageTitle(): Observable<PageTitle> {
    return this.mainPageData$.pipe(
      switchMap((mainPageData) => {
        if (mainPageData.success) {
          return this.translateService
            .get(
              mainPageData.value.type === 'EYOUTH'
                ? 'verhaal.eyouth-gebeurtenis-details-page.title'
                : 'verhaal.gebeurtenis-details-page.title',
            )
            .pipe(
              map((detail) => ({
                title: mainPageData.value.titel,
                category: detail,
              })),
            );
        } else {
          return asType<Observable<string>>(
            this.translateService.get('verhaal.gebeurtenis-details-page.title'),
          );
        }
      }),
    );
  }

  getMeldingenToMarkAsRead() {
    return this.mainPageData$.pipe(
      map(
        (mainPageData) =>
          new MeldingenToMarkAsRead(
            {
              ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.PARKOUR_GEBEURTENIS_PAGE
                .specificMeldingen,
              ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.EYOUTH_GEBEURTENIS_PAGE
                .specificMeldingen,
            },
            (melding) =>
              mainPageData.success && melding.metaData.gebeurtenisId === mainPageData.value.id,
          ),
      ),
    );
  }
}
