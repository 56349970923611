import { AfterViewInit, Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TabsProfileTypes } from './tabs.types';
import { MeldingenService } from '../profiel/service/meldingen.service';
import { ContextService } from '../shared/services/context.service';
import { UserService } from '../user/service/user.service';
import { combineLatest } from 'rxjs';
import { IonTabBar, IonTabButton, IonTabs } from '@ionic/angular/standalone';
import { ActivatedRoute } from '@angular/router';
import { meldingenForPage } from '../meldingen/config';
import { PageLayoutSidebarComponent } from '../shared/components/page-layout-sidebar/page-layout-sidebar.component';
import { ParkourIconComponent, ParkourNewIndicatorComponent } from '@parkour/ui';
import { CoreModule } from '../core/core.module';
import { ProfielIndicatorComponent } from '../shared/components/profiel-indicator/profiel-indicator.component';

@Component({
  standalone: true,
  selector: 'parkour-tabs',
  templateUrl: 'tabs.page.html',
  styleUrls: ['./tabs.page.css'],
  imports: [
    PageLayoutSidebarComponent,
    ParkourIconComponent,
    CoreModule,
    ParkourNewIndicatorComponent,
    ProfielIndicatorComponent,
    IonTabs,
    IonTabBar,
    IonTabButton,
  ],
})
export class TabsPage implements AfterViewInit, OnInit {
  @ViewChildren('tabButton') tabButtons!: QueryList<IonTabButton>;

  actions = TabsProfileTypes;
  data$ = combineLatest({
    context: this.contextService.context$,
    user: this.userService.getCurrentUser$(),
  });

  hasWisselTeamIndicator$ = this.meldingenService.hasWisselTeamMeldingen();
  hasVerhaalTabMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForPage.VERHAAL_TAB.allMeldingen,
  );
  hasDoelTabMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForPage.DOELEN_TAB.allMeldingen,
  );
  hasProfielTabMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForPage.PROFIEL_TAB_MOBILE.allMeldingen,
  );
  hasBerichtenTabMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForPage.BERICHTEN_TAB.allMeldingen,
  );

  contextId = '';

  constructor(
    private readonly contextService: ContextService,
    private readonly meldingenService: MeldingenService,
    private readonly userService: UserService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.contextId = this.activatedRoute.snapshot.paramMap.get('contextId') ?? '';
  }

  ngAfterViewInit() {
    /*TODO: Remove when Ionic fixes Iontabbutton not accessible with keyboard navigation*/
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.tabButtons.map((tabButton: any) => {
      if (tabButton.el.shadowRoot) {
        const observer = new MutationObserver((mutationsList, observer) => {
          for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
              const tabButtonEl: HTMLLinkElement =
                tabButton.el.shadowRoot.querySelector('.button-native');

              if (tabButtonEl) {
                tabButtonEl.setAttribute('tabindex', '0');

                observer.disconnect();

                return;
              }
            }
          }
        });

        observer.observe(tabButton.el.shadowRoot, {
          childList: true,
          subtree: true,
        });
      }
    });
  }
}
