import {Injectable} from "@angular/core";
import {ModalController} from "@ionic/angular/standalone";
import type {ComponentProps} from '@ionic/core';

@Injectable({
    providedIn: "root",
})
export class ParkourModalService {
    constructor(private readonly modalController: ModalController) {}

    async showFullscreenModal(component: Function, props: ComponentProps<Function> = {}): Promise<void> {
        const modal = await this.modalController.create({
            component: component,
            showBackdrop: true,
            cssClass: "full-screen-modal",
            componentProps: props
        });

        await modal.present();
        await modal.onWillDismiss();
    }

    async closeTopModal() {
        await this.modalController.dismiss();
    }
}
