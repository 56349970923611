<swiper-container
  [attr.a11y-next-slide-message]="'buttons.volgende' | translate"
  [attr.a11y-prev-slide-message]="'buttons.vorige' | translate"
  a11y="true"
  class="[--swiper-navigation-sides-offset:0]"
  grab-cursor="true"
  navigation="true"
  pagination="true"
  parkourCarouselElement
  slides-per-view="auto"
  watch-overflow="true"
>
  <swiper-slide *ngFor="let slide of slides" class="flex justify-center w-full">
    <div class="flex flex-col gap-y-4 max-w-[16rem] sm:max-w-[18rem] lg:max-w-[24rem] align-middle">
      <parkour-image [alt]="slide.image.alt" [src]="slide.image.src" />

      <div class="flex flex-col gap-y-1">
        <div class="flex gap-x-2 items-center justify-center text-p-200">
          <parkour-icon [name]="slide.icon" class="flex" />

          <h3>{{ slide.title }}</h3>
        </div>

        <p>{{ slide.description }}</p>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
