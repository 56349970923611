import { Component } from '@angular/core';
import { ParkourBlockPlaceholderComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-geen-gebeurtenissen-placeholder',
  standalone: true,
  imports: [ParkourBlockPlaceholderComponent],
  templateUrl: './geen-gebeurtenissen-placeholder.component.html',
})
export class GeenGebeurtenissenPlaceholderComponent {}
