import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActieItemGroup } from '../doel-form/doel-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ParkourButtonComponent,
  ParkourFormFieldWithoutLabelComponent,
  ParkourInputComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'parkour-actie-item-edit',
  templateUrl: './actie-item-edit.component.html',
  imports: [
    ReactiveFormsModule,
    ParkourFormFieldWithoutLabelComponent,
    ParkourInputComponent,
    ParkourButtonComponent,
    TranslateModule,
  ],
})
export class ActieItemEditComponent {
  @Input({ required: true }) actieItemGroup!: ActieItemGroup;
  @Output() actieDeleted = new EventEmitter<void>();

  deleteActie() {
    this.actieDeleted.emit();
  }
}
