import { Rol } from './persoon.dto';
import { ProfielId } from './profiel.dto';
import { ApiProperty } from '@nestjs/swagger';
import { JongereProfielDto } from './jongere-profiel.dto';

export class AnoniemeContextDto {
  readonly contextId!: 'anoniem';
  readonly type!: 'anoniem';
}

export class VoorMezelfContextDto {
  readonly type!: 'voor-mezelf';
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
}

export class TeamlidContextDto {
  readonly type!: 'teamlid';
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
  readonly jongereProfiel!: JongereProfielDto;
  @ApiProperty({ type: String }) // TODO: Backwards compatibility: 1.0001.0041, remove teamlidProfielId from contextDto
  readonly teamlidProfielId!: ProfielId;
  @ApiProperty({ type: String })
  readonly rol!: Rol;
  readonly geblokkeerd!: boolean;
}

export class JongereContextDto {
  readonly type!: 'jongere';
  @ApiProperty({ type: String })
  readonly contextId!: ProfielId;
}

export type ContextDto =
  | AnoniemeContextDto
  | VoorMezelfContextDto
  | TeamlidContextDto
  | JongereContextDto;
