export const environment = {
  production: true,
  API_BASE_URL: 'https://parkour-test.opgroeien.be',
  watWatBaseUrl: 'https://www.watwat.be',
  globalHeaderEmbedCode:
    'https://tni.widgets.burgerprofiel.dev-vlaanderen.be/api/v1/widget/275a984a-0a80-456d-9204-97e1a22d446d',
  jitsiApiEmbedCode:
    'https://8x8.vc/vpaas-magic-cookie-42c59fa2663d4492934d3d2a9c7565c8/external_api.js',
  ongelezenMeldingenInterval: 30000,
  opentelemetry: {
    enabled: false,
    tracingUrl: '',
  },
  logging: 'debug',
  matomo: {
    enabled: true,
    trackerUrl: 'https://matomo.parkour-test.opgroeien.be/',
    siteId: 1,
  },
  mockGlobalHeader: false,
  debugOptionsEnabled: true,
};
