<parkour-profiel-type-label
  *ngIf="externeHoedanigheid !== 'BEGELEIDER_PERSONEELSLID'; else profielSwitchable"
  [profielType]="currentProfiel.profielType"
  classes="text-n-600 typo-body"
/>

<ng-template #profielSwitchable>
  <parkour-action
    (clicked)="onProfielSwitchClick()"
    [ariaExpanded]="selectModal.isOpen"
    ariaControls="profiel-select-modal"
    classes="flex items-center justify-center gap-2"
  >
    <parkour-profiel-type-label
      [profielType]="currentProfiel.profielType"
      classes="text-n-600 typo-body"
    />

    <parkour-icon class="inline-flex text-n-600" name="chevron-bottom" />
  </parkour-action>

  <ion-modal
    #selectModal="parkourModalBehavior"
    class="modal"
    id="profiel-select-modal"
    parkourModalBehavior
  >
    <ng-template>
      <ul
        [attr.aria-activedescendant]="currentProfiel.id"
        class="bg-n-100 rounded overflow-hidden border-[1.5px] border-n-500 divide-y-[1.5px] divide-n-500 my-[calc(var(--ion-safe-area-top)+1rem)]"
        role="listbox"
      >
        @for (profiel of profielen; track profiel.id) {
          <li
            role="option"
            [id]="profiel.id"
            [attr.aria-selected]="profiel.id === currentProfiel.id"
          >
            <parkour-profiel-select-option
              (selected)="onProfielSelect($event)"
              [isSelected]="profiel.id === currentProfiel.id"
              [profiel]="profiel"
            />
          </li>
        }

        <li>
          @if (canAddPersoonlijkProfiel()) {
            <parkour-action
              (click)="onAddPersoonlijkProfielClick()"
              classes="flex items-center gap-x-3 p-4 w-full"
            >
              <parkour-badge icon="plus" iconSize="3" />

              <span class="typo-body variant-body-fat">{{
                'profiel.personal-profile-button' | translate
              }}</span>
            </parkour-action>
          }

          @if (canAddProfessioneelProfiel()) {
            <parkour-action
              (click)="onAddProfessioneelProfielClick()"
              classes="flex items-center gap-x-3 p-4 w-full"
            >
              <parkour-badge icon="plus" iconSize="3" />

              <span class="typo-body variant-body-fat">{{
                'profiel.professional-profile-button' | translate
              }}</span>
            </parkour-action>
          }
        </li>
      </ul>
    </ng-template>
  </ion-modal>
</ng-template>
