<parkour-action
  (clicked)="this.selected.emit(profiel)"
  [ngClasses]="{
    'bg-n-500': isSelected,
  }"
  classes="flex items-center justify-between p-4 w-full"
>
  <div class="flex items-center gap-x-3">
    <parkour-avatar
      [image]="{ type: 'image', src: profiel | profielFoto | async }"
      class="w-[3rem]"
    />

    <div class="text-left space-y-1">
      <span class="typo-body variant-body-fat">{{ getProfielnaamWithoutFallback(profiel) }}</span>

      <p *ngIf="profiel.profielType === 'PERSOONLIJK'"></p>
      <p>
        @switch (profiel.profielType) {
          @case ('PERSOONLIJK') {
            {{ 'profiel.personal-profile' | translate }}
          }
          @case ('PROFESSIONEEL') {
            {{ 'profiel.professional-profile' | translate }}
            <parkour-professioneel-indicator />
          }
        }
      </p>
    </div>
  </div>

  @if (isSelected) {
    <parkour-icon class="text-positive" name="check" size="3" />
  }
</parkour-action>
