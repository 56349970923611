import { UserWithPersoonInfo } from './user';
import { AangemeldZonderProfielUserDto } from 'parkour-web-app-dto';

export class AangemeldZonderProfielUser extends UserWithPersoonInfo {
  static fromAangemeldZonderProfielUserDto(
    dto: AangemeldZonderProfielUserDto,
  ): AangemeldZonderProfielUser {
    return new AangemeldZonderProfielUser(dto.persoonId, dto.voornaam, dto.naam);
  }
}
