<form (ngSubmit)="onFotoSubmit()" class="flex flex-col gap-y-6">
  <div class="w-[10rem] h-[10rem] self-center">
    @if (isImageCropperHidden) {
      <parkour-avatar [image]="{ type: 'image', src: getProfielFoto() | secureImage | async }" />
    } @else {
      <p class="typo-body variant-body-fat text-center">{{ 'profiel.crop-image' | translate }}</p>
    }
  </div>
  @if (imageToCrop) {
    <image-cropper
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
      [aspectRatio]="1 / 1"
      [hidden]="isImageCropperHidden"
      [imageBase64]="imageToCrop"
      [maintainAspectRatio]="true"
      [resizeToWidth]="700"
      [format]="'jpeg'"
      class="rounded"
    />
  }

  <div class="flex flex-col gap-y-6">
    <h4>{{ 'profiel.upload-image' | translate }}</h4>

    @if (!isNativeApp()) {
      <parkour-button
        (clicked)="handleClickOpenImageLibrary()"
        class="w-full"
        iconAfter="camera"
        variant="tertiary"
      >
        {{ 'profiel.upload-image' | translate }}
      </parkour-button>
    } @else {
      <parkour-button
        (clicked)="handleClickTakeSelfie()"
        class="w-full"
        iconAfter="camera"
        variant="tertiary"
      >
        {{ 'profiel.create-selfie' | translate }}
      </parkour-button>

      <parkour-button
        (clicked)="handleClickOpenImageLibrary()"
        class="w-full"
        iconAfter="ai-images"
        variant="tertiary"
      >
        {{ 'profiel.open-library' | translate }}
      </parkour-button>
    }
  </div>

  <div class="flex flex-col gap-y-6">
    <h4>{{ 'profiel.select-avatar' | translate }}</h4>

    <parkour-profiel-avatar-select
      (ngModelChange)="onAvatarChange($event)"
      [formControl]="fotoDetailsFormgroup.controls.avatar"
      [items]="avatars"
    />
  </div>

  <parkour-button class="w-full" iconAfter="disk" type="submit">
    {{ 'profiel.image-submit-button' | translate }}
  </parkour-button>
</form>
