import { Component } from '@angular/core';
import { UserService } from '../../../user/service/user.service';
import { UserWithPersoonInfo } from '../../../user/model/user';
import { Observable } from 'rxjs';
import { ExterneHoedanigheid } from 'parkour-web-app-dto';
import { ProfielCreateService } from '../../../profiel/service/profiel-create.service';
import { asResult, Result } from '../../../utils';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourPictureComponent,
} from '@parkour/ui';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { IonContent, IonHeader, ViewWillEnter } from '@ionic/angular/standalone';

@Component({
  standalone: true,
  templateUrl: './profiel-create.page.html',
  imports: [
    NgIf,
    AsyncPipe,
    ParkourDividerComponent,
    ParkourPictureComponent,
    ParkourButtonComponent,
    ContentUnavailableCardComponent,
    PageLayoutComponent,
    IonHeader,
    IonContent,
  ],
})
export class ProfielCreatePage implements ViewWillEnter {
  externalUser$: Observable<UserWithPersoonInfo> = new Observable<UserWithPersoonInfo>();
  externeHoedanigheid$: Observable<Result<ExterneHoedanigheid>> = new Observable();

  constructor(
    private readonly userService: UserService,
    private readonly profielCreateService: ProfielCreateService,
  ) {}

  ionViewWillEnter(): void {
    this.externalUser$ = this.userService.userWithExterneInfo$();

    this.externeHoedanigheid$ = asResult(this.userService.getExterneHoedanigheid());
  }

  async onPersoonlijkProfielClick() {
    this.profielCreateService.startCreatingPersoonlijkProfiel();
  }

  async onProfessioneelProfielClick() {
    this.profielCreateService.startCreatingProfessioneelProfiel();
  }
}
