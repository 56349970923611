<div aria-live="assertive" class="chat-flow">
  @if (
    isEenOpEenGesprek(gesprek) &&
    getDeelnemerProfiel(gesprek).afwezigheid.afwezig &&
    checkIfActiveDate(
      getDeelnemerProfiel(gesprek).afwezigheid.van,
      getDeelnemerProfiel(gesprek).afwezigheid.tot
    )
  ) {
    <div class="chat-flow__wrapper chat-flow__afwezigheid">
      <parkour-event-afwezigheid
        [deelnemer]="getDeelnemerProfiel(gesprek)"
        class="relative z-0 self-center max-w-[28rem] w-full"
      />
    </div>
  }

  @if (isEenOpEenGesprek(gesprek) && gesprek.videogesprekId) {
    <div class="chat-flow__wrapper">
      <parkour-event-start-videogesprek
        class="relative z-0 self-center max-w-[28rem] w-full"
        (clicked)="onVideoGesprekJoin()"
      />
    </div>
  }

  @for (bericht of berichten.slice().reverse(); track bericht[0].id) {
    <div class="chat-flow__wrapper">
      @for (subBericht of bericht.slice().reverse(); track subBericht.id) {
        @if (subBericht | isSupportedBericht) {
          @switch (subBericht.type) {
            @case ('EVENT') {
              <parkour-bericht-event [bericht]="subBericht" />
            }
            @case ('GEMASKEERD') {
              <parkour-bericht-gemaskeerd
                [isUser]="subBericht.deelnemerId === user.profiel.id"
                [gesprekWithDeelnemers]="gesprek"
              />
            }
            @default {
              <parkour-bericht-message
                (rapporteer)="onRapporteer($event)"
                (closingPopover)="closedBerichtMessagePopover()"
                (deleteReactie)="onDeleteReactie($event)"
                (openedPopover)="openedBerichtMessagePopover()"
                (selectedReactie)="onSelectedReactie($event)"
                (verwijderd)="onVerwijderd(subBericht.id)"
                (saveNotitie)="onSaveNotitie($event)"
                (replyBericht)="onReplyBericht($event)"
                (scrollToBottom)="onScrollToBottom()"
                [bericht]="subBericht"
                [gesprekId]="gesprekId"
                [gesprekWithDeelnemers]="gesprek"
                [isUser]="subBericht.deelnemerId === user.profiel.id"
                [userProfielId]="user.profiel.id"
                [berichten]="berichten"
                [disabled]="isGesprekGestopt()"
              />
            }
          }
        }
      }
    </div>
  }
</div>
