<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <div class="pb-8 container container-space">
      <section class="flex flex-col gap-y-8 pb-10">
        <div class="max-lg:full-bleed relative z-0 w-full h-full lg:max-w-[46.5rem] lg:mx-auto">
          <parkour-picture
            [sources]="{
              default: {
                src: '/assets/home/background/default/background_home_default.webp',
                fallback: '/assets/home/background/default/background_home_default.png',
              },
              md: {
                src: '/assets/home/background/md/background_home_md.webp',
                fallback: '/assets/home/background/md/background_home_md.png',
              },
            }"
            alt=""
            class="absolute h-full w-full blur-[32px]"
            fetchPriority="high"
          />

          <parkour-picture
            [sources]="{
              default: {
                src: '/assets/home/background/default/background_home_default.webp',
                fallback: '/assets/home/background/default/background_home_default.png',
              },
              md: {
                src: '/assets/home/background/md/background_home_md.webp',
                fallback: '/assets/home/background/md/background_home_md.png',
              },
            }"
            alt=""
            class="relative z-10 h-full w-full"
            fetchPriority="high"
          />
        </div>

        <div class="container-1-col space-y-8">
          <div class="flex flex-col gap-y-6">
            <h1 class="text-center">Welkom bij PARKOUR!</h1>

            <parkour-divider></parkour-divider>
          </div>

          <parkour-aanmeld-button
            *ngIf="showLoginButton$ | async"
            [redirectAfterLogin]="'/app/me/home'"
            class="block"
            data-testid="login"
          ></parkour-aanmeld-button>
          <parkour-button
            *ngIf="showProfielButton$ | async"
            [link]="['..', 'profiel']"
            class="w-full block"
          >
            Maak een profiel
          </parkour-button>
        </div>
      </section>

      <section *ngIf="(isProfielUser$ | async) === false" class="full-bleed py-10 bg-n-200">
        <div class="container container-space text-center">
          <div class="flex flex-col gap-y-2 container-1-col">
            <h2>Aan de slag</h2>

            <p>Ontdek wat PARKOUR jou en je team te bieden heeft</p>

            <parkour-onboarding [slides]="onboardingSlides" class="block flex-1" />
          </div>
        </div>
      </section>

      <section class="container-1-col flex flex-col gap-y-6 pt-10">
        <h2 class="text-center self-center">
          Waarmee kunnen we <br />
          vandaag helpen?
        </h2>

        <form (ngSubmit)="clearSearchTerm()">
          <parkour-form-field
            [inputFormControl]="null"
            [isLabelHidden]="true"
            inputId="artikel-search-button"
            label="Wat zoek je?"
          >
            <parkour-input
              [(ngModel)]="searchTerm"
              [testId]="'home-page-artikel-search-term'"
              inputId="artikel-search-button"
              inputPlaceholder="Wat zoek je?"
              name="artikel-search-button"
            >
              <parkour-button
                [ariaLabel]="'buttons.search' | translate"
                [testId]="'home-page-artikel-search'"
                format="iconButton"
                icon="search"
                size="medium"
                type="submit"
              />
            </parkour-input>
          </parkour-form-field>
        </form>

        <ul class="grid grid-cols-2 gap-4">
          <li *ngFor="let thema of homePageBasisThemas">
            <parkour-thema-button [thema]="thema" />
          </li>
        </ul>

        <div>
          <div
            class="grid transition-[grid-template-rows] duration-300 {{
              showMoreThemes ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'
            }}"
          >
            <div class="overflow-hidden">
              <ul class="grid grid-cols-2 gap-4">
                <li *ngFor="let thema of homePageMeerThemas">
                  <parkour-thema-button [isHidden]="!showMoreThemes" [thema]="thema" />
                </li>
              </ul>
            </div>
          </div>

          <div class="flex justify-center {{ showMoreThemes ? 'mt-8' : '' }}">
            <parkour-button
              (click)="showMoreThemes = !showMoreThemes"
              [iconAfter]="showMoreThemes ? 'chevron-top' : 'chevron-down'"
              class="block w-full"
              variant="ghost"
            >
              {{ showMoreThemes ? "Minder thema's" : "Meer thema's" }}
            </parkour-button>
          </div>
        </div>
      </section>

      <section class="container-1-col pt-10">
        <div class="flex items-center gap-x-2 mb-4">
          <parkour-icon class="flex" name="book" size="3" />

          <h2 class="typo-h4">Artikels</h2>

          <div class="flex-1 h-[1.5px] w-full opacity-30 bg-n-600"></div>
        </div>

        <parkour-artikels-preview
          (clickedBookmark)="onArtikelBookmarkClicked($event)"
          *parkourSectionContent="{ data: mainPageData$ | async }; let artikelsResult"
          [artikels]="artikelsResult.results"
          class="block"
        />
      </section>

      <section class="container-1-col pt-10">
        <div class="flex items-center gap-x-2 mb-4">
          <parkour-icon class="flex" name="book" size="3" />

          <h2 class="typo-h4">Hulplijnen</h2>

          <div class="flex-1 h-[1.5px] w-full opacity-30 bg-n-600"></div>
        </div>
        <ul class="flex flex-col gap-2">
          @if (fixedOrganisations$ | async; as fixedOrganisations) {
            @for (organisation of reorderOrganisations(fixedOrganisations); track organisation.id) {
              <li>
                <parkour-helpline-card-item
                  [segmentsToHulplijn]="['hulplijn', String(organisation.id)]"
                  [organisation]="organisation"
                />
              </li>
            }
          } @else {
            <li>
              <parkour-helpline-card-item
                [segmentsToHulplijn]="['hulplijn', String(offlineTeleOnthaalOrganisation.id)]"
                [organisation]="offlineTeleOnthaalOrganisation"
              />
            </li>
          }
        </ul>

        <parkour-button
          [link]="['hulplijnen']"
          class="self-center block w-full mt-4"
          iconAfter="chevron-right"
          variant="ghost"
        >
          Alle hulplijnen
        </parkour-button>
      </section>

      <section class="full-bleed lg:container pt-10 flex flex-col gap-y-10">
        <parkour-over-parkour class="block" />

        @if (!isNativeApp()) {
          <parkour-download-apps />
        }
      </section>
    </div>
  </parkour-page-layout>
</ion-content>
