import { Component, OnInit } from '@angular/core';
import { combineLatest, map, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';
import { ContextService } from '../../services/context.service';
import { UserService } from '../../../user/service/user.service';
import { ProfielService } from '../../../profiel/service/profiel.service';
import { asResult, Result, Success } from '../../../utils';
import { Context } from '../../model/context';
import { User, UserWithProfiel } from '../../../user/model/user';
import { ContextOption } from '../../../profiel/model/context-option';
import { SidebarTeamlidContextCardComponent } from './sidebar-teamlid-context-card/sidebar-teamlid-context-card.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { SidebarWisselTeamPopoverComponent } from './sidebar-wissel-team-popover/sidebar-wissel-team-popover.component';
import { SidebarJongereContextCardComponent } from './sidebar-jongere-context-card/sidebar-jongere-context-card.component';
import { SidebarVoorMezelfContextCardComponent } from './sidebar-voor-mezelf-context-card/sidebar-voor-mezelf-context-card.component';
import { SidebarProfielPopoverComponent } from './sidebar-profiel-popover/sidebar-profiel-popover.component';
import { AnomymousUser } from '../../../user/model/anomymous-user';
import { MeldingenService } from '../../../profiel/service/meldingen.service';
import { NavigationEnd, Router } from '@angular/router';
import {
  ParkourActionComponent,
  ParkourIconComponent,
  ParkourNewIndicatorComponent,
} from '@parkour/ui';
import { ProfielnaamPipe } from '../../pipes/profielnaam.pipe';
import { PusherService } from '../../../bericht/service/pusher.service';
import { meldingenForPage } from '../../../meldingen/config';
import { TranslateModule } from '@ngx-translate/core';
import { AanmeldButtonComponent } from '../aanmeld-button/aanmeld-button.component';
import { PageLayoutSidebarNavItemComponent } from '../page-layout-sidebar-nav-item/page-layout-sidebar-nav-item.component';
import { AbsoluteRoutePipe } from '../../pipes/absolute-route.pipe';
import { ProfielIndicatorComponent } from '../profiel-indicator/profiel-indicator.component';
import { ProfielTypeLabelComponent } from '../../../profiel/component/profiel-type-label/profiel-type-label.component';
import { ShowIfSuccesfulDirective } from '../../directives/show-if-succesful.directive';

@Component({
  selector: 'parkour-page-layout-sidebar',
  standalone: true,
  templateUrl: './page-layout-sidebar.component.html',
  imports: [
    SidebarTeamlidContextCardComponent,
    AsyncPipe,
    SidebarWisselTeamPopoverComponent,
    SidebarJongereContextCardComponent,
    SidebarVoorMezelfContextCardComponent,
    NgIf,
    SidebarProfielPopoverComponent,
    ProfielnaamPipe,
    TranslateModule,
    ParkourActionComponent,
    AanmeldButtonComponent,
    ParkourIconComponent,
    ParkourNewIndicatorComponent,
    PageLayoutSidebarNavItemComponent,
    AbsoluteRoutePipe,
    ProfielIndicatorComponent,
    ProfielTypeLabelComponent,
    ShowIfSuccesfulDirective,
  ],
})
export class PageLayoutSidebarComponent implements OnInit {
  public isProfielPopoverOpen = false;
  public isProfielPopoverActive = false;

  showLoginButton$ = this.userService
    .getCurrentUser$()
    .pipe(map((user) => user instanceof AnomymousUser));
  userData$: Observable<{
    context: Context;
    user: User;
  }> = new Observable();
  hasVerhaalTabMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForPage.VERHAAL_TAB.allMeldingen,
  );
  hasDoelTabMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForPage.DOELEN_TAB.allMeldingen,
  );
  hasProfielTabDesktopMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForPage.PROFIEL_TAB_DESKTOP.allMeldingen,
  );
  hasBerichtenTabMeldingen$ = this.meldingenService.hasMeldingenInCurrentContextByType(
    meldingenForPage.BERICHTEN_TAB.allMeldingen,
  );
  private onStartOrContextenChanged$ = this.pusherService
    .createPusherObservableForEvent('contexten-changed')
    .pipe(startWith('started'));
  mijnContexten$: Observable<Result<ContextOption[]>> = combineLatest([
    this.userService.getCurrentUser$(),
    this.onStartOrContextenChanged$,
  ]).pipe(
    switchMap(([user]) =>
      user instanceof UserWithProfiel
        ? asResult(this.profielService.getMijnSortedContexten())
        : of(new Success<ContextOption[]>([])),
    ),
  );

  constructor(
    private router: Router,
    private readonly contextService: ContextService,
    private readonly userService: UserService,
    private readonly profielService: ProfielService,
    private readonly meldingenService: MeldingenService,
    private readonly pusherService: PusherService,
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.isProfielPopoverActive = e.url.includes('/profiel');
      }
    });
  }

  ngOnInit(): void {
    this.userData$ = combineLatest({
      context: this.contextService.context$,
      user: this.userService.getCurrentUser$(),
    }).pipe(shareReplay(1));
  }

  profielPopoverOpen() {
    this.isProfielPopoverOpen = true;
  }

  profielPopoverClose() {
    this.isProfielPopoverOpen = false;
  }

  userHasProfiel(user: User): user is UserWithProfiel {
    return user instanceof UserWithProfiel;
  }
}
