import { Rol } from 'parkour-web-app-dto';
import { TeamlidProfiel } from '../team/model/teamlid.model';
// TODO: Rollen toevoegen aan JSON?
const rolLabels: { [key in Rol]: string } = {
  BEGELEIDER: 'Begeleider',
  CONSULENT: 'Consulent',
  FAMILIE: 'Familie',
  OUDER: 'Ouder',
  PARTNER: 'Partner',
  VRIEND: 'Vriend',
  ANDERE: 'Andere',
} as const;

export function getRolLabel(rol: Rol): string {
  return rolLabels[rol];
}

export const getRolLabelForTeamlid = (teamlid: TeamlidProfiel) => {
  switch (teamlid.status) {
    case 'UITGENODIGD':
      return '';
    case 'UNSUPPORTED':
      return '';
    case 'ACTIEF':
      return teamlid.rol !== undefined ? getRolLabel(teamlid.rol) : '';
    case 'GEBLOKKEERD':
      return teamlid.rol !== undefined ? getRolLabel(teamlid.rol) : '';
    case 'INACTIEF':
      return 'Inactief';
  }
};
