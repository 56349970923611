import { Injectable } from '@angular/core';
import { PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { asType, isNativeApp } from '../../utils';
import { UserService } from '../../user/service/user.service';
import { User, UserWithProfiel } from '../../user/model/user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RegistreerVoorMeldingenDto } from 'parkour-web-app-dto';
import { PluginListenerHandle } from '@capacitor/core';
import { MeldingenService } from './meldingen.service';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Router } from '@angular/router';
import { LoggingService } from '../../core/logging.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificatieService {
  tokenRegistrationSubscription?: PluginListenerHandle;

  constructor(
    private readonly loggingService: LoggingService,
    private readonly meldingenService: MeldingenService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly http: HttpClient,
  ) {}

  private async attemptRegisterForNotifications(user: User) {
    if (user instanceof UserWithProfiel && isNativeApp()) {
      await this.registerForPushNotifications(user);
    }
  }

  private async registerForPushNotifications(user: UserWithProfiel): Promise<void> {
    this.tokenRegistrationSubscription?.remove();
    this.tokenRegistrationSubscription = await PushNotifications.addListener(
      'registration',
      (token) => this.onTokenReceived(user, token),
    );

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive === 'granted') {
      await PushNotifications.register();
    }
  }

  private onTokenReceived(user: UserWithProfiel, token: Token) {
    this.registerToken(user.profiel.id, token.value).subscribe();
  }

  private registerToken(persoonId: string, value: string) {
    return this.http.post(
      `${environment.API_BASE_URL}/api/meldingen/persoon/${persoonId}/pushNotificatieRegistratie`,
      asType<RegistreerVoorMeldingenDto>({ token: value }),
    );
  }

  public initialize() {
    if (isNativeApp()) {
      this.addHandlers();
    }

    this.userService
      .getCurrentUser$()
      .subscribe((user) => this.attemptRegisterForNotifications(user));
  }

  async displayLocalNotification(notification: PushNotificationSchema) {
    this.loggingService.log('Attempting to schedule local notification', notification.body);
    const permissionStatus = await LocalNotifications.checkPermissions();
    if (permissionStatus.display === 'granted') {
      await LocalNotifications.schedule({
        notifications: [
          {
            title: '',
            body: notification.body || '',
            id: new Date().getUTCDate(),
            schedule: { at: new Date(new Date().getTime() + 500) },
            extra: {
              ...notification.data,
            },
          },
        ],
      });
    }
  }

  private async addHandlers() {
    await PushNotifications.addListener('registrationError', (err) => {
      this.loggingService.error('Registration error: ', err.error);
    });
    await PushNotifications.addListener('pushNotificationReceived', (notification) => {
      this.loggingService.log('Push notification notification received: ', notification.data);

      this.meldingenService.fetchOngelezenMeldingen();

      const meldingId = notification.data.meldingId;

      if (meldingId) {
        this.meldingenService.isMeldingSupportedEnGelezen(meldingId).subscribe((gelezen) => {
          if (!gelezen) {
            this.displayLocalNotification(notification);
          } else {
            this.loggingService.log('Melding is al gelezen, wordt niet getoond');
          }
        });
      }
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      this.loggingService.log(
        'Push notification action performed: ',
        notification.notification.data,
      );

      this.meldingenService.fetchOngelezenMeldingen();

      const meldingId = notification.notification.data.meldingId;

      if (meldingId) {
        this.navigateToMelding(meldingId);
      }
    });
    await LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
      this.loggingService.log('Local notification action performed', notification);

      const meldingId = notification.notification.extra.meldingId;
      if (meldingId) {
        this.navigateToMelding(meldingId);
      }
    });

    this.loggingService.log('Added push notification handlers');
  }

  private navigateToMelding(meldingId: string) {
    this.router.navigate(['app', 'me', 'profiel', 'meldingen'], { queryParams: { meldingId } });
  }
}
