import { Component, Input } from "@angular/core";

import {
  icons,
  TParkourIconName,
  TParkourIconSizes,
  TParkourIconTypes,
} from "./parkour-icon.types";
import { NgClass } from "@angular/common";

@Component({
  standalone: true,
  selector: "parkour-icon",
  templateUrl: "./parkour-icon.component.html",
  imports: [NgClass],
})
export class ParkourIconComponent {
  @Input() public classes?: string;
  @Input({ required: true }) public name!: TParkourIconName;
  @Input() public type: TParkourIconTypes = "default";
  @Input() public size: TParkourIconSizes = "default";
  @Input() public ariaLabel?: string;
  protected readonly icons = icons;
}
