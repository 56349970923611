import { inject } from '@angular/core';
import { map } from 'rxjs';
import { ContextService } from '../shared/services/context.service';
import { CanMatchFn } from '@angular/router';

export const actiefAlsJongereMatcher: CanMatchFn = () =>
  inject(ContextService).context$.pipe(
    map((context) => {
      return context.type === 'jongere';
    }),
  );

export const actiefAlsTeamlidMatcher: CanMatchFn = () =>
  inject(ContextService).context$.pipe(
    map((context) => {
      return context.type === 'teamlid';
    }),
  );

export const inTeamMatcher: CanMatchFn = () =>
  inject(ContextService).context$.pipe(
    map((context) => {
      return context.type === 'teamlid' || context.type === 'jongere';
    }),
  );

export const actiefVoorMezelfMatcher: CanMatchFn = () =>
  inject(ContextService).context$.pipe(
    map((context) => {
      return context.type === 'voor-mezelf';
    }),
  );

export const actiefWithProfielMatcher: CanMatchFn = () =>
  inject(ContextService).context$.pipe(
    map((context) => {
      return context.type !== 'anoniem';
    }),
  );
