import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProfielId } from 'parkour-web-app-dto';

@Injectable({
  providedIn: 'root',
})
export class NavigationHelperService {
  constructor(private readonly router: Router) {}

  public navigateToLoginPage(redirectUrlOverride?: string, redirectProfielId?: ProfielId) {
    const redirectUrl = encodeURIComponent(
      redirectUrlOverride ??
        this.router.getCurrentNavigation()?.initialUrl.toString() ??
        this.router.url,
    );

    this.router.navigate(['/app/login'], {
      queryParams: {
        redirectProfielId,
        redirectAfterLogin: redirectUrl,
      },
      info: {
        overridePopups: true,
      },
      onSameUrlNavigation: 'reload',
    });
  }
}
