import { Component, Input } from '@angular/core';
import { ParkourIconComponent, ParkourUiComponentsModule } from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { LinkifyPipe } from '../../pipes/linkify.pipe';
import { JongereDeelnemer, TeamlidDeelnemer } from '../../model/gesprek';
import { isProfessioneel } from '../../../team/model/teamlid.model';
import { teleOnthaalOrganisation } from '../../../shared/services/watwat.service';
import { HelplineCardItemComponent } from '../../../home/component/helpline-card-item/helpline-card-item.component';

@Component({
  selector: 'parkour-event-afwezigheid',
  templateUrl: './event-afwezigheid.component.html',
  standalone: true,
  imports: [
    ParkourUiComponentsModule,
    TranslateModule,
    DatePipe,
    LinkifyPipe,
    HelplineCardItemComponent,
    ParkourIconComponent,
  ],
})
export class EventAfwezigheidComponent {
  @Input({ required: true }) deelnemer!: TeamlidDeelnemer | JongereDeelnemer;
  protected readonly isProfessioneel = isProfessioneel;
  protected readonly teleOnthaalOrganisation = teleOnthaalOrganisation;
  protected readonly String = String;
}
