@switch (profielType) {
  @case ('PERSOONLIJK') {
    <p [class]="classes">
      {{ 'profiel.personal-profile' | translate }}
    </p>
  }
  @case ('PROFESSIONEEL') {
    <p [class]="classes">
      {{ 'profiel.professional-profile' | translate }}
    </p>
  }
}
