import {
  AfwezigheidDto,
  ProfielId,
  TeamlidProfielDto,
  TeamlidUpdateDto,
} from 'parkour-web-app-dto';
import { JongereProfiel } from '../../profiel/model/profiel';

export type TeamlidProfiel =
  | TeamlidProfielDto
  | {
      readonly viewType: 'TEAMLID';
      readonly status: 'UNSUPPORTED';
      readonly id: ProfielId;
      readonly voornaam?: string;
      readonly naam?: string;
      readonly afwezigheid: AfwezigheidDto;
    };

export type TeamlidUpdate = TeamlidUpdateDto;

export const isProfessioneel = (profiel: TeamlidProfiel | JongereProfiel): boolean => {
  return profiel.status === 'ACTIEF' && profiel.profielType === 'PROFESSIONEEL';
};
