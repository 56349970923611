import { UserDto } from 'parkour-web-app-dto';
import { User } from './user';
import { AnomymousUser } from './anomymous-user';
import { AangemeldZonderProfielUser } from './aangemeld-zonder-profiel-user';
import { AangemeldeUser } from './aangemelde-user';

export function userFromDto(dto: UserDto): User {
  switch (dto.role) {
    case 'ANONYMOUS':
      return new AnomymousUser();
    case 'PERSOON':
      return AangemeldZonderProfielUser.fromAangemeldZonderProfielUserDto(dto);
    case 'AANGEMELD':
      return AangemeldeUser.fromAangemeldeUserDto(dto);
  }
}
