import { Component, ViewChild } from '@angular/core';
import { map, Observable, of, ReplaySubject, Subject, switchMap } from 'rxjs';
import {
  ParkourPageGradientComponent,
  ParkourSegmentComponent,
  TParkourSegmentItems,
} from '@parkour/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ArtikelWithBookmark, WatwatService } from '../../../shared/services/watwat.service';
import { Result } from '../../../utils';
import { BewaardeArtikelsComponent } from '../../component/bewaarde-artikels/bewaarde-artikels.component';
import { BewaardeNotitiesComponent } from '../../component/bewaarde-notities/bewaarde-notities.component';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { BewaardeHulplijnenComponent } from '../../component/bewaarde-hulplijnen/bewaarde-hulplijnen.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ArtikelId, BookmarkDto, BookmarkId, BookmarksDto } from 'parkour-web-app-dto';
import { BookmarkService } from 'src/app/shared/services/bookmark.service';
import { ParkourPaginatedPage } from '../../../shared/parkour-paginated-page';
import { AsyncPipe } from '@angular/common';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import {
  OrganisationWithBookmark,
  OrganisationWithBookmarkQueryResult,
} from '../../../shared/model/artikel';

@Component({
  standalone: true,
  templateUrl: './bewaarde-items.page.html',
  imports: [
    ParkourBackButtonDirective,
    TranslateModule,
    ParkourHeaderActionComponent,
    ParkourPageGradientComponent,
    ParkourSegmentComponent,
    BewaardeArtikelsComponent,
    BewaardeHulplijnenComponent,
    BewaardeNotitiesComponent,
    PageLayoutComponent,
    AsyncPipe,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    IonButtons,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonTitle,
    ParkourRefreshDirective,
  ],
})
export class BewaardeItemsPage extends ParkourPaginatedPage<ArtikelWithBookmark> {
  @ViewChild('bewaardeNotitiesComponent') bewaardeNotitiesComponent!: BewaardeNotitiesComponent;
  readonly bewaardeOrganisations$: Subject<Result<OrganisationWithBookmarkQueryResult>> =
    new ReplaySubject(1);

  readonly organisationBookmarks$: Observable<OrganisationWithBookmark[]> =
    this.watwatService.getBookmarksWithOrganisation();
  activeSegmentItem = 'artikels';
  segmentItems: TParkourSegmentItems = [];
  protected readonly String = String;

  constructor(
    private readonly watwatService: WatwatService,
    private readonly bookmarkService: BookmarkService,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  override ionViewWillEnter(): void {
    super.ionViewWillEnter();
    this.bewaardeNotitiesComponent.initializeData();
    this.translate
      .get([
        'profiel.bewaarde-items-page.tabs.articles',
        'profiel.bewaarde-items-page.tabs.helplines',
        'profiel.bewaarde-items-page.tabs.notes',
      ])
      .subscribe((translations) => {
        this.segmentItems = [
          {
            label: translations['profiel.bewaarde-items-page.tabs.articles'],
            value: 'artikels',
          },
          {
            label: translations['profiel.bewaarde-items-page.tabs.helplines'],
            value: 'hulplijnen',
          },
          {
            label: translations['profiel.bewaarde-items-page.tabs.notes'],
            value: 'notities',
          },
        ];
      });
  }

  override retrievePage = (pageNumber: number) => {
    const pageSize = 20;
    return this.bookmarkService.getBookmarks('ARTIKEL', undefined, pageSize, pageNumber).pipe(
      switchMap((bookmarks: BookmarksDto) => {
        const bookmarkItemIds = bookmarks.map((bookmark: BookmarkDto) => bookmark.itemId);

        if (bookmarkItemIds.length === 0) {
          return of({
            content: [],
            last: true,
          });
        }

        return this.watwatService
          .getArtikels({
            artikelIds: bookmarkItemIds,
            amount: pageSize,
          })
          .pipe(
            map((result) => ({
              content: result.results,
              last: result.results.length < pageSize,
            })),
          );
      }),
    );
  };

  onArtikelBookmarkClicked(event: {
    hasBookmarked: boolean;
    bookmarkId: BookmarkId | undefined;
    artikelId: ArtikelId;
  }) {
    if (!event.hasBookmarked && event.bookmarkId) {
      this.bookmarkService.deleteBookmark(event.bookmarkId).subscribe(() => {
        this.removeElementsFromCollection((element) => element.id === event.artikelId);
      });
    }
  }
  segmentChanged(event: Event): void {
    this.activeSegmentItem = (event as CustomEvent).detail.value;
  }
}
