<ion-header class="w-full transform-none">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'start.profiel-create-contact-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <!-- TODO: replace image with the chosen user image -->
    <!-- TODO: UX Copy profiel aan JSON toevoegen -->
    <parkour-page-image
      classes="absolute top-0 left-0 z-0"
      src="/assets/onboarding/background/background_onboarding_blur.jpg"
    />

    <div class="relative z-10 py-8 container container-space">
      <parkour-info-card class="block container-1-col">
        <p>Bij lange inactiviteit sturen we je een melding voor het stopzetten van je profiel.</p>
      </parkour-info-card>

      <form (ngSubmit)="onSubmit()" class="mt-8 container-1-col">
        <div class="p-4 rounded bg-n-200">
          <h2 class="typo-h3">Contactgegevens</h2>

          <p class="typo-body text-n-600 mt-1">Enkel voor jou zichtbaar</p>

          <parkour-profile-details-contact-edit
            [contactFormGroup]="contactFormGroup"
            class="block mt-4"
          />
        </div>

        <parkour-button class="block w-full mt-8" iconAfter="arrow-right" type="submit"
          >Volgende stap
        </parkour-button>
      </form>
    </div>
  </parkour-page-layout>
</ion-content>
