<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.instellingen.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-gradient />
    <div class="relative z-10 py-8 container container-space flex flex-col gap-y-8">
      <div class="container-1-col gap-y-8">
        <h1 class="text-center mb-4">
          {{ 'profiel.instellingen.title' | translate }}
        </h1>

        <parkour-divider></parkour-divider>

        @if (isNativeApp()) {
          <div class="flex flex-col gap-y-2 pt-8">
            <div class="relative p-4 w-full flex flex-col gap-4 bg-n-200 rounded">
              <div class="flex justify-between gap-x-4">
                <div class="flex-1">
                  <div class="mb-4 flex items-center gap-x-2">
                    <parkour-icon [name]="'people-3'" />
                    <h2 class="typo-h5">
                      {{ 'profiel.instellingen.biometrics-title' | translate }}
                    </h2>
                  </div>
                  <div class="flex flex-row justify-between">
                    <p class="text-n-1000 typo-body variant-body-fat">
                      {{ 'profiel.instellingen.biometrics-subtitle' | translate }}
                    </p>
                    <parkour-toggle
                      [(ngModel)]="this.biometricsEnabled"
                      (toggleChange)="onToggleChanged($event)"
                      [ariaLabel]="'biometrics-toggle'"
                      class="flex"
                    >
                    </parkour-toggle>
                  </div>
                </div>
              </div>
            </div>
            @if (appInfo$ | async; as appInfo) {
              <div class="p-4 w-full flex flex-col gap-4 bg-n-200 rounded">
                <div class="flex justify-between gap-x-4">
                  <div class="flex-1">
                    <div class="mb-4 flex items-center gap-x-2">
                      <parkour-icon [name]="'info-circle'" />
                      <h2 class="typo-h5">
                        {{ 'profiel.instellingen.version-title' | translate }}
                      </h2>
                    </div>
                    <div class="flex flex-col">
                      <p class="text-n-1000 typo-body variant-body-fat">
                        {{ 'profiel.instellingen.version-subtitle' | translate }}
                      </p>
                      <p>{{ appInfo.version }}</p>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          @if (environment.debugOptionsEnabled) {
            <parkour-button (clicked)="onClearBiometrics()" class="w-full" variant="tertiary">
              {{ 'Verwijder token' }}
            </parkour-button>
          }
        }
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
