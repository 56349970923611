<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <parkour-page-with-hero
        [heroImage]="teamlidContext$ | profielFotoOfJongere | async"
        [title]="'doelen.doelen-van-jongere-page.doelen-van' | translate"
      >
        @if (
          data.actieveDoelen.length > 0 ||
          data.behaaldeDoelen.length > 0 ||
          data.voorstellen.length > 0
        ) {
          <ng-container *parkourSectionContent="{ data: segmentItems$ | async }; let segmentItems">
            <div class="container-1-col">
              <parkour-segment
                (changed)="segmentChanged($event)"
                [defaultValue]="activeSegmentItem"
                [items]="segmentItems"
              />
            </div>

            <div [ngSwitch]="activeSegmentItem" class="container-1-col">
              <div *ngSwitchCase="'actieveDoelen'" class="flex flex-col gap-y-8">
                <parkour-button class="w-full" iconAfter="plus-circle" link="suggereer">
                  {{ 'doelen.doelen-van-jongere-page.suggestie' | translate }}
                </parkour-button>

                @if (data.actieveDoelen.length > 0) {
                  <ul class="space-y-6">
                    <li *ngFor="let doel of data.actieveDoelen">
                      <parkour-doel-list-item
                        [doel]="doel"
                        [showNewIndicator]="
                          (ongelezenDoelMeldingen$ | hasMeldingenForDoel: doel.id | async) ?? false
                        "
                      ></parkour-doel-list-item>
                    </li>
                  </ul>
                } @else {
                  @if (teamlidContext$ | async; as context) {
                    <p>
                      {{
                        'doelen.doelen-page.geen-doelen-teamlid'
                          | translate: { jongere: context.jongereProfiel | profielnaam }
                      }}
                    </p>
                  }
                }
              </div>

              <div *ngSwitchCase="'behaaldeDoelen'" class="flex flex-col gap-y-8">
                <parkour-button class="w-full" iconAfter="plus-circle" link="suggereer">
                  {{ 'doelen.doelen-van-jongere-page.suggestie' | translate }}
                </parkour-button>

                @if (data.behaaldeDoelen.length > 0) {
                  <ul class="space-y-6">
                    <li *ngFor="let doel of data.behaaldeDoelen">
                      <parkour-doel-list-item
                        [doel]="doel"
                        [showNewIndicator]="
                          (ongelezenDoelMeldingen$ | hasMeldingenForDoel: doel.id | async) ?? false
                        "
                      />
                    </li>
                  </ul>
                } @else {
                  @if (teamlidContext$ | async; as context) {
                    <p>
                      {{
                        'doelen.behaalde-doelen-van-jongere-page.geen-doelen'
                          | translate
                            : {
                                jongere: context.jongereProfiel | profielnaam,
                              }
                      }}
                    </p>
                  }
                }
              </div>
            </div>
          </ng-container>
        } @else {
          <div class="container-1-col flex flex-col gap-y-8">
            <parkour-button class="w-full" iconAfter="plus-circle" link="suggereer">
              {{ 'doelen.doelen-van-jongere-page.suggestie' | translate }}
            </parkour-button>

            <div class="relative">
              <parkour-block-placeholder
                [sources]="{
                  default: {
                    src: '/assets/doelen/placeholder/placeholder_doelen_default.svg',
                  },
                  md: {
                    src: '/assets/doelen/placeholder/placeholder_doelen_md.svg',
                  },
                }"
                alt=""
                class="block"
              />

              <div
                class="absolute bottom-0 left-0 w-full h-full pointer-events-none bg-gradient-to-t from-n-100 to-transparent"
                role="presentation"
              ></div>
            </div>
          </div>
        }
      </parkour-page-with-hero>
    </ng-container>
  </parkour-page-layout>
</ion-content>
