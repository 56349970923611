import { Component } from '@angular/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';

import { ContextDto, UitnodigingId, validatedUitnodidingId } from 'parkour-web-app-dto';
import { combineLatest, map, Observable, of } from 'rxjs';
import { ProfielService } from '../../service/profiel.service';
import { Uitnodiging } from '../../../uitnodiging/model/uitnodiging';
import { UitnodigingenService } from '../../../uitnodiging/service/uitnodinginen.service';
import { ProfielCreateService } from '../../service/profiel-create.service';
import { ContextOption } from '../../model/context-option';
import { ContextService } from '../../../shared/services/context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { meldingenForWisselTeamPage } from '../../../meldingen/config';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ParkourPageImageComponent, ParkourTagComponent } from '@parkour/ui';
import { ProfielFotoComponent } from '../../../shared/components/profiel-foto/profiel-foto.component';
import { ProfielSelectComponent } from '../../component/profiel-select/profiel-select.component';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { TeamSwitchCardContentComponent } from '../../component/team-switch-card/team-switch-card-content.component';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielFotoPipe } from '../../../shared/pipes/profiel-foto.pipe';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { UitnodigingConfirmationComponent } from '../../component/uitnodiging-confirmation/uitnodiging-confirmation.component';
import { ParkourProfielTeamSwitchComponent } from '../../component/team-switch/profiel-team-switch.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../shared/marks-meldingen-as-read';
import { MyProfiel, Profiel } from '../../model/profiel';
import AuthService from '../../../authentication/service/auth.service';
import { AangemeldeUser } from '../../../authentication/user';

export type WisselTeamPageData = {
  profiel: MyProfiel;
  currentContext: ContextDto;
  mijnProfielen: MyProfiel[];
  user: AangemeldeUser;
  contexts: ContextOption[];
  huidigeUitnodiging: Uitnodiging | undefined;
  verstuurdeUitnodigingenVoorMij: Array<Uitnodiging>;
  hangendeUitnodigingenVoorMij: Array<Uitnodiging>;
};

@Component({
  standalone: true,
  templateUrl: './wissel-team.page.html',
  imports: [
    NgIf,
    ParkourPageImageComponent,
    ProfielFotoComponent,
    ProfielSelectComponent,
    ContentUnavailableCardComponent,
    NgForOf,
    TeamSwitchCardContentComponent,
    ParkourTagComponent,
    ParkourBackButtonDirective,
    AsyncPipe,
    TranslateModule,
    ProfielFotoPipe,
    ProfielnaamPipe,
    UitnodigingConfirmationComponent,
    ParkourProfielTeamSwitchComponent,
    PageLayoutComponent,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    IonTitle,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonBackButton,
    ParkourRefreshDirective,
    PageContentDirective,
  ],
})
export class WisselTeamPage
  extends ParkourDataPage<WisselTeamPageData>
  implements MarksMeldingenAsReadOnView
{
  constructor(
    private readonly contextService: ContextService,
    private readonly profielService: ProfielService,
    private readonly uitnodigingenService: UitnodigingenService,
    private readonly profielCreateService: ProfielCreateService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  override retrieveMainData(): Observable<WisselTeamPageData> {
    const uitnodigingId = this.activatedRoute.snapshot.queryParamMap.get('uitnodigingId');

    if (uitnodigingId) {
      this.uitnodigingenService.saveMyCurrentUitnodigingId(validatedUitnodidingId(uitnodigingId));
    }

    return combineLatest({
      profiel: this.profielService.getCurrentUserProfiel$(),
      currentContext: this.contextService.context$,
      mijnProfielen: this.profielService.getMyProfielen(),
      user: this.authService.getAangemeldeUser$(),
      contexts: this.profielService.getMijnSortedContexten(),
      huidigeUitnodiging: this.uitnodigingenService.getMijnAangekomenUitnodiging(),
      verstuurdeUitnodigingenVoorMij: this.uitnodigingenService
        .getUitnodigingenVoorMij()
        .pipe(
          map((uitnodigingen) =>
            uitnodigingen.filter((uitnodiging) => uitnodiging.status === 'VERSTUURD'),
          ),
        ),
      hangendeUitnodigingenVoorMij: this.uitnodigingenService
        .getUitnodigingenVoorMij()
        .pipe(
          map((uitnodigingen) =>
            uitnodigingen.filter((uitnodiging) => uitnodiging.status === 'AANVAARD'),
          ),
        ),
    });
  }

  getMeldingenToMarkAsRead() {
    return of(new MeldingenToMarkAsRead(meldingenForWisselTeamPage.specificMeldingen));
  }

  onJaClick(uitnodigingId: UitnodigingId) {
    this.uitnodigingenService.accepteerUitnodiging(uitnodigingId).subscribe(() => {
      this.refreshMainData();
    });
  }

  verwijderUitnodinging(uitnodigingId: UitnodigingId) {
    this.uitnodigingenService
      .weigerUitnodiging(uitnodigingId)
      .subscribe(() => this.refreshMainData());
  }

  onProfielSelect(profiel: Profiel) {
    this.authService.switchProfiel(profiel.id, '/app/me/profiel/wissel-team').subscribe();
  }

  onCreatePersoonlijkProfiel() {
    this.profielCreateService.startCreatingPersoonlijkProfiel();
  }

  createProfessioneelProfiel() {
    this.profielCreateService.startCreatingProfessioneelProfiel();
  }

  contextChanged(context: ContextOption, profiel: Profiel) {
    if (context.geblokkeerd) {
      this.router.navigate(['..', 'team', 'geblokkeerd', context.contextId], {
        relativeTo: this.activatedRoute,
      });
    } else {
      const url =
        context.contextId === profiel.id && !profiel.isJongere ? ['profiel'] : ['profiel', 'team'];
      this.contextService.switchContext(context.contextId, url);
    }
  }
}
