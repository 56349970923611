import { Component, Input } from '@angular/core';
import {
  GebeurtenisGedeeldMelding,
  JeugdhulpHistoriekGedeeldMelding,
  NieuwDrieKolommenDocumentMelding,
  NieuwEyouthGebeurtenisMelding,
  VerhaalItem,
} from 'parkour-web-app-dto';
import { NgClass } from '@angular/common';
import { ParkourGebeurtenisListItemCardComponent } from '../parkour-gebeurtenis-list-item-card/parkour-gebeurtenis-list-item-card.component';
import { JeugdhulpHistoriekListItemCardComponent } from '../jeugdhulp-historiek-list-item-card/jeugdhulp-historiek-list-item-card.component';
import { DrieKolommenGebeurtenisListItemCardComponent } from '../drie-kolommen-gebeurtenis-list-item-card/drie-kolommen-gebeurtenis-list-item-card.component';

@Component({
  standalone: true,
  selector: 'parkour-gebeurtenis-list-item',
  templateUrl: './gebeurtenis-list-item.component.html',
  imports: [
    NgClass,
    ParkourGebeurtenisListItemCardComponent,
    JeugdhulpHistoriekListItemCardComponent,
    DrieKolommenGebeurtenisListItemCardComponent,
  ],
})
export class GebeurtenisListItemComponent {
  @Input({ required: true }) verhaalItem!: VerhaalItem;

  @Input() set ongelezenGebeurtenisMeldingen(
    ongelezenMeldingen: Array<
      | NieuwDrieKolommenDocumentMelding
      | NieuwEyouthGebeurtenisMelding
      | GebeurtenisGedeeldMelding
      | JeugdhulpHistoriekGedeeldMelding
    >,
  ) {
    this.ongelezenDrieKolommenMeldingen = ongelezenMeldingen.filter(
      (melding): melding is NieuwDrieKolommenDocumentMelding =>
        melding.type === 'NIEUW_DRIE_KOLOMMEN_DOCUMENT',
    );

    this.ongelezenParkourGebeurtenisMeldingen = ongelezenMeldingen.filter(
      (melding): melding is GebeurtenisGedeeldMelding => melding.type === 'GEBEURTENIS_GEDEELD',
    );

    this.ongelezenJeugdhulpHistoriekMeldingen = ongelezenMeldingen.filter(
      (melding): melding is JeugdhulpHistoriekGedeeldMelding | NieuwEyouthGebeurtenisMelding =>
        melding.type === 'JEUGDHULPHISTORIEK_GEDEELD' ||
        melding.type === 'NIEUWE_EYOUTH_GEBEURTENIS',
    );
  }

  @Input() isLastItem?: boolean = false;

  protected ongelezenDrieKolommenMeldingen: Array<NieuwDrieKolommenDocumentMelding> = [];
  protected ongelezenParkourGebeurtenisMeldingen: Array<GebeurtenisGedeeldMelding> = [];
  protected ongelezenJeugdhulpHistoriekMeldingen: Array<
    JeugdhulpHistoriekGedeeldMelding | NieuwEyouthGebeurtenisMelding
  > = [];
}
