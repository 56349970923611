<form (ngSubmit)="onSubmit()" [formGroup]="doelForm" [id]="formId" class="flex flex-col gap-y-8">
  <parkour-doel-edit-data-section
    [doelFormGroup]="doelForm.controls.doelDataSection"
    [initialState]="initialState"
  />

  <div class="flex flex-col gap-y-4 container-1-col">
    <h2 class="typo-h3">{{ 'doelen.doel-toevoegen-page.acties.title' | translate }}</h2>

    <parkour-acties-select-section [actieSectionGroup]="doelForm.controls.actieSection" />
  </div>

  <div class="flex flex-col gap-y-6 container-1-col">
    <div class="space-y-2">
      <h2 class="typo-h3">{{ 'doelen.doel-toevoegen-page.delen.title' | translate }}</h2>

      <p>{{ 'doelen.doel-toevoegen-page.delen.description' | translate }}</p>
    </div>

    <parkour-delen-section
      [delenFormGroup]="doelForm.controls.gedeeldMetSection"
      [teamleden]="teamleden"
    />
  </div>
</form>
