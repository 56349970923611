<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="berichtenHero"
      [title]="'berichten.gesprekken-overview-page.title' | translate"
    >
      <ng-container *ngIf="profiel$ | async as profiel">
        <div class="space-y-8 container-1-col">
          <p class="text-center">
            @if (profiel.profielType !== 'PROFESSIONEEL') {
              {{ 'berichten.gesprekken-overview-page.niet-in-team' | translate }}
            } @else {
              {{ 'berichten.gesprekken-overview-page.niet-in-team-professioneel' | translate }}
            }
          </p>

          <parkour-block-placeholder
            [sources]="{
              default: {
                src: '/assets/berichten/placeholder/placeholder_berichten_default.svg',
              },
              md: {
                src: '/assets/berichten/placeholder/placeholder_berichten_md.svg',
              },
            }"
            alt=""
            class="block"
          />
        </div>
      </ng-container>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
