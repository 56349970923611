import { Component } from '@angular/core';
import {
  ParkourActionComponent,
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourIconComponent,
  ParkourNewIndicatorComponent,
  ParkourPageComponent,
  ParkourPageGradientComponent,
  ParkourToastService,
  ParkourToggleComponent,
} from '@parkour/ui';
import { AsyncPipe, DatePipe } from '@angular/common';
import { finalize, from, map, switchMap } from 'rxjs';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { FormsModule } from '@angular/forms';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourPage } from '../../../shared/parkour-page';
import { isNativeApp } from '../../../utils';
import { App } from '@capacitor/app';
import { BiometricsService } from '../../../authentication/service/biometrics.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'parkour-instellingen',
  standalone: true,
  imports: [
    AsyncPipe,
    ParkourButtonComponent,
    ParkourToggleComponent,
    FormsModule,
    IonBackButton,
    IonButtons,
    IonHeader,
    IonTitle,
    IonToolbar,
    ParkourBackButtonDirective,
    ParkourHeaderActionComponent,
    TranslateModule,
    IonRefresher,
    IonRefresherContent,
    PageLayoutComponent,
    IonContent,
    ParkourRefreshDirective,
    ParkourPageComponent,
    ParkourDividerComponent,
    ParkourPageGradientComponent,
    DatePipe,
    ParkourActionComponent,
    ParkourNewIndicatorComponent,
    ParkourIconComponent,
  ],
  templateUrl: './instellingen.page.html',
})
export class InstellingenPage extends ParkourPage implements ViewWillEnter {
  biometricsEnabled: boolean = false;
  appInfo$ = from(App.getInfo());

  constructor(
    private readonly biometricsService: BiometricsService,
    private readonly toastService: ParkourToastService,
  ) {
    super();
  }

  override ionViewWillEnter() {
    super.ionViewWillEnter();
    this.refreshBiometricsEnabled();
  }

  enableBiometrics() {
    this.biometricsService
      .enableBiometrics()
      .pipe(
        switchMap(() =>
          this.translateService.get([
            'biometrics.configure-biometrics.success-title',
            'biometrics.configure-biometrics.success-description',
          ]),
        ),
        switchMap((translations) =>
          this.toastService.showToast({
            header: translations['biometrics.configure-biometrics.success-title'],
            content: translations['biometrics.configure-biometrics.success-description'],
            error: false,
          }),
        ),
        finalize(() => this.refreshBiometricsEnabled()),
      )
      .subscribe();
  }

  disableBiometrics() {
    this.biometricsService
      .disableBiometrics()
      .pipe(map(() => this.refreshBiometricsEnabled()))
      .subscribe();
  }

  refreshBiometricsEnabled() {
    this.biometricsService
      .getStatus()
      .subscribe((status) => (this.biometricsEnabled = status === 'INGESTELD'));
  }

  async onToggleChanged(enabled: boolean) {
    if (enabled) {
      this.enableBiometrics();
    } else {
      this.disableBiometrics();
    }
  }

  protected readonly isNativeApp = isNativeApp;

  onClearBiometrics() {
    this.biometricsService
      .clearBiometrics()
      .pipe(finalize(() => this.refreshBiometricsEnabled()))
      .subscribe(() => this.toastService.showToast({ header: 'Biometrics cleared' }));
  }

  protected readonly environment = environment;
}
