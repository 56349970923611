import { inject } from '@angular/core';
import { filter, map, of, take, timeout } from 'rxjs';
import { UserService } from '../../../user/service/user.service';
import { UserWithProfiel } from '../../../user/model/user';
import { ActivatedRouteSnapshot, CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { AnomymousUser } from '../../../user/model/anomymous-user';
import { AangemeldZonderProfielUser } from '../../../user/model/aangemeld-zonder-profiel-user';
import { ContextService } from '../../../shared/services/context.service';
import { mapLoginError } from '../../../shared/login-error-mappings';
import { ParkourToastService } from '@parkour/ui';
import AuthService from '../../../authentication/service/auth.service';

export const waitUntilCorrectContextLoaded: CanMatchFn = (_: Route, segments: UrlSegment[]) => {
  const contextService: ContextService = inject(ContextService);
  const contextId = segments[1].path;

  if (!contextService.isContextId(contextId)) {
    return of(false);
  }

  return contextService.context$.pipe(
    map((context) => {
      return context.contextId === contextId;
    }),
    filter((isContextCorrect) => isContextCorrect),
    timeout({ each: 10000, with: () => of(false) }), // After 10 seconds, assume the context won't  be correct
    take(1),
  );
};

export const addContextToUrlRedirect = () => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);

  return userService.getCurrentUser$().pipe(
    map((user) => {
      if (user instanceof UserWithProfiel) {
        return router.createUrlTree(['app', user.profiel.id, 'home']);
      } else {
        return router.createUrlTree(['app', 'anoniem', 'home']);
      }
    }),
  );
};

export const preventAnonymousIfLoggedIn = (route: ActivatedRouteSnapshot) => {
  const userService: UserService = inject(UserService);
  const router: Router = inject(Router);

  return userService.getCurrentUser$().pipe(
    map((user) => {
      if (route.paramMap.get('contextId') === 'anoniem' && user instanceof UserWithProfiel) {
        return router.createUrlTree(['app', user.profiel.id, 'home']);
      }

      return true;
    }),
  );
};

export const showLoginErrorsGuards = (route: ActivatedRouteSnapshot) => {
  const toastService: ParkourToastService = inject(ParkourToastService);
  const router: Router = inject(Router);
  const error = route.queryParamMap.get('error');
  const errorConfig = mapLoginError(error);

  toastService.showToast({
    header: errorConfig.titel,
    content: errorConfig.description,
    error: true,
  });

  return router.createUrlTree(['/']);
};

export const replaceMeWithContextIdOrLogin = (route: ActivatedRouteSnapshot) => {
  const userService: UserService = inject(UserService);
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  return userService.getCurrentUser$().pipe(
    map((user) => {
      if (user instanceof AnomymousUser) {
        router.navigate(['/'], {
          info: {
            overridePopups: true,
          },
        });
        authService.login({ redirectUrl: route.url.join('/') }).subscribe(); //first navigate to login page
        return false;
      } else if (user instanceof AangemeldZonderProfielUser) {
        // Wacht tot user profiel gekozen heeft.
        return true;
      }

      let contextId = 'anoniem';
      if (user instanceof UserWithProfiel) {
        contextId = user.profiel.id;
      }

      const segments = route.url.map((segment) => segment.path);

      return router.createUrlTree(['app', contextId, ...segments], {
        queryParams: route.queryParams,
      });
    }),
  );
};
