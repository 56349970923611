import { Injectable } from '@angular/core';
import { Avatar, ProfielNaamType, ProfielType } from 'parkour-web-app-dto';
import { ProfielService } from './profiel.service';
import { Observable, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../user/service/user.service';
import { User, UserWithProfiel } from '../../user/model/user';

export type NaamData = {
  roepnaam: string | undefined;
  profielnaamType: ProfielNaamType;
};

export type ProfielFotoData = {
  avatar: Avatar;
  profielFoto: string | undefined;
  profielFotoFile: File | undefined;
};

export type OverJezelfData = {
  bio: string | undefined;
  links: string[];
  adres: string | undefined;
};

export type ContactData = {
  email: string | undefined;
  telefoonnummer: string | undefined;
};

@Injectable({
  providedIn: 'root',
})
export class ProfielCreateService {
  profielData: {
    naamData?: NaamData;
    profielFotoData?: ProfielFotoData;
    overJezelfData?: OverJezelfData;
    contactData?: ContactData;
  } = {};

  voorwaardenChecked = false;
  profielType: ProfielType = 'PERSOONLIJK';

  private _redirectUrl?: string;

  constructor(
    private readonly profielService: ProfielService,
    private readonly router: Router,
    private readonly userService: UserService,
  ) {}

  resetProfielData() {
    this.profielData = {};
    this.voorwaardenChecked = false;
    this.profielType = 'PERSOONLIJK';
  }

  startCreatingProfiel() {
    this.router.navigate(['/app', 'start', 'profiel', 'nieuw']);
  }

  startCreatingProfessioneelProfiel() {
    this.profielType = 'PROFESSIONEEL';

    this.router.navigate(['/app', 'start', 'profiel', 'nieuw', 'voorwaarden']);
  }

  startCreatingPersoonlijkProfiel() {
    this.profielType = 'PERSOONLIJK';

    this.router.navigate(['/app', 'start', 'profiel', 'nieuw', 'voorwaarden']);
  }

  isProfessioneel(): boolean {
    return this.profielType === 'PROFESSIONEEL';
  }

  setProfielFotoData(profielFotoData: ProfielFotoData) {
    this.profielData.profielFotoData = profielFotoData;
  }

  setNaamData(naamData: NaamData) {
    this.profielData.naamData = naamData;
  }

  setOverJezelfData(overJezelfData: OverJezelfData) {
    this.profielData.overJezelfData = overJezelfData;
  }

  setContactData(contactData: ContactData) {
    this.profielData.contactData = contactData;
  }

  setVoorwaardenChecked(checked: boolean) {
    this.voorwaardenChecked = checked;
  }

  createMyProfiel(): Observable<void> {
    if (!this.voorwaardenChecked) {
      throw new Error('Voorwaarden not checked');
    }

    const profielFotoData = this.profielData.profielFotoData;
    const contactData = this.profielData.contactData;
    const overJezelfData = this.profielData.overJezelfData;
    const naamData = this.profielData.naamData;

    if (!contactData || !overJezelfData || !naamData || !profielFotoData) {
      throw new Error('Profile data not complete');
    }

    return this.createProfielWithoutProfielFoto(
      naamData,
      overJezelfData,
      contactData,
      profielFotoData,
    ).pipe(
      switchMap(() => {
        if (profielFotoData.profielFotoFile) {
          return this.profielService.uploadMyProfielFoto(profielFotoData.profielFotoFile);
        } else {
          return of(undefined);
        }
      }),
    );
  }

  private createProfielWithoutProfielFoto(
    naamData: NaamData,
    overJezelfData: OverJezelfData,
    contactData: ContactData,
    profielFotoData: ProfielFotoData,
  ) {
    return this.profielService
      .createMyProfiel({
        profielType: this.profielType,
        ...naamData,
        ...overJezelfData,
        ...contactData,
        avatar: profielFotoData.avatar,
      })
      .pipe(
        tap(() => this.userService.refeshUser()),
        switchMap(() =>
          this.userService.waitUntilUserMeetsRequirements(
            (user: User) => user instanceof UserWithProfiel,
          ),
        ),
      );
  }
}
