<ion-header class="w-full transform-none"></ion-header>

<ion-content [scrollY]="false">
  <parkour-page-layout>
    <ng-container *ngIf="user$ | async as user">
      <div class="flex flex-col pt-8 pb-12 h-[100%] relative">
        <div class="z-10 mb-4 container container-space">
          <div class="container-1-col">
            <h1 class="text-center">
              @switch (user.profiel.profielType) {
                @case ('PERSOONLIJK') {
                  Profiel aangemaakt!
                }
                @case ('PROFESSIONEEL') {
                  Professioneel profiel
                  <parkour-icon name="badge-on" />
                  aangemaakt!
                }
              }
            </h1>
            <!-- TODO: UX Copy profiel aan JSON toevoegen -->

            <parkour-divider class="inline-block w-full mt-4" />
          </div>
        </div>

        <div class="relative z-0 flex flex-col justify-center flex-1 container">
          <div class="relative w-full aspect-square container-1-col">
            <parkour-image
              [rounded]="true"
              [src]="user.profiel | profielFoto: 'full' | async"
              alt=""
              class="absolute z-0 blur-[29px] w-[110%] translate-x-[-5%]"
              fit="cover"
              position="center"
            />

            <parkour-image
              [rounded]="true"
              [src]="user.profiel | profielFoto: 'full' | async"
              alt=""
              class="relative z-10 block w-[110%] translate-x-[-5%]"
              fit="cover"
              position="center"
            />
          </div>
        </div>

        <div
          class="absolute bottom-0 left-0 w-full h-[50%] pointer-events-none bg-gradient-to-b from-transparent to-n-100 to-80%"
          role="presentation"
        ></div>

        <div class="sticky z-10 container container-space bottom-12">
          <div class="container-1-col">
            <p class="text-center typo-h2">
              Blij dat je erbij bent {{ user.profiel | profielnaam }} !
            </p>

            <parkour-button (clicked)="redirectToUrl()" class="block w-full mt-6">
              @if (hasUitnodiging) {
                Bekijk je uitnodigingen
              } @else {
                Ontdek PARKOUR
              }
            </parkour-button>
          </div>
        </div>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
