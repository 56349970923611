import { Component, ViewChild } from '@angular/core';
import { DoelenService } from '../../../doel-shared/service/doelen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import { validateDoelId } from '../../../utils';
import { DoelUpsert } from '../../../doel-shared/model/doel-upsert';
import { Doel } from '../../../doel-shared/model/doel';
import { TeamService } from '../../../../team/service/team.service';
import { DoelEditFormComponent } from '../../../doel-shared/component/doel-edit-form/doel-edit-form.component';
import { DirtyFormCheck } from '../../../../shared/components/dirty-form-guard';
import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import { combineResults, Result } from '../../../../utils';
import { AppTitlePrefix } from '../../../../app-title.prefix';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import { ParkourHeaderActionComponent } from '../../../../shared/components/parkour-header-action/parkour-header-action.component';
import {
  ParkourButtonComponent,
  ParkourPageGradientComponent,
  ParkourPageImageComponent,
  ParkourPopupComponent,
} from '@parkour/ui';
import { AsyncPipe } from '@angular/common';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { themaMapping } from '../../../../home/model/thema.const';

@Component({
  standalone: true,
  templateUrl: './doelen-edit.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourHeaderActionComponent,
    ParkourPageImageComponent,
    DoelEditFormComponent,
    ParkourButtonComponent,
    AsyncPipe,
    ParkourPopupComponent,
    TranslateModule,
    PageLayoutComponent,
    PageContentDirective,
    IonBackButton,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonTitle,
    IonContent,
    ParkourPageGradientComponent,
  ],
})
export class DoelenEditPage implements ViewWillEnter, DirtyFormCheck {
  id: string | null = null;
  data$: Observable<
    Result<{
      doel: Doel;
      teamleden: TeamlidProfiel[];
    }>
  > = new Observable();
  busy = false;

  @ViewChild('doelForm') doelForm?: DoelEditFormComponent;

  isVerwijderDoelPopupOpen = false;
  protected readonly themaMapping = themaMapping;

  constructor(
    private doelenService: DoelenService,
    private teamService: TeamService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly appTitlePrefix: AppTitlePrefix,
    private readonly translate: TranslatePipe,
  ) {}

  async ionViewWillEnter(): Promise<void> {
    this.id = this.route.snapshot.paramMap.get('id');

    this.data$ = combineLatest({
      doel: this.doelenService.getDoel(validateDoelId(this.id)),
      teamleden: this.teamService.getMyTeamleden(),
    }).pipe(
      map((data) => combineResults(data)),
      shareReplay(1),
    );

    this.data$.subscribe((data) => {
      const categoryName = this.translate.transform('doelen.doel-edit-page.title');

      if (data.success) {
        this.appTitlePrefix.setTitleWithCategory(data.value.doel.naam, categoryName);
      } else {
        this.appTitlePrefix.setTitleWithPrefix(categoryName);
      }
    });
  }

  onSubmit(doelData: DoelUpsert) {
    this.doelForm?.doelForm.markAllAsTouched();

    if (this.doelForm?.doelForm.valid) {
      this.doelenService.updateDoel(validateDoelId(this.id), { ...doelData }).subscribe({
        complete: () => {
          this.busy = false;
          this.doelForm?.doelForm.markAsPristine();
          this.router.navigate(['..'], { relativeTo: this.route });
        },
        error: (e) => {
          this.busy = false;
          throw e;
        },
      });
    }
  }

  isFormDirty() {
    return this.doelForm?.doelForm.dirty ?? false;
  }

  goBack() {
    // TODO: Check for better solution
    setTimeout(() => {
      this.router.navigate(['..'], { relativeTo: this.route });
    }, 300);
  }

  verwijderDoel() {
    this.isVerwijderDoelPopupOpen = false;

    this.doelenService.deleteDoel(validateDoelId(this.id)).subscribe({
      complete: () => {
        this.busy = false;
        this.doelForm?.doelForm.markAsPristine();
        this.router.navigate(['../../'], { relativeTo: this.route });
      },
      error: (e) => {
        this.busy = false;
        throw e;
      },
    });
  }
}
