import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { BerichtenService } from '../../service/berichten.service';
import { BerichtId, DoelId, GesprekId, ProbleemReden } from 'parkour-web-app-dto';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UserService } from '../../../user/service/user.service';
import { User, UserWithProfiel } from '../../../user/model/user';
import { ProbleemService } from '../../../profiel/service/probleem.service';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourFormFieldComponent,
  ParkourInfoCardComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPageGradientComponent,
  ParkourSelectComponent,
  ParkourTextareaComponent,
  ParkourToastService,
} from '@parkour/ui';
import { Observable, take } from 'rxjs';
import { Bericht, Gesprek } from '../../model/gesprek';
import { CreateBerichtProbleem } from '../../model/berichtProbleem';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { AsyncPipe } from '@angular/common';
import { IsSupportedBerichtPipe } from '../../pipes/is-supported-bericht.pipe';
import { BerichtTextWrapperComponent } from '../../component/bericht-text-wrapper/bericht-text-wrapper.component';
import { BerichtTextContentComponent } from '../../component/bericht-text-content/bericht-text-content.component';
import { BerichtAttachmentContentComponent } from '../../component/bericht-attachment-content/bericht-attachment-content.component';
import { BerichtSpeechWrapperComponent } from '../../component/bericht-speech-wrapper/bericht-speech-wrapper.component';
import { BerichtSpeechContentComponent } from '../../component/bericht-speech-content/bericht-speech-content.component';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { OpenGesprek } from '../../model/OpenGesprek';

@Component({
  templateUrl: './bericht-probleem-add.page.html',
  standalone: true,
  imports: [
    TranslateModule,
    PageLayoutComponent,
    ParkourPageGradientComponent,
    ParkourDividerComponent,
    AsyncPipe,
    IsSupportedBerichtPipe,
    BerichtTextWrapperComponent,
    BerichtTextContentComponent,
    BerichtAttachmentContentComponent,
    BerichtSpeechWrapperComponent,
    BerichtSpeechContentComponent,
    ParkourLoadingSpinnerComponent,
    ReactiveFormsModule,
    ParkourFormFieldComponent,
    ParkourSelectComponent,
    ParkourTextareaComponent,
    ParkourInfoCardComponent,
    ParkourButtonComponent,
    ParkourBackButtonDirective,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
})
export class BerichtProbleemAddPage implements ViewWillEnter, OnInit {
  user$ = this.userService.getCurrentUser$();
  bericht$: Observable<Bericht> = new Observable<Bericht>();
  gesprek$: Observable<Gesprek | OpenGesprek> = new Observable<Gesprek | OpenGesprek>();
  berichtId!: BerichtId;
  gesprekId!: GesprekId;
  doelId!: DoelId;

  berichtProbleemForm!: FormGroup<{
    type: FormControl<string>;
    beschrijving: FormControl<string>;
  }>;
  typeOptions = [
    {
      value: 'FRAUDE_BEDROG',
      label: 'Fraude of bedrog',
    },
    {
      value: 'HAATSPRAAK',
      label: 'Haatspraak',
    },
    {
      value: 'ONGEPAST',
      label: 'Ongepaste dingen',
    },
    {
      value: 'PESTEN',
      label: 'Pesten',
    },
    {
      value: 'ZELFDODING',
      label: 'Zelfdoding of zelfbeschadiging',
    },
    {
      value: 'ANDERE',
      label: 'Iets anders',
    },
  ];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly berichtenService: BerichtenService,
    private readonly userService: UserService,
    private readonly probleemService: ProbleemService,
    private readonly toastService: ParkourToastService,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    this.berichtProbleemForm = this.formBuilder.group({
      type: this.formBuilder.nonNullable.control('FRAUDE_BEDROG', Validators.required),
      beschrijving: this.formBuilder.nonNullable.control('', Validators.required),
    });
  }

  ionViewWillEnter(): void {
    this.gesprekId = this.route.snapshot.queryParamMap.get('gesprekId') as GesprekId;
    this.berichtId = this.route.snapshot.queryParamMap.get('berichtId') as BerichtId;
    this.doelId = this.route.snapshot.queryParamMap.get('doelId') as DoelId;

    this.bericht$ = this.berichtenService.getBericht(this.gesprekId, this.berichtId);
    this.gesprek$ = this.doelId
      ? this.berichtenService.getGesprekOfDoel(this.doelId)
      : this.berichtenService.getGesprek(this.gesprekId);
  }

  isFormDirty() {
    return this.berichtProbleemForm?.dirty || false;
  }

  hasProfiel(user: User): user is UserWithProfiel {
    return user instanceof UserWithProfiel;
  }

  submitForm() {
    this.user$.pipe(take(1)).subscribe((user) => {
      const formValues = this.berichtProbleemForm.getRawValue();

      this.berichtProbleemForm.markAllAsTouched();

      if (this.berichtProbleemForm.valid && this.hasProfiel(user)) {
        const berichtProbleem: CreateBerichtProbleem = {
          type: 'BERICHT',
          beschrijving: formValues.beschrijving,
          reden: formValues.type as ProbleemReden,
          berichtId: this.berichtId,
          gesprekId: this.gesprekId,
          rapporteerder: {
            type: 'AANGEMELD',
            profielId: user.profiel.id,
          },
        };

        this.probleemService.addProbleem(berichtProbleem).subscribe({
          complete: () => {
            this.berichtProbleemForm?.markAsPristine();
            this.berichtProbleemForm?.reset();
            this.toastService.showToast({ header: 'Het bericht is gerapporteerd.' });
            this.router.navigate(['..'], {
              relativeTo: this.route,
              queryParams: this.doelId && { sheet: true },
            });
          },
          error: (e) => {
            throw e;
          },
        });
      }
    });
  }
}
