<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'doelen.doel-add-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <ng-container *ngIf="teamleden$ | async as teamleden">
        <parkour-header-action
          *ngIf="doelForm?.doelForm"
          [label]="'doelen.doel-edit-page.doel-opslaan' | translate"
          form="doel-new-form"
          icon="disk"
          testId="submit"
          type="submit"
        />
      </ng-container>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div
      *ngIf="teamleden$ | async as teamleden"
      class="relative z-10 py-8 container container-space"
    >
      <ng-container *ngIf="teamleden && initialStateLoaded">
        <div class="container-1-col">
          <parkour-doel-form
            #doelForm
            [initialState]="initialState"
            [submitDoel]="submitDoel"
            [teamleden]="teamleden"
            formId="doel-new-form"
          />
        </div>
      </ng-container>
    </div>
  </parkour-page-layout>
</ion-content>
