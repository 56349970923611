import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Jitsi } from 'capacitor-jitsi-meet';
import { isNativeApp } from '../../utils';
import { UserWithProfiel } from '../../user/model/user';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TeamlidProfiel } from '../../team/model/teamlid.model';
import { JongereProfiel } from '../../profiel/model/profiel';
import { BeeldbellenService, VideogesprekData } from '../beeldbellen.service';
import { ParkourToastService } from '@parkour/ui';
import { PusherService } from '../../bericht/service/pusher.service';
import { ParkourHeaderActionComponent } from '../../shared/components/parkour-header-action/parkour-header-action.component';
import { ProfielFotoBadgeComponent } from '../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { RolLabelPipe } from '../../shared/pipes/rol-label.pipe';
import { ProfessioneelIndicatorComponent } from '../../shared/components/professioneel-indicator/professioneel-indicator.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielnaamPipe } from '../../shared/pipes/profielnaam.pipe';
import { ParkourBackButtonDirective } from '../../shared/directives/parkour-back-button.directive';
import { IonButtons, IonHeader, IonToolbar } from '@ionic/angular/standalone';
import { LoggingService } from '../../core/logging.service';
import { ActivityDetectionService } from '../../authentication/service/activity-detection.service';

type Participant = {
  avatarURL: string;
  displayName: string;
  id: string;
  role: string;
};

interface JitsiMeetWebApi {
  dispose(): void;

  getRoomsInfo(): Promise<{
    rooms: {
      id: string;
      isMainRoom: boolean;
      jid: string;
      participants: Participant[];
    }[];
  }>;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addEventListener(type: string, f: (event: any) => void): void;
}

type JitsiConfigOverwrite = {
  subject: string;
  prejoinConfig: {
    enabled: boolean;
    hideDisplayName: boolean;
    hideExtraJoinButtons: string[];
  };
  startWithAudioMuted: boolean;
  startWithVideoMuted: boolean;
  speakerStats: {
    disabled: boolean;
    disableSearch: boolean;
  };
  hideAddRoomButton: boolean;
  breakoutRooms: {
    hideAddRoomButton: boolean;
    hideAutoAssignButton: boolean;
    hideJoinRoomButton: boolean;
  };
  lobby: {
    enableChat: boolean;
  };
  remoteVideoMenu: {
    disablePrivateChat: boolean;
  };
  disableThirdPartyRequests: boolean;
  disableChatSmileys: boolean;
  disableModeratorIndicator: boolean;
  participantsPane: {
    enabled: boolean;
    hideModeratorSettingsTab: boolean;
    hideMoreActionsButton: boolean;
    hideMuteAllButton: boolean;
  };
  disableInviteFunctions: boolean;
  disableBeforeUnloadHandler: boolean; // disables callstats.js's window.onbeforeunload parameter.
  enableDisplayNameInStats: boolean;
  enableEmailInStats: boolean;
  gatherStats: boolean;
  disableReactions: boolean;
  disableReactionsModeration: boolean;
  disablePolls: boolean;
  disableProfile: boolean;
  toolbarButtons: string[];
  mainToolbarButtons: string[];
  notifications: string[];
  securityUi: {
    hideLobbyButton: boolean;
    disableLobbyPassword: boolean;
  };
  whiteboard: {
    enabled: boolean;
  };
  etherpad_base: string | null;
  openSharedDocumentOnJoin: boolean;
  p2p: {
    enabled: boolean;
  };
};

declare let JitsiMeetExternalAPI: new (
  arg0: string,
  arg1: {
    lang: string;
    roomName: string;
    parentNode: HTMLElement;
    jwt: string;
    GENERATE_ROOMNAMES_ON_WELCOME_PAGE: boolean;
    disableInviteFunctions: boolean;
    configOverwrite: Partial<JitsiConfigOverwrite>;
  },
) => JitsiMeetWebApi | undefined;

@Component({
  standalone: true,
  templateUrl: './jitsi.component.html',
  imports: [
    ParkourHeaderActionComponent,
    ProfielFotoBadgeComponent,
    RolLabelPipe,
    ProfessioneelIndicatorComponent,
    TranslateModule,
    ProfielnaamPipe,
    ParkourBackButtonDirective,
    IonHeader,
    IonToolbar,
    IonButtons,
  ],
})
export class JitsiComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('jaasContainer') jaasContainer!: ElementRef<HTMLElement>;
  @Input({ required: true }) jwt!: string;
  @Input({ required: true }) roomId!: string;
  @Input({ required: true }) customRoomName!: string;
  @Input({ required: true }) user!: UserWithProfiel;
  @Input({ required: true }) data!: VideogesprekData;
  @Output() readyToClose = new EventEmitter<void>();

  SERVER_URL = '8x8.vc';
  private webApi?: JitsiMeetWebApi;
  private routerSubscription?: Subscription;
  private jitsiConfigOverrides: Partial<JitsiConfigOverwrite> = {
    subject: '',
    prejoinConfig: {
      enabled: false,
      hideDisplayName: true,
      hideExtraJoinButtons: [],
    },
    startWithAudioMuted: true,
    startWithVideoMuted: true,
    speakerStats: {
      disabled: true,
      disableSearch: true,
    },
    hideAddRoomButton: true,
    breakoutRooms: {
      hideAddRoomButton: true,
      hideAutoAssignButton: true,
      hideJoinRoomButton: true,
    },
    lobby: {
      enableChat: false,
    },
    remoteVideoMenu: {
      disablePrivateChat: true,
    },
    disableChatSmileys: true,
    disableModeratorIndicator: true,
    participantsPane: {
      enabled: false,
      hideModeratorSettingsTab: true,
      hideMoreActionsButton: true,
      hideMuteAllButton: true,
    },
    disableInviteFunctions: true,
    disableBeforeUnloadHandler: true, // disables callstats.js's window.onbeforeunload parameter.
    enableDisplayNameInStats: false,
    enableEmailInStats: false,
    gatherStats: false,
    disableReactions: true,
    disableReactionsModeration: true,
    disablePolls: true,
    // disableProfile: true, BREAKS JITSI CALL ON ANDROID
    securityUi: {
      hideLobbyButton: true,
      disableLobbyPassword: true,
    },
    disableThirdPartyRequests: true,
    toolbarButtons: [
      'camera',
      'closedcaptions',
      'desktop',
      'embedmeeting',
      'filmstrip',
      'fullscreen',
      'hangup',
      'highlight',
      'microphone',
      'noisesuppression',
      'select-background',
      'settings',
      'tileview',
      'toggle-camera',
      'videoquality',
    ],
    mainToolbarButtons: ['microphone', 'camera'],
    notifications: [],
    whiteboard: {
      enabled: false,
    },
    etherpad_base: null,
    openSharedDocumentOnJoin: false,
    p2p: {
      enabled: true,
    },
  };
  private pusherSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly toastService: ParkourToastService,
    private readonly beeldbellenService: BeeldbellenService,
    private readonly pusherService: PusherService,
    private readonly loggingService: LoggingService,
    private readonly activityDetectionService: ActivityDetectionService,
  ) {}

  ngOnInit(): void {
    this.pusherSubscription = this.pusherService
      .createPusherObservableForEvent('videogesprek-call-gestopt')
      .subscribe(() => {
        this.toastService.showToast({
          header: 'Het videogesprek is stopgezet',
          content:
            'Er is iets misgegaan waardoor de privacy van de call niet meer gegarandeerd kon worden. De call is stopgezet, probeer opniew.',
          error: true,
        });
      });

    this.activityDetectionService.startKeepingSessionActive();
  }

  ngAfterViewInit(): void {
    this.initializeJitsi();
  }

  ngOnDestroy(): void {
    this.pusherSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    window.removeEventListener('onConferenceTerminated', this.onJitsiClosed);
    window.removeEventListener('onConferenceLeft', this.onJitsiClosed);
    this.activityDetectionService.stopKeepingSessionActive();
  }

  async initializeJitsi() {
    this.routerSubscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.closeJitsiCall();
      }
    });
    if (isNativeApp()) {
      await this.initializeJitsiMobile();
    } else {
      await this.initializeJitsiWeb();
    }
  }

  getGespreksPartner(): TeamlidProfiel | JongereProfiel {
    return this.user.profiel.id !== this.data.teamlid.id ? this.data.teamlid : this.data.jongere;
  }

  async initializeJitsiWeb() {
    this.webApi = new JitsiMeetExternalAPI(this.SERVER_URL, {
      lang: 'nl',
      roomName: this.roomId,
      parentNode: this.jaasContainer.nativeElement,
      jwt: this.jwt,
      GENERATE_ROOMNAMES_ON_WELCOME_PAGE: true,
      disableInviteFunctions: true,
      configOverwrite: {
        ...this.jitsiConfigOverrides,
        subject: this.customRoomName,
      },
    });

    this.webApi?.addEventListener('readyToClose', () => {
      this.readyToClose.emit();
    });

    this.webApi?.addEventListener('p2pStatusChanged', async (event) => {
      if (!event.isP2p) {
        this.loggingService.log('The call is NOT using P2P.');
        const roomInfo = await this.getRoomInfo();

        if (roomInfo.participants.length > 1) {
          this.closeCallForEveryone(roomInfo.fullRoomName);
        }
      } else {
        this.loggingService.log('The call is using P2P connection.');
      }
    });
  }

  private async getRoomInfo(): Promise<{ participants: Participant[]; fullRoomName: string }> {
    const roomInfo = await this.webApi?.getRoomsInfo();

    const mainRoom = roomInfo?.rooms.find((room) => room.isMainRoom);
    const participants = mainRoom?.participants ?? [];
    return { participants: participants, fullRoomName: mainRoom?.id ?? '' };
  }

  async initializeJitsiMobile() {
    await Jitsi.joinConference({
      roomName: this.roomId,
      url: 'https://' + this.SERVER_URL,
      featureFlags: {
        'add-people.enabled': false,
        'android.screensharing.enabled': false,
        'breakout-rooms.enabled': false,
        'calendar.enabled': false,
        'car-mode.enabled': false,
        'help.enabled': false,
        'invite.enabled': false,
        'live-streaming.enabled': false,
        'prejoinpage.enabled': false,
        'raise-hand.enabled': false,
        'reactions.enabled': false,
        'recording.enabled': false,
        'security-options.enabled': false,
        'settings.enabled': false,
        'speakerstats.enabled': false,
      },

      startWithAudioMuted: true,
      startWithVideoMuted: true,
      token: this.jwt,
      configOverrides: {
        ...this.jitsiConfigOverrides,
        subject: this.customRoomName,
      },
      chatEnabled: false,
      inviteEnabled: false,
    });

    window.addEventListener('onConferenceTerminated', this.onJitsiClosed);
    window.addEventListener('onConferenceLeft', this.onJitsiClosed);
  }

  closeJitsiCall() {
    if (this.webApi) {
      this.webApi.dispose();
    } else {
      Jitsi.leaveConference();
    }

    this.readyToClose.emit();
  }

  private closeCallForEveryone(fullRoomName: string) {
    this.beeldbellenService.stopCall(this.data.videogesprekId, fullRoomName).subscribe({
      error: (e) => {
        this.loggingService.error(e);
      },
    });
  }

  onJitsiClosed = () => {
    this.readyToClose.emit();
  };
}
