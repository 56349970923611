import { Component, ElementRef, ViewChild } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { AfspraakId, AfspraakViewDetail, validateVideogesprekId } from 'parkour-web-app-dto';
import { KalenderService } from '../../service/kalender.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ParkourButtonComponent,
  ParkourIconComponent,
  ParkourPageGradientComponent,
  ParkourPopupService,
} from '@parkour/ui';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { UserWithProfiel } from '../../../user/model/user';
import { UserService } from '../../../user/service/user.service';
import { BeeldbellenService } from '../../../beeldbellen/beeldbellen.service';
import { getGespreksPartner } from '../../model/afspraak';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { ProfielFotoBadgeComponent } from '../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { ShowIfSuccesfulDirective } from '../../../shared/directives/show-if-succesful.directive';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import { getProfielnaam } from '../../../profiel/model/profiel-in-team';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import { ParkourDataPage } from '../../../shared/parkour-data-page';
import { CustomPageTitle } from '../../../shared/custom-page-title';

export type KalenderAfspraakDetailPageData = {
  afspraak: AfspraakViewDetail;
  user: UserWithProfiel;
};

@Component({
  standalone: true,
  templateUrl: './kalender-afspraak-detail.page.html',
  imports: [
    ParkourBackButtonDirective,
    TranslateModule,
    NgIf,
    ParkourHeaderActionComponent,
    ParkourPageGradientComponent,
    ProfielFotoBadgeComponent,
    ParkourIconComponent,
    ParkourButtonComponent,
    ShowIfSuccesfulDirective,
    AsyncPipe,
    PageLayoutComponent,
    PageContentDirective,
    ProfielnaamPipe,
    DatePipe,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    ParkourRefreshDirective,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonBackButton,
  ],
})
export class KalenderAfspraakDetailPage
  extends ParkourDataPage<KalenderAfspraakDetailPageData>
  implements CustomPageTitle
{
  @ViewChild('jaasContainer') jaasContainer!: ElementRef<HTMLElement>;

  protected readonly getGespreksPartner = getGespreksPartner;

  constructor(
    private readonly kalenderService: KalenderService,
    private readonly userService: UserService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly popupService: ParkourPopupService,
    private readonly beeldbellenService: BeeldbellenService,
    private readonly translate: TranslatePipe,
    private readonly router: Router,
  ) {
    super();
  }

  override retrieveMainData(): Observable<KalenderAfspraakDetailPageData> {
    const afspraakId = this.activatedRoute.snapshot.paramMap.get('afspraakId');

    if (!afspraakId) {
      throw new Error('afspraakId cannot be empty');
    }

    return combineLatest({
      afspraak: this.kalenderService.getAfspraak(afspraakId),
      user: this.userService.userWithProfiel$(),
    });
  }

  getCustomPageTitle() {
    return this.generateDefaultTitle((data) => {
      if (data.afspraak.type === 'VIDEOGESPREK') {
        return getProfielnaam(getGespreksPartner(data.user, data.afspraak));
      } else if (data.afspraak.type === 'BASIC') {
        return data.afspraak.titel;
      } else {
        return '';
      }
    }, 'profiel.kalender-afspraak-detail-page.title');
  }

  override ionViewWillEnter(): void {
    super.ionViewWillEnter();

    this.jaasContainer.nativeElement.style.display = 'hidden';
  }

  removeAfspraak(afspraakId: AfspraakId) {
    this.popupService
      .showPopup({
        title: this.translate.transform(
          'kalender.kalender-afspraak-detail-page.popup-verwijder-title',
        ),
        description: this.translate.transform(
          'kalender.kalender-afspraak-detail-page.popup-verwijder-description',
        ),
        jaText: this.translate.transform(
          'kalender.kalender-afspraak-detail-page.popup-verwijder-yes-button',
        ),
        icon: 'trash',
      })
      .then((result) => {
        if (result) {
          this.kalenderService
            .deleteAfspraak(afspraakId)
            .subscribe(() =>
              this.router.navigate(['../../../'], { relativeTo: this.activatedRoute }),
            );
        }
      });
  }

  startVideogesprek(afspraak: AfspraakViewDetail, naam: string, user: UserWithProfiel) {
    if (afspraak.type === 'BASIC') {
      return;
    }

    this.beeldbellenService
      .startVideogesprek(
        {
          jongere: afspraak.jongere,
          teamlid: afspraak.teamlid,
          videogesprekId: validateVideogesprekId(afspraak.gesprek?.id),
        },
        naam,
        user,
      )
      .subscribe();
  }

  canEditAfspraak(user: UserWithProfiel, afspraak: AfspraakViewDetail) {
    return user.profiel.isJongere && afspraak.type === 'BASIC';
  }

  canRemoveAfspraak(user: UserWithProfiel, afspraak: AfspraakViewDetail) {
    return user.isProfessioneel() && afspraak.type === 'VIDEOGESPREK';
  }
}
