import { Component, Input, OnInit } from '@angular/core';
import { ContextService } from '../../services/context.service';
import { environment } from '../../../../environments/environment';
import { Failure } from '../../../utils';
import { ParkourButtonComponent, ParkourLottiePlayerComponent } from '@parkour/ui';
import { getActiveTab, Tab } from '../../../tabs/routes';
import { ActivatedRoute } from '@angular/router';

@Component({
  standalone: true,
  selector: 'parkour-not-found',
  templateUrl: './not-found.component.html',
  imports: [ParkourLottiePlayerComponent, ParkourButtonComponent],
})
export class NotFoundComponent<T> implements OnInit {
  @Input() public failure?: Failure<T>;
  protected readonly environment = environment;
  protected activeTab: Tab | undefined = undefined;

  constructor(
    private readonly contextService: ContextService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.activeTab = getActiveTab(this.activatedRoute.snapshot);
  }

  onClickNavigateHome() {
    const activeTab = getActiveTab(this.activatedRoute.snapshot);
    const route = activeTab ? [activeTab] : [];
    this.contextService.navigateToAbsoluteUrl(route);
  }
}
