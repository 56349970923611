<form
  (ngSubmit)="onSubmit()"
  [formGroup]="deelnemersFormGroup"
  [id]="formId"
  class="flex flex-col gap-y-8"
>
  <!-- TODO: UX Copy gesprek aan JSON toevoegen -->
  <p class="typo-label">
    @switch (context.type) {
      @case ('jongere') {
        Voeg minstens twee teamleden toe
      }
      @case ('teamlid') {
        Voeg minstens één teamlid toe
      }
    }
  </p>

  <parkour-form-field-without-label
    [additionalErrors]="deelnemersFormGroup.touched ? deelnemersFormGroup.errors : null"
    [errorMessages]="{
      aantalDeelnemersDrie: 'Er moeten minstens drie teamleden zijn.',
    }"
    [required]="true"
    class="block"
    inputId="volledige-naam"
  >
    <ul class="flex flex-col gap-y-6">
      <li
        *ngFor="let checkedForm of deelnemersFormGroup.controls.teamleden.controls; let i = index"
        class="flex flex-row gap-x-3"
      >
        @if (teamleden[i].status === 'ACTIEF') {
          <parkour-teamlid-checkbox [formGroup]="checkedForm" [teamlid]="teamleden[i]" />
        }
      </li>
    </ul>
  </parkour-form-field-without-label>

  <!-- TODO: A11y, two h1 on same page -->
  @if (geblokkeerdeTeamleden.length > 0) {
    <p class="typo-label">Geblokkeerde teamleden</p>

    <ul class="flex flex-col gap-y-6">
      @for (teamlid of geblokkeerdeTeamleden; track $index) {
        <li class="flex flex-row gap-x-3">
          <parkour-teamlid-checkbox [geblokkeerd]="true" [teamlid]="teamlid" />
        </li>
      }
    </ul>
  }

  <parkour-button class="block w-full" iconAfter="paper-plane" type="submit">
    {{ 'berichten.groepsgesprek-add-page.groepsgesprek-start' | translate }}
  </parkour-button>
</form>
