<ion-header class="w-full transform-none"> </ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-image
      classes="absolute top-0 left-0 z-0"
      src="/assets/onboarding/background/background_onboarding_blur.jpg"
    />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <h1 class="pt-8 mb-4 text-center">Gebruiksvoorwaarden</h1>

        <div class="rounded overflow-hidden">
          <div class="px-4 py-6 bg-n-300">
            <article class="wysiwyg space-y-8">
              <section class="space-y-3">
                <h3 class="typo-h4">Samenvatting van de Gebruiksvoorwaarden</h3>

                <p>
                  Het agentschap Opgroeien (“we” of “wij”) vindt het belangrijk om je op heldere
                  wijze te informeren over de manier waarop je gebruik mag maken van de
                  PARKOUR-applicatie. Daarom hebben we deze samenvatting gemaakt, die je in minder
                  dan twee minuten op de hoogte brengt van de belangrijkste gebruiksvoorwaarden. Het
                  is echter belangrijk om alle gebruiksvoorwaarden te lezen, zodat je al jouw
                  rechten en verplichtingen kent. Je stemt hiermee immers in, zodat je de
                  PARKOUR-applicatie kan gebruiken.
                </p>
              </section>

              <section class="mt-8 space-y-3">
                <h3 class="typo-h4">Eerst en vooral, wat is de PARKOUR-applicatie?</h3>

                <p>
                  PARKOUR is een veilige en transparante online omgeving, waar jongeren de regie
                  krijgen. Jongeren vinden er informatie over zichzelf en over thema’s die hen
                  bezighouden. Ze kunnen er gemakkelijk communiceren met hun steunfiguren en ze
                  werken er aan hun doelen.
                </p>
              </section>

              <section class="space-y-3">
                <h3 class="typo-h4">Wanneer kan je de PARKOUR-applicatie gebruiken?</h3>

                <p>
                  Om de Applicatie te gebruiken, moet je deze Gebruiksvoorwaarden accepteren als
                  onderdeel van de Deelnameovereenkomst. Je mag de Applicatie alleen gebruiken voor
                  het gebruik dat hierboven is vermeld. Je mag de Applicatie niet (laten) gebruiken
                  voor illegale doeleinden. Indien je merkt dat dit gebeurt, moet je ons dit melden
                  via de Meldknop in PARKOUR.
                </p>

                <p>
                  Via deze Applicatie zal je toegang hebben tot vertrouwelijke informatie. Denk
                  eraan dat indien je deze vertrouwelijke informatie deelt, je hiervoor zelf
                  verantwoordelijk bent. Indien je een beroepsgeheim hebt, moet je dit respecteren.
                </p>

                <p>
                  Als je onze Gebruiksvoorwaarden schendt, riskeer je dat je geen gebruik meer mag
                  maken van de Applicatie.
                </p>

                <p>
                  Het gebruik van de Applicatie is geen nieuwe typemodule van de jeugdhulpverlening.
                  Het gebruik ervan doet aldus niet meer rechten ontstaan (bijvoorbeeld inzake de
                  beschikbaarheid van de hulpverlener) dan jouw huidige rechten op basis van de
                  regelgeving inzake jeugdhulp.
                </p>

                <p>
                  Als je technische problemen hebt, kan je dit melden via de Meldknop in PARKOUR.
                </p>
              </section>

              <section class="space-y-3">
                <h3 class="typo-h4">Ook belangrijk om te weten</h3>

                <p>
                  Het agentschap Opgroeien blijft altijd de eigenaar van de Applicatie, met inbegrip
                  van alle gerelateerde intellectuele eigendomsrechten.
                </p>

                <p>
                  Het agentschap Opgroeien is niet aansprakelijk voor schade ten gevolge van het
                  gebruik van de Applicatie. Jij bent wel aansprakelijk indien je de Applicatie
                  gebruikt of laat gebruiken voor illegale doeleinden.
                </p>

                <p>
                  Lees zeker de volledige Gebruiksvoorwaarden, waarin alles in meer detail wordt
                  uitgelegd.
                </p>
              </section>
            </article>
          </div>

          <div class="px-4 py-6 bg-n-200">
            <h2 class="mb-4 typo-h3">Volledige Gebruiksvoorwaarden</h2>

            <article class="wysiwyg space-y-8">
              <section class="space-y-3">
                <ol>
                  <li>
                    <h3 class="typo-h4">Algemeen</h3>

                    <ol>
                      <li>
                        In deze Gebruiksvoorwaarden van het agentschap Opgroeien (hierna ook “wij”
                        of “ons”) wordt bepaald hoe je (hierna “je” of “Gebruiker”) de Applicatie
                        kan gebruiken.
                      </li>

                      <li>
                        Deze Gebruiksvoorwaarden vormen een juridisch bindende overeenkomst tussen
                        ons en jou en zijn van toepassing op jouw gebruik van de Applicatie. Door de
                        Applicatie, op welke wijze dan ook, te gebruiken, erken je en stem je ermee
                        in dat jouw gebruik van de Applicatie uitsluitend door deze
                        Gebruiksvoorwaarden wordt beheerst. Indien je niet akkoord gaat met enige
                        bepaling van deze Gebruiksvoorwaarden, ben je niet gerechtigd de Applicatie
                        op enige wijze te (blijven) gebruiken of je er toegang tot te verschaffen.
                      </li>

                      <li>
                        In deze Gebruiksvoorwaarden hebben een aantal begrippen een specifieke
                        betekenis, aangeduid met een hoofdletter. Je vindt de definities van deze
                        begrippen in Bijlage 1.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h3 class="typo-h4">Wat is PARKOUR?</h3>

                    <ol>
                      <li>
                        PARKOUR is een veilige en transparante online omgeving, waar jongeren de
                        regie krijgen. Jongeren vinden er informatie over zichzelf en over thema’s
                        die hen bezighouden. We bouwden deze app opdat de jongeren veilig kunnen
                        communiceren met het eigen team, dat de jongeren inzage krijgen in de
                        basisgegevens uit het jeugdhulpdossier, toegang tot informatie op maat en
                        dat zij de begeleiding zelf mee vorm kunnen geven en zelf aan het stuur
                        staan. Ze kunnen er gemakkelijk communiceren met hun steunfiguren en ze
                        werken er aan hun doelen.
                      </li>

                      <li>
                        PARKOUR is geen nieuwe typemodule van de jeugdhulpverlening, doch slechts
                        een modaliteit van de bestaande jeugdhulpverlening. Het gebruik ervan doet
                        geen bijkomende rechten ontstaan.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h3 class="typo-h4">Rechtmatig gebruik van de applicatie</h3>

                    <ol>
                      <li>
                        <p>
                          Met betrekking tot het gebruik van de Applicatie is elke Gebruiker
                          verplicht:
                        </p>

                        <ul>
                          <li>
                            alle regelgeving, waaronder, maar niet beperkt tot, bepalingen met
                            betrekking tot het beroepsgeheim en de bescherming van Persoonsgegevens,
                            na te leven;
                          </li>

                          <li>
                            zich te houden aan alle interne richtlijnen of beleidsregels die zijn
                            opgesteld door de organisatie waarbij de Gebruiker in dienst is of
                            waarbij hij is aangesloten. Dit omvat onder meer alle regels met
                            betrekking tot professioneel gedrag en deontologie, vertrouwelijkheid en
                            gegevensbescherming;
                          </li>

                          <li>
                            zich te onthouden van manipulatie van de verstrekte informatie, op welke
                            wijze dan ook of met behulp van welke techniek dan ook;
                          </li>

                          <li>
                            <p>
                              geen gegevens, berichten of documenten te versturen via de Applicatie,
                              of gegevens of documenten te laden via de Applicatie:
                            </p>

                            <ul>
                              <li>
                                waarbij de rechten (met inbegrip van, maar niet beperkt tot, morele
                                rechten of intellectuele eigendomsrechten) van Derde Partijen worden
                                geschonden;
                              </li>

                              <li>
                                waarvan de inhoud schadelijk, lasterlijk, gewelddadig, onwettig,
                                obsceen of vernederend is of waarbij de privacy van Derde Partijen
                                wordt geschonden;
                              </li>

                              <li>
                                waarvan het gebruik of bezit door de Gebruiker bij wet of bij
                                overeenkomst verboden is;
                              </li>

                              <li>
                                die virussen of instructies bevatten die schade kunnen toebrengen
                                aan de Applicatie en/of de via de Applicatie geleverde diensten
                                en/of aan de Applicatie gekoppelde of met de Applicatie verbonden
                                systemen, applicaties en andere toepassingen.
                              </li>
                            </ul>

                            <p>
                              Mits instemming van de jongere mogen bepaalde gegevens, berichten of
                              documenten uit achterliggende dossiersystemen van Jeugdhulp wel worden
                              verstuurd via de Applicatie.
                            </p>
                          </li>
                        </ul>
                      </li>

                      <li>
                        Je bent medeverantwoordelijk om een veilig en vertrouwelijk gebruik van de
                        Applicatie te waarborgen. Je mag de Applicatie alleen gebruiken in
                        overeenstemming met het beoogde gebruik. Je gebruikt dit platform niet om
                        toegang te verlenen aan personen die dit platform willen gebruiken om
                        misdrijven te plegen, en je gebruikt dit platform zelf ook niet om dit te
                        doen. In het geval van misbruik van de Applicatie, waaronder niet handelen
                        in het belang van de jongere, behouden wij ons het recht voor om gebruikers
                        de toegang tot de Applicatie te ontzeggen.
                      </li>

                      <li>
                        Wij verwachten van jou dat je ons via de Meldknop meldt indien je vaststelt
                        dat de Applicatie voor illegale doeleinden wordt gebruikt, of indien de
                        verbintenissen in artikel 3.1. en 3.2. worden geschonden.
                      </li>

                      <li>
                        De PARKOUR-applicatie is uitsluitend bedoeld voor persoonlijk gebruik. Elke
                        Deelnemer dient de applicatie in zijn eigen naam te gebruiken en mag zijn
                        toegangsgegevens niet met anderen delen.
                      </li>

                      <li>
                        De inhoud van jouw PARKOUR-applicatie is alleen zichtbaar voor jou. Jij
                        beslist welke gegevens, documenten en berichten je met een teamlid deelt. De
                        inhoud van jouw PARKOUR-applicatie kan enkel worden opgevraagd door een
                        gerechtelijke overheid (procureur des Konings of onderzoekrechter in het
                        kader van een strafonderzoek). De inhoud van jouw PARKOUR-applicatie kan
                        niet worden opgevraagd door een jeugdrechter of een sociale dienst van de
                        jeugdrechtbank in het kader van jeugdhulp.
                      </li>

                      <li>
                        Je gaat ermee akkoord dat het gebruik van de Applicatie in strijd met de
                        voorwaarden en waarborgen van de Gebruiker in deze sectie een ongeoorloofd
                        en ongepast gebruik van de Applicatie vormt.
                      </li>

                      <li>
                        We kunnen jouw gebruik van de Applicatie steeds onmiddellijk beperken,
                        beëindigen of opschorten, zonder voorafgaande kennisgeving of
                        aansprakelijkheid, naar eigen goeddunken, omwille van een door jou gepleegde
                        of veroorzaakte schending van deze Gebruiksvoorwaarden of de onderliggende
                        Deelnameovereenkomst en zonder beperking, met inbegrip van, maar niet
                        beperkt tot, een schending van de Gebruiksvoorwaarden, of andere
                        instructies, richtlijnen of beleidsregels. Een dergelijke opschorting door
                        ons zal doorgaan totdat je de schending die heeft geleid tot de opschorting
                        of beperking hebt verholpen.
                      </li>

                      <li>
                        Wij kunnen niet aansprakelijk worden gesteld voor enig verlies of schade,
                        door jou of door Derde Partijen, als gevolg van het niet naleven van de
                        bovenstaande vereisten (zie ook titel 7 en 8).
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h3 class="typo-h4">
                      Intellectuele eigendomsrechten, licentie &amp; beperkingen
                    </h3>

                    <ol>
                      <li>
                        In het kader van deze Gebruiksvoorwaarden tussen ons en de Gebruiker, blijft
                        de Applicatie, met inbegrip van al zijn functionaliteiten, en alle
                        (gevestigde en/of toepasselijke) daaraan gerelateerde of bijbehorende
                        wereldwijde Intellectuele Eigendomsrechten onze exclusieve eigendom.
                      </li>

                      <li>
                        Alle rechten in en op de Applicatie die in deze Gebruiksvoorwaarden niet
                        uitdrukkelijk aan de Gebruiker zijn verleend, zijn aan ons voorbehouden.
                        Behalve zoals uitdrukkelijk bepaald in deze Gebruiksvoorwaarden, wordt er
                        geen uitdrukkelijke of impliciete licentie verleend aan de Gebruiker met
                        betrekking tot de Applicatie of enig deel daarvan, met inbegrip van enig
                        recht om enige broncode, gegevens of ander technisch materiaal met
                        betrekking tot de Applicatie te verkrijgen.
                      </li>

                      <li>
                        Wij verlenen je hierbij een persoonlijk, herroepelijk, niet-exclusief,
                        niet-overdraagbaar, niet-sublicentieerbaar gebruiksrecht voor de duur van de
                        overeenkomst om de Applicatie te gebruiken in overeenstemming met de
                        Gebruiksvoorwaarden.
                      </li>

                      <li>
                        <p>Jij, noch een Derde partij, zal onder geen enkele omstandigheid:</p>

                        <ul>
                          <li>
                            de software decompileren, demonteren of reverse-engineeren, of proberen
                            de broncode, onderliggende ideeën, algoritmen, bestandsformaten of
                            programmeerinterfaces van de Applicatie op enigerlei wijze te
                            reconstrueren of te ontdekken;
                          </li>

                          <li>
                            de Applicatie of enig deel daarvan distribueren, verkopen, verhuren, in
                            sublicentie geven, leasen, uitlenen, distribueren of anderszins
                            overdragen aan een Derde Partij;
                          </li>

                          <li>
                            productidentificatie, eigendomsinformatie, auteursrechtvermeldingen,
                            digitale watermerken of andere vermeldingen in of op de Applicatie
                            wijzigen, verwijderen of onleesbaar maken;
                          </li>

                          <li>
                            een deel van de Applicatie wijzigen, aanpassen of veranderen, een
                            afgeleid werk maken van een deel van de Applicatie of de Applicatie
                            integreren in of met andere software, behalve voor zover dit
                            uitdrukkelijk schriftelijk door ons is toegestaan;
                          </li>

                          <li>
                            de Applicatie gebruiken om illegale activiteiten uit te voeren of te
                            bevorderen;
                          </li>

                          <li>
                            malafide en illegale praktijken ondersteunen door personen aan het team
                            toe te voegen die de applicatie wensen te gebruiken om daar illegale
                            activiteiten mee uit te voeren
                          </li>

                          <li>
                            een automatisch, elektronisch of handmatig hoog-volume proces gebruiken
                            om toegang te krijgen tot de Applicatie, deze te doorzoeken of
                            informatie te verzamelen over de Applicatie (met inbegrip van maar niet
                            beperkt tot robots, spiders of scripts);
                          </li>

                          <li>
                            opzettelijk virussen, Trojaanse paarden, corrupte bestanden of andere
                            vernietigende of bedrieglijke elementen verspreiden of de Applicatie
                            gebruiken voor onwettige, indringende, inbreuk-makende, lasterlijke of
                            frauduleuze doeleinden;
                          </li>

                          <li>
                            technische en andere beschermende maatregelen in de Applicatie
                            verwijderen of anderszins omzeilen.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h3 class="typo-h4">Ondersteuning</h3>

                    <p>
                      Als je technische problemen ervaart of klachten hebt, neem dan contact op via
                      de Meldknop in de PARKOUR app. Wij zullen alle redelijke inspanningen leveren
                      om jouw vragen in de kortst mogelijke tijd te beantwoorden en jouw technische
                      problemen met de Applicatie op te lossen. Ook voor klachten (bijvoorbeeld over
                      mogelijk misbruik) zullen wij zo snel mogelijk reageren.
                    </p>
                  </li>

                  <li>
                    <h3 class="typo-h4">Aansprakelijkheid</h3>

                    <ol>
                      <li>
                        Iedere partij kan slechts aangesproken worden voor de schade die hij zelf
                        veroorzaakt heeft, of door zijn nalatigheid kan worden toegewezen. Iedere
                        aansprakelijkheid “in solidum” is uitgesloten.
                      </li>

                      <li>
                        Wij zijn nooit aansprakelijk voor:
                        <ul>
                          <li>
                            Schade in verband met de onjuistheid of onvolledigheid van de gegevens
                            van de Gebruiker;
                          </li>
                          <li>
                            Schade in verband met het ongeoorloofd of illegaal gebruik van de
                            PARKOUR-applicatie door Gebruikers;
                          </li>
                          <li>
                            Schade verband houdende met het gebruik van door PARKOUR-applicatie aan
                            de Gebruiker voorgeschreven zaken, materialen of software van derden;
                            dit betekent dat wij niet aansprakelijk zijn voor repressieve,
                            incidentele of indirecte schade van welke aard dan ook, met inbegrip
                            van, maar niet beperkt tot, schade of kosten als gevolg van verlies van
                            winst, gegevens, inkomsten, goodwill, van de aankoop van vervangende
                            diensten of van schade aan eigendommen die voortvloeit uit het gebruik
                            van de Applicatie, ongeacht de oorzaak van de claim of de theorie van de
                            aansprakelijkheid - op grond van onrechtmatigheid, contractueel of
                            anderszins -, ook al zijn we op de hoogte van de waarschijnlijkheid van
                            dergelijke schade;
                          </li>
                          <li>
                            Schade in verband met de inschakeling van door PARKOUR-applicatie aan de
                            Gebruiker voorgeschreven leveranciers;
                          </li>
                          <li>
                            Schade door gemiste besparingen, gederfde winst, of enige andere vorm
                            van indirecte, incidentele of gevolgschade, ongeacht de aard van de
                            handeling;
                          </li>
                          <li>
                            Schade verband houdende met handelingen of nalatigheid van de Gebruiker
                            (zoals, maar niet beperkt tot, ongeautoriseerd of onrechtmatig gebruik
                            van de PARKOUR-applicatie, niet-respecteren van de gebruikershandleiding
                            van PARKOUR-applicatie, ongeoorloofd delen van de gegevens van de
                            Gebruiker, etc.);
                          </li>
                          <li>
                            Voor verlies of schade veroorzaakt door virussen of ander technologisch
                            schadelijk materiaal dat het toestel waarmee je de Applicatie gebruikt
                            of ander beschermd materiaal kan besmetten door jouw gebruik van de
                            Applicatie.
                          </li>
                        </ul>
                        <p>
                          De Gebruiker verbindt zich ertoe om onmiddellijk de nodige maatregelen te
                          treffen om zoveel als mogelijk de schade te beperken en ons hierover in te
                          lichten en hierover informatie over te maken.
                        </p>
                      </li>

                      <li>
                        De Applicatie is geen nieuwe typemodule van de jeugdhulpverlening, doch
                        slechts een modaliteit van het bestaande jeugdhulpverleningstraject. Het
                        gebruik ervan doet geen bijkomende rechten ontstaan. Onze aansprakelijkheid
                        en deze van de jeugdhulpverlener beperkt zich daarom tot deze binnen de
                        bestaande jeugdhulpverlening.
                      </li>

                      <li>
                        De Applicatie kan onnauwkeurigheden en typfouten bevatten. Wij waarborgen
                        niet de juistheid of volledigheid van de inhoud en de diensten die op de
                        Applicatie worden aangeboden. Bovendien behouden wij ons uitdrukkelijk het
                        recht voor om eventuele foutieve informatie over de Applicatie te corrigeren
                        (zie Titel 8).
                      </li>

                      <li>
                        Voor zover toegestaan door de toepasselijke wetgeving, verlenen wij de
                        licentie op de Applicatie op een "as is" en "as available"-basis en verlenen
                        wij geen enkele waarborg, hetzij expliciet, impliciet, wettelijk of
                        anderszins, met betrekking tot de Applicatie (met inbegrip van alle daarin
                        opgenomen inhoud), met inbegrip van (zonder beperking) enige impliciete
                        garanties van bevredigende kwaliteit, verhandelbaarheid, geschiktheid voor
                        een bepaald doel of niet-inbreuk (zie Titel 8).
                      </li>

                      <li>
                        Wij zijn op geen enkele wijze aansprakelijk voor schade als gevolg van jouw
                        handelen of nalaten op basis van de inhoud die beschikbaar is op de
                        Applicatie. Wij kunnen ook niet aansprakelijk worden gesteld voor
                        handelingen, advies, fouten, weglatingen, verklaringen, waarborgen of
                        overtredingen van andere teamleden.
                      </li>

                      <li>
                        De totale aansprakelijkheid van het agentschap Opgroeien wegens een
                        toerekenbare tekortkoming in de naleving van de gebruiksvoorwaarden, of op
                        enige andere rechtsgrond dan ook, is beperkt tot de vergoeding van de
                        directe en bewezen schade die door de Gebruiker werd geleden en kan nooit
                        meer bedragen dan een bedrag van 10.000,00 euro per gebeurtenis en per
                        kalenderjaar. Een reeks van samenhangende feiten wordt voor de toepassing
                        van dit artikel als één gebeurtenis beschouwd. De bepalingen van dit artikel
                        doen geen afbreuk aan enige andere aansprakelijkheidsbeperking.
                      </li>

                      <li>
                        Je bent aansprakelijk en zal ons vrijwaren voor alle schade indien je de
                        PARKOUR-applicatie gebruikt in strijd met de Gebruiksvoorwaarden, in het
                        bijzonder in strijd met artikel 3. Deze schade is alle mogelijke schade
                        (repressieve, incidentele of indirecte schade van welke aard dan ook, met
                        inbegrip van, maar niet beperkt tot, schade of kosten als gevolg van de
                        aankoop van vervangende diensten of van schade aan eigendommen of personen,
                        ongeacht de oorzaak van de claim of de theorie van de aansprakelijkheid - op
                        grond van onrechtmatigheid, contractueel of anderszins - die wij lijden, al
                        dan niet op vordering van Derde Partijen.
                      </li>
                    </ol>
                  </li>

                  <li>
                    <h3 class="typo-h4">Garanties &amp; disclaimers</h3>

                    <ol>
                      <li>
                        De verbintenissen van Opgroeien zijn middelenverbintenissen. Dit betekent
                        dat Opgroeien zich steeds zal inspannen om verbintenissen naar best vermogen
                        en volgens de regels van de kunst in de informaticapraktijk uit te voeren,
                        zonder echter het resultaat te kunnen garanderen. PARKOUR wordt dan ook ter
                        beschikking gesteld aan de Gebruiker “as is”. Opgroeien zorgt er aldus voor
                        dat PARKOUR substantieel functioneert, zonder echter de foutloosheid van
                        o.m. het (achterliggende) computerprogramma, software, broncode of de
                        gebruikershandleiding te garanderen. De Gebruiker erkent en aanvaardt aldus
                        dat PARKOUR nooit volkomen perfect of vrij van onvolkomenheden kan zijn en
                        dat niet alle onvolkomenheden kunnen of zullen worden hersteld. Gebruiker
                        erkent en aanvaardt dat Opgroeien geen garanties geeft met betrekking tot de
                        accuraatheid, integriteit, kwaliteit en beschikbaarheid van data op PARKOUR.
                      </li>

                      <li>
                        <p>
                          Zoals hierin bepaald, zullen wij jou vrijwaren en verdedigen tegen
                          onderbouwde en gegronde eisen van Derde Partijen voor zover de eis
                          gesteund is op een schending van de Intellectuele Eigendomsrechten van de
                          Derde Partij door de Applicatie, met uitsluiting van eisen die het gevolg
                          zijn van:
                        </p>
                        <ul>
                          <li>jouw ongeoorloofd gebruik van de Applicatie;</li>
                          <li>
                            wijzigingen van de Applicatie door jou of door een door jou aangestelde
                            Derde Partij;
                          </li>
                          <li>
                            jouw verzuim om de laatste versie van de Applicatie te gebruiken of jouw
                            onvermogen om correcties in de Applicatie te installeren als we hebben
                            aangegeven dat de update of correctie nodig was om een mogelijke
                            schending te voorkomen;
                          </li>
                          <li>
                            jouw gebruik van de Applicatie in combinatie met producten of diensten
                            die niet specifiek van ons zijn, zonder onze uitdrukkelijke toestemming.
                          </li>
                        </ul>
                      </li>

                      <li>
                        <p>
                          Voor een dergelijke vrijwaringsverplichting gelden de volgende
                          voorwaarden:
                        </p>
                        <ul>
                          <li>
                            Wij zullen onmiddellijk schriftelijk op de hoogte worden gesteld van een
                            dergelijke vordering of claim;
                          </li>
                          <li>
                            Wij zullen de verdediging en de afwikkeling van een dergelijke vordering
                            naar eigen goeddunken bepalen;
                          </li>
                          <li>
                            Op ons verzoek zul je volledig met ons meewerken aan de verdediging en
                            afwikkeling van een dergelijke vordering op onze kosten;
                          </li>
                          <li>
                            Je doet geen uitspraken over onze aansprakelijkheid met betrekking tot
                            een dergelijke claim, noch ga je akkoord met een schikking met
                            betrekking tot een dergelijke claim, zonder onze voorafgaande
                            schriftelijke toestemming.
                          </li>
                        </ul>

                        <p>
                          Indien aan deze voorwaarden is voldaan, zullen wij je vrijwaren voor alle
                          schade en kosten die je als gevolg van een dergelijke claim hebt
                          opgelopen, zoals toegekend door een bevoegde rechtbank in laatste
                          instantie of zoals overeengekomen in de schikking met onze aanklagers.
                        </p>
                      </li>

                      <li>
                        <p>
                          Indien de Applicatie naar onze redelijke mening waarschijnlijk het
                          onderwerp is van een eis door een Derde Partij, behouden wij ons, naar
                          eigen goeddunken en op eigen kosten, het recht voor:
                        </p>
                        <ul>
                          <li>
                            de Applicatie (of het (vermeende) inbreuk-makende deel daarvan) te
                            wijzigen zodat de inbreuk niet langer bestaat, maar de gelijkwaardige
                            functionaliteit behouden blijft;
                          </li>
                          <li>
                            voor jou een licentie te verkrijgen om de Applicatie te blijven
                            gebruiken in overeenstemming met deze Gebruiksvoorwaarden.
                          </li>
                        </ul>
                      </li>
                    </ol>
                  </li>
                </ol>

                <h3 class="typo-h4">Bijlage 1 &mdash; Definities</h3>

                <p>
                  In deze Gebruiksvoorwaarden hebben de volgende specifieke definities de volgende
                  betekenis:
                </p>

                <ul>
                  <li>
                    <strong>“Applicatie”:</strong> de PARKOUR-applicatie, die het voorwerp uitmaakt
                    van deze Gebruiksvoorwaarden en die je aangeboden wordt voor gebruik tijdens het
                    PARKOUR-pilootproject;
                  </li>
                  <li>
                    <strong>“Derde Partijen”:</strong> elke natuurlijke persoon of rechtspersoon of
                    elke entiteit die geen partij is bij deze Gebruiksvoorwaarden;
                  </li>
                  <li>
                    <strong>“Gebruiker”:</strong> de gebruikers van de Applicatie, die deelnemen aan
                    het PARKOUR-pilootproject overeenkomstig de algehele Deelnameovereenkomst;
                  </li>
                  <li>
                    <strong>“Intellectuele Eigendomsrechten”</strong>
                    betekent alle nu bekende of later bijkomende (a) auteursrechten, naburige
                    rechten en morele rechten; (b) handelsmerk- of dienstmerkrechten; (c)
                    handelsgeheimrechten, knowhow, vakkennis; (d) octrooien, octrooirechten en
                    industriële eigendomsrechten; lay-out ontwerprechten, ontwerprechten;
                    aanvullende beschermingscertificaten (f) handels- en firmanamen, domeinnamen,
                    databankrechten, huurrechten en alle andere industriële en intellectuele
                    eigendomsrechten of gelijkaardige rechten (al dan niet geregistreerd); (g) alle
                    registraties, registratieaanvragen, vernieuwingen, uitbreidingen, opdelingen,
                    verbeteringen of heruitgaven met betrekking tot deze rechten en het recht om een
                    van voorgenoemde punten toe te passen, te behouden en op te leggen, in elk geval
                    en in elk rechtsgebied wereldwijd, voor zolang deze bescherming geldt;
                  </li>
                  <li>
                    <strong>"Meldknop”:</strong> knop binnen de PARKOUR-applicatie waarmee je
                    misbruik van de applicatie of technische problemen kan rapporteren
                  </li>
                  <li>
                    <strong>“Persoonsgegevens”:</strong>
                    alle informatie over een geïdentificeerde of identificeerbare persoon zoals
                    gedefinieerd in de Algemene Verordening Gegevensbescherming (AVG).
                  </li>
                  <li><strong>“Jongere”:</strong> elke persoon tussen 13 en 25 jaar.</li>
                </ul>
              </section>
            </article>
          </div>
        </div>

        <div class="pt-6">
          <parkour-checkbox [(ngModel)]="voorwaardenChecked">
            Ik aanvaard de gebruiksvoorwaarden
          </parkour-checkbox>
          <parkour-form-error-message
            [errorMessage]="'Aanvaard de gebruiksvoorwaarden.'"
            [show]="showVoorwaardenCheckedRequiredError"
          />
        </div>

        <parkour-button (clicked)="onNextStep()" class="w-full block pt-6" iconAfter="arrow-right">
          Volgende stap
        </parkour-button>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
