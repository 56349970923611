<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="['..']" />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'verhaal.gebeurtenis-suggestie-add-page.title' | translate }}
    </ion-title>

    <ion-buttons slot="end">
      <parkour-header-action
        [label]="'verhaal.gebeurtenis-suggestie-add-page.submit' | translate"
        [loading]="formBusy()"
        form="gebeurtenis-suggestie-form"
        icon="paper-plane"
        testId="save-gebeurtenis"
        type="submit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" [parkourRefresh]="onPageRefresh">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-with-hero [heroImage]="hero$ | async" [showTitle]="false" [title]="''">
      <parkour-custom-foto-edit
        [formControl]="gebeurtenisSuggestieForm.controls.imageUploadData"
        [aspectRatio]="16 / 9"
        [fotoName]="'verhaal-foto'"
        [mode]="'create'"
        hero-content
        [existingImageGetFunction]="getGebeurtenisImage"
      />
      <parkour-form
        [errorLabelMapping]="false"
        [formId]="'gebeurtenis-suggestie-form'"
        [formGroup]="gebeurtenisSuggestieForm"
        [submitObservableGenerator]="submitGebeurtenisSuggestie"
      >
        <div class="flex flex-col gap-y-8">
          <div class="container-1-col flex flex-col gap-y-8">
            <parkour-form-field
              inputId="titel"
              [inputFormControl]="gebeurtenisSuggestieForm.controls.titel"
              [required]="true"
              [label]="'verhaal.gebeurtenis-suggestie-add-page.gebeurtenis-titel.label' | translate"
              class="block"
            >
              <parkour-input
                inputId="titel"
                [required]="true"
                [formControl]="gebeurtenisSuggestieForm.controls.titel"
                [inputPlaceholder]="
                  'verhaal.gebeurtenis-suggestie-add-page.gebeurtenis-titel.placeholder' | translate
                "
                class="block"
              />
            </parkour-form-field>

            <parkour-form-field
              inputId="datum"
              [required]="true"
              [inputFormControl]="gebeurtenisSuggestieForm.controls.datum"
              [label]="'verhaal.gebeurtenis-suggestie-add-page.gebeurtenis-datum.label' | translate"
              class="block"
            >
              <parkour-input
                inputId="datum"
                [required]="true"
                [formControl]="gebeurtenisSuggestieForm.controls.datum"
                [inputPlaceholder]="
                  'verhaal.gebeurtenis-suggestie-add-page.gebeurtenis-datum.placeholder' | translate
                "
                type="date"
                class="block"
              >
              </parkour-input>
            </parkour-form-field>

            <parkour-form-field
              inputId="beschrijving"
              [inputFormControl]="gebeurtenisSuggestieForm.controls.beschrijving"
              [label]="
                'verhaal.gebeurtenis-suggestie-add-page.gebeurtenis-beschrijving.label' | translate
              "
              class="block"
            >
              <parkour-textarea
                inputId="beschrijving"
                class="block"
                [formControl]="gebeurtenisSuggestieForm.controls.beschrijving"
                [inputPlaceholder]="
                  'verhaal.gebeurtenis-suggestie-add-page.gebeurtenis-beschrijving.placeholder'
                    | translate
                "
              />
            </parkour-form-field>

            <parkour-attachments-upload
              [formControl]="gebeurtenisSuggestieForm.controls.bijlagen"
            />

            <parkour-button
              [loading]="formBusy()"
              class="block w-full"
              iconAfter="paper-plane"
              type="submit"
            >
              {{ 'verhaal.gebeurtenis-suggestie-add-page.submit' | translate }}
            </parkour-button>
          </div>
        </div>
      </parkour-form>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
