@if (cookiesEnabled) {
  @switch (content.content.type) {
    @case ('youtube') {
      <youtube-player [videoId]="videoId ?? ''" class="video" [playerVars]="playerVars" />
    }
    @case ('vimeo') {
      <div #vimeoPlayer class="video"></div>
    }
  }
} @else {
  <parkour-info-card>
    <div class="flex flex-col gap-y-2">
      <!-- TODO: UX Copy cookie inhoud aan JSON toevoegen -->
      <p class="typo-body variant-body-fat">We kunnen deze inhoud niet weergeven</p>

      <p>
        Hier staat ingevoegde inhoud die cookies kan schrijven of uitlezen, maar je hebt hiervoor
        geen toestemming gegeven.
      </p>

      <parkour-button (clicked)="onOpenCookieVoorkeuren()" size="none" variant="ghost-light">
        Cookievoorkeuren
      </parkour-button>
    </div>
  </parkour-info-card>
}
