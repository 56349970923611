<div class="flex items-center justify-center w-full">
  @if (hulplijn$ | async; as hulplijn) {
    <parkour-helpline-card-item
      class="border-[1px] border-solid border-n-500 rounded block h-full"
      [organisation]="hulplijn"
      [segmentsToHulplijn]="['hulplijn', String(hulplijn.id)]"
    >
      <ng-content></ng-content>
    </parkour-helpline-card-item>
  } @else {
    <parkour-content-unavailable-card class="block w-full h-full" />
  }
</div>
