import { Injectable } from '@angular/core';
import { ParkourPopupService } from '@parkour/ui';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { isNativeApp } from '../../utils';

interface CapcatitorPlugin<PermissionName extends string> {
  requestPermissions(): Promise<{ [key in PermissionName]: PermissionState | string }>;
  checkPermissions(): Promise<{ [key in PermissionName]: PermissionState | string }>;
}

type ErrorPopupData = { errorTitle: string; errorDescription: string };

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private readonly popupService: ParkourPopupService) {}

  async withPermissions<PermissionName extends string>(
    capacitorPlugin: CapcatitorPlugin<PermissionName>,
    permissionName: PermissionName,
    grantedCallback: () => void,
    errorPopupData: ErrorPopupData,
  ) {
    if (!isNativeApp()) {
      grantedCallback();
      return;
    }
    const checkPermissionsResult = await capacitorPlugin.checkPermissions();
    let status: PermissionState | string = checkPermissionsResult[permissionName];
    if (status !== 'granted') {
      status = (await capacitorPlugin.requestPermissions())[permissionName];
    }

    if (status !== 'denied') {
      grantedCallback();
    } else {
      await this.showPermissionsDeniedPopup(errorPopupData);
    }
  }

  public async showPermissionsDeniedPopup(errorPopupData: ErrorPopupData) {
    const result = await this.popupService.showPopup({
      title: errorPopupData.errorTitle,
      description: errorPopupData.errorDescription,
      icon: 'alert-triangle',
      jaText: isNativeApp() ? 'Instellingen openen' : 'Oké',
      neeText: 'Annuleren',
    });
    if (result && isNativeApp()) {
      await NativeSettings.open({
        optionAndroid: AndroidSettings.ApplicationDetails,
        optionIOS: IOSSettings.App,
      });
    }
  }
}
