<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title>{{ 'profiel.probleem-page.title' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <h1 class="text-center mb-4">
          {{ 'profiel.probleem-page.title' | translate }}
        </h1>

        <parkour-divider></parkour-divider>

        <section class="mt-8">
          <p>{{ 'profiel.probleem-page.description' | translate }}</p>
          @if (formLoaded) {
            <parkour-form
              [formGroup]="probleemForm.form"
              [formId]="'probleem-form'"
              [errorLabelMapping]="false"
              [submitObservableGenerator]="submitProbleem"
              class="mt-8 space-y-8"
              id="probleem-form"
            >
              <parkour-form-field
                [inputFormControl]="probleemForm.form.controls.type"
                [label]="'profiel.probleem-page.issue-type' | translate"
                [required]="true"
                class="block"
                inputId="probleem-type"
              >
                <parkour-select
                  [formControl]="probleemForm.form.controls.type"
                  [options]="typeOptions"
                  [required]="true"
                  placeholder=""
                  selectId="probleem-type"
                />
              </parkour-form-field>

              <parkour-form-field
                [errorMessages]="{ required: 'Vul een beschrijving in.' }"
                [inputFormControl]="probleemForm.form.controls.beschrijving"
                [label]="'profiel.probleem-page.issue-description' | translate"
                [required]="true"
                class="block"
                inputId="probleem-beschrijving"
              >
                <parkour-textarea
                  [required]="true"
                  class="block"
                  formControlName="beschrijving"
                  inputId="probleem-beschrijving"
                />
              </parkour-form-field>

              @if (probleemForm.type === 'AANGEMELD') {
                @if (probleemForm.userWithProfiel.profiel.email) {
                  <p>
                    {{ 'profiel.probleem-page.reminder-email' | translate }}
                    <span class="typo-body variant-body-fat">{{
                      probleemForm.userWithProfiel.profiel.email
                    }}</span>
                  </p>
                }

                @if (probleemForm.userWithProfiel.profiel.telefoonnummer) {
                  <p>
                    {{ 'profiel.probleem-page.reminder-phone' | translate }}
                    <span class="typo-body variant-body-fat">{{
                      probleemForm.userWithProfiel.profiel.telefoonnummer
                    }}</span>
                  </p>
                }

                <parkour-info-card class="block" [style]="'bg-n-200'">
                  <p>{{ 'profiel.probleem-page.fyi' | translate }}</p>
                </parkour-info-card>
              } @else {
                <div>
                  <h2 class="typo-h4">{{ 'profiel.probleem-page.contact.title' | translate }}</h2>

                  <p class="mt-3">{{ 'profiel.probleem-page.contact.description' | translate }}</p>
                </div>

                @if (probleemForm.type === 'ANONIEM') {
                  <parkour-form-field
                    [inputFormControl]="probleemForm.form.controls.voornaam"
                    [label]="'profiel.probleem-page.contact.firstname' | translate"
                    [required]="true"
                    class="block"
                    inputId="probleem-voornaam"
                  >
                    <parkour-input
                      [required]="true"
                      class="block"
                      formControlName="voornaam"
                      inputId="probleem-voornaam"
                    />
                  </parkour-form-field>

                  <parkour-form-field
                    [inputFormControl]="probleemForm.form.controls.naam"
                    [label]="'profiel.probleem-page.contact.lastname' | translate"
                    [required]="true"
                    class="block"
                    inputId="probleem-familienaam"
                  >
                    <parkour-input
                      [required]="true"
                      class="block"
                      formControlName="naam"
                      inputId="probleem-familienaam"
                    />
                  </parkour-form-field>

                  <parkour-form-field
                    [errorMessages]="{
                      pattern: 'Vul een geldig e-mailadres in.',
                    }"
                    [inputFormControl]="probleemForm.form.controls.email"
                    [label]="'profiel.probleem-page.contact.email' | translate"
                    [required]="true"
                    class="block"
                    inputId="probleem-email"
                  >
                    <parkour-input
                      [required]="true"
                      class="block"
                      formControlName="email"
                      inputId="probleem-email"
                      type="email"
                      pattern=".*@.*"
                    />
                  </parkour-form-field>

                  <parkour-info-card class="block" [style]="'bg-n-200'">
                    <p>{{ 'profiel.probleem-page.fyi' | translate }}</p>
                  </parkour-info-card>
                }
              }

              <parkour-button class="block w-full mt-10" iconAfter="paper-plane" type="submit">
                {{ 'buttons.notification-send' | translate }}
              </parkour-button>
            </parkour-form>
          }
        </section>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
