import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, mergeMap, Observable, of, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import {
  ContextId,
  ContextOptionDto,
  MyProfielDto,
  MyProfielUpsertDto,
  ProfielId,
} from 'parkour-web-app-dto';
import { asType, deepEqual, stripNullProperties } from '../../utils';
import { UserService } from '../../user/service/user.service';
import { ContextOption } from '../model/context-option';
import { environment } from '../../../environments/environment';
import { ContextService } from '../../shared/services/context.service';
import { TeamService } from '../../team/service/team.service';
import { ProfielInTeam } from '../../profiel/model/profiel-in-team';
import { Router } from '@angular/router';
import { AangemeldZonderProfielUser } from '../../user/model/aangemeld-zonder-profiel-user';
import { ActieveJongereProfiel, MyProfiel } from '../model/profiel';
import { Capacitor, HttpHeaders } from '@capacitor/core';
import { LoggingService } from '../../core/logging.service';

@Injectable({
  providedIn: 'root',
})
export class ProfielService {
  constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly contextService: ContextService,
    private readonly teamService: TeamService,
    private readonly router: Router,
    private readonly loggingService: LoggingService,
  ) {}

  private _redirectUrl?: string;

  get redirectUrl(): string {
    return this._redirectUrl ?? '/app/me/home';
  }

  public initialize() {
    this.userService
      .getCurrentUser$()
      .pipe(distinctUntilChanged(deepEqual))
      .subscribe((user) => {
        if (user instanceof AangemeldZonderProfielUser) {
          this.ensureUserHasProfiel();
        }
      });
  }

  updateMyProfiel(profiel: MyProfielUpsertDto): Observable<MyProfiel> {
    return this.http
      .put<MyProfielDto>(
        `${environment.API_BASE_URL}/api/profiel/me`,
        asType<MyProfielUpsertDto>(stripNullProperties(profiel)),
      )
      .pipe(
        tap(() => {
          this.userService.refeshUser();
        }),
        map((dto) => ({ ...dto })),
        tap(() => this.teamService.invalidateCache()),
      );
  }

  createMyProfiel(profiel: MyProfielUpsertDto): Observable<MyProfielDto> {
    return this.http
      .post<MyProfielDto>(
        `${environment.API_BASE_URL}/api/profiel/me`,
        asType<MyProfielUpsertDto>(stripNullProperties(profiel)),
      )
      .pipe(
        tap(() => {
          this.userService.refeshUser();
        }),
      );
  }

  uploadMyProfielFoto(profielFoto: File): Observable<void> {
    let headers: HttpHeaders = {};
    if (Capacitor.isNativePlatform()) {
      headers = { 'Content-Type': 'multipart/form-data; boundary=uploadMyProfielFoto' };
    }
    const data: FormData = new FormData();
    data.append('file', profielFoto);

    return this.http
      .put<void>(`${environment.API_BASE_URL}/api/profiel/me/foto`, data, { headers })
      .pipe(
        tap(async () => {
          this.userService.refeshUser();
          this.teamService.invalidateCache();
        }),
      );
  }

  getMijnSortedContexten(): Observable<ContextOption[]> {
    return this.http
      .get<ContextOptionDto[]>(`${environment.API_BASE_URL}/api/profiel/me/contexten`)
      .pipe(
        map((contexten) => {
          contexten.sort((contextA, contextB) => {
            if (contextA.geblokkeerd !== contextB.geblokkeerd) {
              return contextA.geblokkeerd ? 1 : -1;
            }
            return contextA.teamOwnerNaam.localeCompare(contextB.teamOwnerNaam);
          });

          return contexten;
        }),
      );
  }

  public isActiefInContext(contextId: ContextId): Observable<boolean> {
    return this.getMijnSortedContexten().pipe(
      map((result) => {
        return (
          result.find(
            (contextOption) => contextOption.contextId === contextId && !contextOption.geblokkeerd,
          ) !== undefined
        );
      }),
    );
  }

  getMyProfielen(): Observable<MyProfielDto[]> {
    return this.http.get<Array<MyProfielDto>>(`${environment.API_BASE_URL}/api/profiel/me/all`);
  }

  deleteMyProfielFoto() {
    return this.http.delete(`${environment.API_BASE_URL}/api/profiel/me/foto`).pipe(
      tap(() => {
        this.userService.refeshUser();
        this.teamService.invalidateCache();
      }),
    );
  }

  getProfielInCurrentTeam(profielId: ProfielId): Observable<ProfielInTeam> {
    return this.contextService.contextWithJongere$().pipe(
      mergeMap((context) => {
        if (context.contextId === profielId) {
          switch (context.type) {
            case 'jongere':
              return this.userService.userWithProfiel$().pipe(
                map((user) =>
                  asType<ActieveJongereProfiel>({
                    viewType: 'JONGERE',
                    ...user.profiel,
                  }),
                ),
              );
            case 'teamlid':
              return of(context.jongereProfiel);
          }
        } else {
          return this.teamService.getTeamlidInCurrentContext(profielId);
        }
      }),
    );
  }

  markeerMijnProfielVoorVerwijdering(): Observable<void> {
    return this.http
      .post<void>(`${environment.API_BASE_URL}/api/profiel/me/markeer-voor-verwijdering`, {})
      .pipe(
        tap(() => {
          this.userService.refeshUser();
        }),
      );
  }

  onmarkeerMijnProfielVoorVerwijdering() {
    return this.http
      .post<void>(`${environment.API_BASE_URL}/api/profiel/me/onmarkeer-voor-verwijdering`, {})
      .pipe(
        tap(() => {
          this.userService.refeshUser();
        }),
      );
  }

  private ensureUserHasProfiel() {
    this.loggingService.log('User has no profiel yet, attempt to select one');

    this.getMyProfielen().subscribe((profielen) => {
      this._redirectUrl =
        this.router.getCurrentNavigation()?.initialUrl.toString() ?? this.router.url;

      if (profielen.length === 0) {
        this.router.navigate(['/app', 'start', 'profiel', 'nieuw']);
      } else {
        this.router.navigate(['/app', 'start', 'profiel', 'selecteer']);
      }
    });
  }
}
