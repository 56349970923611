<article
  [ngClass]="{ 'clickable-card-200': !!segmentsToHulplijn }"
  class="flex items-start gap-4 p-4 rounded h-full"
>
  <parkour-image
    [fit]="'contain'"
    [src]="organisation.image"
    alt="tele-onthaal logo"
    class="w-[3.5rem] h-[3.5rem] rounded overflow-hidden block bg-n-1000"
  />

  <div class="flex-1 divide-y-[1.5px] divide-n-300 flex flex-col gap-y-2">
    <div>
      <div class="flex justify-between gap-x-2">
        @if (segmentsToHulplijn) {
          <parkour-action
            [ariaLabel]="getAriaLabel()"
            [link]="segmentsToHulplijn"
            classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 focus-visible:outline-none"
          >
            <ng-container [ngTemplateOutlet]="title"></ng-container>
          </parkour-action>
        } @else {
          <ng-container [ngTemplateOutlet]="title"></ng-container>
        }

        <ng-template #title>
          <h1 class="typo-body variant-body-fat">
            {{ organisation.title }}
          </h1>
        </ng-template>

        <div class="flex items-center gap-x-2">
          @if (isBookmarkable) {
            <parkour-bookmark-button
              (clicked)="onClickedBookmark()"
              [isActive]="!!organisation.bookmarkId"
              class="relative z-10"
            />
          }

          <ng-content></ng-content>
        </div>
      </div>

      <p class="mt-1 text-n-600">
        {{ organisation.description }}
      </p>
    </div>

    @if (organisation.methods.length > 0) {
      <div class="pt-2 flex items-end justify-between gap-2">
        <parkour-helpline-icon-list
          [nowOpen]="true"
          [methods]="organisation.methods"
          color="text-s-100"
        />

        <parkour-helpline-icon-list class="ml-auto" [methods]="organisation.methods" />
      </div>
    }
  </div>
</article>
