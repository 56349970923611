<parkour-avatar
  [image]="
    profiel.status === 'ACTIEF' ||
    profiel.status === 'UITGENODIGD' ||
    profiel.status === 'UITGENODIGDE_WEERGAVE'
      ? { type: 'image', src: profiel | profielFoto: getFotoType() | async }
      : { type: 'icon', name: getIconNaam(profiel) }
  "
  [professional]="profiel.status === 'ACTIEF' && profiel.profielType === 'PROFESSIONEEL'"
  [badgeCount]="badgeCount"
  class="block"
  [ngClass]="{
    'w-[10rem] h-[10rem]': size === '10',
    'w-[5.5rem] h-[5.5rem]': size === '5.5',
    'w-[4rem] h-[4rem] rounded': size === '4',
    'w-[3rem] h-[3rem] rounded': size === '3',
    'opacity-50': grayout,
  }"
/>
