import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

import { Thema } from 'parkour-web-app-dto';
import { themaMapping } from '../../../home/model/thema.const';
import { ParkourActionComponent, ParkourImageComponent } from '@parkour/ui';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { BookmarkButtonComponent } from '../../../shared/components/bookmark-button/bookmark-button.component';

interface IParkourArticleCardImageProps {
  src: string;
  alt: string;
}

@Component({
  selector: 'parkour-article-card',
  standalone: true,
  templateUrl: './artikel-card.component.html',
  styleUrls: ['./artikel-card.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgClass,
    NgTemplateOutlet,
    ParkourImageComponent,
    ParkourActionComponent,
    BookmarkButtonComponent,
  ],
})
export class ArtikelCardComponent {
  @Input({ required: true }) public image!: IParkourArticleCardImageProps;
  @Input({ required: true }) public title!: string;
  @Input() public link?: string[];
  @Input({ required: true }) public themes!: Thema[];
  @Input() public isBookmarked: boolean = false;
  @Input() public isBookmarkable: boolean = true;
  @Input() public testId?: string;

  @Output() public clickedBookmark: EventEmitter<boolean> = new EventEmitter<boolean>();

  getThemaLabelString(themes: Thema[]) {
    return themes.map((theme) => themaMapping[theme].label).join(' | ');
  }

  onClickedBookmark(hasBookmarked: boolean) {
    this.clickedBookmark.emit(hasBookmarked);
  }
}
