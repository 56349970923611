<div class="mt-3" role="alert">
  <div class="rounded bg-n-200 p-4 flex gap-x-2">
    <parkour-icon class="text-n-500" name="clock-snooze" size="3" />

    <div class="space-y-1">
      @if (deelnemer.afwezigheid.van && deelnemer.afwezigheid.tot) {
        <span class="typo-body variant-body-fat"
          >{{
            'profiel.unavailable-time-from-to'
              | translate
                : {
                    from: deelnemer.afwezigheid.van | date: 'dd.MM.yyyy',
                    to: deelnemer.afwezigheid.tot | date: 'dd.MM.yyyy',
                  }
          }}
        </span>
      } @else {
        <span class="typo-body variant-body-fat"
          >{{
            'profiel.unavailable-time-from'
              | translate
                : {
                    from: deelnemer.afwezigheid.van | date: 'dd.MM.yyyy',
                  }
          }}
        </span>
      }

      @if (deelnemer.afwezigheid.bericht) {
        <p class="wysiwyg" [innerHTML]="deelnemer.afwezigheid.bericht | linkify"></p>
      }
    </div>
  </div>

  @if (isProfessioneel(deelnemer)) {
    <parkour-helpline-card-item
      [segmentsToHulplijn]="['hulplijn', String(teleOnthaalOrganisation.id)]"
      [organisation]="teleOnthaalOrganisation"
      class="block mt-3"
    />
  }
</div>
