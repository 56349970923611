import { Injectable } from '@angular/core';
import { EMPTY, map, merge, Observable, shareReplay, startWith, Subject, switchMap } from 'rxjs';
import { repeatableIgnoreErrorsExceptInitial } from '../../utils';

export type GlobalRefreshCause = 'global-refresh';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  get refeshed$(): Observable<GlobalRefreshCause> {
    return this._refeshed$.asObservable().pipe(map(() => 'global-refresh'));
  }

  private _refeshed$ = new Subject<void>();

  constructor() {}

  public repeatOnRefresh<T>(source: Observable<T>): Observable<T> {
    return this.refeshed$.pipe(
      startWith(undefined),
      switchMap(() => source),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  public repeatOnRefreshWithInitialOnError<T, RefreshCause = 'other'>(
    source: (refreshCause?: GlobalRefreshCause | RefreshCause) => Observable<T>,
    onError: T,
    otherRefreshTrigger: Observable<RefreshCause> = EMPTY,
    mode: 'switch' | 'concat' = 'switch',
    initialErrorLog?: string,
    ignoredErrorLog?: string,
  ): Observable<T> {
    return repeatableIgnoreErrorsExceptInitial(
      source,
      onError,
      merge(this.refeshed$, otherRefreshTrigger),
      mode,
      initialErrorLog,
      ignoredErrorLog,
    );
  }

  public refresh() {
    this._refeshed$.next();
  }
}
