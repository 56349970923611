import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ParkourDividerComponent,
  ParkourHeroComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { DoelPlaceholderComponent } from '../../component/doelen-placeholder/doel-placeholder.component';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import { ParkourPage } from '../../../../shared/parkour-page';
import { Profiel } from '../../../../profiel/model/profiel';
import { ProfielService } from '../../../../profiel/service/profiel.service';

@Component({
  standalone: true,
  templateUrl: './doel-empty-state-no-team.page.html',
  imports: [
    ParkourPageImageComponent,
    NgIf,
    ParkourHeroComponent,
    ParkourDividerComponent,
    AsyncPipe,
    TranslateModule,
    PageLayoutComponent,
    DoelPlaceholderComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonHeader,
    IonContent,
  ],
})
export class DoelenEmptyStateNoTeamPage extends ParkourPage {
  profiel$: Observable<Profiel> = this.profielService.getCurrentUserProfiel$();

  constructor(private readonly profielService: ProfielService) {
    super();
  }
}
