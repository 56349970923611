<ion-header parkourAdaptToSidebarVisible>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'over-parkour.waarom-parkour-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space">
      <div class="container-1-col">
        <h1 class="text-center mb-4">
          {{ 'over-parkour.waarom-parkour-page.title' | translate }}
        </h1>

        <parkour-divider></parkour-divider>

        <article class="wysiwyg space-y-8">
          <section class="mt-8 space-y-3">
            <p>
              PARKOUR is een nieuwe manier van werken die ervoor zorgt dat jongeren de baas zijn
              over hun gegevens. De regie ligt bij hen, tijdens het hele traject:
            </p>

            <ul>
              <li>
                Zij bepalen welke gegevens erin komen, kunnen die elk moment inzien en beslissen wat
                ermee gebeurt (transparantie).
              </li>

              <li>
                Zij stellen een team samen van mensen die kunnen helpen, zowel officieel als
                informeel (netwerkgericht werken).
              </li>

              <li>
                Samen met hun team werken ze aan plannen en acties. Zo vergroot hun inbreng aan het
                eigen traject (co-creatie).
              </li>

              <li>
                Ze kunnen veilig chatten en beeldbellen met teamleden en zo snel in contact komen en
                info delen.
              </li>
            </ul>

            <p>
              Verder vinden ze er de weg naar betrouwbare info en hulplijnen voor tal van thema’s
              die hen bezighouden.
            </p>

            <p>
              Dat alles zorgt ervoor dat jongeren makkelijker hulp krijgen en dat die hulp ook
              blijft lopen doorheen hun hele traject, van preventie tot nazorg (nabijheid en
              continuïteit).
            </p>
          </section>
        </article>
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
