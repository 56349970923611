import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { Params, RouterLink } from "@angular/router";
import { throttle } from "lodash";

import { TParkourActionTypes } from "./parkour-action.types";
import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";

@Component({
  standalone: true,
  selector: "parkour-action",
  templateUrl: "./parkour-action.component.html",
  imports: [NgIf, NgClass, RouterLink, NgTemplateOutlet],
})
export class ParkourActionComponent {
  @ViewChild("action") action!: ElementRef<
    HTMLButtonElement | HTMLAnchorElement
  >;
  @Input() public classes?: string;
  @Input() public ngClasses?: object;
  @Input() public href?: string;
  @Input() public link?: string[] | string | null;
  @Input() public routeInfo?: unknown;
  @Input() public queryParams: Params = {};
  @Input() public type?: TParkourActionTypes = "button";
  @Input() public disabled?: boolean;
  @Input() public ariaLabel?: string;
  @Input() public ariaExpanded?: boolean;
  @Input() public ariaPressed?: boolean;
  @Input() public ariaCurrent?: boolean;
  @Input() public ariaSelected?: boolean;
  @Input() public ariaControls?: string;
  @Input() public tabIndex?: number;
  @Input() public testId?: string;
  @Input() public download: string | true | undefined;
  @Input() public fillContainer: boolean = false;

  @Output() public clicked: EventEmitter<MouseEvent> =
    new EventEmitter<MouseEvent>();
  @Output() public focussed: EventEmitter<FocusEvent> =
    new EventEmitter<FocusEvent>();
  @Output() public blurred: EventEmitter<FocusEvent> =
    new EventEmitter<FocusEvent>();
  @Output() public spaced: EventEmitter<Event> = new EventEmitter<Event>();

  readonly fillContainerClass = `before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 focus-visible:outline-none`;

  public click(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    const throttledClick = throttle(() => this.clicked.emit(event), 1000, {
      trailing: false,
      leading: true,
    });

    throttledClick();
  }

  public blur(event: FocusEvent): void {
    if (this.disabled) {
      return;
    }

    this.blurred.emit(event);
  }

  public focus(event: FocusEvent): void {
    if (this.disabled) {
      return;
    }

    this.focussed.emit(event);
  }

  public space(event: Event): void {
    if (this.disabled) {
      return;
    }

    this.spaced.emit(event);
  }

  setFocus() {
    this.action.nativeElement.focus();
  }
}
