<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title>{{ 'verhaal.gebeurtenis-edit-page.title' | translate }} </ion-title>

    <ion-buttons slot="end">
      <parkour-header-action
        *ngIf="mainPageData$ | async"
        [label]="'verhaal.gebeurtenis-edit-page.gebeurtenis-save' | translate"
        [loading]="gebeurtenisForm()?.form()?.busy ?? false"
        form="gebeurtenis-edit-form"
        icon="disk"
        testId="save-gebeurtenis"
        type="submit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <parkour-gebeurtenis-form
        [initialState]="{
          id: data.gebeurtenis.id,
          titel: data.gebeurtenis.titel,
          omschrijving: data.gebeurtenis.omschrijving,
          startDatum: data.gebeurtenis.startDatum,
          gedeeldMet: data.gebeurtenis.gedeeldMet,
          deelMode: data.gebeurtenis.deelMode,
          fotoId: data.gebeurtenis.fotoId,
          thema: data.gebeurtenis.thema,
          bijlagen: data.gebeurtenis.bijlagen,
        }"
        [submitGebeurtenisForm]="getSubmitGeneratorForId(data.gebeurtenis.id)"
        [teamleden]="data.teamleden"
        formId="gebeurtenis-edit-form"
        [getExistingImage]="getExistingGebeurtenisImage"
        (existingFileDownload)="onExistingFileDownload($event, data.gebeurtenis)"
      />

      <div class="full-bleed container-space bg-n-200 pt-6 pb-8 mt-8">
        <div class="flex flex-col gap-y-8 container-1-col">
          <!-- TODO: UX Copy verhaal aan JSON toevoegen -->
          <div class="space-y-2">
            <h3>Stopzetten</h3>
          </div>

          <parkour-button
            (clicked)="onDeleteGebeurtenis()"
            class="block w-full"
            iconAfter="trash"
            variant="tertiary"
          >
            Gebeurtenis verwijderen
          </parkour-button>
        </div>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
