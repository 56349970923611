<article
  [attr.data-testid]="testId"
  [ngClass]="{
    'cursor-pointer card-hover-state-300': link,
  }"
  class="relative flex gap-4 p-4 bg-n-200 rounded"
>
  <parkour-image
    [alt]="image.alt"
    [src]="image.src"
    class="w-27"
    classes="flex rounded-sm aspect-square overflow-hidden"
  ></parkour-image>

  <div class="flex-1 grid grid-cols-1 grid-rows-[auto_1fr_auto] gap-1">
    @if (link) {
      <parkour-action
        [link]="link"
        classes="parkour-article-card__action focus-visible:outline-none"
      >
        <ng-container [ngTemplateOutlet]="titleContent"></ng-container>
      </parkour-action>
    } @else {
      <ng-container [ngTemplateOutlet]="titleContent"></ng-container>
    }

    <ng-template #titleContent>
      <h1 class="typo-body variant-body-fat line-clamp-3">{{ title }}</h1>
    </ng-template>

    <span class="typo-body variant-body-small text-n-600 row-start-3 row-end-5 line-clamp-1">{{
      getThemaLabelString(themes)
    }}</span>
  </div>

  <div class="flex items-center justify-start self-start gap-x-2">
    @if (isBookmarkable) {
      <parkour-bookmark-button
        (clicked)="onClickedBookmark($event)"
        [isActive]="isBookmarked"
        class="relative z-10"
      />
    }

    <ng-content></ng-content>
  </div>
</article>
