import { Component } from '@angular/core';
import {
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { CoreModule } from '../../../core/core.module';
import {
  ParkourButtonComponent,
  ParkourImageComponent,
  ParkourModalService,
  ParkourPageGradientComponent,
} from '@parkour/ui';
import { BiometricsService } from '../../../authentication/service/biometrics.service';
import { switchMap } from 'rxjs';

@Component({
  selector: 'parkour-biometrics-config-modal',
  standalone: true,
  imports: [
    IonTitle,
    IonHeader,
    IonToolbar,
    IonContent,
    IonItem,
    IonInput,
    CoreModule,
    ParkourButtonComponent,
    ParkourPageGradientComponent,
    ParkourImageComponent,
  ],
  templateUrl: './biometrics-config-modal.component.html',
})
export class BiometricsConfigModalComponent {
  protected success = false;

  constructor(
    private readonly biometricsService: BiometricsService,
    private readonly parkourModalService: ParkourModalService,
  ) {}

  onBiometricsActivateClick() {
    this.biometricsService.enableBiometrics().subscribe(() => (this.success = true));
  }

  onCancelClick() {
    this.biometricsService
      .disableBiometrics()
      .pipe(switchMap(() => this.parkourModalService.closeTopModal()))
      .subscribe();
  }

  onVolgendeStapClick() {
    this.parkourModalService.closeTopModal();
  }
}
