import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ContextId, ProfielId } from 'parkour-web-app-dto';

import { ContextOption } from '../../model/context-option';
import { asType } from '../../../utils';
import { MeldingenService } from '../../service/meldingen.service';
import { meldingenForWisselTeamPage } from '../../../meldingen/config';
import { combineLatest, map } from 'rxjs';
import { AsyncPipe, NgClass } from '@angular/common';
import {
  ParkourActionComponent,
  ParkourBadgeComponent,
  ParkourIconComponent,
  ParkourNewIndicatorComponent,
} from '@parkour/ui';
import { RolLabelWithDefaultPipe } from '../../../shared/pipes/rol-label-with-default.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { TeamSwitchCardContentComponent } from '../team-switch-card/team-switch-card-content.component';

@Component({
  standalone: true,
  selector: 'parkour-profiel-team-switch',
  templateUrl: './profiel-team-switch.component.html',
  imports: [
    NgClass,
    ParkourActionComponent,
    RolLabelWithDefaultPipe,
    TranslateModule,
    TeamSwitchCardContentComponent,
    ParkourBadgeComponent,
    ParkourNewIndicatorComponent,
    AsyncPipe,
    ParkourIconComponent,
  ],
})
export class ParkourProfielTeamSwitchComponent implements OnChanges {
  @Input({ required: true }) isJongere!: boolean;
  @Input({ required: true }) profielId!: ProfielId;
  @Input({ required: true }) contexten!: ContextOption[];
  @Input() public disabled = false;
  @Input({ required: true }) context!: ContextId | undefined;
  @Output() contextSelected = new EventEmitter<ContextOption>();

  constructor(private readonly meldingenService: MeldingenService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('context' in changes) {
      this.activeIndex = this.contexten.findIndex((obj) => obj.contextId === this.context);
    }
  }

  activeIndex = 0;

  onContextSelected(value: ContextOption) {
    if (!this.disabled) {
      this.contextSelected.emit(value);
    }
  }

  hasMeldingenInContext(contextId: ContextId) {
    return combineLatest([
      this.meldingenService.hasMeldingenInContextByType(contextId),
      this.hasMelingenOnBlockedPage(contextId),
    ]).pipe(
      map(
        ([meldingenInContext, hasMelingenOnBlockedPage]) =>
          meldingenInContext || hasMelingenOnBlockedPage,
      ),
    );
  }

  hasMelingenOnBlockedPage(contextId: ContextId) {
    return this.meldingenService
      .getMeldingenInCurrentContextByType(
        meldingenForWisselTeamPage.GEBLOKKEERD_TEAM_PAGE.allMeldingen,
      )
      .pipe(
        map(
          (meldingen) =>
            meldingen.filter((melding) => melding.metaData.jongereId === contextId).length > 0,
        ),
      );
  }

  protected readonly asType = asType;
}
