import { Component } from '@angular/core';
import { MeldingView } from '../../model/meldingen';
import { ActivatedRoute, Router } from '@angular/router';
import { ContextUrl } from '../../../shared/services/context.service';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import {
  ParkourBlockPlaceholderComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPageGradientComponent,
} from '@parkour/ui';
import { ParkourMeldingComponent } from '../../component/parkour-notification/parkour-melding.component';
import { ParkourUnsupportedMeldingComponent } from '../../component/parkour-unsupported-melding/parkour-unsupported-melding.component';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourPaginatedPage } from '../../../shared/parkour-paginated-page';
import { switchMap } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../shared/directives/parkour-refresh-directive';
import AuthService from '../../../authentication/service/auth.service';

@Component({
  standalone: true,
  templateUrl: './meldingen.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourLoadingSpinnerComponent,
    ParkourMeldingComponent,
    ParkourUnsupportedMeldingComponent,
    ParkourBlockPlaceholderComponent,
    TranslateModule,
    PageLayoutComponent,
    AsyncPipe,
    IonBackButton,
    IonRefresher,
    IonRefresherContent,
    IonContent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    ParkourRefreshDirective,
    IonInfiniteScroll,
  ],
})
export class MeldingenPage extends ParkourPaginatedPage<MeldingView> {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {
    super();
  }

  override retrievePage = (pageNumber: number) => {
    return this.authService
      .getAangemeldeUser$()
      .pipe(switchMap((user) => this.meldingenService.getMeldingen(user.profielId, pageNumber)));
  };

  override ionViewWillEnter(): void {
    super.ionViewWillEnter();

    const meldingId = this.activatedRoute.snapshot.queryParamMap.get('meldingId');

    if (meldingId) {
      this.router
        .navigate([], {
          queryParams: {
            meldingId: null,
          },
          queryParamsHandling: 'merge',
        })
        .then(() => this.meldingenService.navigateToMelding(meldingId));
    }
  }

  handleMeldingClicked(meldingId: string, link: string[] | ContextUrl) {
    this.meldingenService.markMeldingAsRead(meldingId).subscribe();

    if (!Array.isArray(link)) {
      this.router.navigate(link.path, {
        queryParams: { ...link.queryParams },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate(link);
    }
  }
}
