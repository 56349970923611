import { Component } from '@angular/core';
import { UserService } from '../../../user/service/user.service';
import { Observable } from 'rxjs';
import { ProfielService } from '../../../profiel/service/profiel.service';
import { IonContent, IonHeader, ViewWillEnter } from '@ionic/angular/standalone';
import { UserWithPersoonInfo } from '../../../user/model/user';
import { getProfielnaamWithoutFallback, MyProfielDto } from 'parkour-web-app-dto';
import { asResult, Result } from '../../../utils';
import { Router } from '@angular/router';
import {
  ParkourActionComponent,
  ParkourDividerComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { ProfielFotoComponent } from '../../../shared/components/profiel-foto/profiel-foto.component';
import { ProfessioneelIndicatorComponent } from '../../../shared/components/professioneel-indicator/professioneel-indicator.component';
import { ContentUnavailableCardComponent } from '../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { MyProfiel } from '../../../profiel/model/profiel';

@Component({
  standalone: true,
  templateUrl: './profiel-selecteer.page.html',
  imports: [
    ParkourPageImageComponent,
    AsyncPipe,
    NgIf,
    ParkourActionComponent,
    ParkourDividerComponent,
    ProfielFotoComponent,
    ProfessioneelIndicatorComponent,
    ContentUnavailableCardComponent,
    PageLayoutComponent,
    IonHeader,
    IonContent,
  ],
})
export class ProfielSelecteerPage implements ViewWillEnter {
  user$ = new Observable<UserWithPersoonInfo>();
  profielen$ = new Observable<Result<MyProfiel[]>>();
  protected readonly getProfielnaamWithoutFallback = getProfielnaamWithoutFallback;

  constructor(
    private readonly userService: UserService,
    private readonly profielService: ProfielService,
    private readonly router: Router,
  ) {}

  ionViewWillEnter(): void {
    this.user$ = this.userService.userWithExterneInfo$();
    this.profielen$ = asResult(this.profielService.getMyProfielen());
  }

  onProfielClick(profiel: MyProfielDto) {
    this.userService
      .setProfiel(profiel.id)
      .subscribe(() => this.router.navigateByUrl(this.profielService.redirectUrl));
  }
}
