import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { of } from 'rxjs';
import AuthService from '../../authentication/service/auth.service';
import { LoggingService } from '../logging.service';

export const PARKOUR_SCHEME = 'be.opgroeien.parkour';

@Injectable({
  providedIn: 'root',
})
export class DeeplinkingConfig {
  constructor(
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly authService: AuthService,
    private readonly loggingService: LoggingService,
  ) {}

  configureDeepLinking() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => this.routeToDeeplink(event.url));
    });
  }

  routeToDeeplink(url: string) {
    let slug: string | undefined;
    if (url.startsWith(PARKOUR_SCHEME)) {
      slug = url.split(/be\.opgroeien\.parkour:\//).pop();
    }
    if (url.startsWith('https://parkour')) {
      slug = url.split(/parkour.*\.opgroeien\.be/).pop();
    }
    this.loggingService.log(`Redirecting to slug ${slug}`);
    if (slug?.startsWith('/login/oauth2/code/idm')) {
      of(this.authService.handleAuthenticationCallbackForMobileApp(slug)).subscribe();
    } else if (slug?.startsWith('/app/login/oauth2/code/idm')) {
      of(
        this.authService.handleAuthenticationCallbackForMobileApp(slug?.replace('/app', '')),
      ).subscribe();
    } else if (slug) {
      this.router.navigateByUrl(slug);
    }
  }
}
