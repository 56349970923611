<form [formGroup]="doelFormGroup" class="flex flex-col gap-y-8">
  @if (initialState.thema) {
    <parkour-hero
      [image]="{
        sources: themaMapping[initialState.thema].largeImage.sources,
      }"
    >
      <ng-container *ngTemplateOutlet="heroContent"></ng-container>
    </parkour-hero>
  } @else {
    <parkour-hero
      [image]="{
        sources: {
          lg: {
            src: '/assets/doelen/background/lg/background_doelen_lg.jpg',
            fallback: '/assets/doelen/background/lg/background_doelen_lg.webp',
          },
          md: {
            src: '/assets/doelen/background/md/background_doelen_md.jpg',
            fallback: '/assets/doelen/background/md/background_doelen_md.webp',
          },
          default: {
            src: '/assets/doelen/background/default/background_doelen_default.jpg',
            fallback: '/assets/doelen/background/default/background_doelen_default.webp',
          },
        },
      }"
    >
      <ng-container *ngTemplateOutlet="heroContent"></ng-container>
    </parkour-hero>
  }

  <ng-template #heroContent>
    <div class="flex flex-col items-center gap-y-4 text-center container-1-col">
      <div class="flex flex-col gap-y-1 w-full">
        <parkour-form-field
          [inputFormControl]="doelFormGroup.controls.naam"
          [label]="'doelen.doel-toevoegen-page.form.naam' | translate"
          [required]="true"
          inputId="naam"
        >
          <parkour-input
            [required]="true"
            class="block w-full"
            formControlName="naam"
            inputId="naam"
          />
        </parkour-form-field>
      </div>

      <parkour-divider />
    </div>
  </ng-template>

  <div class="container-1-col">
    <h2 class="typo-h3">{{ 'doelen.doel-toevoegen-page.form.thema' | translate }}</h2>

    <ion-radio-group
      [formControl]="doelFormGroup.controls.thema"
      class="grid grid-cols-2 gap-2 mt-4"
    >
      @for (thema of themas; track thema) {
        <parkour-doel-thema [thema]="thema" class="block w-full" />
      }
    </ion-radio-group>
  </div>

  <div class="container-1-col">
    <parkour-form-field
      [inputFormControl]="doelFormGroup.controls.streefDatum"
      [label]="'doelen.doel-toevoegen-page.form.streefdatum' | translate"
      class="block"
      inputId="streefDatum"
    >
      <parkour-input
        [formControl]="doelFormGroup.controls.streefDatum"
        class="block"
        inputId="streefDatum"
        inputPlaceholder="'forms.date-format' | translate"
        type="date"
      >
      </parkour-input>
    </parkour-form-field>
  </div>
</form>
