import { Component, EventEmitter, Input, Output } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { NgIf } from "@angular/common";
import { ParkourLottiePlayerComponent } from "../parkour-lottie-player/parkour-lottie-player.component";

@Component({
  standalone: true,
  selector: "parkour-animation-modal",
  templateUrl: "./parkour-animation-modal.component.html",
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(":leave", [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
  imports: [NgIf, ParkourLottiePlayerComponent],
})
export class ParkourAnimationModalComponent {
  @Input({ required: true }) public isOpen!: boolean;
  @Input({ required: true }) public src!: string;

  @Output() completed = new EventEmitter<void>();

  onCompleted() {
    this.completed.emit();
  }
}
