<ul>
  <li *ngFor="let maandGroep of afsprakenPerMaand">
    <p class="bg-n-200 py-1 px-2 rounded w-fit mb-3 mt-3 typo-body variant-body-fat capitalize">
      {{ maandGroep.datum | date: 'LLLL yyyy' }}
    </p>

    <ul>
      <li *ngFor="let dagGroep of maandGroep.afspraken" class="flex gap-x-4">
        <div [ngClass]="{ 'opacity-60': hasFade }" class="flex flex-col text-center w-16">
          <p class="typo-body uppercase">{{ dagGroep.datum | date: 'EEEEEE' }}</p>

          <p class="typo-body variant-body-fat">{{ dagGroep.datum | date: 'dd' }}</p>
        </div>

        <ul class="space-y-2 flex-1 mb-2">
          <li
            *ngFor="let afspraak of dagGroep.afspraken"
            [ngClass]="{
              'bg-n-300':
                afspraak.type === 'VIDEOGESPREK' &&
                afspraak.gesprek?.status === 'GESTART' &&
                'GESTART' &&
                canJoinGesprek(afspraak),
            }"
            class="relative p-4 w-full flex flex-col gap-4 bg-n-200 card-hover-state-300 rounded"
          >
            @if (afspraak.type === 'VIDEOGESPREK') {
              <div
                *ngIf="getGespreksPartner(user, afspraak) as gespreksPartner"
                class="flex justify-between gap-x-4"
              >
                <div [ngClass]="{ 'opacity-60': hasFade }" class="space-y-2 flex-1">
                  <p>{{ afspraak.van | date: 'HH:mm' }} - {{ afspraak.tot | date: 'HH:mm' }}</p>

                  <p class="text-n-1000 typo-body variant-body-fat">
                    <parkour-action
                      [ariaLabel]="getAfspraakVideoGesprekAriaLabel(afspraak)"
                      [link]="['afspraak', afspraak.id, 'detail']"
                      classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 focus-visible:outline-none"
                    >
                      {{
                        'kalender.kalender-page.videogesprek-met'
                          | translate: { naam: gespreksPartner | profielnaam }
                      }}
                    </parkour-action>
                  </p>
                </div>

                <div class="relative">
                  <parkour-new-indicator [show]="ongelezenAfspraakIds.includes(afspraak.id)" />

                  <parkour-profiel-foto-badge
                    [alt]="false"
                    [ngClass]="{ 'opacity-60': hasFade }"
                    [profiel]="gespreksPartner"
                    [size]="'3'"
                  />
                </div>
              </div>
            } @else if (afspraak.type === 'BASIC') {
              <div class="flex justify-between gap-x-4">
                <div [ngClass]="{ 'opacity-60': hasFade }" class="space-y-2 flex-1">
                  <p>{{ afspraak.van | date: 'HH:mm' }} - {{ afspraak.tot | date: 'HH:mm' }}</p>

                  <p class="text-n-1000 typo-body variant-body-fat">
                    <parkour-action
                      [ariaLabel]="getAfspraakBasicAriaLabel(afspraak)"
                      [link]="['afspraak', afspraak.id, 'detail']"
                      classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 focus-visible:outline-none"
                    >
                      {{ afspraak.titel }}
                    </parkour-action>
                  </p>
                </div>

                <div class="relative">
                  <parkour-new-indicator [show]="ongelezenAfspraakIds.includes(afspraak.id)" />
                </div>
              </div>
            }

            @if (
              afspraak.type === 'VIDEOGESPREK' &&
              afspraak.gesprek?.status === 'GESTART' &&
              canJoinGesprek(afspraak)
            ) {
              <parkour-button
                (clicked)="onJoinAfspraak(afspraak)"
                class="mt-4 w-full relative z-50"
                iconAfter="video-camera"
              >
                {{ 'kalender.join-gesprek-button' | translate }}
              </parkour-button>
            }
          </li>
        </ul>
      </li>
    </ul>
  </li>
</ul>
