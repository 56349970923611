import { Component } from '@angular/core';
import { UserService } from '../../../../user/service/user.service';
import {
  ParkourBlockPlaceholderComponent,
  ParkourDividerComponent,
  ParkourHeroComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { User } from '../../../../user/model/user';
import { Observable } from 'rxjs';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';

@Component({
  standalone: true,
  templateUrl: './verhalen-no-team.page.html',
  imports: [
    ParkourPageImageComponent,
    AsyncPipe,
    NgIf,
    ParkourHeroComponent,
    ParkourDividerComponent,
    TranslateModule,
    ParkourBlockPlaceholderComponent,
    PageLayoutComponent,
    IonHeader,
    IonContent,
    PageContentDirective,
    ParkourRefreshDirective,
    IonRefresher,
    IonRefresherContent,
  ],
})
export class VerhalenNoTeamPage extends ParkourDataPage<User> {
  constructor(private readonly userService: UserService) {
    super();
  }

  override retrieveMainData(): Observable<User> {
    return this.userService.getCurrentUser$();
  }
}
