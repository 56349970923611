<ng-container *ngIf="(link || href) && !disabled; else actionButton">
  <a
    #action
    *ngIf="link; else externalLink"
    [attr.aria-label]="ariaLabel"
    [attr.data-testid]="testId"
    [attr.download]="download"
    [ngClass]="ngClasses"
    [queryParams]="queryParams"
    [routerLink]="link"
    [info]="routeInfo"
    [tabIndex]="tabIndex"
    class="block {{ classes }} {{ fillContainer ? fillContainerClass : '' }}"
  >
    <ng-container [ngTemplateOutlet]="actionContent"></ng-container>
  </a>

  <ng-template #externalLink>
    <a
      #action
      [attr.aria-label]="ariaLabel"
      [attr.data-testid]="testId"
      [attr.download]="download"
      [href]="href"
      [ngClass]="ngClasses"
      [rel]="'noopener noreferer'"
      [tabIndex]="tabIndex"
      [target]="'_blank'"
      class="block {{ classes }} {{ fillContainer ? fillContainerClass : '' }}"
    >
      <ng-container [ngTemplateOutlet]="actionContent"></ng-container>
    </a>
  </ng-template>
</ng-container>

<ng-template #actionButton>
  <button
    #action
    (blur)="blur($event)"
    (click)="click($event)"
    (focus)="focus($event)"
    (keydown.space)="space($event)"
    (keyup.space)="space($event)"
    [attr.aria-controls]="ariaControls"
    [attr.aria-current]="ariaCurrent"
    [attr.aria-disabled]="disabled"
    [attr.aria-expanded]="ariaExpanded"
    [attr.aria-label]="ariaLabel"
    [attr.aria-pressed]="ariaPressed"
    [attr.aria-selected]="ariaSelected"
    [attr.data-testid]="testId"
    [disabled]="disabled"
    [ngClass]="ngClasses"
    [tabIndex]="tabIndex"
    [type]="type"
    class="{{ classes }}"
  >
    <ng-container [ngTemplateOutlet]="actionContent"></ng-container>
  </button>
</ng-template>

<ng-template #actionContent>
  <ng-content></ng-content>
</ng-template>
