import { Injectable } from '@angular/core';
import { isParkourSecureStorageError, ParkourSecureStorage } from 'parkour-secure-storage';
import {
  catchError,
  filter,
  forkJoin,
  from,
  map,
  Observable,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { ParkourModalService } from '@parkour/ui';
import { HumanReadableError, TranslatedHumanReadableError } from '../../core/human-readable-error';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Device } from '@capacitor/device';
import { UserService } from '../../user/service/user.service';
import { UserWithPersoonInfo, UserWithProfiel } from '../../user/model/user';
import { BiometricsConfigModalComponent } from '../../shared/components/biometrics-config-modal/biometrics-config-modal.component';
import { isNativeApp } from '../../utils';
import { MatomoTracker } from 'ngx-matomo-client';
import { LocalStorageService } from '../../shared/services/local-storage.service';

const BIOMETRICS_STATUS_KEY = 'BIOMETRICS';
const BIOMETRICS_PERSOON_ID = 'BIOMETRICS_PERSOON_ID';

export type BiometricStatus = 'INGESTELD' | 'GEWEIGERD' | 'NIET_INGESTELD';
const BIOMETRICS_TOKEN_KEY = 'token';

const BIOMETRICS_POPUP_TITLE = 'Gebruik biometrie om aan te melden.';
const BIOMETRICS_POPUP_CANCEL = 'Annuleer';

@Injectable({
  providedIn: 'root',
})
export class BiometricsService {
  readonly TRACKER_CATEGORY = 'biometrics';

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly userService: UserService,
    private readonly parkourModalService: ParkourModalService,
    private readonly http: HttpClient,
    private readonly matomoTracker: MatomoTracker,
  ) {}

  configPopupOpen = false;

  public initialize() {
    this.userService
      .getCurrentUser$()
      .pipe(
        filter((user) => user instanceof UserWithProfiel),
        switchMap(() => this.getStatus()),
        filter((status) => status === 'NIET_INGESTELD'),
        switchMap(() => ParkourSecureStorage.getAvailabilityStatus()),
        filter((result) => result.status === 'ENROLLED'),
        filter(() => !this.configPopupOpen),
      )
      .subscribe(async () => {
        this.configPopupOpen = true;
        await this.parkourModalService.showFullscreenModal(BiometricsConfigModalComponent);
        this.configPopupOpen = false;
      });
  }

  private generateBiometricsToken(): Observable<string> {
    return from(Device.getId()).pipe(
      switchMap((deviceId) =>
        this.http.post(
          `${environment.API_BASE_URL}/api/auth/token`,
          {
            deviceId: deviceId.identifier,
          },
          {
            responseType: 'text',
          },
        ),
      ),
    );
  }

  enableBiometrics(): Observable<void> {
    this.matomoTracker.trackEvent(this.TRACKER_CATEGORY, 'enable');
    return from(ParkourSecureStorage.getAvailabilityStatus()).pipe(
      map((availabilityStatus) => {
        if (
          availabilityStatus &&
          (availabilityStatus.status === 'NOT_ENROLLED' ||
            availabilityStatus.status === 'HARDWARE_UNAVAILABLE')
        ) {
          throw new TranslatedHumanReadableError(
            'biometrics.configure-biometrics.not-enrolled-error',
          );
        }
      }),
      switchMap(this.generateAndSaveBiometricsToken()),
      switchMap(() => this.userService.userWithExterneInfo$()),
      switchMap((user) => {
        return from(
          Promise.all([
            this.localStorageService.setValue(BIOMETRICS_STATUS_KEY, 'INGESTELD'),
            this.localStorageService.setValue(BIOMETRICS_PERSOON_ID, user.persoonId),
          ]),
        );
      }),
      map(() => undefined),
    );
  }

  private generateAndSaveBiometricsToken() {
    return () =>
      this.generateBiometricsToken().pipe(
        switchMap((token) =>
          ParkourSecureStorage.save({
            key: BIOMETRICS_TOKEN_KEY,
            value: token,
            title: BIOMETRICS_POPUP_TITLE,
            cancelLabel: BIOMETRICS_POPUP_CANCEL,
          }),
        ),
        catchError((e: Error) => {
          return from(this.localStorageService.setValue(BIOMETRICS_STATUS_KEY, 'GEWEIGERD')).pipe(
            map(() => {
              throw this.mapError(e);
            }),
          );
        }),
      );
  }

  mapError(e: Error): HumanReadableError {
    if (isParkourSecureStorageError(e)) {
      switch (e.code) {
        case 'NO_LOCKSCREEN':
          return new TranslatedHumanReadableError(
            {
              headerKey: 'biometrics.configure-biometrics.no-lockscreen',
              messageKey: 'biometrics.configure-biometrics.no-lockscreen-message',
            },
            { cause: e },
          );
        default:
          return new TranslatedHumanReadableError('biometrics.configure-biometrics.failed', {
            cause: e,
          });
      }
    } else {
      return new TranslatedHumanReadableError('biometrics.configure-biometrics.failed', {
        cause: e,
      });
    }
  }

  clearBiometrics(): Observable<void> {
    return from(ParkourSecureStorage.remove({ key: BIOMETRICS_TOKEN_KEY })).pipe(
      switchMap(() =>
        Promise.all([
          this.localStorageService.removeValue(BIOMETRICS_STATUS_KEY),
          this.localStorageService.removeValue(BIOMETRICS_PERSOON_ID),
        ]),
      ),
      map(() => undefined),
    );
  }

  disableBiometrics(): Observable<void> {
    this.matomoTracker.trackEvent(this.TRACKER_CATEGORY, 'disable');
    return from(ParkourSecureStorage.remove({ key: BIOMETRICS_TOKEN_KEY })).pipe(
      switchMap(() => this.userService.userWithExterneInfo$()),
      switchMap((user) =>
        Promise.all([
          this.localStorageService.setValue(BIOMETRICS_STATUS_KEY, 'GEWEIGERD'),
          this.localStorageService.setValue(BIOMETRICS_PERSOON_ID, user.persoonId),
        ]),
      ),
      map(() => undefined),
    );
  }

  getStatus(): Observable<BiometricStatus> {
    if (!isNativeApp()) {
      return of('GEWEIGERD');
    }

    return forkJoin([
      this.userService.getCurrentUser$().pipe(take(1)),
      from(this.localStorageService.getValue(BIOMETRICS_PERSOON_ID)),
      from(this.localStorageService.getValue(BIOMETRICS_STATUS_KEY)),
    ]).pipe(
      map(([user, persoonId, statusString]) => {
        if (statusString === null) {
          return 'NIET_INGESTELD';
        } else if (user instanceof UserWithPersoonInfo && user.persoonId != persoonId) {
          return 'GEWEIGERD';
        } else {
          return statusString as BiometricStatus;
        }
      }),
    );
  }

  getBiometricsToken(): Observable<string> {
    return from(
      ParkourSecureStorage.get({
        key: BIOMETRICS_TOKEN_KEY,
        title: BIOMETRICS_POPUP_TITLE,
        cancelLabel: BIOMETRICS_POPUP_CANCEL,
      }),
    ).pipe(
      map((token) => {
        if (!token.value) {
          throw new Error('Biometrics token is null');
        }
        return token.value;
      }),
      catchError((err: Error) => {
        if (
          'code' in err &&
          (err.code === 'ITEM_NOT_FOUND' || err.code === 'BIOMETRICS_INVALIDATED')
        ) {
          return this.clearBiometrics().pipe(switchMap(() => throwError(() => err)));
        } else {
          throw err;
        }
      }),
    );
  }
}
