import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfielCreateService } from '../../../profiel/service/profiel-create.service';
import { IonContent, IonHeader, ViewWillEnter } from '@ionic/angular/standalone';
import {
  ParkourButtonComponent,
  ParkourCheckboxComponent,
  ParkourFormErrorMessageComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { FormsModule } from '@angular/forms';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';

@Component({
  standalone: true,
  templateUrl: './profiel-create-voorwaarden.page.html',
  imports: [
    ParkourPageImageComponent,
    ParkourCheckboxComponent,
    FormsModule,
    ParkourFormErrorMessageComponent,
    ParkourButtonComponent,
    PageLayoutComponent,
    IonHeader,
    IonContent,
  ],
})
export class ProfielCreateVoorwaardenPage implements ViewWillEnter {
  voorwaardenChecked = false;
  showVoorwaardenCheckedRequiredError = false;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly profileCreateService: ProfielCreateService,
  ) {}

  ionViewWillEnter(): void {
    this.voorwaardenChecked = false;
  }

  onNextStep() {
    if (!this.voorwaardenChecked) {
      this.showVoorwaardenCheckedRequiredError = true;
      return;
    }

    this.profileCreateService.setVoorwaardenChecked(this.voorwaardenChecked);
    this.router.navigate(['../', 'profielnaam'], { relativeTo: this.route });
  }
}
