import { Observable, ReplaySubject } from 'rxjs';

export type SimplePage<T> = {
  readonly content: T[];
  readonly last: boolean;
};

export type GetPageFunction<T> = (pageNumber: number) => Observable<SimplePage<T>>;

export type PaginatedData<T> = { content: T[]; complete: boolean };

export class Pagination<T> extends ReplaySubject<PaginatedData<T>> {
  private pageNumber = 0;
  private currentContent: T[] = [];
  private isComplete = false;

  constructor(
    private readonly getPage: GetPageFunction<T>,
    private readonly onError: () => void,
  ) {
    super(1);

    this.fetchNextPage(() => {});
  }

  public fetchNextPage(onComplete: () => void) {
    this.getPage(this.pageNumber).subscribe({
      next: (page) => {
        this.currentContent = [...this.currentContent, ...page.content];
        this.isComplete = page.last;
        this.pageNumber++;
        onComplete();
        this.next({ content: this.currentContent, complete: this.isComplete });
      },
      error: () => {
        onComplete();
        this.onError();
      },
    });
  }

  public replaceElementInContent(element: T, condition: (element: T) => boolean) {
    this.currentContent = this.currentContent.map((currentElement) => {
      if (condition(currentElement)) {
        return element;
      }
      return currentElement;
    });

    this.next({ content: this.currentContent, complete: this.isComplete });
  }

  public removeElementsFromCollection(condition: (element: T) => boolean) {
    this.currentContent = this.currentContent.filter(
      (currentElement) => !condition(currentElement),
    );

    this.next({ content: this.currentContent, complete: this.isComplete });
  }
}
