import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DoelUpsert } from '../../model/doel-upsert';
import { DoelFormService } from '../../service/doel-form.service';
import { DelenSectionGroup } from '../../../../delen/model/delen-form-group';
import { DelenFormService } from '../../../../delen/service/delen-form.service';
import { Thema } from 'parkour-web-app-dto';
import { TeamlidProfiel } from '../../../../team/model/teamlid.model';
import { TranslateModule } from '@ngx-translate/core';
import { DoelEditDataSectionComponent } from '../doel-edit-data-section/doel-edit-data-section.component';
import { ActiesSelectSectionComponent } from '../acties-select-section/acties-select-section.component';
import { DelenSectionComponent } from '../../../../delen/component/delen-section/delen-section.component';

export type GedeeldMetItemGroup = FormGroup<{
  gedeeldMet: FormControl<boolean>;
}>;

export type ActieItemGroup = FormGroup<{
  naam: FormControl<string>;
  voltooid: FormControl<boolean>;
  order: FormControl<number>;
}>;

export type ActieSectionGroup = FormGroup<{
  acties: FormArray<ActieItemGroup>;
}>;
export type DoelDataSectionGroup = FormGroup<{
  naam: FormControl<string>;
  omschrijving: FormControl<string | ''>;
  thema: FormControl<Thema | ''>;
  streefDatum: FormControl<string>;
}>;

@Component({
  standalone: true,
  selector: 'parkour-doel-edit-form',
  templateUrl: './doel-edit-form.component.html',
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    DoelEditDataSectionComponent,
    ActiesSelectSectionComponent,
    DelenSectionComponent,
  ],
})
export class DoelEditFormComponent implements OnInit {
  @Input() formId = '';
  @Input({ required: true }) teamleden!: Array<TeamlidProfiel>;
  @Input({ required: true }) busy = false;
  @Input({ required: true }) initialState!: Partial<DoelUpsert>;
  @Output() doelSubmitted = new EventEmitter<DoelUpsert>();

  doelForm!: FormGroup<{
    doelDataSection: DoelDataSectionGroup;
    actieSection: ActieSectionGroup;
    gedeeldMetSection: DelenSectionGroup;
  }>;

  constructor(
    private readonly doelFormService: DoelFormService,
    private readonly formBuilder: FormBuilder,
    private readonly delenFormService: DelenFormService,
  ) {}

  ngOnInit(): void {
    this.doelForm = this.formBuilder.nonNullable.group({
      doelDataSection: this.doelFormService.createDoelDataFormGroup(this.initialState),
      actieSection: this.doelFormService.createActieSectionFormGroup(this.initialState),
      gedeeldMetSection: this.delenFormService.createDelenFormGroup(
        this.teamleden,
        this.initialState,
      ),
    });
  }

  async onSubmit() {
    const formValues = this.doelForm.getRawValue();

    if (formValues.doelDataSection.thema === '') {
      return;
    }

    this.doelSubmitted.emit({
      naam: formValues.doelDataSection.naam,
      omschrijving: '',
      thema: formValues.doelDataSection.thema,
      streefDatum:
        formValues.doelDataSection.streefDatum !== ''
          ? new Date(formValues.doelDataSection.streefDatum)
          : undefined,
      acties: formValues.actieSection.acties.map((actie) => ({
        naam: actie.naam,
        voltooid: actie.voltooid,
        order: actie.order,
      })),
      deelMode: formValues.gedeeldMetSection.deelMode,
      gedeeldMet: this.teamleden
        .filter((teamLid, index) => formValues.gedeeldMetSection.gedeeldMet[index].gedeeldMet)
        .map((teamlid) => teamlid.id),
    });
  }
}
