import { Injectable } from '@angular/core';
import {
  catchError,
  distinctUntilChanged,
  firstValueFrom,
  from,
  map,
  NEVER,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  switchMap,
  take,
} from 'rxjs';
import { AnoniemeContextDto, ContextId, ProfielId } from 'parkour-web-app-dto';
import { Context, JongereContext, TeamlidContext } from '../model/context';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ParkourToastService } from '@parkour/ui';
import { RefreshService } from './refresh.service';
import { LoggingService } from '../../core/logging.service';

export type ContextUrlQueryParams = {
  [key: string]: boolean;
};

export type ContextUrl = {
  path: string[];
  queryParams?: ContextUrlQueryParams;
};

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private readonly contextId$ = new ReplaySubject<ContextId>(1);
  public readonly context$: Observable<Context> = this.refreshService.repeatOnRefresh(
    this.contextId$.pipe(
      distinctUntilChanged(),
      switchMap((id) => this.retrieveContext(id)),
    ),
  );

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly toastService: ParkourToastService,
    private readonly refreshService: RefreshService,
    private readonly loggingService: LoggingService,
  ) {
    this.contextId$.pipe(distinctUntilChanged()).subscribe((contextId) => {
      this.loggingService.log('Current context id:', contextId);
    });

    this.context$.subscribe((context) => {
      this.loggingService.log('Current context:', context);
    });
  }

  public getUrlInContext(
    contextId: ContextId,
    urlSegments: string[],
    queryParams?: ContextUrlQueryParams,
  ): ContextUrl {
    return {
      path: ['app', contextId, ...urlSegments],
      queryParams,
    };
  }

  public configureContextFromUrl(url: string): void {
    const segments = url.split('/');

    if (segments.length >= 3) {
      const id = segments[2];

      if (this.isContextId(id)) {
        this.contextId$.next(id);
        return;
      } else if (id === 'me') {
        return;
      } else {
        this.loggingService.log('Url does not contain contextId, context remains anoniem', url);
      }
    }

    this.contextId$.next('anoniem');
  }

  public teamlidContext$(): Observable<TeamlidContext> {
    return this.context$.pipe(
      map((context) => {
        if (context.type === 'teamlid') {
          return context;
        }

        throw new Error('Must be working in context of a teamlid to access this data');
      }),
      take(1),
      shareReplay(1),
    );
  }

  public contextIdOfJongere$(): Observable<ProfielId> {
    return this.context$.pipe(
      map((context) => {
        if (context.type === 'teamlid') {
          return context.jongereProfiel.id;
        }

        if (context.type === 'jongere') {
          return context.contextId;
        }

        throw new Error('Must be working in context of a teamlid to access this data');
      }),
      take(1),
    );
  }

  public contextWithJongere$(): Observable<JongereContext | TeamlidContext> {
    return this.context$.pipe(
      map((context) => {
        if (context.type === 'teamlid' || context.type === 'jongere') {
          return context;
        }

        throw new Error('Must be working in context of a jongere to access this data');
      }),
      take(1),
    );
  }

  async navigateToAbsoluteUrl(segments: string[]): Promise<boolean> {
    return this.router.navigate(await firstValueFrom(this.getAbsoluteUrl(segments)));
  }

  getAbsoluteUrl(segments: string[]): Observable<string[]> {
    return this.context$.pipe(
      take(1),
      map((context) => ['/app', context.contextId, ...segments]),
    );
  }

  async switchContext(contextId: ContextId, url?: string[]): Promise<boolean> {
    if (url) {
      const absoluteSegments = ['app', contextId, ...url];
      return this.router.navigate(absoluteSegments);
    } else {
      const segments = this.router.url.split('/');
      segments[2] = contextId;
      const sameUrl = segments.join('/');

      return this.router.navigateByUrl(sameUrl);
    }
  }

  private retrieveContext(contextId: ContextId): Observable<Context> {
    // If the context is anoniem, we don't need to make a request to the backend so parkour can be used offline
    if (contextId === 'anoniem') {
      return of<AnoniemeContextDto>({
        contextId: 'anoniem',
        type: 'anoniem',
      });
    }

    return this.httpClient
      .get<Context>(`${environment.API_BASE_URL}/api/context/${contextId}`)
      .pipe(
        catchError(() => {
          this.toastService.showToast({
            header: 'Jammer!',
            content: 'Er ging iets mis tijdens het ophalen van je teaminformatie.',
            error: true,
          });
          return from(this.switchContext('anoniem', ['home'])).pipe(
            switchMap(() => NEVER),
            catchError(() => NEVER),
          );
        }),
      );
  }

  public isContextId(value: string): value is ContextId {
    const regex = /^[a-z,0-9,-]{36,36}$/;

    return value === 'anoniem' || regex.test(value);
  }
}
