import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { switchMap, take, tap } from 'rxjs';
import { User, UserWithProfiel } from '../../../user/model/user';
import { UserService } from '../../../user/service/user.service';
import { ProbleemService } from '../../service/probleem.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourFormFieldComponent,
  ParkourInfoCardComponent,
  ParkourInputComponent,
  ParkourPageGradientComponent,
  ParkourSelectComponent,
  ParkourTextareaComponent,
  ParkourToastService,
} from '@parkour/ui';
import { ProbleemType } from 'parkour-web-app-dto';
import { CreateDefaultProbleem } from '../../model/probleem';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { ParkourFormComponent } from '../../../shared/components/parkour-form/parkour-form.component';
import { RawValueOfFormGroup } from '../../../shared/components/parkour-form/parkour-form.types';

type AangemeldProbleemForm = FormGroup<{
  type: FormControl<string>;
  beschrijving: FormControl<string>;
}>;

type AnoniemProbleemForm = FormGroup<{
  type: FormControl<string>;
  beschrijving: FormControl<string>;
  voornaam: FormControl<string>;
  naam: FormControl<string>;
  email: FormControl<string>;
}>;

@Component({
  standalone: true,
  templateUrl: './probleem-add.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourPageGradientComponent,
    ParkourDividerComponent,
    ReactiveFormsModule,
    ParkourFormFieldComponent,
    ParkourSelectComponent,
    ParkourTextareaComponent,
    ParkourInfoCardComponent,
    ParkourInputComponent,
    ParkourButtonComponent,
    TranslateModule,
    AsyncPipe,
    PageLayoutComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    ParkourFormComponent,
  ],
})
export class ProbleemAddPage implements ViewWillEnter {
  user$ = this.userService.getCurrentUser$();
  formLoaded = false;
  probleemForm!:
    | {
        type: 'AANGEMELD';
        form: AangemeldProbleemForm;
        userWithProfiel: UserWithProfiel;
      }
    | {
        type: 'ANONIEM';
        form: AnoniemProbleemForm;
      };

  typeOptions = [
    {
      value: 'FOUT_GEBRUIKER',
      label: 'Fout gedrag gebruiker',
    },
    {
      value: 'FOUT_PARKOUR',
      label: 'Fout in werking PARKOUR',
    },
    {
      value: 'OPMERKING',
      label: 'Vraag of klacht',
    },
  ];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly probleemService: ProbleemService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly toastService: ParkourToastService,
  ) {}

  ionViewWillEnter(): void {
    this.user$.pipe(take(1)).subscribe((user) => {
      if (user instanceof UserWithProfiel) {
        const form = this.formBuilder.group({
          type: this.formBuilder.nonNullable.control('FOUT_GEBRUIKER', Validators.required),
          beschrijving: this.formBuilder.nonNullable.control('', Validators.required),
        });
        this.probleemForm = {
          type: 'AANGEMELD',
          form,
          userWithProfiel: user,
        };
      } else {
        const form = this.formBuilder.group({
          type: this.formBuilder.nonNullable.control('FOUT_GEBRUIKER', Validators.required),
          beschrijving: this.formBuilder.nonNullable.control('', Validators.required),
          voornaam: this.formBuilder.nonNullable.control('', Validators.required),
          naam: this.formBuilder.nonNullable.control('', Validators.required),
          email: this.formBuilder.nonNullable.control('', Validators.required),
        });

        this.probleemForm = {
          type: 'ANONIEM',
          form,
        };
      }
      this.formLoaded = true;
    });
  }

  hasProfiel(user: User): user is UserWithProfiel {
    return user instanceof UserWithProfiel;
  }

  isFormDirty() {
    return this.probleemForm.form.dirty || false;
  }

  submitProbleem = (
    formValue: RawValueOfFormGroup<AnoniemProbleemForm | AangemeldProbleemForm>,
  ) => {
    if (this.probleemForm.type === 'ANONIEM') {
      return this.submitAnoniemProbleem(formValue as RawValueOfFormGroup<AnoniemProbleemForm>);
    } else {
      return this.submitAangemeldProbleem(formValue as RawValueOfFormGroup<AangemeldProbleemForm>);
    }
  };

  submitAnoniemProbleem = (formValue: RawValueOfFormGroup<AnoniemProbleemForm>) =>
    this.addProbleem({
      type: formValue.type as ProbleemType,
      beschrijving: formValue.beschrijving,
      rapporteerder: {
        type: 'ANONIEM',
        naam: formValue.naam,
        voornaam: formValue.voornaam,
        email: formValue.email,
      },
    });

  submitAangemeldProbleem = (formValue: RawValueOfFormGroup<AangemeldProbleemForm>) =>
    this.userService.userWithProfiel$().pipe(
      switchMap((user) =>
        this.addProbleem({
          type: formValue.type as ProbleemType,
          beschrijving: formValue.beschrijving,
          rapporteerder: {
            type: 'AANGEMELD',
            profielId: user.profiel.id,
          },
        }),
      ),
    );

  private addProbleem(createProbleem: CreateDefaultProbleem) {
    return this.probleemService.addProbleem(createProbleem).pipe(
      tap(() => this.toastService.showToast({ header: 'Je melding is verzonden naar Opgroeien.' })),
      tap(() => this.router.navigate(['..'], { relativeTo: this.route })),
    );
  }
}
