import { RoleDto } from 'parkour-web-app-dto';
import { getProfielnaam } from '../../profiel/model/profiel-in-team';
import { MyProfiel } from '../../profiel/model/profiel';

export type UserRole = RoleDto;
export abstract class User {
  abstract isIngelogd(): boolean;

  isProfessioneel(): boolean {
    return false;
  }
}

export abstract class UserWithPersoonInfo extends User {
  constructor(
    public readonly persoonId: string,
    public readonly voornaam: string,
    public readonly naam: string,
  ) {
    super();
  }

  isIngelogd() {
    return true;
  }

  getVolledigeNaam(): string {
    return `${this.voornaam} ${this.naam}`;
  }

  getVoornaam(): string {
    return this.voornaam;
  }

  getExternalId(): string {
    return this.persoonId;
  }
}

export function validateUserWithExternalInfo(
  user: User | undefined,
  errorMessage = 'User must be a logged in using ACM/IDM',
): UserWithPersoonInfo {
  if (!(user instanceof UserWithPersoonInfo)) {
    throw new Error(errorMessage);
  }

  return user;
}

export abstract class UserWithProfiel extends UserWithPersoonInfo {
  protected constructor(
    persoonId: string,
    public readonly profiel: MyProfiel,
  ) {
    super(persoonId, profiel.voornaam, profiel.naam);
  }

  override isProfessioneel(): boolean {
    return this.profiel.profielType === 'PROFESSIONEEL';
  }

  getProfielNaam(): string {
    return getProfielnaam(this.profiel);
  }
}

export function validateUserWithProfiel(
  user: User,
  errorMessage = 'Gebruiker heeft geen profiel',
): UserWithProfiel {
  if (!(user instanceof UserWithProfiel)) {
    throw new Error(errorMessage);
  }

  return user;
}
