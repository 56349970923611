import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { UserService } from '../../user/service/user.service';
import { Router } from '@angular/router';
import { Capacitor, CapacitorHttp, HttpParams, HttpResponse } from '@capacitor/core';
import { catchError, finalize, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { Browser } from '@capacitor/browser';
import { LoggingService } from '../../core/logging.service';
import { BiometricsService } from './biometrics.service';
import { asType, isNativeApp } from '../../utils';
import { HttpClient } from '@angular/common/http';
import { LoginWithTokenDto, ProfielId, SessionType } from 'parkour-web-app-dto';
import { Device } from '@capacitor/device';
import { ParkourModalService, ParkourPopupService } from '@parkour/ui';
import { TranslateService } from '@ngx-translate/core';
import { ParkourError } from '../../core/parkour-error';
import { MatomoTracker } from 'ngx-matomo-client';
import { AcmIdmLoginModalComponent } from '../acm-idm-login-modal/acm-idm-login-modal.component';

type RedirectConfig = { redirectUrl?: string; redirectProfielId?: ProfielId };

@Injectable({
  providedIn: 'root',
})
export default class AuthService {
  readonly TRACKER_CATEGORY = 'authentication';

  constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly loggingService: LoggingService,
    private readonly biometricsService: BiometricsService,
    private readonly popupService: ParkourPopupService,
    private readonly translateService: TranslateService,
    private readonly http: HttpClient,
    private readonly ngZone: NgZone,
    private readonly matomoTracker: MatomoTracker,
    private readonly modalService: ParkourModalService,
  ) {}

  public login(redirectConfig: RedirectConfig): Observable<void> {
    return this.biometricsService.getStatus().pipe(
      map((status) => status === 'INGESTELD'),
      switchMap((biometricsEnabled) => {
        if (biometricsEnabled) {
          return this.startBiometricsFlow(redirectConfig);
        } else {
          return this.startAcmIdmAuthFlow(redirectConfig);
        }
      }),
    );
  }

  private startBiometricsFlow(redirectConfig: RedirectConfig): Observable<void> {
    this.matomoTracker.trackEvent(this.TRACKER_CATEGORY, 'login-start', 'biometric');
    return this.biometricsService.getBiometricsToken().pipe(
      switchMap((token) => this.loginWithToken(token, redirectConfig.redirectProfielId)),
      tap(() => this.onLoginSuccess(redirectConfig.redirectUrl)),
      catchError((err: unknown) => this.onBiometricsError(redirectConfig, err)),
      map(() => undefined),
    );
  }

  private onBiometricsError(redirectConfig: RedirectConfig, err: unknown): Observable<void> {
    this.matomoTracker.trackEvent(this.TRACKER_CATEGORY, 'login-failure', 'biometric');
    const initial$ = this.clearBiometricsTokenIfInvalid(err);

    return initial$.pipe(
      switchMap(() =>
        this.translateService.get([
          'biometrics.login-error.title',
          'biometrics.login-error.description',
        ]),
      ),
      switchMap((translations) =>
        this.popupService.showPopup({
          icon: 'logout',
          title: translations['biometrics.login-error.title'],
          description: translations['biometrics.login-error.description'],
        }),
      ),
      switchMap((result) => {
        if (result) {
          return this.startAcmIdmAuthFlow(redirectConfig);
        } else {
          return this.router.navigateByUrl('/', { info: { overridePopups: true } });
        }
      }),
      map(() => undefined),
    );
  }

  private clearBiometricsTokenIfInvalid(err: unknown) {
    if (err instanceof ParkourError && err.errorType === 'login-ongeldige-biometrics-token') {
      return this.biometricsService.clearBiometrics();
    } else {
      return of(undefined);
    }
  }

  private startAcmIdmAuthFlow(redirectConfig: RedirectConfig): Observable<void> {
    this.matomoTracker.trackEvent(this.TRACKER_CATEGORY, 'login-start', 'acm-idm');
    const redirectUrl = encodeURIComponent(
      redirectConfig.redirectUrl ??
        this.router.getCurrentNavigation()?.initialUrl.toString() ??
        this.router.url,
    );
    if (isNativeApp()) {
      this.openAcmIdmWaitingModal(redirectUrl, redirectConfig.redirectProfielId);
    }
    return this.continueAcmIdmAuthFlow(redirectUrl, redirectConfig.redirectProfielId);
  }

  private continueAcmIdmAuthFlow(redirectUrl: string, redirectProfielId?: ProfielId) {
    switch (Capacitor.getPlatform()) {
      case 'ios':
        return from(this.startAuthenticationFlowForIOS(redirectUrl, redirectProfielId));
      case 'android':
        return from(this.startAuthenticationFlowForAndroid(redirectUrl, redirectProfielId));
      default: {
        let href = `${environment.API_BASE_URL}/login?redirect=${redirectUrl}`;
        if (redirectProfielId) {
          href = `${environment.API_BASE_URL}/login?redirect=${redirectUrl}&redirectProfielId=${redirectProfielId}`;
        }
        window.location.href = href;
        return of(undefined);
      }
    }
  }

  private async startAuthenticationFlowForIOS(redirectUrl: string, redirectProfielId?: ProfielId) {
    try {
      let params: HttpParams;
      if (redirectProfielId) {
        params = { redirect: redirectUrl, native: 'true', redirectProfielId: redirectProfielId };
      } else {
        params = { redirect: redirectUrl, native: 'true' };
      }
      const loginResponse = await CapacitorHttp.get({
        url: `${environment.API_BASE_URL}/login`,
        params,
        disableRedirects: true,
      });
      if (loginResponse.status === 302) {
        const location = this.getLocationFromLoginResponse(loginResponse);
        window.open(location);
      } else {
        throw Error(`Expected redirect but got ${loginResponse.status}`);
      }
    } catch (error) {
      this.loggingService.error(`Login failed: ${JSON.stringify(error)}`);
    }
  }

  private async startAuthenticationFlowForAndroid(
    redirectUrl: string,
    redirectProfielId?: ProfielId,
  ) {
    try {
      let params: HttpParams;
      if (redirectProfielId) {
        params = { redirect: redirectUrl, native: 'true', redirectProfielId: redirectProfielId };
      } else {
        params = { redirect: redirectUrl, native: 'true' };
      }
      const loginResponse = await CapacitorHttp.get({
        url: `${environment.API_BASE_URL}/login?mobile`,
        params,
        disableRedirects: true,
      });
      if (loginResponse.status === 302) {
        const locationHeader = this.getLocationFromLoginResponse(loginResponse);
        window.open(locationHeader);
      } else {
        throw Error(`Expected redirect but got ${loginResponse.status}`);
      }
    } catch (error) {
      this.loggingService.error(`Login failed: ${JSON.stringify(error)}`);
    }
  }

  private getLocationFromLoginResponse(loginResponse: HttpResponse) {
    if (loginResponse.headers['location']) {
      return loginResponse.headers['location'];
    } else if (loginResponse.headers['Location']) {
      return loginResponse.headers['Location'];
    } else {
      throw Error('RedirectUrl cannot be empty');
    }
  }

  public async handleAuthenticationCallbackForMobileApp(callbackSlug: string) {
    try {
      let callbackUrl = `${environment.API_BASE_URL}${callbackSlug}`;
      if (Capacitor.getPlatform() === 'android') {
        callbackUrl = `${callbackUrl}&mobile`;
      }
      const authenticateResponse = await CapacitorHttp.get({
        url: callbackUrl,
        disableRedirects: true,
      });
      const location = this.getLocationFromLoginResponse(authenticateResponse);
      if (authenticateResponse.status === 302 && location) {
        this.ngZone.run(() => {
          this.onLoginSuccess(location);
        });
      } else {
        throw Error(`Expected redirect but got ${authenticateResponse.status} instead`);
      }
    } catch (error) {
      this.loggingService.error(`Login failed: ${JSON.stringify(error)}`);
      await this.router.navigateByUrl('/login-failed');
    }
  }

  private onLoginSuccess(redirectUrl?: string) {
    this.userService.refeshUser();
    this.userService
      .waitUntilUserMeetsRequirements((user) => user.isIngelogd())
      .subscribe(() => {
        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl);
        }
      });
  }

  getSessionType(): Observable<SessionType> {
    return this.http
      .get(`${environment.API_BASE_URL}/api/auth/session/type`, { responseType: 'text' })
      .pipe(map((response) => response as SessionType));
  }

  public logout(): Observable<void> {
    return this.getSessionType().pipe(
      switchMap((sessionType) => {
        if (sessionType === 'web' || sessionType === 'native_acm_idm') {
          switch (Capacitor.getPlatform()) {
            case 'ios':
              return from(this.logoutOfIOS());
            case 'android':
              return from(this.logoutOfAndroid());
            default: {
              window.location.href = `${environment.API_BASE_URL}/logout`;
              return of(undefined);
            }
          }
        } else if (sessionType === 'native_biometrics') {
          return this.http.delete(`${environment.API_BASE_URL}/api/auth/session`).pipe(
            switchMap(() => {
              this.userService.refreshUserAfterLogout();
              return this.userService
                .waitUntilUserMeetsRequirements((user) => !user.isIngelogd())
                .pipe(finalize(() => this.router.navigateByUrl('/')));
            }),
            map(() => undefined),
          );
        } else {
          return of(undefined);
        }
      }),
    );
  }

  private async logoutOfIOS() {
    try {
      const logoutResponse = await CapacitorHttp.get({
        url: `${environment.API_BASE_URL}/logout`,
        disableRedirects: true,
      });
      if (logoutResponse.status === 302) {
        const location = this.getLocationFromLoginResponse(logoutResponse);
        window.open(location);
      } else {
        throw Error(`Expected redirect, but got ${logoutResponse.status} instead`);
      }
    } catch (error) {
      this.loggingService.error(`Logout failed: ${JSON.stringify(error)}`);
    } finally {
      this.userService.refreshUserAfterLogout();
    }
  }

  private async logoutOfAndroid() {
    try {
      const logoutResponse = await CapacitorHttp.get({
        url: `${environment.API_BASE_URL}/logout?mobile`,
        disableRedirects: true,
      });
      if (logoutResponse.status === 302) {
        const location = this.getLocationFromLoginResponse(logoutResponse);
        await Browser.open({ url: location });
      } else {
        throw Error(`Expected redirect, but got ${logoutResponse.status} instead`);
      }
    } catch (error) {
      this.loggingService.error(`Logout failed: ${JSON.stringify(error)}`);
    } finally {
      this.userService.refreshUserAfterLogout();
    }
  }

  private loginWithToken(token: string, redirectProfielId?: ProfielId) {
    let params: HttpParams = {};
    if (redirectProfielId) {
      params = { redirectProfielId: redirectProfielId };
    }
    return from(Device.getId()).pipe(
      switchMap((deviceId) =>
        this.http.post(
          `${environment.API_BASE_URL}/api/auth/session`,
          asType<LoginWithTokenDto>({
            token,
            deviceId: deviceId.identifier,
          }),
          { params },
        ),
      ),
    );
  }

  loginAfterResume(profielId: ProfielId | undefined): Observable<void> {
    return this.biometricsService.getStatus().pipe(
      map((status) => status === 'INGESTELD'),
      switchMap((biometricsEnabled) => {
        if (biometricsEnabled) {
          return this.startBiometricsFlow({ redirectProfielId: profielId });
        } else {
          return this.openTimeoutPopup().pipe(
            switchMap((loginAgain) => {
              if (loginAgain) {
                return this.startAcmIdmAuthFlow({ redirectProfielId: profielId });
              } else {
                return from(this.navigateToHomePage());
              }
            }),
          );
        }
      }),
    );
  }

  private async navigateToHomePage() {
    await this.router.navigateByUrl('/', {
      onSameUrlNavigation: 'reload',
      info: { overridePopups: true },
    });
  }

  openTimeoutPopup(): Observable<boolean> {
    return this.translateService
      .get(['afmelden.expired-title', 'afmelden.expired-description'])
      .pipe(
        switchMap((translations) =>
          this.popupService.showPopup({
            icon: 'logout',
            title: translations['afmelden.expired-title'],
            description: translations['afmelden.expired-description'],
          }),
        ),
      );
  }

  private async openAcmIdmWaitingModal(redirectUrl: string, redirectProfielId?: ProfielId) {
    await this.modalService.showFullscreenModal(AcmIdmLoginModalComponent, {
      aanmeldenClickCallback: () => this.continueAcmIdmAuthFlow(redirectUrl, redirectProfielId),
    });
  }
}
