import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MyProfiel, PrivateInfo, Profiel } from '../../model/profiel';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ActiefTeamlidDto,
  ExterneHoedanigheid,
  Rol,
  rollen,
  UitgenodigdTeamlidDto,
} from 'parkour-web-app-dto';
import { getRolLabel } from '../../rol-labels';
import { Uitnodiging } from '../../../uitnodiging/model/uitnodiging';
import {
  ParkourButtonComponent,
  ParkourCardComponent,
  ParkourDividerComponent,
  ParkourFormFieldComponent,
  ParkourIconComponent,
  ParkourPageImageComponent,
  ParkourSelectComponent,
} from '@parkour/ui';
import { ProfielFotoPipe } from '../../../shared/pipes/profiel-foto.pipe';
import { AsyncPipe, DatePipe, NgClass } from '@angular/common';
import { ProfielFotoComponent } from '../../../shared/components/profiel-foto/profiel-foto.component';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { ProfielSelectComponent } from '../profiel-select/profiel-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { RolLabelPipe } from '../../../shared/pipes/rol-label.pipe';
import { HttpLinkPipe } from '../../../shared/pipes/http-link.pipe';
import { ProfielQrCodeButtonComponent } from '../profiel-qr-code-button/profiel-qr-code-button.component';
import { ProfileDetailsListItemComponent } from '../profile-details-list-item/profile-details-list-item.component';
import { checkIfActiveDate } from '../../../utils';
import { LinkifyPipe } from '../../../bericht/pipes/linkify.pipe';

export type ProfielDetailsMode =
  | {
      type: 'JONGERE';
      profielen: Profiel[];
      externeHoedanigheid: ExterneHoedanigheid;
    }
  | {
      type: 'TEAMEIGENAAR';
    }
  | {
      type: 'TEAMLID';
      initialRol: Rol;
      editable: boolean;
    };

@Component({
  standalone: true,
  selector: 'parkour-profiel-details',
  templateUrl: './profiel-details.component.html',
  imports: [
    ParkourPageImageComponent,
    ProfielFotoPipe,
    AsyncPipe,
    ProfielFotoComponent,
    ProfielnaamPipe,
    ProfielSelectComponent,
    FormsModule,
    ParkourFormFieldComponent,
    TranslateModule,
    ParkourSelectComponent,
    RolLabelPipe,
    ParkourCardComponent,
    ParkourDividerComponent,
    ParkourButtonComponent,
    HttpLinkPipe,
    NgClass,
    ParkourIconComponent,
    ProfielQrCodeButtonComponent,
    ReactiveFormsModule,
    ProfileDetailsListItemComponent,
    DatePipe,
    LinkifyPipe,
  ],
})
export class ProfielDetailsComponent implements OnInit {
  @Input({ required: true }) profiel!: MyProfiel | UitgenodigdTeamlidDto | ActiefTeamlidDto;
  @Input({ required: true }) mode!: ProfielDetailsMode;
  @Input() privateInfo: PrivateInfo | undefined;
  @Input() uitnodiging?: Uitnodiging | undefined;

  @Output() formSubmit = new EventEmitter<Rol>();
  @Output() profielSelect = new EventEmitter<Profiel>();
  @Output() createPersoonlijkProfiel = new EventEmitter<void>();
  @Output() createProfessioneelProfiel = new EventEmitter<void>();

  @Output() verwijderUitnodiging = new EventEmitter<void>();
  @Output() bevestigUitnodiging = new EventEmitter<void>();

  detailsEditForm?: FormGroup<{
    rol: FormControl<Rol>;
  }>;
  rolOptions = Object.entries(rollen).map(([, value]) => ({
    value: value,
    label: getRolLabel(value),
  }));
  protected readonly checkIfActiveDate = checkIfActiveDate;

  constructor(private readonly formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.mode.type === 'TEAMLID' && this.mode.editable) {
      this.detailsEditForm = this.formBuilder.nonNullable.group({
        rol: this.formBuilder.nonNullable.control<Rol>(this.mode.initialRol),
      });
    }
  }

  onDetailsEditSubmit() {
    if (!this.detailsEditForm) {
      throw new Error('Form not loaded');
    }

    this.formSubmit.emit(this.detailsEditForm.value.rol);
  }

  isFormInvalid() {
    return this.detailsEditForm?.invalid;
  }

  getVisualLink(link: string) {
    return link.replace('www.', '').replace('http://', '').replace('https://', '');
  }

  onVerwijderUitnodiging() {
    this.verwijderUitnodiging.emit();
  }

  onBevestigUitnodiging() {
    this.bevestigUitnodiging.emit();
  }
}
