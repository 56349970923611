<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-image
      classes="absolute top-0 left-0 z-0"
      src="/assets/doelen/background/background_doelen_blur.jpg"
    />

    <div
      *ngIf="profiel$ | async as profiel"
      class="relative z-10 pt-4 pb-8 container-space flex flex-col gap-y-8"
    >
      <parkour-hero
        [image]="{
          sources: {
            lg: {
              src: '/assets/doelen/background/lg/background_doelen_lg.webp',
              fallback: '/assets/doelen/background/lg/background_doelen_lg.jpg',
            },
            md: {
              src: '/assets/doelen/background/md/background_doelen_md.webp',
              fallback: '/assets/doelen/background/md/background_doelen_md.jpg',
            },
            default: {
              src: '/assets/doelen/background/default/background_doelen_default.webp',
              fallback: '/assets/doelen/background/default/background_doelen_default.jpg',
            },
          },
        }"
      >
        <div class="flex flex-col items-center gap-y-4 text-center container-1-col">
          <h1>{{ 'doelen.doelen-page.title' | translate }}</h1>

          <parkour-divider />
        </div>
      </parkour-hero>

      <div class="container-1-col flex flex-col gap-y-8">
        <p class="text-center">
          @if (profiel.profielType === 'PROFESSIONEEL') {
            {{ 'doelen.doelen-page.no-team-text-professioneel' | translate }}
          } @else {
            {{ 'doelen.doelen-page.no-team-text' | translate }}
          }
        </p>

        <parkour-doel-placeholder />
      </div>
    </div>
  </parkour-page-layout>
</ion-content>
