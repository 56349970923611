import { Component, OnInit } from '@angular/core';
import {
  ParkourDividerComponent,
  ParkourImageComponent,
  ParkourLoadingSpinnerComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { IonContent, IonHeader } from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../shared/components/page-layout/page-layout.component';
import AuthService from '../service/auth.service';

@Component({
  standalone: true,
  templateUrl: './logout.page.html',
  imports: [
    IonHeader,
    IonContent,
    PageLayoutComponent,
    ParkourImageComponent,
    TranslateModule,
    ParkourDividerComponent,
    ParkourLoadingSpinnerComponent,
  ],
})
export class LogoutPage implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.startLogoutFlow();
  }

  startLogoutFlow() {
    this.authService.logout().subscribe();
  }
}
