type LoginErrorType = 'ongeldige-hoedanigheid' | 'technische-fout';
type ErrorConfig = {
  titel: string;
  description: string;
};

const errorMappings: Record<LoginErrorType, ErrorConfig> = {
  'ongeldige-hoedanigheid': {
    titel: 'Ongeldige hoedanigheid',
    description: 'De hoedanigheid is niet geldig.',
  },
  'technische-fout': {
    titel: 'Technische fout',
    description: 'Er is een technische fout opgetreden.',
  },
};

function isLoginErrorType(error: string | null): error is LoginErrorType {
  if (error === null) {
    return false;
  }

  return error in errorMappings;
}

export function mapLoginError(error: string | null): ErrorConfig {
  if (!isLoginErrorType(error)) {
    return errorMappings['technische-fout'];
  }
  return errorMappings[error];
}
