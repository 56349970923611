<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'profiel.meldingen-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <parkour-page-gradient />

    <div class="relative z-10 py-8 container container-space space-y-8">
      @if (loading) {
        <parkour-loading-spinner />
      }
      @if (paginatedData$ | async; as meldingenData) {
        @if (meldingenData.content.length > 0 || !meldingenData.complete) {
          <ul class="space-y-4 container-1-col" role="feed">
            @for (melding of meldingenData.content; track melding.id) {
              <li>
                @switch (melding.type) {
                  @case ('supported') {
                    <parkour-melding
                      (meldingClicked)="handleMeldingClicked(melding.id, melding.link)"
                      [meldingView]="melding"
                    />
                  }
                  @case ('unsupported') {
                    <parkour-unsuppored-melding [meldingView]="melding" />
                  }
                }
              </li>
            }
          </ul>
          <ion-infinite-scroll
            (ionInfinite)="fetchNextPage($event)"
            [disabled]="meldingenData.complete"
            [threshold]="'0'"
          >
            <div class="infinite-scroll-content">
              <parkour-loading-spinner />
            </div>
          </ion-infinite-scroll>
        } @else {
          <div class="container-1-col space-y-8">
            <p class="text-center">{{ 'meldingen.overview-page.geen-meldingen' | translate }}</p>
            <div>
              <parkour-block-placeholder
                [sources]="{
                  default: {
                    src: '/assets/meldingen/placeholder/placeholder_meldingen_default.svg',
                  },
                  md: {
                    src: '/assets/meldingen/placeholder/placeholder_meldingen_md.svg',
                  },
                }"
                alt=""
                class="block"
              />
            </div>
          </div>
        }
      }
    </div>
  </parkour-page-layout>
</ion-content>
