import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  AfspraakId,
  AfspraakInsertDto,
  AfspraakUpdateDto,
  AfspraakViewDetail,
  VideogesprekId,
} from 'parkour-web-app-dto';
import { asType, stripNullProperties } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class KalenderService {
  constructor(private readonly http: HttpClient) {}

  getAfspraken(): Observable<AfspraakViewDetail[]> {
    return this.http.get<AfspraakViewDetail[]>(`${environment.API_BASE_URL}/api/afspraken`);
  }

  addAfspraak(afspraak: AfspraakInsertDto): Observable<void> {
    return this.http.post<void>(
      `${environment.API_BASE_URL}/api/afspraken`,
      asType<AfspraakInsertDto>(stripNullProperties(afspraak)),
    );
  }

  updateAfspraak(afspraak: AfspraakUpdateDto): Observable<void> {
    return this.http.put<void>(
      `${environment.API_BASE_URL}/api/afspraak/${afspraak.id}`,
      asType<AfspraakUpdateDto>(stripNullProperties(afspraak)),
    );
  }

  getAfspraak(afspraakId: string): Observable<AfspraakViewDetail> {
    return this.http.get<AfspraakViewDetail>(
      `${environment.API_BASE_URL}/api/afspraken/${afspraakId}`,
    );
  }

  deleteAfspraak(afspraakId: string) {
    return this.http.delete<void>(`${environment.API_BASE_URL}/api/afspraken/${afspraakId}`);
  }

  stopVideogesprekCall(
    fullRoomName: string,
    afspraakId: AfspraakId,
    videoGesprekId: VideogesprekId,
  ) {
    return this.http.post<void>(
      `${environment.API_BASE_URL}/api/afspraken/${afspraakId}/videogesprek/${videoGesprekId}/call/stop`,
      {
        fullRoomName,
      },
    );
  }
}
