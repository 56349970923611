<ion-popover
  #popover="parkourPopover"
  (willDismiss)="willDismiss()"
  (willPresent)="willPresent()"
  [showBackdrop]="false"
  [trigger]="triggerId"
  alignment="end"
  parkourPopover
  side="right"
  style="--offset-x: 1.25rem"
>
  <ng-template>
    <div class="bg-n-400 rounded w-[14.25rem] relative">
      <parkour-action (click)="onPopoverClose()" ariaLabel="sluiten" class="absolute top-2 right-3">
        <parkour-icon class="text-n-1000" name="cross" />
      </parkour-action>

      <div class="divide-y-[1.5px] divide-n-600">
        <ul class="space-y-1 pt-8 pl-3 pr-3 pb-3 mt-2 max-h-[40vh] overflow-y-auto">
          @for (context of filterContexten(mijnContexten, 'voor-mezelf'); track $index) {
            <li>
              <parkour-action
                (clicked)="onWisselTeam(context)"
                classes="flex items-center justify-between p-2 gap-x-1 w-full hover:bg-p-100 transition-colors rounded"
              >
                <div class="flex items-center gap-x-3">
                  <parkour-badge [theme]="'n-300'" icon="people-3" iconSize="3" />

                  <p class="text-left typo-body variant-body-fat text-n-1000">
                    <strong>{{ 'team.wisselen.mijnteam-geen-jongere' | translate }}</strong>
                  </p>
                </div>

                <parkour-new-indicator
                  [inline]="true"
                  [show]="hasMeldingenInContext(context.contextId) | async"
                  class="[--notification-outline-color:transparent]"
                />
              </parkour-action>
            </li>
          }

          @for (context of filterContexten(mijnContexten, 'jongere'); track $index) {
            <li>
              <parkour-action
                (clicked)="onWisselTeam(context)"
                classes="flex items-center justify-between p-2 gap-x-1 w-full hover:bg-p-100 transition-colors rounded"
              >
                <div class="flex items-center gap-x-3">
                  <parkour-badge [theme]="'n-300'" icon="people-2" iconSize="3" />

                  <p class="text-left typo-body variant-body-fat text-n-1000">
                    <strong>{{ 'team.wisselen.mijnteam-jongere' | translate }}</strong>
                  </p>
                </div>

                <parkour-new-indicator
                  [inline]="true"
                  [show]="hasMeldingenInContext(context.contextId) | async"
                  class="[--notification-outline-color:transparent]"
                />
              </parkour-action>
            </li>
          }

          @for (context of filterContexten(mijnContexten, 'teamlid'); track $index) {
            <li>
              <parkour-action
                (clicked)="onWisselTeam(context)"
                classes="group flex items-center justify-between p-2 gap-x-1 w-full hover:bg-p-100 transition-colors rounded"
              >
                <div class="flex items-center gap-x-3">
                  <parkour-profiel-foto-badge
                    [size]="'3'"
                    [alt]="false"
                    [profiel]="
                      context.geblokkeerd
                        ? { status: 'GEBLOKKEERD' }
                        : {
                            id: context.contextId,
                            profielFotoId: context.profielFotoId,
                            status: 'ACTIEF',
                          }
                    "
                  />

                  <p class="text-left typo-body variant-body-small text-n-1000">
                    {{ context.rol | rolLabelDefault: 'Teamlid' }}
                    van <br />
                    <strong class="typo-body variant-body-fat">
                      {{ context.teamOwnerNaam }}
                    </strong>
                  </p>
                </div>

                <parkour-new-indicator
                  [inline]="true"
                  [show]="hasMeldingenInContext(context.contextId) | async"
                  class="[--notification-outline-color:transparent]"
                />
              </parkour-action>
            </li>
          }
        </ul>

        <div class="flex items-center">
          <parkour-button
            [link]="['profiel', 'wissel-team']"
            class="block w-full"
            classes="p-4"
            variant="ghost-light"
          >
            <span class="flex items-center gap-x-3"
              >{{ 'sidebar.teams.alle-teams' | translate }}

              <parkour-new-indicator
                [inline]="true"
                [show]="hasMeldingenOnWisselTeamPage$ | async"
                class="[--notification-outline-color:transparent] empty:hidden"
              />
            </span>
          </parkour-button>
        </div>
      </div>
    </div>
  </ng-template>
</ion-popover>
