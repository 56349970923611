<parkour-foto-badge
  [badgeCount]="badgeCount"
  [image]="
    profiel.status === 'ACTIEF' ||
    profiel.status === 'UITGENODIGD' ||
    profiel.status === 'UITGENODIGDE_WEERGAVE'
      ? { type: 'image', src: profiel | profielFoto | async, alt }
      : { type: 'icon', name: getIconNaam(profiel) }
  "
  [size]="size"
  class="block"
/>
