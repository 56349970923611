import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { AfspraakViewDetail, ContextOptionDto, ProfielId } from 'parkour-web-app-dto';
import { DatePipe } from '@angular/common';
import {
  ParkourButtonComponent,
  ParkourFormFieldComponent,
  ParkourIconComponent,
  ParkourInputComponent,
  ParkourTextareaComponent,
} from '@parkour/ui';
import { PersoonSelectComponent } from '../../../shared/components/persoon-select/persoon-select.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormSubmitObservableGenerator,
  ParkourFormComponent,
} from '../../../shared/components/parkour-form/parkour-form.component';
import { RawValueOfFormGroup } from '../../../shared/components/parkour-form/parkour-form.types';

export type BasicAfspraakInsert = {
  type: 'BASIC';
  jongereProfielId: string;
  datum: string;
  van: string;
  tot: string;
  titel: string;
  omschrijving?: string;
};

export type VideogesprekAfspraakInsert = {
  type: 'VIDEOGESPREK';
  jongereProfielId: string;
  datum: string;
  van: string;
  tot: string;
  omschrijving?: string;
  teamlidProfielId: string;
};

export type AfspraakFormGroup = FormGroup<{
  jongere: FormControl<ContextOptionDto | undefined>;
  datum: FormControl<string>;
  van: FormControl<string>;
  tot: FormControl<string>;
  omschrijving: FormControl<string | undefined>;
  titel: FormControl<string | undefined>;
  type: FormControl<'BASIC' | 'VIDEOGESPREK'>;
}>;

export type AfspraakInsert = BasicAfspraakInsert | VideogesprekAfspraakInsert;

const THIRTY_MINUTES = 30 * 60000;

@Component({
  standalone: true,
  selector: 'parkour-afspraak-form',
  templateUrl: './afspraak-form.component.html',
  imports: [
    ReactiveFormsModule,
    ParkourFormFieldComponent,
    PersoonSelectComponent,
    ParkourInputComponent,
    ParkourIconComponent,
    ParkourTextareaComponent,
    ParkourButtonComponent,
    TranslateModule,
    ParkourFormComponent,
  ],
})
export class AfspraakFormComponent implements OnInit {
  @Input({ required: true }) mode!: 'add' | 'edit';
  @Input() busy = false;
  @Input() formId = '';
  @Input({ required: true }) isJongere = false;
  @Input() mijnTeamlidContexten: Array<ContextOptionDto> = [];
  @Input({ required: true }) profielId!: ProfielId;
  @Input({ required: true }) submitAfspraakForm!: FormSubmitObservableGenerator<
    RawValueOfFormGroup<AfspraakFormGroup>
  >;
  afspraakForm!: AfspraakFormGroup;
  @Input() initialState?: Partial<AfspraakViewDetail>;

  startTime!: Date;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    const { startTime, endTime } = this.getDefaultTimes();

    this.startTime = this.roundTime(startTime);

    this.afspraakForm = this.formBuilder.nonNullable.group(
      {
        jongere: this.formBuilder.nonNullable.control<ContextOptionDto | undefined>(undefined),
        datum: this.formBuilder.nonNullable.control(
          this.datePipe.transform(this.initialState?.datum || new Date(), 'yyyy-MM-dd') ?? '',
          Validators.required,
        ),
        van: this.formBuilder.nonNullable.control(
          this.initialState?.van
            ? this.getShortTime(new Date(this.initialState.van))
            : this.getShortTime(startTime) ?? '',
          Validators.required,
        ),
        tot: this.formBuilder.nonNullable.control(
          this.initialState?.tot
            ? this.getShortTime(new Date(this.initialState.tot))
            : this.getShortTime(endTime) ?? '',
          Validators.required,
        ),
        omschrijving: this.formBuilder.nonNullable.control<string | undefined>(
          this.initialState?.omschrijving || undefined,
        ),
        titel: this.formBuilder.nonNullable.control<string | undefined>(
          this.initialState?.type === 'BASIC' && this.initialState.titel
            ? this.initialState.titel
            : undefined,
        ),
        type: this.formBuilder.nonNullable.control<'VIDEOGESPREK' | 'BASIC'>(
          this.initialState?.type || (this.isJongere ? 'BASIC' : 'VIDEOGESPREK'),
        ),
      },
      { validators: [this.minEndTimeValidator()] },
    );
  }

  getTotErrors(): Record<string, string> {
    return {
      ...this.afspraakForm.errors,
      ...this.afspraakForm.controls.tot.errors,
    };
  }

  getDefaultTimes(): {
    startTime: Date;
    endTime: Date;
  } {
    const now = new Date();

    const nextHalfHour = new Date(now);
    nextHalfHour.setMinutes(Math.ceil(now.getMinutes() / 30) * 30);

    return {
      startTime: nextHalfHour,
      endTime: this.addThirtyMinutes(nextHalfHour),
    };
  }

  addThirtyMinutes(date: Date): Date {
    return new Date(date.getTime() + THIRTY_MINUTES);
  }

  updateTimes() {
    const newStartTime = this.roundTime(
      this.getDateFromTimeString(this.afspraakForm.getRawValue().van),
    );
    const currentEndTime = this.roundTime(
      this.getDateFromTimeString(this.afspraakForm.getRawValue().tot),
    );
    const timeDifference = newStartTime.getTime() - this.startTime.getTime();
    const newEndTime = new Date(currentEndTime.getTime() + timeDifference);

    this.afspraakForm.controls.tot.setValue(this.getShortTime(newEndTime) ?? '');

    this.startTime = newStartTime;
  }

  roundTime(date: Date): Date {
    const roundedDate = new Date(date);

    roundedDate.setSeconds(0);
    roundedDate.setMilliseconds(0);

    return roundedDate;
  }

  getShortTime(date: Date): string {
    return this.datePipe.transform(date, 'shortTime') || '';
  }

  getDateFromTimeString(time: string): Date {
    const [hours, minutes] = time.split(':').map(Number);
    const newStartTime = new Date();

    newStartTime.setHours(hours);
    newStartTime.setMinutes(minutes);

    return newStartTime;
  }

  minEndTimeValidator(): ValidatorFn {
    return (form: AbstractControl): ValidationErrors | null => {
      const startTime = this.getDateFromTimeString(form.value.van);
      const endTime = this.getDateFromTimeString(form.value.tot);

      if (endTime.getTime() <= startTime.getTime()) {
        return { totMustBeGreaterThanVan: true };
      }

      return null;
    };
  }
}
