import { Component } from '@angular/core';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import {
  ParkourDividerComponent,
  ParkourHeroComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { AanmeldButtonComponent } from '../../../../shared/components/aanmeld-button/aanmeld-button.component';
import { DoelPlaceholderComponent } from '../../component/doelen-placeholder/doel-placeholder.component';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import { ParkourPage } from '../../../../shared/parkour-page';

@Component({
  standalone: true,
  templateUrl: './doel-empty-state-not-aangemeld.page.html',
  imports: [
    IonHeader,
    IonContent,
    PageLayoutComponent,
    ParkourPageImageComponent,
    ParkourHeroComponent,
    TranslateModule,
    ParkourDividerComponent,
    AanmeldButtonComponent,
    DoelPlaceholderComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
  ],
})
export class DoelenEmptyStateNotAangemeldPage extends ParkourPage {}
