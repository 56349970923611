import { SuggestieId, SuggestieStatus } from './gebeurtenis-suggestie.dto';
import { DoelUpsertDto } from './doel.dto';
import { ApiProperty, getSchemaPath } from '@nestjs/swagger';
import { ProfielId } from './profiel.dto';
import {
  ActiefTeamlidDto,
  GeblokkeerdTeamlidDto,
  InactiefTeamlidDto,
  TeamlidProfielDto,
} from './teamlid-profiel.dto';

export class DoelSuggestieDto {
  @ApiProperty({ type: String })
  readonly id!: SuggestieId;
  @ApiProperty({ type: String })
  readonly status!: SuggestieStatus;
  readonly titel!: string;
  readonly bericht!: string;
  @ApiProperty({ type: String })
  readonly suggestedById!: ProfielId;
  @ApiProperty({
    type: 'object',
    anyOf: [
      { $ref: getSchemaPath(ActiefTeamlidDto) },
      { $ref: getSchemaPath(GeblokkeerdTeamlidDto) },
      { $ref: getSchemaPath(InactiefTeamlidDto) },
    ],
  })
  readonly suggestedBy!: TeamlidProfielDto;
  readonly doelData!: DoelUpsertDto;
  readonly aangemaaktOpTijdstip!: string;
}

export class DoelSuggestieUpsertDto {
  readonly titel!: string;
  readonly bericht!: string;
  @ApiProperty({ type: String })
  readonly suggestedById!: ProfielId;
  @ApiProperty({ type: String })
  readonly suggestedToId!: ProfielId;
  readonly doelUpsertData!: DoelUpsertDto;
}
