import { Component, Input } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { AttachmentBerichtDto, GesprekId } from 'parkour-web-app-dto';
import { BerichtenDatePipe } from '../../pipes/berichten-date.pipe';
import { BerichtenService } from '../../service/berichten.service';
import { FileService } from '../../../shared/services/file.service';
import { TranslateModule } from '@ngx-translate/core';
import { BestandPreviewPipe } from '../../pipes/bestand-preview.pipe';
import {
  ParkourActionComponent,
  ParkourIconComponent,
  ParkourImageComponent,
  ParkourLoadingSpinnerComponent,
} from '@parkour/ui';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { ProfielPipe } from '../../../shared/pipes/profiel.pipe';

@Component({
  selector: 'parkour-bericht-attachment-preview',
  standalone: true,
  imports: [
    AsyncPipe,
    NgIf,
    BerichtenDatePipe,
    TranslateModule,
    NgClass,
    BestandPreviewPipe,
    ProfielnaamPipe,
    ParkourIconComponent,
    ParkourLoadingSpinnerComponent,
    ParkourActionComponent,
    ParkourImageComponent,
    ProfielPipe,
  ],
  templateUrl: './bericht-attachment-preview.component.html',
})
export class BerichtAttachmentPreviewComponent {
  @Input({ required: true }) bericht!: AttachmentBerichtDto;
  @Input({ required: true }) gesprekId!: GesprekId;

  downloading = false;

  constructor(
    private readonly berichtenService: BerichtenService,
    private readonly fileService: FileService,
  ) {}

  onDownloadAttachment(bericht: AttachmentBerichtDto) {
    if (!this.downloading) {
      this.downloading = true;

      this.berichtenService
        .getDownloadLink(this.gesprekId, bericht.id, bericht.bestand.id)
        .subscribe((url) => {
          this.fileService
            .downloadFileFromUrl(url, bericht.bestand.filename)
            .then(() => {
              setTimeout(() => (this.downloading = false), 1000);
            })
            .catch(() => {
              // this.downloading = false;
            });
        });
    }
  }
}
