<parkour-action
  (clicked)="openModal()"
  [ngClasses]="{
    'border-n-500': !error && !disabled,
    'border-negative': error,
    'border-n-300': disabled,
  }"
  class="block"
  classes="flex items-center justify-between border-[1.5px] rounded bg-n-200 w-full p-3"
>
  @if (_value) {
    <parkour-persoon-select-option [option]="_value"></parkour-persoon-select-option>
  } @else {
    <div class="flex items-center gap-x-3 w-full">
      <p>{{ placeholder }}</p>
    </div>
  }

  <parkour-icon name="chevron-down" size="3" />
</parkour-action>

<parkour-modal [hasSpacing]="false" icon="people" title="Persoon">
  <ul class="divide-y-[1.5px] divide-n-500" content>
    <li *ngFor="let option of options">
      <parkour-action
        (clicked)="selectPerson(option)"
        [ngClass]="{
          'bg-n-200': option === _value,
          'bg-n-100': option !== _value,
        }"
        class="block"
        classes="w-full p-3 flex items-center justify-between hover:bg-n-200 focus:bg-n-200 transition-colors"
      >
        <parkour-persoon-select-option [option]="option"></parkour-persoon-select-option>

        @if (option === _value) {
          <parkour-icon class="text-positive" name="check" size="3" />
        }
      </parkour-action>
    </li>
  </ul>
</parkour-modal>
