import { Component, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ContextOption } from '../../../profiel/model/context-option';
import { PersoonSelectOptionComponent } from './persoon-select-option/persoon-select-option.component';
import { NgClass, NgForOf } from '@angular/common';
import { ParkourActionComponent, ParkourIconComponent, ParkourModalComponent } from '@parkour/ui';

@Component({
  selector: 'parkour-persoon-select',
  templateUrl: './persoon-select.component.html',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PersoonSelectComponent,
    },
  ],
  imports: [
    PersoonSelectOptionComponent,
    NgForOf,
    NgClass,
    ParkourIconComponent,
    ParkourModalComponent,
    ParkourActionComponent,
  ],
})
export class PersoonSelectComponent implements ControlValueAccessor {
  @ViewChild(ParkourModalComponent) parkourModal!: ParkourModalComponent;
  @Input({ required: true }) public options!: ContextOption[];
  @Input({ required: true }) public placeholder!: string;
  @Input() public disabled = false;
  @Input() public error?: string;

  public onChange?: (value: ContextOption) => void;
  public onTouched?: () => void;

  public _value?: ContextOption;

  @Input() set value(val: ContextOption) {
    this.writeValue(val);
  }

  writeValue(value: ContextOption) {
    this._value = value;
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  openModal() {
    if (!this.disabled) {
      this.parkourModal.open();
    }
  }

  selectPerson(option: ContextOption) {
    if (!this.disabled && option !== this._value) {
      this.writeValue(option);
      this.onChange?.(option);

      this.parkourModal.close();
    }
  }
}
