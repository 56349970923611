<form (ngSubmit)="onSubmit()" [id]="formId" class="flex flex-col gap-y-8">
  <parkour-profiel-details-foto-edit
    [fotoFormgroup]="profielForm.controls.fotoData"
    [professioneel]="profiel.profielType === 'PROFESSIONEEL'"
  />

  <div class="relative z-10">
    <div class="container container-space">
      <div class="container-1-col flex flex-col gap-y-2">
        <parkour-afwezig [afwezigFormGroup]="profielForm.controls.afwezigData" />

        <div class="bg-n-200 rounded p-4">
          <div class="mb-4 flex items-center gap-x-2">
            <parkour-icon class="text-n-600" name="people-3" />

            <h2 class="typo-h5">{{ 'profiel.title' | translate }}</h2>
          </div>

          <parkour-profile-details-profielnaam-edit
            [fullName]="profiel.voornaam + ' ' + profiel.naam"
            [naamFormgroup]="profielForm.controls.naamData"
          />

          <parkour-profile-details-over-jezelf-edit
            [overJezelfFormGroup]="profielForm.controls.overJezelfData"
          />
        </div>

        <div class="bg-n-300 rounded p-4">
          <div class="mb-4 flex items-center gap-x-2">
            <parkour-icon class="text-n-600" name="eye-off" />

            <h2 class="typo-h5">{{ 'profiel.prive-informatie-titel' | translate }}</h2>
          </div>

          <parkour-profile-details-contact-edit
            [contactFormGroup]="profielForm.controls.contactData"
          />
        </div>
      </div>
    </div>

    <div class="pt-6 pb-8 bg-n-200 mt-8 full-bleed container-space">
      <div class="container-1-col">
        <h2 class="typo-h3 mb-2">{{ 'profiel.verwijderen-titel' | translate }}</h2>

        <ng-container
          *ngIf="!profiel.gemarkeerdVoorVerwijderingSinds; else gemarkeerdVoorVerwijdering"
        >
          <p>{{ 'profiel.verwijderen-aanvraag-description' | translate }}</p>

          <parkour-button
            [iconAfter]="'trash'"
            [link]="['verwijderen']"
            [variant]="'tertiary'"
            class="block w-full mt-4"
            >{{ 'profiel.verwijderen-aanvragen-button' | translate }}
          </parkour-button>
        </ng-container>
        <ng-template #gemarkeerdVoorVerwijdering>
          <p>
            {{
              'profiel.verwijderen-aangevraagd-description'
                | translate: { datum: profiel.gemarkeerdVoorVerwijderingSinds | date }
            }}
          </p>

          <parkour-button
            (clicked)="onVerwijderingStopzettenClick()"
            [iconAfter]="'trash'"
            [variant]="'tertiary'"
            class="block w-full mt-4"
            >{{ 'profiel.verwijderen-aanvraag-stopzetten-button' | translate }}
          </parkour-button>
        </ng-template>
      </div>
    </div>
  </div>
</form>
