<ion-radio-group [formControl]="naamFormgroup.controls.profielnaamType" class="flex flex-col">
  <parkour-radio
    [label]="'profiel.volledige-naam-tonen' | translate"
    inputId="volledige-naam-mode"
    value="VOLLEDIGE_NAAM"
  >
    <p *ngIf="naamFormgroup.controls.profielnaamType.value === 'VOLLEDIGE_NAAM'" class="typo-body">
      {{ fullName }}
    </p>
  </parkour-radio>

  <parkour-radio
    [label]="'profiel.roepnaam-tonen' | translate"
    class="mt-4"
    inputId="roepnaam-mode"
    value="ROEPNAAM"
  >
    <parkour-form-field-without-label
      [additionalErrors]="getRoepnaamErrors()"
      [errorMessages]="{
        roepnaamRequired: 'Vul een roepnaam in.',
        invisibleUnicode: 'Onzichtbare tekens zijn niet toegestaan.',
        invalidSpacing: 'Spaties aan het begin zijn niet toegestaan',
      }"
      [inputFormControl]="naamFormgroup.controls.roepnaam"
      [required]="true"
      class="w-full"
      inputId="roepnaam"
    >
      <parkour-input
        *ngIf="naamFormgroup.controls.profielnaamType.value === 'ROEPNAAM'"
        [formControl]="naamFormgroup.controls.roepnaam"
        [inputPlaceholder]="'profiel.roepnaam' | translate"
        class="block w-full"
        inputId="roepnaam"
        type="text"
      />
    </parkour-form-field-without-label>
  </parkour-radio>
</ion-radio-group>
