@if (userProfiel === undefined) {
  <parkour-avatar-badge
    [image]="{ type: 'icon', name: 'people-3' }"
    classes="w-[2.625rem] h-[2.625rem] rounded-sm overflow-hidden"
  />
} @else {
  @switch (context.type) {
    @case ('teamlid') {
      <div class="w-[2.625rem] h-[2.625rem] grid grid-cols-[2fr_1fr_2fr] grid-rows-[2fr_1fr_2fr]">
        <parkour-avatar-badge
          [image]="{
            type: 'image',
            src: userProfiel | profielFoto | async,
            alt: userProfiel | profielnaam,
          }"
          class="col-start-1 col-end-3 row-start-1 row-end-3"
          classes="overflow-hidden rounded-sm"
        />

        <parkour-avatar-badge
          [image]="{
            type: 'image',
            src: context.jongereProfiel | profielFoto | async,
            alt: context.jongereProfiel | profielnaam,
          }"
          class="col-start-2 col-end-4 row-start-2 row-end-4"
          classes="overflow-hidden rounded-sm outline outline-[1.5px] outline-[color:var(--avatar-border-color)] transition-colors"
        />
      </div>
    }
    @case ('jongere') {
      <parkour-avatar-badge
        [image]="{
          type: 'image',
          src: userProfiel | profielFoto | async,
          alt: userProfiel | profielnaam,
        }"
        classes="w-[2.625rem] h-[2.625rem] rounded-sm overflow-hidden"
      />
    }
    @case ('voor-mezelf') {
      <parkour-avatar-badge
        [image]="{
          type: 'image',
          src: userProfiel | profielFoto | async,
          alt: userProfiel | profielnaam,
        }"
        classes="w-[2.625rem] h-[2.625rem] rounded-sm overflow-hidden"
      />
    }
    @case ('anoniem') {
      <parkour-avatar-badge
        [image]="{ type: 'icon', name: 'people-3' }"
        classes="w-[2.625rem] h-[2.625rem] rounded-sm overflow-hidden"
      />
    }
  }
}
