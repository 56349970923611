import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { meldingenForPage } from '../../../../meldingen/config';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe } from '@angular/common';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import {
  ParkourButtonComponent,
  ParkourIconComponent,
  ParkourLoadingSpinnerComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { DoelDetailsComponent } from '../../../doel-shared/component/doel-details/doel-details.component';
import { PageSectionDirective } from '../../../../shared/directives/page-section.directive';
import { ReactieOverviewComponent } from '../../../doel-shared/component/reactie-overview/reactie-overview.component';
import { DoelBerichtenComponent } from '../../../../bericht/component/doel-berichten/doel-berichten.component';

import { CustomPageTitle, PageTitle } from '../../../../shared/custom-page-title';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../../shared/marks-meldingen-as-read';
import { DoelDetailPage } from '../../../doel-shared/page/doel-detail/doel-detail-page';
import { ArtikelCardComponent } from '../../../../watwat/components/artikel-card/artikel-card.component';

@Component({
  standalone: true,
  templateUrl: './teamlid-doel-detail.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ParkourBackButtonDirective,
    IonTitle,
    TranslateModule,
    AsyncPipe,
    IonContent,
    PageLayoutComponent,
    PageContentDirective,
    ParkourPageImageComponent,
    DoelDetailsComponent,
    PageSectionDirective,
    ReactieOverviewComponent,
    DoelBerichtenComponent,
    ParkourLoadingSpinnerComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    ArtikelCardComponent,
    ParkourButtonComponent,
    ParkourIconComponent,
  ],
})
export class TeamlidDoelDetailPage
  extends DoelDetailPage
  implements CustomPageTitle, MarksMeldingenAsReadOnView
{
  getCustomPageTitle(): Observable<PageTitle> {
    return this.generateDefaultTitle((data) => data.doel.naam, 'doelen.doel-detail-page.title');
  }

  getMeldingenToMarkAsRead() {
    return this.mainPageData$.pipe(
      map(
        (data) =>
          new MeldingenToMarkAsRead(
            meldingenForPage.DOELEN_TAB.DOELEN_PAGE.DOEL_PAGE.specificMeldingen,
            (melding) => data.success && melding.metaData.doelId === data.value.doel.id,
          ),
      ),
    );
  }

  protected readonly String = String;
}
