<p class="typo-body text-n-600 py-1 inline-block">
  {{ (parkourGebeurtenis.startDatum | date) ?? '' }}
</p>

<div class="clickable-card-200 p-4 rounded">
  <parkour-new-indicator [show]="shouldShowNewIndicator()" />
  <parkour-gebeurtenis-preview-image [gebeurtenis]="parkourGebeurtenis" />

  <div class="flex justify-between">
    <p class="typo-h5">
      <parkour-action
        [link]="['gebeurtenis', parkourGebeurtenis.id]"
        classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 focus-visible:outline-none"
      >
        {{ parkourGebeurtenis.titel }}
      </parkour-action>
    </p>
    @if (parkourGebeurtenis.bijlagen.length > 0) {
      <parkour-icon
        [ariaLabel]="'verhaal.overview-page.voorstel-attachment' | translate"
        name="attachment"
        size="3"
      />
    }
  </div>

  <p class="typo-body mt-2">
    {{ parkourGebeurtenis.omschrijving }}
  </p>

  <parkour-gedeeld-met-indicator
    [deelMode]="parkourGebeurtenis.deelMode"
    [gedeeldMet]="parkourGebeurtenis.gedeeldMet"
    class="border-t-[1.5px] border-n-600/30 flex pt-4 mt-4"
  />
</div>
