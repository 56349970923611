<div class="flex flex-col gap-3 w-full mt-6">
  <div class="relative w-full">
    <parkour-new-indicator
      [show]="hasMijnTeamMeldingen$ | async"
      class="[--notification-outline-color:theme('colors.n-300')]"
    />

    <parkour-button
      [link]="['profiel', 'team']"
      class="block w-full"
      iconBefore="people"
      size="small"
      variant="dark-transparent"
    >
      Mijn team
    </parkour-button>
  </div>

  <div class="relative w-full">
    <parkour-new-indicator
      [show]="wisselTeamIndicator$ | async"
      class="[--notification-outline-color:theme('colors.n-300')]"
    />

    <parkour-button
      [isActive]="isTeamSwitchPopoverOpen"
      class="block w-full"
      iconBefore="sort"
      id="sidebar-jongere-team"
      size="small"
      variant="dark-transparent"
    >
      {{ 'team.wisselen.teams-overview-button' | translate }}
    </parkour-button>
  </div>
</div>

<parkour-sidebar-wissel-team-popover
  (hasDismissed)="isTeamSwitchPopoverOpen = false"
  (hasPresented)="isTeamSwitchPopoverOpen = true"
  [mijnContexten]="mijnContexten"
  [user]="user"
  class="block"
  triggerId="sidebar-jongere-team"
/>
