import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Actie, Doel } from '../../model/doel';
import { themaMapping } from '../../../../home/model/thema.const';
import { DoelDetailsHeroComponent } from '../doel-details-hero/doel-details-hero.component';
import { ParkourActionComponent, ParkourButtonComponent, ParkourIconComponent } from '@parkour/ui';
import { GedeeldMetIndicatorComponent } from '../../../../delen/component/gedeeld-met-indicator/gedeeld-met-indicator.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActieListComponent } from '../actie-list/actie-list.component';
import { GedeeldMetInfoComponent } from '../../../../delen/component/gedeeld-met-info/gedeeld-met-info.component';
import { SuggestieId } from 'parkour-web-app-dto';

@Component({
  standalone: true,
  selector: 'parkour-doel-details',
  templateUrl: './doel-details.component.html',
  imports: [
    DoelDetailsHeroComponent,
    ParkourActionComponent,
    ParkourIconComponent,
    ParkourButtonComponent,
    GedeeldMetIndicatorComponent,
    TranslateModule,
    ActieListComponent,
    GedeeldMetInfoComponent,
  ],
})
export class DoelDetailsComponent {
  @Input({ required: true }) contextType!: 'teamlid' | 'jongere';
  @Input({ required: true }) doel!: Doel;
  @Input() editable = true;
  @Output() actiesModified = new EventEmitter<{ doel: Doel; acties: Actie[] }>();

  @Output() public ignoreGebeurtenisSuggestie: EventEmitter<SuggestieId> =
    new EventEmitter<SuggestieId>();

  updateActies(doel: Doel, acties: Actie[]) {
    this.actiesModified.emit({ doel, acties });
  }

  ignoreGebeurtenis(suggestieId: SuggestieId) {
    this.ignoreGebeurtenisSuggestie.emit(suggestieId);
  }

  protected readonly themaMapping = themaMapping;

  canNavigateToOndersteuning() {
    return this.editable;
  }
}
