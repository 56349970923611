import { ProfielDto } from './profiel.dto';
import { ApiProperty } from '@nestjs/swagger';

export class ActieveJongereDto extends ProfielDto {
  @ApiProperty({ type: String })
  readonly viewType!: 'JONGERE';
  @ApiProperty({ type: String })
  readonly status = 'ACTIEF';
}

// TODO: Backwards compatibility: 1.0001.0041, remove unneeded data
export class GeblokkeerdWeergaveJongereDto extends ProfielDto {
  @ApiProperty({ type: String })
  readonly viewType!: 'JONGERE';
  @ApiProperty({ type: String })
  readonly status = 'GEBLOKKEERDE_WEERGAVE';
}

// TODO: Backwards compatibility: 1.0001.0041, remove unneeded data
export class UitgenodigdWeergaveJongereDto extends ProfielDto {
  @ApiProperty({ type: String })
  readonly viewType!: 'JONGERE';
  @ApiProperty({ type: String })
  readonly status = 'UITGENODIGDE_WEERGAVE';
}

export type JongereProfielDto =
  | ActieveJongereDto
  | GeblokkeerdWeergaveJongereDto
  | UitgenodigdWeergaveJongereDto;
