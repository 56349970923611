import { Component, Input } from '@angular/core';
import { Gebeurtenis } from '../../model/gebeurtenis';
import { verhaalHeroSources } from '../../../constants';
import { ParkourImageComponent, ParkourInfoCardComponent } from '@parkour/ui';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { GedeeldMetIndicatorComponent } from '../../../../delen/component/gedeeld-met-indicator/gedeeld-met-indicator.component';
import { GedeeldMetInfoComponent } from '../../../../delen/component/gedeeld-met-info/gedeeld-met-info.component';
import { ActiefAlsJongereDirective } from '../../../../shared/directives/actief-als-jongere.directive';

@Component({
  standalone: true,
  selector: 'parkour-jeugdhulphistoriek-details-component',
  templateUrl: './jeugdhulp-historiek-details-component.component.html',
  imports: [
    ParkourImageComponent,
    PageWithHeroComponent,
    ParkourInfoCardComponent,
    TranslateModule,
    DatePipe,
    GedeeldMetIndicatorComponent,
    GedeeldMetInfoComponent,
    ActiefAlsJongereDirective,
  ],
})
export class JeugdhulpHistoriekDetailsComponent {
  @Input({ required: true }) gebeurtenis!: Gebeurtenis;

  protected readonly verhaalHeroSources = verhaalHeroSources;
}
