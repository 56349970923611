<article class="bg-n-200 rounded overflow-hidden" data-testid="notitie">
  <div class="flex gap-x-4 p-4">
    <div class="flex-1 flex flex-col gap-y-1">
      <div class="flex justify-between gap-x-2">
        <h1 class="typo-body variant-body-fat wysiwyg" [innerHTML]="notitie.titel | linkify"></h1>

        <parkour-button
          [ariaLabel]="'buttons.edit-notitie' | translate"
          [link]="['notitie', notitie.id, 'wijzig']"
          class="relative z-20"
          format="iconButton"
          icon="edit"
          size="none"
          testId="notitie-edit"
          variant="ghost"
        />
      </div>
    </div>
  </div>
  @if (notitie.beschrijving) {
    <div class="p-4 bg-n-300 rounded-br rounded-bl">
      <span [innerHTML]="notitie.beschrijving | linkify" class="typo-body wysiwyg"></span>
    </div>
  }
</article>
