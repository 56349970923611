import {
  AfspraakId,
  AfspraakInsertDto,
  AfspraakUpdateDto,
  AfspraakViewDetail,
  ProfielId,
  validatedProfielId,
} from 'parkour-web-app-dto';
import { AfspraakFormGroup } from '../component/afspraak-form/afspraak-form.component';
import { UserWithProfiel } from '../../user/model/user';
import { TeamlidProfiel } from '../../team/model/teamlid.model';
import { JongereProfiel } from './profiel';
import { RawValueOfFormGroup } from '../../shared/components/parkour-form/parkour-form.types';

export function toAfpsraakInsertDto(
  afspraakFormData: RawValueOfFormGroup<AfspraakFormGroup>,
  currentUserProfielId: ProfielId,
): AfspraakInsertDto {
  if (afspraakFormData.type === 'BASIC') {
    return {
      type: 'BASIC',
      jongereProfielId: validatedProfielId(currentUserProfielId),
      omschrijving: afspraakFormData.omschrijving,
      van: new Date(`${afspraakFormData.datum}T${afspraakFormData.van}`).toISOString(),
      tot: new Date(`${afspraakFormData.datum}T${afspraakFormData.tot}`).toISOString(),
      titel: afspraakFormData.titel ?? '',
    };
  } else {
    return {
      type: 'VIDEOGESPREK',
      jongereProfielId: validatedProfielId(afspraakFormData.jongere?.contextId),
      teamlidProfielId: validatedProfielId(currentUserProfielId),
      omschrijving: afspraakFormData.omschrijving,
      van: new Date(`${afspraakFormData.datum}T${afspraakFormData.van}`).toISOString(),
      tot: new Date(`${afspraakFormData.datum}T${afspraakFormData.tot}`).toISOString(),
    };
  }
}

export function toAfspraakUpdateDto(
  afspraakId: AfspraakId,
  afspraakFormData: RawValueOfFormGroup<AfspraakFormGroup>,
  currentUserProfielId: ProfielId,
): AfspraakUpdateDto {
  return {
    id: afspraakId,
    type: 'BASIC',
    jongereProfielId: validatedProfielId(currentUserProfielId),
    omschrijving: afspraakFormData.omschrijving,
    van: new Date(`${afspraakFormData.datum}T${afspraakFormData.van}`).toISOString(),
    tot: new Date(`${afspraakFormData.datum}T${afspraakFormData.tot}`).toISOString(),
    titel: afspraakFormData.titel ?? '',
  };
}

export const getGespreksPartner = (
  user: UserWithProfiel,
  afspraak: AfspraakViewDetail,
): TeamlidProfiel | JongereProfiel => {
  return afspraak.type === 'VIDEOGESPREK' && user.profiel.id !== afspraak.teamlid.id
    ? afspraak.teamlid
    : afspraak.jongere;
};
