import { Component } from '@angular/core';
import {
  ContactFormGroup,
  ProfileDetailsContactEditComponent,
} from '../../../profiel/component/profile-details-contact-edit/profile-details-contact-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfielCreateService } from '../../../profiel/service/profiel-create.service';
import { ProfielFormService } from '../../../profiel/service/profiel-form.service';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import {
  ParkourButtonComponent,
  ParkourInfoCardComponent,
  ParkourPageImageComponent,
} from '@parkour/ui';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
@Component({
  standalone: true,
  templateUrl: './profiel-create-contact.page.html',
  imports: [
    ParkourBackButtonDirective,
    ParkourPageImageComponent,
    ParkourInfoCardComponent,
    FormsModule,
    ParkourButtonComponent,
    TranslateModule,
    PageLayoutComponent,
    ProfileDetailsContactEditComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
})
export class ProfielCreateContactPage {
  contactFormGroup: ContactFormGroup = this.profielFormService.createContactFormGroup({});

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly profielFormService: ProfielFormService,
    private readonly profileCreateService: ProfielCreateService,
  ) {}

  onSubmit() {
    this.contactFormGroup.markAllAsTouched();
    if (this.contactFormGroup.valid) {
      this.profileCreateService.setContactData(this.contactFormGroup.getRawValue());
      this.router.navigate(['../', 'jezelf'], { relativeTo: this.route });
    }
  }
}
