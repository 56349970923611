import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  ParkourButtonComponent,
  ParkourIconComponent,
  ParkourPopupComponent,
  TParkourIconName,
} from "@parkour/ui";
import { ModalController } from "@ionic/angular";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { NgIf } from "@angular/common";

@Component({
  standalone: true,
  templateUrl: "./parkour-yes-no-popup.component.html",
  imports: [ParkourIconComponent, NgIf, ParkourButtonComponent],
  providers: [ModalController],
})
export class ParkourYesNoPopupComponent implements OnInit, OnDestroy {
  @ViewChild("popup") popup!: ParkourPopupComponent;
  @Input() public icon?: TParkourIconName;
  @Input() public description!: string;
  @Input() public title!: string;
  @Input() public jaText = "Ja";
  @Input() public neeText = "Nee";
  open = false;
  routerSubscription!: Subscription;

  constructor(
    private readonly modalControl: ModalController,
    private readonly router: Router,
  ) {}

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  ngOnInit() {
    setTimeout(() => (this.open = true), 0); // Popup only seems to open when it doesn't start as "open"
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.handleCloseYesNoPopup();
      }
    });
  }

  onJaClick() {
    this.modalControl.dismiss(null, "confirm");
  }

  onNeeClick() {
    this.modalControl.dismiss(null, "cancel");
  }

  onDismiss() {
    this.modalControl && this.modalControl.dismiss(null, "cancel");
  }

  handleCloseYesNoPopup() {
    if (this.open && this.modalControl) {
      this.modalControl.dismiss(null, "close");
    }
  }
}
