<ul
  [attr.aria-activedescendant]="inputValue"
  [ngClass]="classes"
  class="grid grid-cols-4 md:grid-cols-6 gap-6"
  role="listbox"
>
  <li *ngFor="let item of items" [attr.aria-selected]="value === item" [id]="item" role="option">
    <parkour-action
      (blurred)="blur()"
      (clicked)="handleClick(item)"
      (focussed)="focus(item)"
      (spaced)="space($event, item)"
      [disabled]="disabled"
      [ngClasses]="{
        '!outline-p-100': value === item,
        'opacity-50': disabled,
      }"
      [tabIndex]="0"
      class="flex justify-center items-center transition-all"
      classes="outline hover:outline-n-400 focus-visible:outline-[yellow] outline-transparent outline-[3px] outline-offset-[3px] rounded transition-all"
    >
      <parkour-image
        [alt]="convertAlt(item)"
        [src]="profielFotoService.getAvatarUrl(item) | secureImage | async"
        class="block aspect-square rounded overflow-hidden"
      />
    </parkour-action>
  </li>
</ul>
