<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <ng-container *parkourSectionContent="{ data: mainPageData$ | async }; let data">
      <parkour-page-image
        [src]="data.profiel | profielFoto: 'full' | async"
        classes="absolute top-0 left-0 z-0"
      />

      <div class="container relative z-10 pb-8 container-space pt-15">
        <div class="flex flex-col items-center gap-2 container-1-col">
          <parkour-profiel-foto [profiel]="data.profiel" [size]="'5.5'" class="block" />

          <p class="text-center typo-h3">{{ data.profiel | profielnaam }}</p>

          <parkour-profiel-select
            (createPersoonlijkProfiel)="onCreatePersoonlijkProfiel()"
            (createProfessioneelProfiel)="onCreateProfessioneelProfiel()"
            (profielSelect)="onProfielSelect($event)"
            [currentProfiel]="data.profiel"
            [externeHoedanigheid]="data.user.hoedanigheid"
            [profielen]="data.mijnProfielen"
          />
        </div>

        @if (data.context.type === 'teamlid') {
          <parkour-card
            [image]="data.context.jongereProfiel | profielFoto | async"
            aligned="center"
            class="block mt-6 container-1-col"
            theme="highlight"
          >
            <p class="typo-body">
              {{ data.context.rol | rolLabelDefault: 'Teamlid' }}
              van
            </p>

            <p class="typo-body variant-body-fat">
              {{ data.context.jongereProfiel | profielnaam }}
            </p>

            <div class="flex items-center justify-between gap-4" footer>
              <div class="relative w-full">
                <parkour-new-indicator
                  [show]="hasMijnTeamMeldingen$ | async"
                  class="[--notification-outline-color:theme('colors.highlight')]"
                />

                <parkour-button
                  [link]="'team'"
                  class="w-full"
                  iconAfter="people"
                  variant="dark-transparent"
                >
                  {{ 'team.wisselen.team-detail-button' | translate }}
                </parkour-button>
              </div>

              <div class="relative w-full">
                <parkour-new-indicator
                  [show]="wisselTeamIndicator$ | async"
                  class="[--notification-outline-color:theme('colors.highlight')]"
                />

                <parkour-button
                  [link]="'wissel-team'"
                  class="w-full"
                  classes="pl-3"
                  iconAfter="sort"
                  variant="dark-transparent"
                >
                  {{ 'team.wisselen.teams-overview-button' | translate }}
                </parkour-button>
              </div>
            </div>
          </parkour-card>
        } @else {
          <parkour-card
            [showIndicator]="hasMijnTeamMeldingen$ | async"
            aligned="center"
            class="block mt-6 relative container-1-col"
            icon="people-2"
            theme="n-500"
          >
            <div class="flex items-center justify-between gap-4">
              <parkour-action
                *parkourActiefAlsJongere
                [link]="'team'"
                classes="before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 flex-1 text-left typo-body variant-body-fat"
              >
                {{ 'team.wisselen.mijnteam-jongere' | translate }}
              </parkour-action>

              <div *parkourActiefVoorMezelf class="flex-1 text-left typo-body variant-body-fat">
                {{ 'team.wisselen.mijnteam-geen-jongere' | translate }}
              </div>

              <div
                *ngIf="data.context.type === 'anoniem'"
                class="flex-1 text-left typo-body variant-body-fat"
              >
                {{ 'team.wisselen.mijnteam-geen-jongere' | translate }}
              </div>

              <div class="relative">
                <parkour-new-indicator
                  [show]="wisselTeamIndicator$ | async"
                  class="[--notification-outline-color:theme('colors.n-500')]"
                />

                <parkour-button
                  [link]="'wissel-team'"
                  class="relative z-50"
                  classes="pl-3"
                  iconAfter="sort"
                  variant="dark-transparent"
                >
                  {{ 'team.wisselen.teams-overview-button' | translate }}
                </parkour-button>
              </div>
            </div>
          </parkour-card>
        }

        <ul class="divide-y-[1.5px] divide-n-300 mt-6 container-1-col">
          <li>
            <parkour-profiel-nav-item
              [link]="'mijn'"
              [text]="'profiel-nav.profiel' | translate"
              class="block"
              data-testid="mijn-profiel"
              icon="people-3"
            />
          </li>

          <li>
            <parkour-profiel-nav-item
              [link]="'meldingen'"
              [text]="'profiel-nav.meldingen' | translate"
              class="block"
              icon="notifications"
            />
          </li>

          <li>
            <parkour-profiel-nav-item
              [icon]="'calendar'"
              [link]="'kalender'"
              [showIndicator]="hasKalenderMeldingen$ | async"
              [text]="'profiel-nav.kalender' | translate"
              class="block"
            />
          </li>

          <li>
            <parkour-profiel-nav-item
              [link]="'bewaard'"
              [text]="'profiel-nav.bewaard' | translate"
              class="block"
              icon="bookmark"
            />
          </li>

          <li>
            <parkour-profiel-nav-item
              [link]="['over', 'parkour']"
              [text]="'profiel-nav.about' | translate"
              class="block"
              icon="info-circle"
            />
          </li>

          @if (isNativeApp()) {
            <li>
              <parkour-profiel-nav-item
                [link]="['instellingen']"
                [text]="'profiel-nav.instellingen' | translate"
                class="block"
                icon="info-circle"
              />
            </li>
          }

          <li>
            <parkour-profiel-nav-item
              [link]="['probleem']"
              [text]="'profiel.probleem-page.title' | translate"
              class="block"
              icon="alert-triangle"
            />
          </li>

          <li class="pt-4">
            <parkour-action
              [link]="'/app/logout'"
              classes="flex items-center w-full gap-4 p-4 rounded bg-n-200"
            >
              <parkour-badge icon="logout" iconSize="2" theme="negative" width="w-8" />

              <p class="typo-body variant-body-fat">{{ 'profiel-nav.logout' | translate }}</p>
            </parkour-action>
          </li>
        </ul>
      </div>
    </ng-container>
  </parkour-page-layout>
</ion-content>
