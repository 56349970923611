import { Component } from '@angular/core';
import { ParkourBlockPlaceholderComponent } from '@parkour/ui';

@Component({
  standalone: true,
  selector: 'parkour-doel-placeholder',
  templateUrl: './doel-placeholder.component.html',
  imports: [ParkourBlockPlaceholderComponent],
})
export class DoelPlaceholderComponent {}
