import { Component } from '@angular/core';
import { map, Observable, switchMap, take } from 'rxjs';
import { Gebeurtenis } from '../../../verhaal-shared/model/gebeurtenis';
import { VerhalenService } from '../../../verhaal-shared/service/verhalen.service';
import { ActivatedRoute } from '@angular/router';
import { validateGebeurtenisId } from '../../../verhaal-shared/utils';
import { meldingenForPage } from '../../../../meldingen/config';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe } from '@angular/common';
import { ParkourGebeurtenisDetailsComponent } from '../../../verhaal-shared/component/parkour-gebeurtenis-details-component/parkour-gebeurtenis-details-component.component';
import { JeugdhulpHistoriekDetailsComponent } from '../../../verhaal-shared/component/jeugdhulp-historiek-details-component/jeugdhulp-historiek-details-component.component';
import { ShowIfSuccesfulDirective } from '../../../../shared/directives/show-if-succesful.directive';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
} from '@ionic/angular/standalone';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { GebeurtenisDto } from 'parkour-web-app-dto';
import { CustomPageTitle, PageTitle } from '../../../../shared/custom-page-title';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../../shared/marks-meldingen-as-read';
import { asType } from '../../../../utils';

@Component({
  standalone: true,
  templateUrl: './gebeurtenis-details-for-teamlid-page.html',
  imports: [
    ParkourBackButtonDirective,
    AsyncPipe,
    ParkourGebeurtenisDetailsComponent,
    JeugdhulpHistoriekDetailsComponent,
    TranslateModule,
    ShowIfSuccesfulDirective,
    PageContentDirective,
    PageLayoutComponent,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
    IonBackButton,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
  ],
})
export class GebeurtenisDetailsForTeamlidPage
  extends ParkourDataPage<Gebeurtenis>
  implements CustomPageTitle, MarksMeldingenAsReadOnView
{
  constructor(
    private readonly verhalenService: VerhalenService,
    private readonly route: ActivatedRoute,
  ) {
    super();
  }

  override retrieveMainData(): Observable<GebeurtenisDto> {
    return this.route.paramMap.pipe(
      take(1),
      map((params) => params.get('gebeurtenis-id')),
      map(validateGebeurtenisId),
      switchMap((id) => this.verhalenService.getGebeurtenis(id)),
    );
  }

  getCustomPageTitle(): Observable<PageTitle> {
    return this.mainPageData$.pipe(
      switchMap((gebeurtenis) => {
        if (gebeurtenis.success) {
          if (gebeurtenis.value.type === 'EYOUTH') {
            return this.translateService
              .get('verhaal.eyouth-gebeurtenis-details-page.title')
              .pipe(map((category) => ({ title: gebeurtenis.value.titel, category })));
          } else {
            return this.translateService
              .get('verhaal.gebeurtenis-details-for-teamlid-page.title')
              .pipe(map((category) => ({ title: gebeurtenis.value.titel, category })));
          }
        } else {
          return asType<Observable<string>>(
            this.translateService.get('verhaal.gebeurtenis-details-for-teamlid-page.title'),
          );
        }
      }),
    );
  }

  getMeldingenToMarkAsRead() {
    return this.mainPageData$.pipe(
      map(
        (gebeurtenis) =>
          new MeldingenToMarkAsRead(
            {
              ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.PARKOUR_GEBEURTENIS_PAGE
                .specificMeldingen,
              ...meldingenForPage.VERHAAL_TAB.VERHAAL_PAGE.EYOUTH_GEBEURTENIS_PAGE
                .specificMeldingen,
            },
            (melding) =>
              gebeurtenis.success && melding.metaData.gebeurtenisId === gebeurtenis.value.id,
          ),
      ),
    );
  }
}
