<p class="text-n-700 typo-body">
  {{ 'berichten.gesprek-page.gemaskeerd' | translate }}
  <parkour-action
    class="typo-body variant-body-fat underline inline-block"
    href="https://www.opgroeien.be/over-opgroeien/projecten/parkour/veelgestelde-vragen"
    >{{ 'berichten.gesprek-page.gemaskeerd-link' | translate
    }}<!--
    --></parkour-action
  >
</p>
