import {
  combineLatest,
  defaultIfEmpty,
  from,
  map,
  mergeMap,
  Observable,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { HttpClient, HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import {
  Bericht,
  Gesprek,
  GesprekUpdate,
  GroepsgesprekInsert,
  JongereDeelnemer,
  TeamlidDeelnemer,
} from '../model/gesprek';
import {
  BerichtDto,
  BerichtenPage,
  BerichtId,
  CreateBerichtDto,
  CreateBestandBericht,
  CreateReactieBerichtDto,
  FotoId,
  GesprekDto,
  GesprekId,
  GesprekImageVariant,
  GesprekUpdateDto,
  GroepsgesprekInsertDto,
  ProfielId,
} from 'parkour-web-app-dto';
import { ContextService } from '../../shared/services/context.service';
import { asType } from '../../utils';
import { UserWithProfiel } from '../../user/model/user';
import { TeamService } from '../../team/service/team.service';
import { JongereContext, TeamlidContext } from '../../shared/model/context';
import { UserService } from '../../user/service/user.service';
import { Groepsgesprek } from '../model/groepsgesprek';
import { EenOpEenGesprek } from '../model/eenOpEenGesprek';
import { OpenGesprek } from '../model/OpenGesprek';
import { Capacitor, CapacitorHttp, HttpHeaders } from '@capacitor/core';
import { MatomoTracker } from 'ngx-matomo-client';
import { JongereProfiel } from '../../profiel/model/profiel';

export type BerichtUploadEvent =
  | {
      type: 'progress';
      number: number;
    }
  | {
      type: 'response';
      number: number;
      bericht: Bericht;
    };

@Injectable({
  providedIn: 'root',
})
export class BerichtenService {
  readonly MATOMO_CATEGORY = 'berichten';

  constructor(
    private readonly http: HttpClient,
    private readonly contextService: ContextService,
    private readonly teamService: TeamService,
    private readonly userService: UserService,
    private readonly tracker: MatomoTracker,
  ) {}

  getGesprekken(): Observable<Gesprek[]> {
    return combineLatest([
      this.contextService.contextWithJongere$(),
      this.userService.userWithProfiel$(),
    ]).pipe(
      take(1),
      mergeMap(([context, user]) => {
        let ownerId;
        let externeId;

        switch (context.type) {
          case 'jongere':
            ownerId = context.contextId;
            externeId = context.contextId;
            break;
          case 'teamlid':
            ownerId = context.contextId;
            externeId = user.profiel.id;
            break;
        }

        return this.http
          .get<Array<GesprekDto>>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken`,
            {
              params: {
                ownerId,
                externeId,
              },
            },
          )
          .pipe(
            mergeMap((gesprekken) =>
              combineLatest(gesprekken.map((gesprek) => this.enrichGesprekDto(context, gesprek))),
            ),
            map((gesprekken) => [
              ...gesprekken.sort((a, b) =>
                (a.laatsteBericht?.timestamp ?? a.aangemaaktOp) <
                (b.laatsteBericht?.timestamp ?? b.aangemaaktOp)
                  ? 1
                  : -1,
              ),
            ]),
            defaultIfEmpty([]),
          );
      }),
    );
  }

  getGesprekPartners(jongereId: ProfielId, gesprek: GesprekDto): Observable<TeamlidDeelnemer[]> {
    const partners = gesprek.deelnemers.filter((deelnemer) => deelnemer.id !== jongereId);

    return combineLatest(
      partners.map((partner) =>
        this.teamService.getTeamlidInCurrentContext(partner.id).pipe(
          map((teamlid) =>
            asType<TeamlidDeelnemer>({
              ...teamlid,
              deelnemerId: partner.id,
              channel: partner.channel,
            }),
          ),
        ),
      ),
    ).pipe(defaultIfEmpty([]));
  }

  getBerichten(gesprekId: GesprekId, pageNumber: number): Observable<BerichtenPage> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtenPage>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
            { params: { pageNumber } },
          ),
        ),
      );
  }

  getBlobString(gesprekId: GesprekId, berichtId: BerichtId, bestandId: string): Observable<string> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          from(
            CapacitorHttp.get({
              url: `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}/bestand/${bestandId}`,
              responseType: 'blob',
            }),
          ),
        ),
      )
      .pipe(
        map((response) => {
          return response.data;
        }),
      );
  }

  getBericht(gesprekId: GesprekId, berichtId: BerichtId): Observable<BerichtDto> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtDto>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}`,
          ),
        ),
      );
  }

  deleteBericht(gesprekId: GesprekId, berichtId: BerichtId): Observable<void> {
    this.tracker.trackEvent(this.MATOMO_CATEGORY, 'verwijder-bericht');

    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.delete<void>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}`,
          ),
        ),
      );
  }

  updateGesprek(gesprekId: GesprekId, gesprekUpdate: GesprekUpdate): Observable<void> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.put<void>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}`,
            asType<GesprekUpdateDto>(gesprekUpdate),
          ),
        ),
      );
  }

  getBerichtenPage(gesprekId: GesprekId): Observable<BerichtenPage> {
    const params = new HttpParams().set('pageNumber', 0);

    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtenPage>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
            { params },
          ),
        ),
      );
  }

  getBerichtenPageSinceTime(gesprekId: GesprekId, sinceTime: string): Observable<BerichtenPage> {
    const params = new HttpParams().set('sinceTime', encodeURIComponent(sinceTime));
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtenPage>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
            { params },
          ),
        ),
      );
  }

  getBerichtenPageBeforeTime(gesprekId: GesprekId, beforeTime: string): Observable<BerichtenPage> {
    const params = new HttpParams().set('beforeTime', encodeURIComponent(beforeTime));

    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<BerichtenPage>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
            { params },
          ),
        ),
      );
  }

  stuurTekstBericht(
    gesprekId: GesprekId,
    createBerichtDto: CreateBerichtDto,
  ): Observable<BerichtDto> {
    this.tracker.trackEvent(this.MATOMO_CATEGORY, 'bericht-verzonden', 'tekst');

    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.post<BerichtDto>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten`,
            createBerichtDto,
          ),
        ),
      );
  }

  addReactieBericht(
    gesprekId: GesprekId,
    berichtId: BerichtId,
    createReactieBerichtDto: CreateReactieBerichtDto,
  ): Observable<Bericht> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.put<Bericht>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}/reacties`,
          createReactieBerichtDto,
        ),
      ),
      tap(() => this.tracker.trackEvent(this.MATOMO_CATEGORY, 'reactie-aangemaakt')),
    );
  }

  deleteReactieBericht(gesprekId: GesprekId, berichtId: BerichtId): Observable<Bericht> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) => {
        return this.http.delete<Bericht>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}/reacties`,
        );
      }),
      tap(() => this.tracker.trackEvent(this.MATOMO_CATEGORY, 'reactie-verwijderd')),
    );
  }

  getGesprek(gesprekId: GesprekId): Observable<Gesprek> {
    return combineLatest([
      this.getGesprekDto(gesprekId),
      this.contextService.contextWithJongere$(),
    ]).pipe(
      switchMap(([gesprekDto, context]) => {
        return this.enrichGesprekDto(context, gesprekDto);
      }),
    );
  }

  stuurBestandBericht(
    gesprekId: GesprekId,
    createBestandBericht: CreateBestandBericht,
  ): Observable<BerichtUploadEvent> {
    const data: FormData = new FormData();

    data.append('bestand', createBestandBericht.bestand);
    if (Capacitor.isNativePlatform()) {
      return this.contextService.contextWithJongere$().pipe(
        switchMap((context) => {
          let httpParams = new HttpParams();
          if (createBestandBericht.origineelBerichtId) {
            httpParams = httpParams.set(
              'origineelBerichtId',
              createBestandBericht.origineelBerichtId,
            );
          }
          httpParams = httpParams
            .set('type', createBestandBericht.type)
            .set('deelnemerId', createBestandBericht.deelnemerId);
          return this.http
            .put<BerichtDto>(
              `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/bestand`,
              data,
              {
                params: httpParams,
                headers: { 'Content-Type': 'multipart/form-data; boundary=stuurBestandBericht' },
              },
            )
            .pipe(
              map((bericht) => {
                return asType<BerichtUploadEvent>({
                  type: 'response',
                  number: 100,
                  bericht,
                });
              }),
            );
        }),
        tap(() =>
          this.tracker.trackEvent(
            this.MATOMO_CATEGORY,
            'bericht-verzonden',
            createBestandBericht.type.toLowerCase(),
          ),
        ),
      );
    } else {
      return this.contextService.contextWithJongere$().pipe(
        switchMap((context) => {
          let httpParams = new HttpParams()
            .set('type', String(createBestandBericht.type))
            .set('deelnemerId', String(createBestandBericht.deelnemerId));
          if (createBestandBericht.origineelBerichtId) {
            httpParams = httpParams.set(
              'origineelBerichtId',
              String(createBestandBericht.origineelBerichtId),
            );
          }
          return this.http.put<BerichtDto>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/bestand`,
            data,
            {
              reportProgress: true,
              observe: 'events',
              params: httpParams,
            },
          );
        }),
        map((event) => this.getEventMessage(event)),
        tap(() =>
          this.tracker.trackEvent(
            this.MATOMO_CATEGORY,
            'bericht-verzonden',
            createBestandBericht.type.toLowerCase(),
          ),
        ),
      );
    }
  }

  addGroepsgesprek(groepsgesprek: GroepsgesprekInsert): Observable<GesprekId> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.post<GesprekDto>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken`,
          asType<GroepsgesprekInsertDto>(groepsgesprek),
        ),
      ),
      map((gesprek) => gesprek.id),
      tap(() => this.tracker.trackEvent(this.MATOMO_CATEGORY, 'groepsgesprek-aangemaakt')),
    );
  }

  archiveerGesprek(gesprekId: GesprekId): Observable<Gesprek> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.put<Gesprek>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/archiveer`,
          {},
        ),
      ),
      tap(() => this.tracker.trackEvent(this.MATOMO_CATEGORY, 'groepsgesprek-gearchiveerd')),
    );
  }

  verlaatGesprek(gesprekId: GesprekId): Observable<Gesprek> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.put<Gesprek>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/verlaat`,
          {},
        ),
      ),
      tap(() => this.tracker.trackEvent(this.MATOMO_CATEGORY, 'groepsgesprek-verlaten')),
    );
  }

  getDownloadLink(
    gesprekId: GesprekId,
    berichtId: BerichtId,
    bestandId: string,
  ): Observable<string> {
    return this.contextService.contextWithJongere$().pipe(
      map((context) => {
        return `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/berichten/${berichtId}/bestand/${bestandId}`;
      }),
    );
  }

  getGesprekOfDoel(doelId: string): Observable<OpenGesprek> {
    return this.http.get<GesprekDto>(`${environment.API_BASE_URL}/api/gesprek/doel/${doelId}`).pipe(
      map((gesprekDto) => {
        if (gesprekDto.type !== 'DOEL') {
          throw new Error('Het is niet mogelijk om dit gesprek te gebruiken.');
        }
        return new OpenGesprek(
          gesprekDto.id,
          gesprekDto.aangemaaktOp,
          gesprekDto.status,
          gesprekDto.channel ?? '',
        );
      }),
    );
  }

  uploadGesprekFoto(gesprekId: GesprekId, gesprekFoto: File): Observable<void> {
    let headers: HttpHeaders = {};
    if (Capacitor.isNativePlatform()) {
      headers = { 'Content-Type': 'multipart/form-data; boundary=uploadGesprekFoto' };
    }
    const data: FormData = new FormData();

    data.append('file', gesprekFoto);

    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.put<void>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/foto`,
          data,
          { headers },
        ),
      ),
      tap(() => this.tracker.trackEvent(this.MATOMO_CATEGORY, 'groepsgesprek-foto-geupload')),
    );
  }

  deleteGesprekFoto(gesprekId: GesprekId, gesprekFotoId: FotoId): Observable<void> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http.delete<void>(
          `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/foto/${gesprekFotoId}`,
        ),
      ),
      tap(() => this.tracker.trackEvent(this.MATOMO_CATEGORY, 'groepsgesprek-foto-verwijderd')),
    );
  }

  getGesprekFotoUrl(
    gesprekId: GesprekId,
    gesprekFotoId: FotoId,
    gesprekImageVariant: GesprekImageVariant,
  ): Observable<string> {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        map(
          (context) =>
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/foto/${gesprekFotoId}?variant=${gesprekImageVariant}`,
        ),
      );
  }

  createVideogesprek(gesprekId: GesprekId): Observable<Gesprek> {
    return this.contextService.contextWithJongere$().pipe(
      switchMap((context) =>
        this.http
          .post<GesprekDto>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}/videogesprek`,
            {},
          )
          .pipe(switchMap((gesprekDto) => this.enrichGesprekDto(context, gesprekDto))),
      ),
      tap(() => this.tracker.trackEvent(this.MATOMO_CATEGORY, 'videogesprek-aangemaakt')),
    );
  }

  private getGesprekDto(gesprekId: GesprekId) {
    return this.contextService
      .contextWithJongere$()
      .pipe(
        switchMap((context) =>
          this.http.get<GesprekDto>(
            `${environment.API_BASE_URL}/api/jongere/${context.contextId}/gesprekken/${gesprekId}`,
          ),
        ),
      );
  }

  private enrichGesprekDto(
    context: JongereContext | TeamlidContext,
    gesprekDto: GesprekDto,
  ): Observable<Gesprek> {
    return this.userService.userWithProfiel$().pipe(
      switchMap((user) => {
        const jongere = this.getJongere(context, user);

        const jongereDeelnemer = gesprekDto.deelnemers.find(
          (deelnemer) => deelnemer.id === jongere.id,
        );

        if (!jongereDeelnemer) {
          throw new Error('Jongere is geen deelnemer van gesprek');
        }

        return this.getGesprekPartners(jongere.id, gesprekDto).pipe(
          map((partners) => {
            if (gesprekDto.type === 'GROEPSGESPREK') {
              return new Groepsgesprek(
                gesprekDto.id,
                partners,
                asType<JongereDeelnemer>({
                  ...jongere,
                  deelnemerId: jongereDeelnemer.id,
                  channel: jongereDeelnemer.channel,
                }),
                gesprekDto.aangemaaktOp,
                gesprekDto.status,
                gesprekDto.laatsteBericht,
                gesprekDto.fotoId,
              );
            } else {
              return new EenOpEenGesprek(
                gesprekDto.id,
                partners,
                asType<JongereDeelnemer>({
                  ...jongere,
                  deelnemerId: jongereDeelnemer.id,
                  channel: jongereDeelnemer.channel,
                }),
                gesprekDto.aangemaaktOp,
                gesprekDto.status,
                gesprekDto.laatsteBericht,
                gesprekDto.videogesprekId,
              );
            }
          }),
        );
      }),
    );
  }

  private getJongere(
    currentContext: JongereContext | TeamlidContext,
    userWithProfiel: UserWithProfiel,
  ): JongereProfiel {
    switch (currentContext.type) {
      case 'jongere':
        return { viewType: 'JONGERE', ...userWithProfiel.profiel };
      case 'teamlid':
        return currentContext.jongereProfiel;
    }
  }

  private getEventMessage(event: HttpEvent<Bericht>): BerichtUploadEvent {
    switch (event.type) {
      case HttpEventType.UploadProgress: {
        return {
          type: 'progress',
          number: event.total ? Math.round((100 * event.loaded) / event.total) : 0,
        };
      }
      case HttpEventType.Response: {
        if (event.body) {
          return {
            type: 'response',
            number: 100,
            bericht: event.body,
          };
        } else {
          return {
            type: 'progress',
            number: 0,
          };
        }
      }
      default: {
        return {
          type: 'progress',
          number: 0,
        };
      }
    }
  }
}
