<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let data">
      <parkour-page-with-hero
        [heroImage]="doelHeroSources"
        [title]="'doelen.doelen-page.title' | translate"
      >
        @if (
          data.actieveDoelen.length > 0 ||
          data.behaaldeDoelen.length > 0 ||
          data.voorstellen.length > 0
        ) {
          <ng-container *parkourSectionContent="{ data: segmentItems$ | async }; let segmentItems">
            <div class="container-1-col">
              <parkour-segment
                (changed)="segmentChanged($event)"
                [defaultValue]="activeSegmentItem"
                [items]="segmentItems"
              />
            </div>

            <div [ngSwitch]="activeSegmentItem" class="container-1-col">
              <div *ngSwitchCase="'actieveDoelen'" class="flex flex-col gap-y-6">
                <parkour-button class="w-full" iconAfter="plus-circle" link="add">
                  {{ 'doelen.doelen-page.nieuw-doel' | translate }}
                </parkour-button>

                @if (data.voorstellen.length > 0) {
                  <ul class="space-y-6">
                    @for (voorstel of data.voorstellen; track voorstel.id) {
                      <li>
                        <parkour-doel-voorstel
                          (confirm)="onVoorstelConfirm(voorstel)"
                          (reject)="onVoorstelReject(voorstel)"
                          [canConfirm]="true"
                          [doelSuggestie]="voorstel"
                        />
                      </li>
                    }
                  </ul>
                }

                @if (data.actieveDoelen.length > 0) {
                  <ul class="space-y-6">
                    <li *ngFor="let doel of data.actieveDoelen">
                      <parkour-doel-list-item
                        [doel]="doel"
                        [showNewIndicator]="
                          (ongelezenDoelMeldingen$ | hasMeldingenForDoel: doel.id | async) ?? false
                        "
                      ></parkour-doel-list-item>
                    </li>
                  </ul>
                } @else {
                  <p>{{ 'doelen.doelen-page.geen-doelen' | translate }}</p>
                }
              </div>

              <div *ngSwitchCase="'behaaldeDoelen'" class="flex flex-col gap-y-8">
                @if (data.behaaldeDoelen.length > 0) {
                  <ul class="space-y-6">
                    <li *ngFor="let doel of data.behaaldeDoelen">
                      <parkour-doel-list-item
                        [doel]="doel"
                        [showNewIndicator]="
                          (ongelezenDoelMeldingen$ | hasMeldingenForDoel: doel.id | async) ?? false
                        "
                      ></parkour-doel-list-item>
                    </li>
                  </ul>
                } @else {
                  <p>{{ 'doelen.behaalde-doelen-page.geen-doelen' | translate }}</p>
                }
              </div>
            </div>
          </ng-container>
        } @else {
          <div class="container-1-col space-y-8">
            <p class="text-center">{{ 'doelen.doelen-page.geen-doelen' | translate }}</p>

            <parkour-button class="w-full block" iconAfter="plus-circle" link="add">
              {{ 'doelen.doelen-page.nieuw-doel' | translate }}
            </parkour-button>

            <parkour-block-placeholder
              [sources]="{
                default: {
                  src: '/assets/doelen/placeholder/placeholder_doelen_default.svg',
                },
                md: {
                  src: '/assets/doelen/placeholder/placeholder_doelen_md.svg',
                },
              }"
              alt=""
              class="block"
            />
          </div>
        }
      </parkour-page-with-hero>
    </ng-container>
  </parkour-page-layout>
</ion-content>
