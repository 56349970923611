import { Injectable } from '@angular/core';
import {
  catchError,
  defaultIfEmpty,
  filter,
  interval,
  map,
  of,
  ReplaySubject,
  switchMap,
  take,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { LoggingService } from '../../core/logging.service';
import { isNativeApp } from '../../utils';
import { UserService } from '../../user/service/user.service';

const ONE_MINUTE = 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class ActivityDetectionService {
  readonly lastEvent$ = new ReplaySubject<UIEvent>(1, ONE_MINUTE); // Store the last event for 30 seconds
  public readonly active$ = this.lastEvent$.pipe(
    map(() => true),
    defaultIfEmpty(false),
  );

  constructor(
    private readonly http: HttpClient,
    private readonly loggingService: LoggingService,
    private readonly userService: UserService,
  ) {}

  private listenForActivity(): void {
    addEventListener('touchstart', (e) => this.lastEvent$.next(e));
    addEventListener('mousemove', (e) => this.lastEvent$.next(e));
    addEventListener('keydown', (e) => this.lastEvent$.next(e));
  }

  private sessionAlwaysActive = false;

  private refreshSession() {
    return this.http.post(`${environment.API_BASE_URL}/api/auth/session/refresh`, {}).pipe(
      catchError((e: unknown) => {
        this.loggingService.error('Failed to refresh session', e);
        return of(undefined);
      }),
    );
  }

  public initialize(): void {
    if (isNativeApp()) {
      this.listenForActivity();

      interval(ONE_MINUTE)
        .pipe(
          switchMap(() => this.active$.pipe(take(1))),
          filter((active) => active || this.sessionAlwaysActive),
          switchMap(() => this.userService.getCurrentUser$().pipe(take(1))),
          filter((user) => user.isIngelogd()),
          switchMap(() => this.refreshSession()),
        )
        .subscribe();
    }
  }

  public startKeepingSessionActive() {
    this.sessionAlwaysActive = true;
  }

  public stopKeepingSessionActive() {
    this.sessionAlwaysActive = false;
  }
}
