import { Component, ViewChild } from '@angular/core';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  ViewWillEnter,
} from '@ionic/angular/standalone';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import { TeamService } from '../../../team/service/team.service';
import { isProfessioneel } from 'parkour-web-app-dto';
import { GroepsgesprekFormComponent } from '../../component/groepsgesprek-add-form/groepsgesprek-form.component';
import { GroepsgesprekInsert } from '../../model/gesprek';
import { BerichtenService } from '../../service/berichten.service';
import { ContextService } from '../../../shared/services/context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamlidProfiel } from '../../../team/model/teamlid.model';
import { UserWithProfiel } from '../../../user/model/user';
import { UserService } from '../../../user/service/user.service';
import { JongereContext, TeamlidContext } from '../../../shared/model/context';
import { combineResults, Result } from '../../../utils';
import { ParkourBackButtonDirective } from '../../../shared/directives/parkour-back-button.directive';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ParkourHeaderActionComponent } from '../../../shared/components/parkour-header-action/parkour-header-action.component';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../shared/directives/page-content.directive';
import {
  ParkourAvatarComponent,
  ParkourIconComponent,
  ParkourInfoCardComponent,
  ParkourPageGradientComponent,
} from '@parkour/ui';
import { ProfielFotoPipe } from '../../../shared/pipes/profiel-foto.pipe';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { ParkourProfielBlockComponent } from '../../../shared/components/parkour-profiel-block/parkour-profiel-block.component';
import { ProfielPipe } from '../../../shared/pipes/profiel.pipe';

@Component({
  standalone: true,
  templateUrl: './groepsgesprek-add.page.html',
  imports: [
    ParkourBackButtonDirective,
    TranslateModule,
    AsyncPipe,
    ParkourHeaderActionComponent,
    NgIf,
    PageLayoutComponent,
    PageContentDirective,
    ParkourInfoCardComponent,
    ParkourAvatarComponent,
    ParkourIconComponent,
    ProfielFotoPipe,
    ProfielnaamPipe,
    ParkourProfielBlockComponent,
    ProfielPipe,
    GroepsgesprekFormComponent,
    ParkourPageGradientComponent,
    IonBackButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent,
  ],
})
export class GroepsgesprekAddPage implements ViewWillEnter {
  @ViewChild('groepsgesprekForm') groepsgesprekFormComponent?: GroepsgesprekFormComponent;
  teamleden$: Observable<TeamlidProfiel[]> = new Observable<TeamlidProfiel[]>();
  context$ = this.contextService.context$;
  data$: Observable<
    Result<{
      teamleden: Array<TeamlidProfiel>;
      context: JongereContext | TeamlidContext;
      user: UserWithProfiel;
    }>
  > = new Observable();

  constructor(
    private readonly teamService: TeamService,
    private readonly berichtenService: BerichtenService,
    private readonly contextService: ContextService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  ionViewWillEnter(): void {
    this.data$ = combineLatest({
      teamleden: this.teamService.getMyTeamleden(),
      user: this.userService.userWithProfiel$(),
      context: this.contextService.contextWithJongere$(),
    }).pipe(
      map((data) => combineResults(data)),
      shareReplay(1),
    );
  }

  onSubmit(groepsgesprek: GroepsgesprekInsert) {
    this.berichtenService.addGroepsgesprek(groepsgesprek).subscribe((id) => {
      this.groepsgesprekFormComponent?.deelnemersFormGroup.markAsPristine();
      this.router.navigate(['..', 'gesprek', id], { relativeTo: this.route });
    });
  }

  getTeamledenOptions(teamleden: Array<TeamlidProfiel>, user: UserWithProfiel) {
    return teamleden.filter(
      (teamlid) => teamlid.id !== user.profiel.id && teamlid.status === 'ACTIEF',
    );
  }

  getBeblokkeerdeTeamleden(teamleden: Array<TeamlidProfiel>) {
    return teamleden.filter((teamlid) => teamlid.status === 'GEBLOKKEERD');
  }

  protected readonly isProfessioneel = isProfessioneel;
}
