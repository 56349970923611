<swiper-container
  [attr.a11y-next-slide-message]="'buttons.volgende' | translate"
  [attr.a11y-prev-slide-message]="'buttons.vorige' | translate"
  a11y="true"
  class="artikels-preview [--swiper-navigation-sides-offset:0]"
  grab-cursor="true"
  init="false"
  navigation="true"
  pagination="true"
  parkourCarouselElement
  slides-per-view="1.35"
  space-between="8"
  watch-overflow="true"
>
  @for (artikel of artikels; track artikel.id) {
    <swiper-slide class="artikels-preview__slide transition-all">
      <parkour-artikel-preview-card
        (clickedBookmark)="onClickedBookmark($event, artikel.id, artikel.bookmarkId)"
        [image]="{ src: artikel.image, alt: '' }"
        [isBookmarked]="!!artikel.bookmarkId"
        [link]="['artikel', artikel.id.toString()]"
        [themes]="artikel.themas"
        [title]="artikel.title"
        class="block h-full"
      />
    </swiper-slide>
  }
</swiper-container>
