<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="['../..']" />
    </ion-buttons>

    <ng-container *parkourShowIfSuccessful="mainPageData$ | async; let gebeurtenis">
      <ion-title aria-level="1" role="heading">
        @switch (gebeurtenis.type) {
          @case ('EYOUTH') {
            {{ 'verhaal.eyouth-gebeurtenis-details-page.title' | translate }}
          }
          @case ('PARKOUR') {
            {{ 'verhaal.gebeurtenis-details-page.title' | translate }}
          }
        }
      </ion-title>
    </ng-container>

    <ion-buttons slot="end">
      <parkour-header-action
        *ngIf="mainPageData$ | async"
        [label]="'verhaal.gebeurtenis-details-page.gebeurtenis-edit' | translate"
        [link]="['wijzig']"
        icon="edit"
        testId="edit"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" [parkourRefresh]="onPageRefresh">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let gebeurtenis">
      <article>
        @switch (gebeurtenis.type) {
          @case ('EYOUTH') {
            <parkour-jeugdhulphistoriek-details-component [gebeurtenis]="gebeurtenis" />
          }
          @case ('PARKOUR') {
            <parkour-gebeurtenis-details-component [gebeurtenis]="gebeurtenis" />
          }
        }
      </article>
    </ng-container>
  </parkour-page-layout>
</ion-content>
