@if (active) {
  <div class="flex flex-col gap-y-8" role="tabpanel" tabindex="0">
    @if (this.organisationBookmarks$ | async; as organisationBookmarks) {
      @if (organisationBookmarks.length) {
        <parkour-button [link]="['hulplijnen']" class="w-full">
          {{ 'buttons.find-helplines' | translate }}
        </parkour-button>

        <ul class="flex flex-col gap-y-2" role="feed">
          @for (bewaardeOrganisation of organisationBookmarks; track bewaardeOrganisation.id) {
            <li>
              <parkour-helpline-card-item
                [segmentsToHulplijn]="['hulplijn', String(bewaardeOrganisation.id)]"
                [organisation]="bewaardeOrganisation"
              />
            </li>
          }
        </ul>
      }
      @if (organisationBookmarks.length === 0) {
        <p class="text-center">{{ 'bewaard.no-helplines' | translate }}</p>

        <parkour-button [link]="['hulplijnen']" class="w-full">
          {{ 'buttons.find-helplines' | translate }}
        </parkour-button>

        <parkour-block-placeholder
          [sources]="{
            default: {
              src: '/assets/bewaard/placeholder/placeholder_bewaarde_hulplijnen_default.svg',
            },
            md: {
              src: '/assets/bewaard/placeholder/placeholder_bewaarde_hulplijnen_md.svg',
            },
          }"
          alt=""
          class="block"
        />
      }
    }
  </div>
}
