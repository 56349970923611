import { Component } from '@angular/core';
import { combineLatest, map, Observable, switchMap, take, takeUntil } from 'rxjs';
import { Doel, DoelId } from '../../../doel-shared/model/doel';
import { DoelenService } from '../../../doel-shared/service/doelen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { validateDoelId } from '../../../utils';
import { ReactieService } from '../../../doel-shared/service/reactie.service';
import { InteractieEmoji, ReactieDto } from 'parkour-web-app-dto';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  ViewDidEnter,
} from '@ionic/angular/standalone';
import { BerichtenService } from '../../../../bericht/service/berichten.service';
import { UserService } from '../../../../user/service/user.service';
import { meldingenForPage } from '../../../../meldingen/config';
import { SheetService } from '../../../../shared/services/parkour-sheet.service';
import { TranslateModule } from '@ngx-translate/core';
import { ParkourBackButtonDirective } from '../../../../shared/directives/parkour-back-button.directive';
import { AsyncPipe } from '@angular/common';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { ParkourLoadingSpinnerComponent, ParkourPageImageComponent } from '@parkour/ui';
import { DoelDetailsComponent } from '../../../doel-shared/component/doel-details/doel-details.component';
import { PageSectionDirective } from '../../../../shared/directives/page-section.directive';
import { ReactieOverviewComponent } from '../../../doel-shared/component/reactie-overview/reactie-overview.component';
import { DoelBerichtenComponent } from '../../../../bericht/component/doel-berichten/doel-berichten.component';

import { CustomPageTitle, PageTitle } from '../../../../shared/custom-page-title';
import { ParkourDataPage } from '../../../../shared/parkour-data-page';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import {
  MarksMeldingenAsReadOnView,
  MeldingenToMarkAsRead,
} from '../../../../shared/marks-meldingen-as-read';
import { themaMapping } from '../../../../home/model/thema.const';

@Component({
  standalone: true,
  templateUrl: './doel-teamlid.page.html',
  imports: [
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    ParkourBackButtonDirective,
    IonTitle,
    TranslateModule,
    AsyncPipe,
    IonContent,
    PageLayoutComponent,
    PageContentDirective,
    ParkourPageImageComponent,
    DoelDetailsComponent,
    PageSectionDirective,
    ReactieOverviewComponent,
    DoelBerichtenComponent,
    ParkourLoadingSpinnerComponent,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
  ],
})
export class DoelTeamlidPage
  extends ParkourDataPage<Doel>
  implements CustomPageTitle, MarksMeldingenAsReadOnView, ViewDidEnter
{
  id$ = this.route.paramMap.pipe(
    take(1),
    map((params) => validateDoelId(params.get('id'))),
  );
  interacties$ = this.extraPageData(
    this.id$.pipe(switchMap((id) => this.interactieService.getReactiesForDoel(id))),
  );
  gesprekData$ = this.extraPageData(
    this.id$.pipe(
      switchMap((id) =>
        combineLatest({
          user: this.userService.userWithProfiel$(),
          gesprek: this.berichtenService.getGesprekOfDoel(id),
        }),
      ),
    ),
  );
  protected readonly themaMapping = themaMapping;

  constructor(
    private readonly doelenService: DoelenService,
    private readonly route: ActivatedRoute,
    private readonly interactieService: ReactieService,
    private readonly berichtenService: BerichtenService,
    private readonly userService: UserService,
    private readonly sheetService: SheetService,
    private readonly router: Router,
  ) {
    super();
  }

  override retrieveMainData(): Observable<Doel> {
    return this.id$.pipe(switchMap((id) => this.doelenService.getDoel(id)));
  }

  getCustomPageTitle(): Observable<PageTitle> {
    return this.generateDefaultTitle((doel) => doel.naam, 'doelen.doel-detail-page.title');
  }

  getMeldingenToMarkAsRead() {
    return this.mainPageData$.pipe(
      map(
        (doel) =>
          new MeldingenToMarkAsRead(
            meldingenForPage.DOELEN_TAB.DOELEN_PAGE.DOEL_PAGE.specificMeldingen,
            (melding) => doel.success && melding.metaData.doelId === doel.value.id,
          ),
      ),
    );
  }

  ionViewDidEnter() {
    this.mainPageData$.pipe(
      map((result) => {
        if (this.route.snapshot.queryParams['sheet'] === 'true' && result.success) {
          this.sheetService.open();

          this.sheetService.isOpen.pipe(takeUntil(this.pageWillLeave$)).subscribe((isOpen) => {
            if (!isOpen) {
              this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                  sheet: false,
                },
                queryParamsHandling: 'merge',
              });
            }
          });
        }
      }),
    );
  }

  onReactieSelect(doelId: DoelId, reactieEmoji: InteractieEmoji) {
    this.interactieService.updateReactie(reactieEmoji, doelId).pipe(take(1)).subscribe();
  }

  onReactieUnSelect(doelId: DoelId, reactie: ReactieDto) {
    this.interactieService.verwijderReactie(reactie, doelId).pipe(take(1)).subscribe();
  }
}
