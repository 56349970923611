import { Component, Input } from '@angular/core';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { GesprekFotoBadgeComponent } from '../../../bericht/component/gesprek-foto-badge/gesprek-foto-badge.component';
import {
  GedeeldeResourceBericht,
  Gesprek,
  isSupportedBericht,
} from '../../../bericht/model/gesprek';
import { ParkourActionComponent, ParkourNewIndicatorComponent } from '@parkour/ui';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { VideogesprekDurationPipe } from '../../../bericht/pipes/videogesprek-duration.pipe';
import { UserWithProfiel } from '../../../user/model/user';
import { GesprekId, MeldingOfType } from 'parkour-web-app-dto';
import { BerichtenDatePipe } from '../../../bericht/pipes/berichten-date.pipe';
import { ProfielnaamFromProfielidPipe } from '../../pipes/profielnaam-from-profielid.pipe';
import { getIsTimeToday } from '../../../utils';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'parkour-gesprek-overview-card',
  standalone: true,
  imports: [
    AsyncPipe,
    GesprekFotoBadgeComponent,
    TranslateModule,
    VideogesprekDurationPipe,
    NgIf,
    NgTemplateOutlet,
    ParkourActionComponent,
    ParkourNewIndicatorComponent,
  ],
  providers: [BerichtenDatePipe, VideogesprekDurationPipe, ProfielnaamFromProfielidPipe],
  templateUrl: './gesprek-overview-card.component.html',
})
export class GesprekOverviewCardPage {
  @Input({ required: true }) gesprek!: Gesprek;
  @Input({ required: true }) user!: UserWithProfiel;
  @Input() ongelezenBerichtenMeldingen?:
    | MeldingOfType<'NIEUW_CHAT_BERICHT' | 'NIEUW_CHAT_REACTIE' | 'VIDEOGESPREK_GESTART'>[]
    | null;
  @Input() isStaticCard: boolean = false;

  constructor(
    private readonly berichtenDatePipe: BerichtenDatePipe,
    private readonly translate: TranslatePipe,
    private readonly videoGesprekDuration: VideogesprekDurationPipe,
    private readonly profielnaamFromProfielId: ProfielnaamFromProfielidPipe,
  ) {}

  shouldShowNewIndicator(
    gesprekId: string,
    ongelezenMeldingen: {
      metaData: {
        gesprekId: GesprekId;
      };
    }[],
  ): boolean {
    if (ongelezenMeldingen) {
      return (
        ongelezenMeldingen.find((melding) => melding.metaData.gesprekId === gesprekId) !== undefined
      );
    } else {
      return false;
    }
  }

  getLaatsteBerichtTijdstip(gesprek: Gesprek) {
    return this.berichtenDatePipe.transform(
      gesprek.laatsteBericht?.timestamp ?? gesprek.aangemaaktOp,
    );
  }

  getGedeeldeResourceInhoud(laatsteBericht: GedeeldeResourceBericht): Observable<string> {
    switch (laatsteBericht.resourceType) {
      case 'ARTIKEL':
        return of(this.translate.transform('berichten.gesprekken-overview-page.artikel-shared'));
      case 'HULPLIJN':
        return of(this.translate.transform('berichten.gesprekken-overview-page.hulplijn-shared'));
    }
  }

  getLaatsteBerichtInhoud(): Observable<string> {
    if (this.gesprek.laatsteBericht && isSupportedBericht(this.gesprek.laatsteBericht)) {
      switch (this.gesprek.laatsteBericht.type) {
        case 'GEMASKEERD':
          return of(this.translate.transform('berichten.gesprek-page.gemaskeerd'));
        case 'TEKST':
          return of(this.gesprek.laatsteBericht.inhoud);
        case 'SPRAAK':
          return of(
            this.translate.transform('berichten.gesprekken-overview-page.laatste-bericht-spraak'),
          );
        case 'ATTACHMENT':
          return of(this.gesprek.laatsteBericht.bestand.filename);
        case 'GEDEELDE_RESOURCE':
          return this.getGedeeldeResourceInhoud(this.gesprek.laatsteBericht);
        case 'EVENT':
          switch (this.gesprek.laatsteBericht.gesprekEvent.type) {
            case 'DEELNEMER_LEFT':
              return this.profielnaamFromProfielId
                .transform(this.gesprek.laatsteBericht.gesprekEvent.deelnemerId)
                .pipe(
                  map((profielNaam) =>
                    this.translate.transform(
                      'berichten.gesprekken-overview-page.laatste-bericht-deelnemer-left',
                      {
                        naam: profielNaam,
                      },
                    ),
                  ),
                );
            case 'DEELNEMER_REJOINED':
              return this.profielnaamFromProfielId
                .transform(this.gesprek.laatsteBericht.gesprekEvent.deelnemerId)
                .pipe(
                  map((profielNaam) =>
                    this.translate.transform(
                      'berichten.gesprekken-overview-page.laatste-bericht-deelnemer-rejoined',
                      { naam: profielNaam },
                    ),
                  ),
                );
            case 'VIDEOGESPREK_ENDED':
              return of(
                this.translate.transform(
                  'berichten.gesprekken-overview-page.laatste-bericht-videogesprek-ended',
                  {
                    duration: this.videoGesprekDuration.transform(
                      this.gesprek.laatsteBericht.gesprekEvent.startTimestamp,
                      this.gesprek.laatsteBericht.gesprekEvent.endTimestamp,
                    ),
                  },
                ),
              );
          }
      }
    } else {
      return of(
        this.translate.transform('berichten.gesprekken-overview-page.laatste-bericht-default'),
      );
    }
  }

  getAriaLabel() {
    if (this.gesprek.isGroepsgesprek()) {
      if (getIsTimeToday(this.gesprek.laatsteBericht?.timestamp ?? this.gesprek.aangemaaktOp)) {
        return this.translate.transform(
          'berichten.gesprekken-overview-page.groepsgesprek-card-time',
          {
            amount: this.gesprek.getDeelnemersAndJongere().length,
            gesprekNaam: this.gesprek.getGesprekNaam(this.user),
            bericht: this.getLaatsteBerichtInhoud(),
            time: this.getLaatsteBerichtTijdstip(this.gesprek),
          },
        );
      } else {
        return this.translate.transform(
          'berichten.gesprekken-overview-page.groepsgesprek-card-date',
          {
            amount: this.gesprek.getDeelnemersAndJongere().length,
            gesprekNaam: this.gesprek.getGesprekNaam(this.user),
            bericht: this.getLaatsteBerichtInhoud(),
            date: this.getLaatsteBerichtTijdstip(this.gesprek),
          },
        );
      }
    } else {
      if (getIsTimeToday(this.gesprek.laatsteBericht?.timestamp ?? this.gesprek.aangemaaktOp)) {
        return this.translate.transform('berichten.gesprekken-overview-page.gesprek-card-time', {
          gesprekNaam: this.gesprek.getGesprekNaam(this.user),
          bericht: this.getLaatsteBerichtInhoud(),
          time: this.getLaatsteBerichtTijdstip(this.gesprek),
        });
      } else {
        return this.translate.transform('berichten.gesprekken-overview-page.gesprek-card-date', {
          gesprekNaam: this.gesprek.getGesprekNaam(this.user),
          bericht: this.getLaatsteBerichtInhoud(),
          date: this.getLaatsteBerichtTijdstip(this.gesprek),
        });
      }
    }
  }
}
