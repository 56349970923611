import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Avatar, avatars } from 'parkour-web-app-dto';
import { ProfielFotoService } from '../../service/profiel-foto.service';
import { AsyncPipe, NgClass, NgForOf } from '@angular/common';
import { ParkourActionComponent, ParkourImageComponent } from '@parkour/ui';
import { SecureImagePipe } from '../../../shared/pipes/secure-image.pipe';

@Component({
  standalone: true,
  selector: 'parkour-profiel-avatar-select',
  templateUrl: './profiel-avatar-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ProfielAvatarSelectComponent),
    },
  ],
  imports: [
    NgClass,
    ParkourActionComponent,
    NgForOf,
    ParkourImageComponent,
    AsyncPipe,
    SecureImagePipe,
  ],
})
export class ProfielAvatarSelectComponent {
  @Input() public classes?: string;
  @Input({ required: true }) public items!: readonly Avatar[];
  @Input() required = false;
  @Input() isValid = true;
  @Input() disabled = false;
  inputValue = '';
  onChange!: (value: string) => void;
  onTouched?: () => void;
  currentFocusRadioItem: string | null = '';
  protected readonly alert = alert;
  protected readonly avatars = avatars;

  constructor(protected readonly profielFotoService: ProfielFotoService) {}

  private _value = '';

  get value(): string {
    return this._value;
  }

  @Input() set value(val: string) {
    this.writeValue(val);
  }

  writeValue(value: string) {
    this._value = value;
    this.inputValue = value;
  }

  registerOnChange(fn: () => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  handleClick(value: string) {
    if (!this.disabled) {
      this.writeValue(value);
      this.onChange(value);
    }
  }

  focus(value: string) {
    if (value) {
      this.currentFocusRadioItem = value;
    }
  }

  blur() {
    this.currentFocusRadioItem = null;
  }

  space($event: Event, value: string) {
    if (this.currentFocusRadioItem && this.currentFocusRadioItem === value) {
      $event.preventDefault();

      this.handleClick(value);
    }
  }

  convertAlt(value: string) {
    return value.replace(/_/g, ' ').replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    });
  }
}
