<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button [parkourBackButton]="['../..']" />
    </ion-buttons>

    <ion-title>{{ 'verhaal.drie-kolommen-gebeurtenis-details-page.title' | translate }} </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" [parkourRefresh]="onPageRefresh">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let gebeurtenis">
      <article>
        <parkour-page-image
          classes="absolute top-0 left-0 z-0"
          src="/assets/verhaal/background/background_verhaal_blur.jpg"
        />
        <parkour-drie-kolommen-gebeurtenis-details-component
          *ngIf="gebeurtenis && gebeurtenis.type === 'DRIE_KOLOMMEN'"
          [document]="gebeurtenis"
        />
      </article>
    </ng-container>
  </parkour-page-layout>
</ion-content>
