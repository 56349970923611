import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { asType, isNativeApp } from '../../utils';
import { ParkourToastService } from '@parkour/ui';
import { LoggingService } from '../../core/logging.service';
import { FileId, FotoId } from 'parkour-web-app-dto';
import { catchError, map, Observable, of, startWith, Subscriber, switchMap } from 'rxjs';
import { Capacitor, HttpHeaders } from '@capacitor/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { isFileValid } from '../file-validation';
import { HumanReadableError, TranslatedHumanReadableError } from '../../core/human-readable-error';
import {
  ConvertingImageData,
  ErroredImageData,
  ImageUploadData,
  NewlyUploadedImageData,
  UploadingImageData,
} from '../model/image-upload';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private NATIVE_FILEPATH_PREFIX = 'PARKOUR/';

  constructor(
    private readonly toastService: ParkourToastService,
    private readonly loggingService: LoggingService,
    private readonly http: HttpClient,
  ) {}

  async downloadFileFromUrl(url: string, fileName: string) {
    if (!isNativeApp() && url) {
      const link = document.createElement('a');
      link.download = fileName;
      link.href = url;
      link.click();
    } else if (isNativeApp() && url) {
      const path = await this.getUniqueFileName(this.NATIVE_FILEPATH_PREFIX, fileName);
      try {
        const downloadFileResult = await Filesystem.downloadFile({
          url: url,
          path: path,
          directory: Directory.Documents,
          recursive: true,
        });

        if (downloadFileResult && downloadFileResult.path) {
          await this.openFile(downloadFileResult.path);
        }
      } catch (e) {
        this.toastService.showToast({
          header: 'Er is een fout opgetreden',
          content: 'Momenteel is het niet mogelijk om het bestand te downloaden.',
          error: true,
        });
      }
    }
  }

  private async getUniqueFileName(basePath: string, fileName: string) {
    const parts = fileName.split('.');
    const initialFileName = parts[0];
    const extension = parts.slice(1).join('.');

    let currentFileName = fileName;
    let counter = 0;
    while (await this.checkIfFileExists(basePath + currentFileName)) {
      counter++;
      currentFileName = `${initialFileName}-(${counter}).${extension}`;
    }
    return `${basePath}${currentFileName}`;
  }

  private async checkIfFileExists(path: string): Promise<boolean> {
    try {
      await Filesystem.stat({
        path,
        directory: Directory.Documents,
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async downloadFile(href: string, fileName: string, nativeDestination: Directory) {
    if (!isNativeApp() && href) {
      const link = document.createElement('a');
      link.download = fileName;
      link.href = href;
      link.click();
    } else if (isNativeApp() && href) {
      const path = await this.getUniqueFileName(this.NATIVE_FILEPATH_PREFIX, fileName);
      await this.downloadAndOpenFileNative(href, path, nativeDestination);
    }
  }

  async downloadLocalFile(file: File, fileName: string, nativeDestination: Directory) {
    const url = URL.createObjectURL(file);

    return this.downloadFile(url, fileName, nativeDestination);
  }

  private async downloadAndOpenFileNative(data: string, fileName: string, location: Directory) {
    const result = await Filesystem.writeFile({
      path: this.NATIVE_FILEPATH_PREFIX + fileName,
      data: data,
      directory: location,
      recursive: true,
    });
    if (result) {
      await this.openFile(result.uri);
    }
  }

  private async openFile(filePath: string) {
    try {
      await FileOpener.openFile({
        path: filePath,
      });
    } catch (e) {
      this.loggingService.error('Error opening file', e);
    }
  }

  uploadFile(file: File): Observable<FileId> {
    if (!isFileValid(file)) {
      throw new TranslatedHumanReadableError('file.file-too-large');
    }

    let headers: HttpHeaders = {};
    if (Capacitor.isNativePlatform()) {
      headers = { 'Content-Type': 'multipart/form-data; boundary=uploadFile' };
    }
    const data: FormData = new FormData();

    data.append('file', file);

    return this.http
      .post(`${environment.API_BASE_URL}/api/file`, data, { headers, responseType: 'text' })
      .pipe(map((id) => id as FileId));
  }

  private convertBlobToFile(
    blob: Blob,
    format: string,
    naam: string,
  ): Observable<{ file: File; base64: string }> {
    return new Observable((observer: Subscriber<{ file: File; base64: string }>): void => {
      // if success
      const fileReader = new FileReader();
      fileReader.readAsDataURL(blob);
      fileReader.onload = () => {
        const fileName = naam + '.' + format;
        const base64 = fileReader.result as string;
        const file = new File([blob], fileName, {
          type: blob.type,
        });
        observer.next({ file, base64 });
        observer.complete();
      };

      fileReader.onerror = (error: unknown): void => {
        observer.error(error);
      };
    });
  }

  public uploadImage(image: File, base64: string): Observable<ImageUploadData> {
    if (!isFileValid(image)) {
      throw new TranslatedHumanReadableError('file.file-too-large');
    }

    let headers: HttpHeaders = {};
    if (Capacitor.isNativePlatform()) {
      headers = { 'Content-Type': 'multipart/form-data; boundary=uploadFile' };
    }
    const data: FormData = new FormData();

    data.append('image', image);

    return this.http
      .post(`${environment.API_BASE_URL}/api/image`, data, { headers, responseType: 'text' })
      .pipe(
        map((id) =>
          asType<NewlyUploadedImageData>({
            type: 'newly-uploaded',
            file: image,
            base64,
            imageId: id as FotoId,
          }),
        ),
        startWith(asType<UploadingImageData>({ type: 'uploading', file: image, base64 })),
        catchError(() => of<ErroredImageData>({ type: 'error' })),
      );
  }

  public uploadImageBlob(blob: Blob, format: string, naam: string): Observable<ImageUploadData> {
    return this.convertBlobToFile(blob, format, naam).pipe(
      switchMap(({ file, base64 }) => this.uploadImage(file, base64)),
      startWith(asType<ConvertingImageData>({ type: 'converting' })),
      catchError((err) => {
        if (err instanceof HumanReadableError) {
          throw err;
        }

        return of<ErroredImageData>({ type: 'error' });
      }),
    );
  }
}
