import { Routes } from '@angular/router';
import { DoelenPage } from './page/doelen-page/doelen.page';
import { DoelenAddPage } from './page/doelen-add-page/doelen-add.page';
import { dirtyFormGuard } from '../../shared/components/dirty-form-guard';
import { JongereDoelDetailPage } from './page/doel-detail-page/jongere-doel-detail.page';
import { BerichtProbleemAddPage } from '../../bericht/page/bericht-probleem-add-page/bericht-probleem-add.page';
import { NotitieAddPage } from '../../profiel/page/notitie-add-page/notitie-add.page';
import { DoelenEditPage } from './page/doelen-edit-page/doelen-edit.page';
import { BehaaldeDoelenEditPage } from './page/behaalde-doelen-edit-page/behaalde-doelen-edit.page';
import { artikelOverviewRoutes, specificArtikelRoute } from '../../watwat/routes';
import { verhaalCreateRoutes } from '../../verhaal/verhaal-create-routes';

export const doelJongereRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: DoelenPage,
  },
  {
    path: 'add',
    component: DoelenAddPage,
    canDeactivate: [dirtyFormGuard],
    title: 'doelen.doel-add-page.title',
  },
  {
    path: ':id',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: JongereDoelDetailPage,
      },
      artikelOverviewRoutes,
      specificArtikelRoute,
      {
        path: 'probleem',
        component: BerichtProbleemAddPage,
        canDeactivate: [dirtyFormGuard],
      },
    ],
  },
  {
    path: ':id/notitie',
    component: NotitieAddPage,
  },
  {
    path: ':id/edit',
    component: DoelenEditPage,
    canDeactivate: [dirtyFormGuard],
  },
  {
    path: ':id/edit-behaald',
    component: BehaaldeDoelenEditPage,
  },
  {
    path: ':id/verhaal-suggestie',
    children: verhaalCreateRoutes,
  },
];
