<div class="flex flex-col gap-y-2">
  @if (hasNowOpenMethods()) {
    <span class="uppercase typo-body variant-body-fat variant-body-small text-s-100">{{
      'watwat.hulplijnen-page.open' | translate
    }}</span>
  }

  <ul class="flex items-center gap-2 {{ color }}">
    @for (method of filteredMethods; track method.type) {
      @switch (method.type) {
        @case ('chat') {
          <li>
            <parkour-icon
              class="flex"
              name="bubble"
              [attr.aria-label]="'watwat.hulplijnen-page.types.chat' | translate"
            />
          </li>
        }
        @case ('mail') {
          <li>
            <parkour-icon
              class="flex"
              name="email"
              [attr.aria-label]="'watwat.hulplijnen-page.types.mail' | translate"
            />
          </li>
        }
        @case ('tel') {
          <li>
            <parkour-icon
              class="flex"
              name="telephone"
              [attr.aria-label]="'watwat.hulplijnen-page.types.tel' | translate"
            />
          </li>
        }
        @case ('forum') {
          <li>
            <parkour-icon
              class="flex"
              name="bubbles"
              [attr.aria-label]="'watwat.hulplijnen-page.types.forum' | translate"
            />
          </li>
        }
        @case ('visit') {
          <li>
            <parkour-icon
              class="flex"
              name="pin"
              [attr.aria-label]="'watwat.hulplijnen-page.types.visit' | translate"
            />
          </li>
        }
        @case ('activity') {
          <li>
            <parkour-icon
              class="flex"
              name="calendar"
              [attr.aria-label]="'watwat.hulplijnen-page.types.activity' | translate"
            />
          </li>
        }
      }
    }
  </ul>
</div>
