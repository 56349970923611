import { Injectable } from '@angular/core';
import {
  AfspraakId,
  ContextId,
  DoelId,
  GebeurtenisId,
  GesprekId,
  MeldingOfType,
  MeldingType,
  UitnodigingId,
} from 'parkour-web-app-dto';
import { map, Observable } from 'rxjs';
import {
  ContextService,
  ContextUrl,
  ContextUrlQueryParams,
} from '../../shared/services/context.service';
import { ProfielService } from './profiel.service';
import { SupportedMelding } from '../model/meldingen';

type MeldingLinkGetter<Type extends MeldingType> = (
  melding: MeldingOfType<Type>,
) => Observable<string[] | ContextUrl>;

@Injectable({
  providedIn: 'root',
})
export class MeldingLinkService {
  constructor(
    private readonly contextService: ContextService,
    private readonly profielService: ProfielService,
  ) {}

  public getMeldingLink(melding: SupportedMelding): Observable<string[] | ContextUrl> {
    return this.getMeldingLinkGetter(melding.type)(melding);
  }

  private getMeldingLinkGetter<Type extends MeldingType>(
    meldingType: Type,
  ): MeldingLinkGetter<Type> {
    return this.meldingLinkGetterMap[meldingType];
  }

  private getUrlInContext = (
    contextId: ContextId,
    segments: string[],
    queryParams?: ContextUrlQueryParams,
  ): Observable<ContextUrl> =>
    this.profielService
      .isActiefInContext(contextId)
      .pipe(
        map((actief) =>
          actief
            ? this.contextService.getUrlInContext(contextId, segments, queryParams)
            : { path: [] },
        ),
      );

  private getEyouthGebeurtenisPagePath = (melding: {
    metaData: {
      contextId: ContextId;
      gebeurtenisId: string;
    };
  }) =>
    this.getUrlInContext(melding.metaData.contextId, [
      'verhaal',
      'eyouth',
      melding.metaData.gebeurtenisId,
    ]);

  private getGebeurtenisPagePath = (melding: {
    metaData: {
      contextId: ContextId;
      gebeurtenisId: GebeurtenisId;
    };
  }) =>
    this.getUrlInContext(melding.metaData.contextId, [
      'verhaal',
      'gebeurtenis',
      melding.metaData.gebeurtenisId,
    ]);

  private getDocumentPagePath = (melding: {
    metaData: {
      contextId: ContextId;
      documentId: string;
    };
  }) =>
    this.getUrlInContext(melding.metaData.contextId, [
      'verhaal',
      'document',
      melding.metaData.documentId,
    ]);

  private getDoelPagePath = (melding: {
    metaData: {
      contextId: ContextId;
      doelId: DoelId;
    };
  }) => {
    return this.getUrlInContext(melding.metaData.contextId, ['doelen', melding.metaData.doelId]);
  };

  private getDoelGesprekPagePath = (melding: {
    metaData: {
      contextId: ContextId;
      doelId: DoelId;
    };
  }) => {
    return this.getUrlInContext(melding.metaData.contextId, ['doelen', melding.metaData.doelId], {
      sheet: true,
    });
  };

  private getBerichtGesprekPagePath = (melding: {
    metaData: {
      contextId: ContextId;
      gesprekId: GesprekId;
    };
  }) =>
    this.getUrlInContext(melding.metaData.contextId, [
      'berichten',
      'gesprek',
      melding.metaData.gesprekId,
    ]);

  private getKalenderPagePath = () => this.contextService.getAbsoluteUrl(['profiel', 'kalender']);

  private getWisselTeamPagePath = () =>
    this.contextService.getAbsoluteUrl(['profiel', 'wissel-team']);

  private getUitnodigingPagePath = (melding: {
    metaData: {
      contextId: ContextId;
      uitnodigingId: UitnodigingId;
    };
  }) =>
    this.getUrlInContext(melding.metaData.contextId, [
      'profiel',
      'team',
      'uitnodiging',
      melding.metaData.uitnodigingId,
    ]);

  private getTeamPath = (melding: {
    metaData: {
      contextId: ContextId;
    };
  }): Observable<ContextUrl> =>
    this.getUrlInContext(melding.metaData.contextId, ['profiel', 'team']);

  private getVerhaalPath = (melding: {
    metaData: {
      contextId: ContextId;
    };
  }): Observable<ContextUrl> => this.getUrlInContext(melding.metaData.contextId, ['verhaal']);

  private getGeblokkeerdTeamPath = (melding: {
    metaData: {
      jongereId: string;
    };
  }): Observable<string[]> =>
    this.contextService.getAbsoluteUrl([
      'profiel',
      'team',
      'geblokkeerd',
      melding.metaData.jongereId,
    ]);

  private getAfspraakDetailPagePath = (melding: {
    metaData: {
      afspraakId: AfspraakId;
    };
  }): Observable<string[]> =>
    this.contextService.getAbsoluteUrl([
      'profiel',
      'kalender',
      'afspraak',
      melding.metaData.afspraakId,
      'detail',
    ]);

  private getDoelenOverzichtPagePath = (): Observable<string[]> =>
    this.contextService.getAbsoluteUrl(['doelen']);

  private readonly meldingLinkGetterMap: { [T in MeldingType]: MeldingLinkGetter<T> } = {
    VERWIJDERD_UIT_TEAM: this.getWisselTeamPagePath,
    GEDEBLOKKEERD: this.getTeamPath,
    GEBLOKKEERD: this.getGeblokkeerdTeamPath,
    GEBEURTENIS_GESUGGEREERD: this.getVerhaalPath,
    TEAM_VERLATEN: this.getTeamPath,
    UITNODIGING_AANVAARD: this.getUitnodigingPagePath,
    UITNODIGING_GEWEIGERD: this.getTeamPath,
    UITNODIGING_BEVESTIGD: this.getTeamPath,
    BEVESTIGING_GEWEIGERD: this.getWisselTeamPagePath,
    UITGENODIGD: this.getWisselTeamPagePath,
    NIEUW_DRIE_KOLOMMEN_DOCUMENT: this.getDocumentPagePath,
    AFSPRAAK_AANGEMAAKT: this.getAfspraakDetailPagePath,
    AFSPRAAK_HERINNERING: this.getAfspraakDetailPagePath,
    AFSPRAAK_VERWIJDERD: this.getKalenderPagePath,
    AFSPRAAK_GESTART: this.getKalenderPagePath,
    NIEUW_CHAT_REACTIE: this.getBerichtGesprekPagePath,
    NIEUW_CHAT_BERICHT: this.getBerichtGesprekPagePath,
    VIDEOGESPREK_GESTART: this.getBerichtGesprekPagePath,
    DOEL_GESUGGEREERD: this.getDoelenOverzichtPagePath,
    DOEL_GEDEELD: this.getDoelPagePath,
    DOEL_EMOJI_REACTIE_ADDED: this.getDoelPagePath,
    DOEL_VOLTOOID: this.getDoelPagePath,
    DOEL_NIEUW_BERICHT_REACTIE: this.getDoelGesprekPagePath,
    DOEL_NIEUW_BERICHT: this.getDoelGesprekPagePath,
    GEBEURTENIS_GEDEELD: this.getGebeurtenisPagePath,
    JEUGDHULPHISTORIEK_GEDEELD: this.getEyouthGebeurtenisPagePath,
    NIEUWE_EYOUTH_GEBEURTENIS: this.getEyouthGebeurtenisPagePath,
  };
}
