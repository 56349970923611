<aside
  class="flex flex-col justify-between items-stretch gap-6 pl-3 pr-3 pb-3 pt-6 h-full bg-n-300"
>
  <div class="flex-1">
    <parkour-action [link]="['home'] | absoluteRoute | async">
      <span class="text-center text-n-1000 typo-h1">PARKOUR</span>
    </parkour-action>

    <nav class="mt-18">
      <ul class="space-y-1">
        <li>
          <parkour-page-layout-sidebar-nav-item
            [link]="['home'] | absoluteRoute | async"
            class="block"
            icon="home"
            text="Home"
          />
        </li>

        <li>
          <parkour-page-layout-sidebar-nav-item
            [link]="['verhaal'] | absoluteRoute | async"
            [showNewIndicator]="hasVerhaalTabMeldingen$ | async"
            class="block"
            icon="compass"
            text="Verhaal"
          />
        </li>

        <li>
          <parkour-page-layout-sidebar-nav-item
            [link]="['doelen'] | absoluteRoute | async"
            [showNewIndicator]="hasDoelTabMeldingen$ | async"
            class="block"
            icon="target"
            text="Doelen"
          />
        </li>

        <li>
          <parkour-page-layout-sidebar-nav-item
            [link]="['berichten'] | absoluteRoute | async"
            [showNewIndicator]="hasBerichtenTabMeldingen$ | async"
            class="block relative"
            icon="paper-plane"
            text="Berichten"
          />
        </li>
      </ul>
    </nav>

    <ng-container *ngIf="userData$ | async as userData">
      @if (userHasProfiel(userData.user)) {
        <ng-container *parkourShowIfSuccessful="mijnContexten$ | async; let mijnContexten">
          @switch (userData.context.type) {
            @case ('teamlid') {
              <parkour-sidebar-teamlid-context-card
                [context]="userData.context"
                [mijnContexten]="mijnContexten"
                [user]="userData.user"
              />
            }
            @case ('jongere') {
              <parkour-sidebar-jongere-context-card
                [context]="userData.context"
                [mijnContexten]="mijnContexten"
                [user]="userData.user"
              />
            }
            @case ('voor-mezelf') {
              <parkour-sidebar-voor-mezelf-context-card
                [context]="userData.context"
                [mijnContexten]="mijnContexten"
                [user]="userData.user"
              />
            }
          }
        </ng-container>
      }
    </ng-container>
  </div>

  @if (showLoginButton$ | async) {
    <div class="w-full flex flex-col gap-4">
      <ul class="space-y-1">
        <li>
          <parkour-page-layout-sidebar-nav-item
            [link]="['profiel', 'over', 'parkour']"
            [text]="'profiel-nav.about' | translate"
            class="block"
            icon="info-circle"
          />
        </li>

        <li>
          <parkour-page-layout-sidebar-nav-item
            [link]="['profiel', 'probleem']"
            [text]="'profiel.probleem-page.title' | translate"
            class="block"
            icon="alert-triangle"
          />
        </li>
      </ul>

      <parkour-aanmeld-button
        [redirectAfterLogin]="'/app/me/home'"
        class="block"
        data-testid="login"
      />
    </div>
  }

  <ng-container *ngIf="userData$ | async as userData">
    @if (userHasProfiel(userData.user)) {
      <parkour-action
        id="sidebar-profiel-{{ userData.context.contextId }}"
        class="block relative"
        classes="flex gap-3 py-2 pl-2 pr-1 hover:bg-p-100 hover:[--avatar-border-color:theme('colors.p-100')] focus-visible:bg-n-200 hover:[--avatar-border-color:theme('colors.n-200')] transition-colors rounded w-full"
        [ngClasses]="{
          'bg-p-100 avatar-border-active': isProfielPopoverOpen || isProfielPopoverActive,
        }"
      >
        <parkour-profiel-indicator [context]="userData.context" [user]="userData.user" />

        <div class="text-left">
          <p class="typo-body variant-body-fat text-n-1000">
            {{ userData.user.profiel | profielnaam }}
          </p>

          <span class="flex flex-row gap-2">
            <parkour-profiel-type-label
              classes="typo-body variant-body-small text-n-1000"
              [profielType]="userData.user.profiel.profielType"
            />

            <parkour-icon
              *ngIf="userData.user.profiel.profielType === 'PROFESSIONEEL'"
              class="text-n-1000"
              name="badge-on"
            />
          </span>

          <parkour-sidebar-profiel-popover
            triggerId="sidebar-profiel-{{ userData.context.contextId }}"
            (hasDismissed)="profielPopoverClose()"
            (hasPresented)="profielPopoverOpen()"
          />
        </div>

        <parkour-new-indicator
          [show]="hasProfielTabDesktopMeldingen$ | async"
          class="[--notification-outline-color:theme('colors.n-300')]"
        />
      </parkour-action>
    }
  </ng-container>
</aside>
