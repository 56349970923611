import { Component, Input } from '@angular/core';
import { Context } from '../../model/context';
import { User, UserWithProfiel } from '../../../user/model/user';
import { ParkourAvatarBadgeComponent } from '@parkour/ui';
import { AsyncPipe, NgIf } from '@angular/common';
import { ProfielnaamPipe } from '../../pipes/profielnaam.pipe';
import { ProfielFotoPipe } from '../../pipes/profiel-foto.pipe';

@Component({
  standalone: true,
  selector: 'parkour-profiel-indicator',
  templateUrl: './profiel-indicator.component.html',
  imports: [ParkourAvatarBadgeComponent, NgIf, ProfielnaamPipe, ProfielFotoPipe, AsyncPipe],
})
export class ProfielIndicatorComponent {
  @Input({ required: true }) user!: User;
  @Input({ required: true }) context!: Context;

  isUserWithProfiel(user: User): user is UserWithProfiel {
    return user instanceof UserWithProfiel;
  }
}
