import { Routes } from '@angular/router';
import { VerhaalForJongerePage } from './page/verhaal-for-jongere-page/verhaal-for-jongere-page.component';
import { ParkourGebeurtenisEditPage } from './page/parkour-gebeurtenis-edit-page/parkour-gebeurtenis-edit.page';
import { dirtyFormGuard } from '../../shared/components/dirty-form-guard';
import { GebeurtenisDetailsPage } from './page/gebeurtenis-details-page/gebeurtenis-details.page';
import { JeugdhulpHistoriekEditPage } from './page/jeugdhulp-historiek-edit-page/jeugdhulp-historiek-edit-page.component';
import { DrieKolommenGebeurtenisDetailsPage } from './page/drie-kolommen-gebeurtenis-details-page/drie-kolommen-gebeurtenis-details.page';
import { specificHulplijnRoute } from '../../watwat/routes';
import { verhaalCreateRoutes } from '../verhaal-create-routes';

export const verhaalJongereRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: VerhaalForJongerePage,
  },
  {
    path: 'nieuw',
    children: verhaalCreateRoutes,
  },
  {
    path: 'gebeurtenis/:gebeurtenis-id',
    children: [
      {
        path: 'wijzig',
        component: ParkourGebeurtenisEditPage,
        canDeactivate: [dirtyFormGuard],
      },
      {
        path: '',
        component: GebeurtenisDetailsPage,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'eyouth/:gebeurtenis-id',
    children: [
      {
        path: 'wijzig',
        component: JeugdhulpHistoriekEditPage,
        canDeactivate: [dirtyFormGuard],
      },
      {
        path: '',
        component: GebeurtenisDetailsPage,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'document/:gebeurtenis-id',
    children: [
      {
        path: '',
        component: DrieKolommenGebeurtenisDetailsPage,
        pathMatch: 'full',
      },
      specificHulplijnRoute,
    ],
  },
];
