<div
  *ngIf="isOpen"
  @fadeInOut
  class="animation-modal absolute top-0 left-0 z-40 w-full h-full backdrop-blur-[2px] flex items-center justify-center"
>
  <parkour-lottie-player
    (completed)="onCompleted()"
    [src]="src"
    class="relative z-50 w-[25rem] h-[25rem]"
  />

  <div
    class="absolute top-0 left-0 w-full h-full z-30 bg-n-100 opacity-80"
  ></div>
</div>
