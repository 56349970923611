import { Component } from '@angular/core';
import { IonContent, IonHeader, ViewWillEnter } from '@ionic/angular/standalone';
import { mergeMap, Observable } from 'rxjs';
import { Uitnodiging } from '../../model/uitnodiging';
import { UitnodigingenService } from '../../service/uitnodinginen.service';
import { UitnodigingId, validatedUitnodidingId } from 'parkour-web-app-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../user/service/user.service';
import { AnomymousUser } from '../../../user/model/anomymous-user';
import { User, validateUserWithProfiel } from '../../../user/model/user';
import { ContextService } from '../../../shared/services/context.service';
import { PageLayoutComponent } from '../../../shared/components/page-layout/page-layout.component';
import { AsyncPipe, NgIf } from '@angular/common';
import {
  ParkourButtonComponent,
  ParkourDividerComponent,
  ParkourPictureComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import AuthService from '../../../authentication/service/auth.service';

@Component({
  standalone: true,
  templateUrl: './uitgenodigd.page.html',
  imports: [
    IonHeader,
    IonContent,
    PageLayoutComponent,
    NgIf,
    AsyncPipe,
    ParkourDividerComponent,
    ParkourPictureComponent,
    TranslateModule,
    ParkourButtonComponent,
  ],
})
export class UitgenodigdPage implements ViewWillEnter {
  uitnodiging$ = new Observable<Uitnodiging>();
  user$ = this.userService.getCurrentUser$();

  constructor(
    private readonly uitnodigingenService: UitnodigingenService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly contextService: ContextService,
    private readonly authService: AuthService,
  ) {}

  ionViewWillEnter(): void {
    this.uitnodiging$ = this.activatedRoute.paramMap.pipe(
      mergeMap((params) =>
        this.uitnodigingenService.getUitnodiging(
          validatedUitnodidingId(params.get('uitnodigingId')),
        ),
      ),
    );
  }

  onAanmeldenClick(uitnodigingId: UitnodigingId) {
    this.authService
      .login({ redirectUrl: `/app/me/profiel/wissel-team?uitnodigingId=${uitnodigingId}` })
      .subscribe();
  }

  onWeigerenClick(uitnodigingId: UitnodigingId) {
    this.uitnodigingenService
      .weigerUitnodiging(uitnodigingId)
      .subscribe(() => this.router.navigate(['geweigerd'], { relativeTo: this.activatedRoute }));
  }

  isAnonymousUser(user: User) {
    return user instanceof AnomymousUser;
  }

  onBekijkUitnodiging(uitnodigingId: UitnodigingId, user: User) {
    const profielUser = validateUserWithProfiel(user);

    this.uitnodigingenService.saveMyCurrentUitnodigingId(uitnodigingId);

    this.router.navigate(
      this.contextService.getUrlInContext(profielUser.profiel.id, ['profiel', 'wissel-team']).path,
    );
  }
}
