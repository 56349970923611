import { Component, Input } from '@angular/core';
import { ProfielFotoBadgeComponent } from '../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielFotoId, ProfielId, ProfielType, Rol } from 'parkour-web-app-dto';
import { ProfessioneelIndicatorComponent } from '../../../shared/components/professioneel-indicator/professioneel-indicator.component';
import { RolLabelWithDefaultPipe } from '../../../shared/pipes/rol-label-with-default.pipe';
import { ParkourNewIndicatorComponent, ParkourTagComponent } from '@parkour/ui';

type ProfielData =
  | {
      id: ProfielId;
      profielFotoId: ProfielFotoId;
      status: 'ACTIEF';
      profielType?: ProfielType;
    }
  | { status: 'GEBLOKKEERD' }
  | { status: 'ONBEKEND' };

@Component({
  selector: 'parkour-team-switch-card-content',
  templateUrl: './team-switch-card-content.component.html',
  standalone: true,
  imports: [
    ProfielFotoBadgeComponent,
    TranslateModule,
    ProfessioneelIndicatorComponent,
    RolLabelWithDefaultPipe,
    ParkourNewIndicatorComponent,
    ParkourTagComponent,
  ],
})
export class TeamSwitchCardContentComponent {
  @Input() rol?: Rol;
  @Input({ required: true }) profiel!: ProfielData;
  @Input({ required: true }) showNewIndicator!: boolean;
  @Input({ required: true }) naam!: string;
}
