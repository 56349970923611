import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';

@Injectable({ providedIn: 'root' })
export class SheetService {
  private isOpen$ = new BehaviorSubject<boolean>(false);
  private portal$ = new BehaviorSubject<TemplatePortal | null>(null);

  get isOpen(): Observable<boolean> {
    return this.isOpen$.asObservable();
  }

  get portal(): Observable<TemplatePortal | null> {
    return this.portal$.asObservable();
  }

  open() {
    this.isOpen$.next(true);
  }

  close() {
    this.isOpen$.next(false);
  }

  setModalPortal(portal: TemplatePortal) {
    this.portal$.next(portal);
  }
}
