import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  AfspraakId,
  AfspraakViewDetailDto,
  BasicAfspraakViewDetailDto,
  VideogesprekAfspraakViewDetailDto,
} from 'parkour-web-app-dto';
import { AangemeldeUser } from '../../../authentication/user';
import { getProfielnaam, ProfielInTeam } from '../../../profiel/model/profiel-in-team';
import { DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  ParkourActionComponent,
  ParkourButtonComponent,
  ParkourNewIndicatorComponent,
} from '@parkour/ui';
import { ProfielFotoBadgeComponent } from '../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProfielnaamPipe } from '../../../shared/pipes/profielnaam.pipe';
import { getGespreksPartner } from '../../model/afspraak';

type AfsprakenDagGroep = {
  datum: string;
  afspraken: Array<AfspraakViewDetailDto>;
};
type AfsprakenMaandGroep = {
  datum: Date;
  afspraken: Array<AfsprakenDagGroep>;
};
type AfsprakenMaandGroepTemp = {
  datum: Date;
  afspraken: Array<AfspraakViewDetailDto>;
};

@Component({
  standalone: true,
  selector: 'parkour-afspraken-per-maand',
  templateUrl: './afspraken-per-maand.component.html',
  imports: [
    NgForOf,
    NgClass,
    NgIf,
    ParkourActionComponent,
    ParkourNewIndicatorComponent,
    ProfielFotoBadgeComponent,
    ParkourButtonComponent,
    DatePipe,
    TranslateModule,
    ProfielnaamPipe,
  ],
})
export class AfsprakenPerMaandComponent implements OnChanges {
  @Input({ required: true }) afspraken!: Array<AfspraakViewDetailDto>;
  @Input({ required: true }) user!: AangemeldeUser;
  @Input({ required: true }) ongelezenAfspraakIds!: AfspraakId[];
  @Input() hasFade = false;
  @Output() joinAfspraakClicked = new EventEmitter<AfspraakViewDetailDto>();

  afsprakenPerMaand: Array<AfsprakenMaandGroep> = [];

  constructor(private datePipe: DatePipe) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('afspraken' in changes) {
      this.afsprakenPerMaand = this.groepeerPerMaand(this.afspraken);
    }
  }

  groepeerPerDag(afspraken: AfspraakViewDetailDto[]): Array<AfsprakenDagGroep> {
    const groupedByDate: Array<AfsprakenDagGroep> = [];
    let currentGroup: AfsprakenDagGroep | undefined = undefined;

    afspraken.forEach((afspraak) => {
      if (currentGroup === undefined) {
        currentGroup = {
          datum: afspraak.datum,
          afspraken: [afspraak],
        };
      } else if (afspraak.datum === currentGroup.datum) {
        currentGroup.afspraken.push(afspraak);
      } else {
        groupedByDate.push(currentGroup);
        currentGroup = {
          datum: afspraak.datum,
          afspraken: [afspraak],
        };
      }
    });
    if (currentGroup) {
      groupedByDate.push(currentGroup);
    }
    return groupedByDate;
  }

  groepeerPerMaand(afspraken: AfspraakViewDetailDto[]): Array<AfsprakenMaandGroep> {
    const groupedByDate: Array<AfsprakenMaandGroep> = [];
    let currentGroup: AfsprakenMaandGroepTemp | undefined;

    afspraken.forEach((afspraak) => {
      const date = new Date(afspraak.datum);
      if (currentGroup === undefined) {
        currentGroup = {
          datum: date,
          afspraken: [afspraak],
        };
      } else if (
        date.getMonth() === currentGroup.datum.getMonth() &&
        date.getFullYear() === currentGroup.datum.getFullYear()
      ) {
        currentGroup.afspraken.push(afspraak);
      } else {
        groupedByDate.push({
          datum: currentGroup.datum,
          afspraken: this.groepeerPerDag(currentGroup.afspraken),
        });
        currentGroup = {
          datum: date,
          afspraken: [afspraak],
        };
      }
    });
    if (currentGroup) {
      groupedByDate.push({
        datum: currentGroup.datum,
        afspraken: this.groepeerPerDag(currentGroup.afspraken),
      });
    }
    return groupedByDate;
  }

  onJoinAfspraak(afspraak: AfspraakViewDetailDto) {
    this.joinAfspraakClicked.emit(afspraak);
  }

  canJoinGesprek(afspraak: AfspraakViewDetailDto): boolean {
    return afspraak.type === 'VIDEOGESPREK' && this.user.profielId !== afspraak.teamlid.id;
  }

  getGespreksPartner(user: AangemeldeUser, afspraak: AfspraakViewDetailDto): ProfielInTeam {
    return afspraak.type === 'VIDEOGESPREK' && user.profielId !== afspraak.teamlid.id
      ? afspraak.teamlid
      : afspraak.jongere;
  }

  getAfspraakVideoGesprekAriaLabel(afspraak: VideogesprekAfspraakViewDetailDto): string {
    return `Afspraak op ${this.datePipe.transform(afspraak.datum, 'dd.MM.yyyy')}, ${this.datePipe.transform(afspraak.van, 'HH:mm')} - ${this.datePipe.transform(afspraak.tot, 'HH:mm')}: videogesprek met ${getProfielnaam(getGespreksPartner(this.user, afspraak))} `;
  }

  getAfspraakBasicAriaLabel(afspraak: BasicAfspraakViewDetailDto): string {
    return `Afspraak op ${this.datePipe.transform(afspraak.datum, 'dd.MM.yyyy')}, ${this.datePipe.transform(afspraak.van, 'HH:mm')} - ${this.datePipe.transform(afspraak.tot, 'HH:mm')}: ${afspraak.titel} `;
  }
}
