import { Component } from '@angular/core';
import { forkJoin, Observable, switchMap } from 'rxjs';
import { Doel } from '../../../doel-shared/model/doel';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
  ParkourSegmentComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ContextService } from '../../../../shared/services/context.service';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import { AsyncPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ProfielFotoOfJongerePipe } from '../../../../shared/pipes/profiel-foto-of-jongere.pipe';
import { ContentUnavailableCardComponent } from '../../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { DoelListItemComponent } from '../../../doel-shared/component/doel-list-item/doel-list-item.component';
import { ProfielnaamPipe } from '../../../../shared/pipes/profielnaam.pipe';
import { DoelVoorstelComponent } from '../../../doel-shared/component/doel-voorstel/doel-voorstel.component';
import { DoelOverviewPage } from '../../../doel-overview-page';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { PageSectionDirective } from '../../../../shared/directives/page-section.directive';
import { HasMeldingenForDoelPipe } from '../../../doel-jongere/page/doelen-page/has-meldingen-for-doel.pipe';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import { DoelSuggestie } from 'src/app/suggestie/model/suggestie';
import { SuggestieService } from 'src/app/suggestie/service/suggestie.service';
import AuthService from '../../../../authentication/service/auth.service';

@Component({
  standalone: true,
  templateUrl: './doelen-teamlid.page.html',
  imports: [
    IonHeader,
    IonContent,
    PageLayoutComponent,
    PageWithHeroComponent,
    NgIf,
    ProfielFotoOfJongerePipe,
    AsyncPipe,
    TranslateModule,
    ParkourSegmentComponent,
    NgSwitch,
    NgSwitchCase,
    ContentUnavailableCardComponent,
    NgForOf,
    DoelListItemComponent,
    ProfielnaamPipe,
    ParkourButtonComponent,
    DoelVoorstelComponent,
    ParkourLoadingSpinnerComponent,
    ParkourBlockPlaceholderComponent,
    PageContentDirective,
    PageSectionDirective,
    HasMeldingenForDoelPipe,
    ParkourRefreshDirective,
    IonRefresher,
    IonRefresherContent,
  ],
})
export class DoelenTeamlidPage extends DoelOverviewPage {
  teamlidContext$ = this.contextService.teamlidContext$();
  constructor(
    private readonly contextService: ContextService,
    private readonly authService: AuthService,
    private readonly suggestieService: SuggestieService,
  ) {
    super();
  }

  override getDoelSuggesties(): Observable<DoelSuggestie[]> {
    return forkJoin([this.teamlidContext$, this.authService.getAangemeldeUser$()]).pipe(
      switchMap(([context, user]) =>
        this.suggestieService.getDoelSuggestiesBy(user.profielId, context.contextId),
      ),
    );
  }

  getActieveDoelen(): Observable<Doel[]> {
    return this.doelenService.getGedeeldeActieveDoelen();
  }

  getBehaaldeDoelen(): Observable<Doel[]> {
    return this.doelenService.getGedeeldeBehaaldeDoelen();
  }
}
