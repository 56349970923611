<parkour-page-with-hero [heroImage]="hero$ | async" [showTitle]="false" [title]="''">
  <parkour-custom-foto-edit
    [formControl]="registerGebeurtenisForm.controls.imageUploadData"
    [aspectRatio]="16 / 9"
    [fotoName]="'verhaal-foto'"
    [mode]="'create'"
    hero-content
    [existingImageGetFunction]="getExistingImage"
  />
  <parkour-form
    #form
    [formGroup]="registerGebeurtenisForm"
    [formId]="formId"
    [errorLabelMapping]="false"
    [submitObservableGenerator]="submitForm"
  >
    <div class="flex flex-col gap-y-8">
      <div class="container-1-col flex flex-col gap-y-8">
        <!-- TODO: UX Copy verhaal aan JSON toevoegen -->
        <parkour-form-field
          [inputFormControl]="registerGebeurtenisForm.controls.titel"
          [required]="true"
          class="block"
          inputId="register-input-title"
          label="Titel"
        >
          <parkour-input
            [required]="true"
            class="block"
            formControlName="titel"
            inputId="register-input-title"
            inputPlaceholder="Geef je gebeurtenis een titel"
          />
        </parkour-form-field>

        <parkour-form-field
          [inputFormControl]="registerGebeurtenisForm.controls.datum"
          [required]="true"
          class="block"
          inputId="register-input-date"
          label="Datum"
        >
          <parkour-input
            [required]="true"
            class="block"
            formControlName="datum"
            inputId="register-input-date"
            inputPlaceholder="DD-MM-JJ"
            type="date"
          >
          </parkour-input>
        </parkour-form-field>

        <parkour-form-field
          [inputFormControl]="registerGebeurtenisForm.controls.omschrijving"
          class="block"
          inputId="register-input-description"
          label="Beschrijving"
        >
          <parkour-textarea
            class="block"
            formControlName="omschrijving"
            inputId="register-input-description"
            inputPlaceholder="Beschrijf je gebeurtenis"
          />
        </parkour-form-field>

        <parkour-attachments-upload
          (existingFileDownload)="onExistingFileDownload($event)"
          [formControl]="registerGebeurtenisForm.controls.bijlagen"
        />

        <div class="flex flex-col gap-y-6">
          <div class="space-y-2">
            <h2 class="typo-h3">Delen</h2>

            <p>Met wie wil je dit delen? Je kan dit altijd aanpassen.</p>
          </div>

          <parkour-delen-section
            [delenFormGroup]="registerGebeurtenisForm.controls.delenSection"
            [teamleden]="teamleden"
          />
        </div>

        <parkour-button [loading]="form.busy" class="block w-full" iconAfter="disk" type="submit">
          {{ 'verhaal.gebeurtenis-add-page.gebeurtenis-save' | translate }}
        </parkour-button>
      </div>
    </div>
  </parkour-form>
</parkour-page-with-hero>
