<ion-header class="w-full transform-none"></ion-header>
<!-- TODO: UX Copy profiel wisselen aan JSON toevoegen -->
<ion-content>
  <parkour-page-layout>
    <parkour-page-image
      classes="absolute top-0 left-0 z-0"
      src="/assets/onboarding/background/background_onboarding_blur.jpg"
    />

    <div
      *ngIf="{
        user: user$ | async,
        profielen: profielen$ | async,
      } as data"
      class="relative z-10 py-8 container-space flex flex-col gap-y-8"
    >
      <ng-container *ngIf="data.profielen && data.user">
        <div class="flex flex-col items-center gap-y-4 text-center">
          <h1>Hallo {{ data.user.getVoornaam() }}</h1>

          <parkour-divider />
        </div>

        <p class="text-center">
          Leuk je terug te zien! <br />
          Kies het profiel waarmee je wil aanmelden.
        </p>

        @if (data.profielen.success) {
          <ul class="bg-n-100 rounded border-[1.5px] border-n-500 divide-y-[1.5px] divide-n-500">
            @for (profiel of data.profielen.value; track profiel.id) {
              <li>
                <parkour-action
                  (clicked)="onProfielClick(profiel)"
                  classes="flex items-center gap-x-3 p-4 w-full"
                >
                  <parkour-profiel-foto [profiel]="profiel" [size]="'3'" />

                  <div class="text-left space-y-1">
                    <span class="typo-body variant-body-fat">{{
                      getProfielnaamWithoutFallback(profiel)
                    }}</span>

                    <p>
                      @switch (profiel.profielType) {
                        @case ('PERSOONLIJK') {
                          Persoonlijk profiel
                        }
                        @case ('PROFESSIONEEL') {
                          Professioneel profiel
                          <parkour-professioneel-indicator />
                        }
                      }
                    </p>
                  </div>
                </parkour-action>
              </li>
            }
          </ul>
        } @else {
          <parkour-content-unavailable-card
            [description]="'Je profiel kan niet ingeladen worden, probeer later opnieuw'"
          />
        }
      </ng-container>
    </div>
  </parkour-page-layout>
</ion-content>
