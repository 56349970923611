import {
  BerichtEvent,
  BerichtId,
  CreateBerichtDto,
  CreateBestandBericht,
  GesprekId,
  ReactieEvent,
} from 'parkour-web-app-dto';
import { AttachmentBericht, Bericht, SpraakBericht, TextBericht } from '../../model/gesprek';
import { BerichtenService, BerichtUploadEvent } from '../../service/berichten.service';
import { BerichtenContainer } from './berichten-container';
import { BerichtFooterComponent } from '../bericht-footer/bericht-footer.component';
import { last, Observable, Subscription, switchMap, tap } from 'rxjs';
import { InfiniteScrollCustomEvent } from '@ionic/angular/standalone';
import { AfterContentChecked, Component, ElementRef } from '@angular/core';
import { ParkourPopupService } from '@parkour/ui';
import { PusherService } from '../../service/pusher.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HumanReadableError } from '../../../core/human-readable-error';
import { LoggingService } from 'src/app/core/logging.service';
import { Channel } from 'pusher-js';

@Component({ template: '' })
export abstract class GesprekContainerComponent implements AfterContentChecked {
  protected readonly berichtenContainer = new BerichtenContainer();
  protected busyUploadingFile = false;
  protected uploadingPercentage = 0;
  protected isReplyBerichtMode = false;
  protected replyBericht!: TextBericht | AttachmentBericht | SpraakBericht;
  private shouldScrollToBottom = false;
  private nieuwBerichtSubscription: Subscription | undefined;
  private berichtBewerktSubscription: Subscription | undefined;
  private berichtVerwijderdSubscription: Subscription | undefined;

  protected constructor(
    protected readonly berichtenService: BerichtenService,
    private readonly popupService: ParkourPopupService,
    protected readonly pusherService: PusherService,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    private readonly loggingService: LoggingService,
  ) {
    this.berichtenContainer.berichtAdded.subscribe(() => {
      this.shouldScrollToBottom = true;
    });
  }

  abstract get gesprekId(): GesprekId;

  abstract get berichtFooter(): BerichtFooterComponent;

  abstract get chatAnchor(): ElementRef;

  abstract get chatFlow(): ElementRef;

  abstract get chatFlowScroll(): ElementRef;

  fetchInitialPage() {
    this.berichtenContainer.reset();
    this.berichtenService
      .getBerichtenPage(this.gesprekId)
      .subscribe((berichtenPage) => this.berichtenContainer.addOldBerichtenPage(berichtenPage));
  }

  subscribeOnBerichtenEvents(channel: Observable<Channel | undefined>) {
    this.nieuwBerichtSubscription = channel
      .pipe(
        switchMap(() =>
          this.pusherService.createBerichtenPusherSubscription(channel, 'nieuw-bericht'),
        ),
      )
      .subscribe(() => {
        this.fetchNewBerichten();
      });

    this.berichtBewerktSubscription = channel
      .pipe(
        switchMap(() =>
          this.pusherService.createBerichtenPusherSubscription(channel, 'bericht-bewerkt'),
        ),
      )
      .subscribe((event: BerichtEvent) => this.fetchUpdatedBericht(event.berichtId));

    this.berichtVerwijderdSubscription = channel
      .pipe(
        switchMap(() =>
          this.pusherService.createBerichtenPusherSubscription(channel, 'bericht-verwijderd'),
        ),
      )
      .subscribe((event: BerichtEvent) => {
        this.berichtenContainer.deleteBericht(event.berichtId);
      });
  }

  unsubscribeFromBerichtenEvents() {
    this.nieuwBerichtSubscription?.unsubscribe();
    this.berichtBewerktSubscription?.unsubscribe();
    this.berichtVerwijderdSubscription?.unsubscribe();
  }

  fetchNewBerichten() {
    const latestTimestamp = this.berichtenContainer.getLatestBerichtTimestamp();

    if (!latestTimestamp) {
      this.loggingService.log('First page not yet loaded, fetching initial page');
      this.berichtenService
        .getBerichtenPage(this.gesprekId)
        .subscribe((berichtenPage) => this.berichtenContainer.addOldBerichtenPage(berichtenPage));
    } else {
      this.berichtenService
        .getBerichtenPageSinceTime(this.gesprekId, latestTimestamp)
        .subscribe((berichtenPage) => {
          this.berichtenContainer.addNewBerichtenPage(berichtenPage);
        });
    }
  }

  onStuurBericht(createBerichtDto: CreateBerichtDto) {
    if (this.isReplyBerichtMode) {
      createBerichtDto = {
        ...createBerichtDto,
        origineelBerichtId: this.replyBericht.id,
      };

      this.isReplyBerichtMode = false;
    }

    if (createBerichtDto.type === 'TEKST' || createBerichtDto.type === 'GEDEELDE_RESOURCE') {
      return this.berichtenService
        .stuurTekstBericht(this.gesprekId, createBerichtDto)
        .subscribe((bericht: Bericht) => this.berichtenContainer.addBericht(bericht));
    } else if (createBerichtDto.type === 'ATTACHMENT' || createBerichtDto.type === 'SPRAAK') {
      return this.berichtenService
        .stuurBestandBericht(this.gesprekId, createBerichtDto)
        .subscribe((event: BerichtUploadEvent) => {
          if (event.type === 'response') {
            this.berichtenContainer.addBericht(event.bericht);
          }
        });
    } else {
      throw new HumanReadableError('Dit bericht kan verzonden worden.');
    }
  }

  onUploadFile(createBestandBericht: CreateBestandBericht) {
    this.busyUploadingFile = true;

    if (this.isReplyBerichtMode) {
      createBestandBericht = {
        ...createBestandBericht,
        origineelBerichtId: this.replyBericht.id,
      };

      this.isReplyBerichtMode = false;
    }

    this.berichtenService
      .stuurBestandBericht(this.gesprekId, createBestandBericht)
      .pipe(
        tap(({ number }) => {
          this.uploadingPercentage = number;
        }),
        last(),
        tap(() => this.resetUpload()),
      )
      .subscribe((event) => {
        if (event.type === 'response') {
          this.berichtenContainer.addBericht(event.bericht);
        }
      });
  }

  onSelectedReactie(event: ReactieEvent) {
    this.berichtenService
      .addReactieBericht(this.gesprekId, event.berichtId, event.reactie)
      .subscribe((bericht) => this.berichtenContainer.updateBericht(bericht));
  }

  onDeleteBericht(berichtId: BerichtId) {
    this.popupService
      .showPopup({
        title: 'Bericht verwijderen',
        description: 'Wil je jouw bericht verwijden?',
        icon: 'trash',
        neeText: 'Nee',
        jaText: 'Ja',
      })
      .then((result) => {
        if (result) {
          this.berichtenService
            .deleteBericht(this.gesprekId, berichtId)
            .subscribe(() => this.berichtenContainer.deleteBericht(berichtId));
        }
      });
  }

  onDeleteReactie(berichtId: BerichtId) {
    this.berichtenService
      .deleteReactieBericht(this.gesprekId, berichtId)
      .subscribe((bericht) => this.berichtenContainer.updateBericht(bericht));
  }

  onIonInfinite(event: Event) {
    if (!this.berichtenContainer.isLastPage) {
      const oldestBerichtTimestamp = this.berichtenContainer.getOldestBerichtTimestamp();

      if (!oldestBerichtTimestamp) {
        this.loggingService.log('No oldest timestamp found, skipping infinite scroll');
        this.berichtenService.getBerichtenPage(this.gesprekId).subscribe((berichtenPage) => {
          this.berichtenContainer.addOldBerichtenPage(berichtenPage);
          (event as InfiniteScrollCustomEvent).target.complete();
        });
      } else {
        this.berichtenService
          .getBerichtenPageBeforeTime(this.gesprekId, oldestBerichtTimestamp)
          .subscribe((berichten) => {
            this.berichtenContainer.addOldBerichtenPage(berichten);

            (event as InfiniteScrollCustomEvent).target.complete();
          });
      }
    } else {
      (event as InfiniteScrollCustomEvent).target.complete();
    }
  }

  ngAfterContentChecked() {
    if (this.chatAnchor && this.shouldScrollToBottom) {
      this.scrollToBottom();

      this.shouldScrollToBottom = false;
    }
  }

  markShouldScrollToBottom() {
    this.shouldScrollToBottom = true;
  }

  onSaveNotitie(event: { titel: string; beschrijving: string }) {
    this.router.navigate(['notitie'], {
      relativeTo: this.activatedRoute,
      queryParams: {
        titel: event.titel,
        beschrijving: event.beschrijving,
      },
    });
  }

  onReplyBericht(bericht: TextBericht | AttachmentBericht | SpraakBericht) {
    this.replyBericht = bericht;
    this.isReplyBerichtMode = true;

    this.scrollToBottom();
  }

  onCloseReplyBericht() {
    this.isReplyBerichtMode = false;
  }

  private fetchUpdatedBericht(berichtId: BerichtId) {
    this.berichtenService
      .getBericht(this.gesprekId, berichtId)
      .subscribe((bericht) => this.berichtenContainer.updateBericht(bericht));
  }

  private scrollToBottom() {
    setTimeout(() => {
      this.chatAnchor.nativeElement.scrollIntoView();
    });
  }

  private resetUpload() {
    this.busyUploadingFile = false;
    this.uploadingPercentage = 0;
    this.berichtFooter.resetUploadFile();
  }
}
