import { Injectable } from '@angular/core';
import { map, pairwise } from 'rxjs';
import { LoggingService } from '../../core/logging.service';
import { UserRefreshCause, UserService } from '../../user/service/user.service';
import { User, UserWithProfiel } from '../../user/model/user';
import { ProfielId } from 'parkour-web-app-dto';
import { Router } from '@angular/router';
import { GlobalRefreshCause } from '../../shared/services/refresh.service';
import AuthService from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TimeoutDetectionService {
  constructor(
    private readonly loggingService: LoggingService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {}

  userChanged$ = this.userService.userWithRefreshCause$.pipe(
    pairwise(),
    map(([oldUser, newUser]) => ({
      oldUser: oldUser.user,
      newUser: newUser.user,
      refreshCause: newUser.refreshCause,
    })),
  );

  public initialize(): void {
    this.userChanged$.subscribe(({ oldUser, newUser, refreshCause }) => {
      this.loggingService.log('User changed from ', oldUser, ' to ', newUser);
      if (oldUser.isIngelogd() && !newUser.isIngelogd()) {
        this.onUserNoLongerLoggedIn(oldUser, refreshCause);
      }
    });
  }

  private onUserNoLongerLoggedIn(
    oldUser: User,
    refreshCause: UserRefreshCause | GlobalRefreshCause,
  ): boolean {
    const profielId = oldUser instanceof UserWithProfiel ? oldUser.profiel.id : undefined;

    switch (refreshCause) {
      case 'initial':
        // User should never be logged out on initial refresh, ignoring
        return false;
      case 'global-refresh':
        this.showSessionTimeoutPopup(profielId);
        return true;
      case 'user-refresh':
        this.showSessionTimeoutPopup(profielId);
        return true;
      case 'resume':
        this.authService.loginAfterResume(profielId).subscribe();
        return true;
      case 'logout':
        // It is normal that the user is not logged in after logout, ignoring
        return false;
    }
  }

  showSessionTimeoutPopup(profielId?: ProfielId) {
    this.authService.openTimeoutPopup().subscribe((loginAgain) => {
      if (loginAgain) {
        this.authService.login({ redirectProfielId: profielId }).subscribe();
      } else {
        this.navigateToHomePage();
      }
    });
  }

  private navigateToHomePage() {
    this.router.navigateByUrl('/', {
      onSameUrlNavigation: 'reload',
      info: { overridePopups: true },
    });
  }
}
