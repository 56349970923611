<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button parkourBackButton />
    </ion-buttons>

    <ion-title aria-level="1" role="heading"
      >{{ 'doelen.doel-detail-page.title' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <parkour-page-layout>
    <ng-container *parkourPageContent="mainPageData$ | async; let doel">
      <article>
        <parkour-page-image
          [src]="themaMapping[doel.thema].largeImage.blur"
          classes="absolute top-0 left-0 z-0"
        />

        <div class="relative z-10 pt-4 pb-8 container container-space flex flex-col gap-y-8">
          <parkour-doel-details [contextType]="'teamlid'" [doel]="doel" [editable]="false" />

          <ng-container *parkourSectionContent="{ data: gesprekData$ | async }; let gesprekData">
            @if (gesprekData.user) {
              <parkour-reactie
                (reactieSelect)="onReactieSelect(doel.id, $event)"
                (reactieUnSelect)="onReactieUnSelect(doel.id, $event)"
                *parkourSectionContent="{ data: interacties$ | async }; let interacties"
                [reactieOverview]="interacties"
                [userProfielId]="gesprekData.user.profiel.id"
                class="reacties block container-1-col"
              />
            }

            @if (gesprekData.gesprek && gesprekData.user) {
              <parkour-doel-berichten
                [doelId]="doel.id"
                [gesprek]="gesprekData.gesprek"
                [user]="gesprekData.user"
                class="block container-1-col"
              />
            }
          </ng-container>
        </div>
      </article>
    </ng-container>
  </parkour-page-layout>
</ion-content>
