<ion-header></ion-header>

<ion-content>
  <ion-refresher [parkourRefresh]="onPageRefresh" slot="fixed">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <parkour-page-layout>
    <parkour-page-with-hero
      [heroImage]="verhaalHeroSources"
      [title]="'verhaal.overview-page.title' | translate"
    >
      <div class="container-1-col">
        <ng-container *ngIf="mainPageData$ | async as verhaal">
          @if (verhaal.success) {
            <p *ngIf="verhaal.value.items.length === 0" class="text-center mb-8">
              Je hebt nog geen gebeurtenissen in je verhaal.
            </p>
          }
        </ng-container>

        <parkour-button class="w-full flex" iconAfter="plus-circle" link="nieuw">
          Gebeurtenis toevoegen
        </parkour-button>

        <div class="mt-8">
          <ng-container
            *parkourSectionContent="
              { data: gebeurtenisSuggesties$ | async, hideSpinner: true };
              let suggesties
            "
          >
            <ul class="space-y-6">
              @for (suggestie of suggesties; track suggestie.id) {
                <li>
                  <parkour-gebeurtenis-voorstel
                    [gebeurtenisSuggestie]="suggestie"
                    (confirm)="onSuggestieConfirm(suggestie)"
                    (reject)="onSuggestieReject(suggestie)"
                  />
                </li>
              }
            </ul>
          </ng-container>
          <ng-container
            *parkourSectionContent="
              {
                data: mainPageData$ | async,
                errorDescription:
                  'We kunnen sommige gebeurtenissen nu niet weergeven, probeer later opnieuw.',
              };
              let verhaal
            "
          >
            <div data-testid="gebeurtenis-list">
              <parkour-gebeurtenis-error-card *ngIf="verhaal.error" class="block mt-4" />

              <ul *ngIf="verhaal.items.length > 0 || verhaal.error; else noVerhaal" class="mt-6">
                <li *ngFor="let item of verhaal.items; index as i">
                  <parkour-gebeurtenis-list-item
                    [isLastItem]="verhaal.items.length - 1 === i"
                    [ongelezenGebeurtenisMeldingen]="(ongelezenGebeurtenisMeldingen$ | async) ?? []"
                    [verhaalItem]="item"
                  />
                </li>
              </ul>

              <ng-template #noVerhaal>
                <parkour-block-placeholder
                  [sources]="{
                    default: {
                      src: '/assets/verhaal/placeholder/placeholder_verhaal_default.svg',
                    },
                    md: {
                      src: '/assets/verhaal/placeholder/placeholder_verhaal_md.svg',
                    },
                  }"
                  alt=""
                  class="block mt-8"
                />
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
    </parkour-page-with-hero>
  </parkour-page-layout>
</ion-content>
