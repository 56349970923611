import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MeldingIcon, SupportedMeldingView } from '../../model/meldingen';
import { ProfielFotoBadgeComponent } from '../../../shared/components/profiel-foto-badge/profiel-foto-badge.component';
import {
  IParkourAvatarBadgeImageProps,
  ParkourActionComponent,
  ParkourAvatarBadgeComponent,
  ParkourBadgeComponent,
  ParkourIconComponent,
} from '@parkour/ui';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  standalone: true,
  selector: 'parkour-melding',
  templateUrl: './parkour-melding.component.html',
  imports: [
    ProfielFotoBadgeComponent,
    ParkourAvatarBadgeComponent,
    ParkourBadgeComponent,
    ParkourActionComponent,
    TranslateModule,
    DatePipe,
    ParkourIconComponent,
  ],
})
export class ParkourMeldingComponent {
  @Input({ required: true }) public meldingView!: SupportedMeldingView;
  @Output() meldingClicked = new EventEmitter<void>();

  onMeldingClicked() {
    this.meldingClicked.emit();
  }

  getMeldingIcon(meldingIcon: MeldingIcon): IParkourAvatarBadgeImageProps {
    switch (meldingIcon) {
      case 'vlaanderen': {
        return {
          type: 'image',
          src: '/assets/meldingen/vlaanderen-icon.svg',
          alt: false,
        };
      }
      case 'kalender': {
        return {
          type: 'icon',
          name: 'calendar',
        };
      }
    }
  }
}
