import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  public log(...value: unknown[]): void {
    if (environment.logging === 'debug') {
      // eslint-disable-next-line no-console
      console.log(...value);
    }
  }

  public error(...value: unknown[]): void {
    // eslint-disable-next-line no-console
    console.error(...value);
  }

  public warn(value: unknown[]): void {
    // eslint-disable-next-line no-console
    console.warn(...value);
  }
}
