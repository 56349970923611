<parkour-page-image
  [src]="profiel | profielFoto: 'full' | async"
  classes="absolute top-0 left-0 z-0"
/>

<div class="relative z-10 py-8 container container-space">
  <div class="flex flex-col items-center gap-4 container-1-col">
    <parkour-profiel-foto [profiel]="profiel" [size]="'10'" />

    <!-- TODO a11y -->
    <h1>{{ profiel | profielnaam }}</h1>

    @if (mode.type === 'JONGERE' && profiel.status === 'ACTIEF') {
      <parkour-profiel-select
        (createPersoonlijkProfiel)="createPersoonlijkProfiel.emit()"
        (createProfessioneelProfiel)="createProfessioneelProfiel.emit()"
        (profielSelect)="profielSelect.emit($event)"
        [currentProfiel]="profiel"
        [externeHoedanigheid]="mode.externeHoedanigheid"
        [profielen]="mode.profielen"
      />
    } @else if (mode.type === 'TEAMLID') {
      @if (detailsEditForm) {
        <form (ngSubmit)="onDetailsEditSubmit()" class="w-full" id="teamlid-form">
          <parkour-form-field
            [inputFormControl]="detailsEditForm.controls.rol"
            [label]="'profiel.relatie' | translate"
            [required]="true"
            class="block"
            inputId="rol"
          >
            <parkour-select
              [formControl]="detailsEditForm.controls.rol"
              [options]="rolOptions"
              placeholder=""
              selectId="rol"
            >
            </parkour-select>
          </parkour-form-field>
        </form>
      } @else {
        @if (mode && mode.initialRol) {
          <p class="text-n-600" data-testid="rol">
            {{ mode.initialRol | rolLabel }}
          </p>
        }
      }
    }

    <parkour-divider />
  </div>

  @if (uitnodiging) {
    @switch (uitnodiging.status) {
      @case ('AANVAARD') {
        <parkour-card class="block mt-4" icon="check">
          <p class="font-medium">
            {{ 'team.uitnodiging.uitnodiging-teamlid-activeren-title' | translate }}
          </p>
          <p>{{ 'team.uitnodiging.uitnodiging-teamlid-activeren-description' | translate }}</p>

          <div class="flex items-center justify-between gap-4 mt-4">
            <parkour-button
              (clicked)="onVerwijderUitnodiging()"
              class="w-full"
              size="small"
              variant="tertiary"
            >
              {{ 'buttons.nee' | translate }}
            </parkour-button>
            <parkour-button
              (clicked)="onBevestigUitnodiging()"
              class="w-full"
              size="small"
              variant="secondary"
            >
              {{ 'buttons.ja' | translate }}
            </parkour-button>
          </div>
        </parkour-card>
      }
    }
  }

  <div class="flex flex-col gap-y-2 mt-8 container-1-col">
    @if (mode.type === 'JONGERE' && profiel.status === 'ACTIEF') {
      <parkour-profiel-qr-code-button [profiel]="profiel" />
    }

    @if (
      (profiel.afwezigheid.afwezig && mode.type === 'JONGERE') ||
      ((this.mode.type === 'TEAMLID' || this.mode.type === 'TEAMEIGENAAR') &&
        profiel.afwezigheid.afwezig &&
        checkIfActiveDate(profiel.afwezigheid.van, profiel.afwezigheid.tot))
    ) {
      <div class="bg-n-200 rounded p-4">
        <div class="mb-2 flex items-center gap-x-2">
          <parkour-icon class="text-n-600" name="clock-snooze" />

          <h2 class="typo-h5">{{ 'profiel.unavailable' | translate }}</h2>
        </div>

        <div class="space-y-1">
          @if (profiel.afwezigheid.van && profiel.afwezigheid.tot) {
            <span class="typo-body variant-body-fat"
              >{{
                'profiel.unavailable-time-from-to'
                  | translate
                    : {
                        from: profiel.afwezigheid.van | date: 'dd.MM.yyyy',
                        to: profiel.afwezigheid.tot | date: 'dd.MM.yyyy',
                      }
              }}
            </span>
          } @else {
            <span class="typo-body variant-body-fat"
              >{{
                'profiel.unavailable-time-from'
                  | translate
                    : {
                        from: profiel.afwezigheid.van | date: 'dd.MM.yyyy',
                      }
              }}
            </span>
          }

          @if (profiel.afwezigheid.bericht) {
            <p class="wysiwyg" [innerHTML]="profiel.afwezigheid.bericht | linkify"></p>
          }
        </div>
      </div>
    }

    <div class="bg-n-200 rounded p-4">
      <div class="mb-4 flex items-center gap-x-2">
        <parkour-icon class="text-n-600" name="people-3" />

        <h2 class="typo-h5">{{ 'profiel.title' | translate }}</h2>
      </div>

      <div class="divide-y-[1.5px] divide-n-300">
        @if (profiel.bio || profiel.links.length > 0) {
          <div class="pb-4 flex flex-col gap-y-4">
            @if (profiel.bio) {
              <p data-testid="bio">{{ profiel.bio }}</p>
            }

            <ul>
              @for (link of profiel.links; track $index) {
                <li>
                  <parkour-button
                    classes="!text-left truncate"
                    [href]="link | httpLink"
                    iconBefore="link"
                    variant="ghost-light"
                    data-testid="link"
                  >
                    {{ getVisualLink(link) }}
                  </parkour-button>
                </li>
              }
            </ul>
          </div>
        }

        @if (profiel.voornaam || profiel.adres) {
          <ul
            [ngClass]="{
              'pt-4': profiel.bio || profiel.links.length > 0,
            }"
            class="space-y-4"
          >
            @if (profiel.voornaam) {
              <li>
                <parkour-profile-details-list-item
                  [detail]="profiel.voornaam + ' ' + profiel.naam"
                  [label]="'profiel.volledige-naam' | translate"
                  data-testid="volledige-naam"
                />
              </li>
            }

            @if (profiel.adres) {
              <li>
                <parkour-profile-details-list-item
                  [detail]="profiel.adres"
                  [label]="'profiel.contactadres' | translate"
                  data-testid="contact-adres"
                />
              </li>
            }
          </ul>
        }
      </div>
    </div>

    @if (privateInfo && (privateInfo.email || privateInfo.telefoonnummer)) {
      <div class="bg-n-300 rounded p-4">
        <div class="mb-4 flex items-center gap-x-2">
          <parkour-icon class="text-n-600" name="eye-off" />

          <h2 class="typo-h5">{{ 'profiel.prive-informatie-titel' | translate }}</h2>
        </div>

        <ul class="space-y-4">
          @if (privateInfo.email) {
            <li>
              <parkour-profile-details-list-item
                [detail]="privateInfo.email"
                [label]="'profiel.emailadres' | translate"
                data-testid="emailadres"
              />
            </li>
          }

          @if (privateInfo.telefoonnummer) {
            <li>
              <parkour-profile-details-list-item
                [detail]="privateInfo.telefoonnummer"
                [label]="'profiel.telefoonnummer' | translate"
                data-testid="telefoonnummer"
              />
            </li>
          }
        </ul>
      </div>
    }
  </div>
</div>
