import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Doel } from '../../../doel-shared/model/doel';
import { DoelSuggestie } from '../../../../suggestie/model/suggestie';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ParkourBlockPlaceholderComponent,
  ParkourButtonComponent,
  ParkourLoadingSpinnerComponent,
  ParkourSegmentComponent,
} from '@parkour/ui';
import { doelHeroSources } from '../../../doel-shared/constants';
import { PageWithHeroComponent } from '../../../../shared/components/page-with-hero/page-with-hero.component';
import { AsyncPipe, NgForOf, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ContentUnavailableCardComponent } from '../../../../shared/components/content-unavailable-card/content-unavailable-card.component';
import { DoelListItemComponent } from '../../../doel-shared/component/doel-list-item/doel-list-item.component';
import { DoelVoorstelComponent } from '../../../doel-shared/component/doel-voorstel/doel-voorstel.component';
import { PageLayoutComponent } from '../../../../shared/components/page-layout/page-layout.component';
import { PageContentDirective } from '../../../../shared/directives/page-content.directive';
import { HasMeldingenForDoelPipe } from './has-meldingen-for-doel.pipe';
import { PageSectionDirective } from '../../../../shared/directives/page-section.directive';
import { DoelOverviewPage } from '../../../doel-overview-page';
import {
  IonContent,
  IonHeader,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/angular/standalone';
import { ParkourRefreshDirective } from '../../../../shared/directives/parkour-refresh-directive';
import { MarksMeldingenAsReadOnView } from '../../../../shared/marks-meldingen-as-read';
import { repeatOn } from '../../../../utils';
import { SuggestieService } from '../../../../suggestie/service/suggestie.service';

@Component({
  standalone: true,
  templateUrl: './doelen.page.html',
  imports: [
    PageWithHeroComponent,
    ParkourSegmentComponent,
    NgSwitch,
    NgSwitchCase,
    ParkourButtonComponent,
    ContentUnavailableCardComponent,
    NgIf,
    NgForOf,
    DoelListItemComponent,
    TranslateModule,
    DoelVoorstelComponent,
    ParkourLoadingSpinnerComponent,
    ParkourBlockPlaceholderComponent,
    AsyncPipe,
    PageLayoutComponent,
    PageContentDirective,
    HasMeldingenForDoelPipe,
    PageSectionDirective,
    IonRefresher,
    IonRefresherContent,
    ParkourRefreshDirective,
    IonContent,
    IonHeader,
  ],
})
export class DoelenPage extends DoelOverviewPage implements MarksMeldingenAsReadOnView {
  protected readonly doelHeroSources = doelHeroSources;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly suggestieService: SuggestieService,
  ) {
    super();
  }

  override getDoelSuggesties(): Observable<DoelSuggestie[]> {
    return this.suggestieService.getDoelSuggesties().pipe(repeatOn(this.refreshVoorstellen));
  }

  override getActieveDoelen(): Observable<Doel[]> {
    return this.doelenService.getActieveDoelen();
  }

  override getBehaaldeDoelen(): Observable<Doel[]> {
    return this.doelenService.getBehaaldeDoelen();
  }

  onVoorstelConfirm(voorstel: DoelSuggestie) {
    this.router.navigate(['add'], {
      queryParams: { suggestieId: voorstel.id },
      relativeTo: this.activatedRoute,
    });
  }

  onVoorstelReject(voorstel: DoelSuggestie) {
    this.suggestieService
      .rejectSuggestie(voorstel.id)
      .subscribe(() => this.refreshVoorstellen.next());
  }
}
