import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  combineLatest,
  map,
  mergeMap,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {
  AfwezigheidDto,
  ProfielId,
  TeamlidProfielDto,
  TeamlidUpdateDto,
  UserDto,
} from 'parkour-web-app-dto';
import { asType } from '../../utils';
import { Injectable } from '@angular/core';
import { ContextService } from '../../shared/services/context.service';
import { environment } from '../../../environments/environment';
import { TeamlidProfiel, TeamlidUpdate } from '../model/teamlid.model';
import { JongereProfiel } from '../../profiel/model/profiel';

type JongereProfielWithAnyStatus =
  | JongereProfiel
  | {
      readonly viewType: 'JONGERE';
      readonly status: string;
      readonly id: ProfielId;
      readonly voornaam?: string;
      readonly naam?: string;
      readonly afwezigheid: AfwezigheidDto;
    };

type TeamlidProfielWithAnyStatus =
  | TeamlidProfiel
  | {
      readonly viewType: 'TEAMLID';
      readonly status: string;
      readonly id: ProfielId;
      readonly voornaam?: string;
      readonly naam?: string;
      readonly afwezigheid: AfwezigheidDto;
    };

@Injectable({ providedIn: 'root' })
export class TeamService {
  private readonly cacheVersion$ = new BehaviorSubject(0);
  private readonly cachedTeamleden: Map<ProfielId, Observable<TeamlidProfiel[]>> = new Map();
  public readonly teamleden$: Observable<TeamlidProfiel[]> = combineLatest([
    this.contextService.context$,
    this.cacheVersion$,
  ]).pipe(
    switchMap(([context]) => {
      switch (context.type) {
        case 'anoniem':
          return of([]);
        case 'voor-mezelf':
          return of([]);
        case 'teamlid':
        case 'jongere':
          if (!this.cachedTeamleden.has(context.contextId)) {
            this.cachedTeamleden.set(
              context.contextId,
              this.http
                .get<
                  Array<TeamlidProfielDto>
                >(`${environment.API_BASE_URL}/api/jongere/${context.contextId}/team`)
                .pipe(shareReplay(1)),
            );
          }
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return this.cachedTeamleden.get(context.contextId)!;
      }
    }),
  );
  public readonly actieveTeamleden$ = this.teamleden$.pipe(
    map((teamleden) => teamleden.filter((teamlid) => !(teamlid.status === 'GEBLOKKEERD'))),
  );
  private readonly supportedJongereStatuses: { [key in JongereProfiel['status']]: boolean } = {
    ACTIEF: true,
    UITGENODIGDE_WEERGAVE: true,
    GEBLOKKEERDE_WEERGAVE: true,
    UNSUPPORTED: true,
  };
  private readonly supportedTeamlidStatuses: { [key in TeamlidProfiel['status']]: boolean } = {
    UITGENODIGD: true,
    UNSUPPORTED: true,
    GEBLOKKEERD: true,
    INACTIEF: true,
    ACTIEF: true,
  };

  constructor(
    private http: HttpClient,
    private contextService: ContextService,
  ) {}

  getMyTeamleden(): Observable<Array<TeamlidProfiel>> {
    return this.contextService.contextIdOfJongere$().pipe(
      mergeMap((context) => {
        return this.http.get<Array<TeamlidProfielDto>>(
          `${environment.API_BASE_URL}/api/jongere/${context}/team`,
        );
      }),
    );
  }

  getTeamlidInCurrentContext(teamlidId: ProfielId): Observable<TeamlidProfiel> {
    return this.teamleden$.pipe(
      take(1),
      mergeMap((teamleden) => {
        const result = teamleden.find((teamlid) => teamlid.id === teamlidId);
        if (!result) {
          return this.getUncachedTeamlidInCurrentContext(teamlidId);
        }
        return of(result);
      }),
    );
  }

  private getUncachedTeamlidInCurrentContext(profielId: ProfielId) {
    return this.contextService
      .contextIdOfJongere$()
      .pipe(
        mergeMap((contextId) =>
          this.http.get<TeamlidProfielDto>(
            `${environment.API_BASE_URL}/api/jongere/${contextId}/team/${profielId}`,
          ),
        ),
      );
  }

  public invalidateCache() {
    this.cachedTeamleden.clear();
    this.cacheVersion$.next(this.cacheVersion$.value + 1);
  }

  getTeamlidInTeamOf(teamlidId: ProfielId, contextId: ProfielId): Observable<TeamlidProfiel> {
    return this.http
      .get<TeamlidProfielWithAnyStatus>(
        `${environment.API_BASE_URL}/api/jongere/${contextId}/team/${teamlidId}`,
      )
      .pipe(map((profiel) => this.mapTeamlidProfielWithAnyStatusToTeamlidProfiel(profiel)));
  }

  removeTeamlid(jongereId: ProfielId, teamlidId: ProfielId) {
    return this.http
      .delete<UserDto>(`${environment.API_BASE_URL}/api/jongere/${jongereId}/team/${teamlidId}`)
      .pipe(tap(() => this.invalidateCache()));
  }

  activeerTeamlid(jongereId: ProfielId, teamlidId: ProfielId) {
    return this.http
      .put<void>(
        `${environment.API_BASE_URL}/api/jongere/${jongereId}/team/${teamlidId}/activeer`,
        {},
      )
      .pipe(tap(() => this.invalidateCache()));
  }

  blokkeerTeamlid(jongereId: ProfielId, teamlidId: ProfielId) {
    return this.http
      .put<void>(
        `${environment.API_BASE_URL}/api/jongere/${jongereId}/team/${teamlidId}/blokkeer`,
        {},
      )
      .pipe(tap(() => this.invalidateCache()));
  }

  updateTeamlid(jongereId: ProfielId, teamlidId: ProfielId, teamlidUpsert: TeamlidUpdate) {
    return this.http
      .put<void>(
        `${environment.API_BASE_URL}/api/jongere/${jongereId}/team/${teamlidId}`,
        asType<TeamlidUpdateDto>({ ...teamlidUpsert }),
      )
      .pipe(tap(() => this.invalidateCache()));
  }

  getJongereOrTeamlid(
    jongereId: ProfielId,
    profiel: ProfielId,
  ): Observable<JongereProfiel | TeamlidProfiel> {
    if (jongereId === profiel) {
      return this.getJongere(profiel);
    } else {
      return this.getTeamlidInTeamOf(profiel, jongereId);
    }
  }

  getJongere(jongereId: ProfielId): Observable<JongereProfiel> {
    return this.http
      .get<JongereProfielWithAnyStatus>(`${environment.API_BASE_URL}/api/jongere/${jongereId}`)
      .pipe(map((profiel) => this.mapJongereProfielWithAnyStatusToJongereProfiel(profiel)));
  }

  private isJongereProfielSupported(
    profiel: JongereProfielWithAnyStatus,
  ): profiel is JongereProfiel {
    return Object.keys(this.supportedJongereStatuses).includes(profiel.status);
  }

  private isTeamlidProfielSupported(
    profiel: TeamlidProfielWithAnyStatus,
  ): profiel is TeamlidProfiel {
    return Object.keys(this.supportedTeamlidStatuses).includes(profiel.status);
  }

  private mapJongereProfielWithAnyStatusToJongereProfiel(
    profiel: JongereProfielWithAnyStatus,
  ): JongereProfiel {
    if (this.isJongereProfielSupported(profiel)) {
      return profiel;
    } else {
      return {
        ...profiel,
        viewType: 'JONGERE',
        status: 'UNSUPPORTED',
      };
    }
  }

  private mapTeamlidProfielWithAnyStatusToTeamlidProfiel(
    profiel: TeamlidProfielWithAnyStatus,
  ): TeamlidProfiel {
    if (this.isTeamlidProfielSupported(profiel)) {
      return profiel;
    } else {
      return {
        ...profiel,
        viewType: 'TEAMLID',
        status: 'UNSUPPORTED',
      };
    }
  }
}
