<parkour-card
  [image]="context.jongereProfiel | profielFoto | async"
  aligned="center"
  class="block mt-6"
  content
  image="/assets/home/background.png"
  theme="highlight"
>
  <p class="typo-body variant-body-small text-n-1000">
    {{ context.rol | rolLabelDefault: 'Teamlid' }} van <br />
    <strong class="typo-body variant-body-fat"> {{ context.jongereProfiel | profielnaam }} </strong>
  </p>

  <div class="flex flex-col items-stretch gap-3" footer>
    <div class="relative w-full">
      <parkour-button
        [link]="['profiel', 'team']"
        class="w-full"
        iconAfter="people"
        size="small"
        variant="dark-transparent"
      >
        {{ 'team.wisselen.team-detail-button' | translate }}
      </parkour-button>
    </div>

    <div class="relative w-full">
      <parkour-new-indicator
        [show]="wisselTeamIndicator$ | async"
        class="[--notification-outline-color:theme('colors.highlight')]"
      />

      <parkour-button
        [isActive]="isTeamSwitchPopoverOpen"
        class="w-full"
        iconAfter="sort"
        id="sidebar-teamlid-team-{{ context.contextId }}"
        size="small"
        variant="dark-transparent"
      >
        {{ 'team.wisselen.teams-overview-button' | translate }}
      </parkour-button>
    </div>
  </div>
</parkour-card>

<parkour-sidebar-wissel-team-popover
  (hasDismissed)="isTeamSwitchPopoverOpen = false"
  (hasPresented)="isTeamSwitchPopoverOpen = true"
  [mijnContexten]="mijnContexten"
  [user]="user"
  class="block"
  triggerId="sidebar-teamlid-team-{{ context.contextId }}"
/>
